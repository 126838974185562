const es = {
	LEGALS_TITLE: "Legales",
	LEGALS_LINK1: "Aviso sobre Constitución",
	LEGALS_LINK2: "Términos y Condiciones de uso",
	LEGALS_LINK3: "Aviso de Privacidad",
	LEGALS_LINK4: "Leyendas de Riesgo",
	LEGALS_LINK5: "Educación Financiera",
	LEGALS_LINK6: "UNE",
	LEGALS_LINK7: "Buró de Entidades Financieras",
	LEGALS_LINK8: "CONDUSEF",
	LEGALS_LEYEND: "Betacredit, S.A. de C.V., SOFOM, E.N.R., en su constitución y operación no requiere de autorización de la Secretaría de Hacienda y Crédito Público y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores únicamente en materia de prevención y detección de Operaciones con Recursos de Procedencia Ilícita y Financiamiento al Terrorismo.",
	BODY_TEXT: "Comienza a disfrutar de nuestra flexibilidad",
	BODY_LOGIN: "Iniciar Sesión",
	PLATFORM: "Plataforma",
	FULL_NAME: "Nombre Completo",
	EMAIL: "Correo electrónico",
	PHONE: "Teléfono",
	CREATED_AT: "Creado",
	AMOUNT: "Monto",
	CLABE_ACCOUNT: "Cuenta Clabe",
	NEXT: "Siguiente",
	BACK: "Regresar",
	SKIP: "Saltar",
	FINISH: "Terminar",
	CLOSE: "Cerrar",
	HOTEL_NAME: "Nombre del Hotel",
	GENERAL_DATA_RL: "Datos Generales del Representante Legal",
	NAMES: "Nombre(s)",
	FIRST_NAME: "Apellido Paterno",
	SECOND_NAME: "Apellido Materno",
	CELL_PHONE: "Numero Celular",
	RFC: "RFC",
	STATUS: "Estado",
	FAIL_TO_UPLOAD_INFO: "Error al cargar información",
	DUE_DATE: "Fecha exigible",
	EXPECTED_SALES: "Ventas esperadas",
	ESTIMATED_PAYMENT: "Pago estimado",
	PAYMENTS: "Pagos",
	PAYMENTS_TABLE_TOOLTIP1: "Equivalente a tus ventas de <br> los últimos 12 meses",
	PAYMENTS_TABLE_TOOLTIP2: "Monto total a pagar cada mes <br> de acuerdo al % de retención <br> seleccionado incluye capital e <br> intereses",
	FINISHED_APPLICATION: "¡Has terminado con tu solicitud!",
	GRATITUDE_MESSAGE: "Estamos revisando tu solicitud, una vez aprobada, te haremos llegar un correo con los datos de tu desembolso.",
	GRATITUDE_MESSAGE_DOS: "Estamos revisando tu firma digital, una vez aprobada tu solicitud, te haremos llegar un correo con los datos de tu desembolso",
	AMAZON_UPLOAD_STATEMENT: "Estado de Cuenta Amazon. Sube el Screenshot del Paso 2",
	AMAZON_UPLOAD_STATEMENT_DESC: "(estadísticas de desempeño como vendedor y comprobación de desempeño)",
	AMAZON_UPLOAD_PAYMENTS: "Reporte de Pagos",
	AMAZON_UPLOAD_DESC: "(de la última fecha posible al día de hoy)",
	AMAZON_UPLOAD_SALES_CTRL_PANEL: "Panel de Control de Ventas",
	MAIN_BALANCE: "Saldo principal",
	DISBURSEMENTS: "Desembolsos",
	AMORTIZATION: "Amortización",
	CAPITALIZATION: "Capitalización",
	FINAL_BALANCE: "Saldo final",
	ACCRUED_INTEREST: "Interés debengado",
	PAYED_INTEREST: "Interés pagado",
	REMAINDER: "Remanente",
	SETTLEMENT_MONTH: "Mes de liquidación",
	PERIOD: "Período",
	AMORTIZATION_TABLE: "Tabla de amortización",
	CREDIT_SUMMARY: "Resumen de crédito",
	TERM: "Plazo",
	MONTH: "Mes",
	MONTHS: "Meses",
	RETENTION: "Retención",
	CHANGE_CLABE: "Cambia tu cuenta CLABE registrada",
	END_REQUEST: "Termina tu solicitud en nuestra App",
	SUMMARY: "Resumen",
	STEP_1_1: "Para finalizar tu registro y empezar el proceso de solicitud de crédito, es necesario que cambies la cuenta bancaria que tienes registrada en tu perfil de Amazon Seller Central.",
	STEP_1_2: "Tu cuenta bancaria asignada es",
	BANK: "Banco",
	STEP_1_3: "Por favor carga un pantallazo (screenshot) que muestre la nueva cuenta bancaria asignada a tu perfil",
	NEXT_STEPS: "Pasos a seguir",
	SEND: "Enviar",
	ALMOST_READY: "¡Estás a un paso de disponer de tu crédito Bontu!",
	STEP_2_1: "Para finalizar, solo necesitas bajar nuestra app e cualquier tienda digital, seleccionar tu contrato y firmarlo de manera digital, ¡es muy fácil!",
	FIND_OUT_APP_IN: "Encuentra nuestra app en",
	STEP_2_2: "Después de que descargues nuestra app, sigue las instrucciones que te enviamos vía email para que firmes de manera digital tu contrato de crédito.",
	THANKS_FOR_USING_BONTU: "¡Gracias por utilizar Bontu!",
	UNDERSTOOD: "Entendido",
	STEP_3_1: "Recibirás un correo electrónico con las instrucciones para la firma, o de lo contrario puedes insertar el siguiente código QR directamente en la aplicación de Bontu para completar este paso.",
	STEP_3_2: "Sigue las intrucciones en tu dispositivo móvil y completa el proceso de Firma Autógrafa Digital.",
	STEP_3_3: "¡Terminaste! Gracias por completar todos los pasos necesarios para obtener tu crédito. En breve te enviaremos un correo electrónico a la dirección que tenemos registrada para notificarte el resultado de tu solicitud. En caso de ser autorizada, recibirás los recursos en la cuenta bancaria que nos proporcionaste.",
	STEP_3_4: "Cualquier duda o aclaración, por favor búscanos a través de contacto@bontu.mx o en el teléfono 01-800-02-BONTU.",
	PERSONAL_INFORMATION: "Información personal",
	ADDITIONAL_INFORMATION: "Información adicional",
	CHANGE_BANK_ACCOUNT: "Cambio de Cuenta Bancaria",
	GENERAL_INFORMATION: "Información general",
	LEGAL_SHAREHOLDERS_DATA: "Datos del Representante Legal y Accionistas",
	LEGAL_REPRESENTATIVE: "Representante Legal",
	SHAREHOLDERS: "Accionistas",
	ADDRESS: "Dirección de domicilio",
	OTHERS: "Otros",
	INSTRUCTIVE: "Instructivo",
	STEP_1: "Paso 1",
	STEP_2: "Paso 2",
	STEP_3: "Paso 3",
	STEP_4: "Paso 4",
	STEP_5: "Paso 5",
	GENERAL_DATA: "Datos Generales",
	LEGAL_DOCUMENTS: "Documentación Legal",
	AMAZON_SELLER_DOCS: "Documentación de Amazon Seller Central",
	THANKS: "¡Muchas Gracias!",
	SELECT_AN_OPTION: "Escoge una opción de cada una",
	SHOW_OFFER_DETAIL: "Ver detalle de la oferta",
	OFFER_DETAIL: "Detalle de la oferta",
	ESTIMATED_PAYMENTS: 'Pagos estimados',
	INTEREST_BONTU_QUOTE: '*En Bontu, la tasa de interés no genera IVA',
	PREAUTHORIZED_LINE: 'Línea pre-autorizada',
	WITHHOLDING_SALES: 'Retención sobre ventas',
	MONTHLY_INTEREST: 'Tasa de interés mensual',
	OPENING_COMMISSION: 'Comisión por apertura',
	AMOUNT_RECEIVE: 'Monto a recibir',
	GREETINS: "Hola",
	CONFIRM_OFFER_DATA: "Verifica la siguiente información y si algún dato es incorrecto lo puedes modificar.",
	CONFIRM_OFFER_DATA_TERMS: "Confirmo que los datos son correctos.",
	CONFIRM_OFFER_DATA_EXPRESSION: "Excelente",
	CONFIRM_OFFER_DATA_NOTICE: "Hemos recibido tu información y a continuación recibirás un correo electrónico para continuar con tu proceso de solicitud de crédito desde la plataforma de Bontu.",
	CONFIRM_OFFER_DATA_SUCCESS: "La confirmación de los datos fue exitosa",
	CONFIRM_OFFER_DATA_THANKS: "Gracias por usar Bontu",
	CONFIRM_OFFER_DATA_CONFIRM: "Ya tenemos registrada tu información. Para continuar con tu proceso de solicitud de crédito, ingresa a la plataforma de Bontu.",
	CONFIRM_OFFER_DATA_EXPIRED: "Tu oferta ya está vencida",
	CONFIRM_OFFER_DATA_EXPIRED_MESSAGE: "Sigue usando XXXXXX y pronto podrás volver a recibir una oferta con Bontu",
	FAIL_TO_UPLOAD_DOCUMENTS: "Error al cargar documentos",
	UPLOAD_THE_FOLLOWING_DOCUMENTS: "Sube los siguientes documentos",
	INVALID_DOCUMENT: 'Documento no valido',
	DRAG_AND_DROP_YOUR_FILE_HERE: 'Arrastra y suelta tu archivo aqui ó',
	CHOOSE_YOUR_FILE: 'elige tu archivo',
	DOCUMENTATION: 'Documentación',
	FINALIZE: 'Finalizar',
	BEGIN: 'Empezar',
	JUMP: 'Omitir',
	CONTINUE: 'Continuar',
	REQUESTED_AMOUNT: 'Monto solicitado',
	SIGN_YOUR_REQUEST: 'Firma tu solicitud',
	YOU_ARE_ONE_STEP_AWAY: '¡Estás a un paso de terminar tu solicitud!',
	MESSAGE_TO_SIGN_ONLINE: "Bontu te ofrece la experiencia de firmar tu solicitud de crédito 100% en línea.",
	MESSAGE_TO_SIGN_ONLINE_TWO: 'Te pedimos que tengas a la mano tu dispositivo móvil para realizar este proceso desde nuestra herramienta de firma digital.',
	QR_CODE_ONE: 'Escanea el',
	QR_CODE_TWO: 'código QR',
	QR_CODE_THREE: 'que se encuentra en esta pantalla con tu',
	QR_CODE_FOUR: 'celular.',
	QR_CODE_FIVE: 'Haz clic en el botón',
	QR_CODE_SIX: 'Firmar contrato.',
	SIGN_INSTRUCTION_ONE: 'Para continuar con el proceso desde tu celular, utiliza tu navegador',
	SIGN_INSTRUCTION_TWO: 'Chrome',
	SIGN_INSTRUCTION_THREE: 'si usas Android o',
	SIGN_INSTRUCTION_FOUR: ' Safari',
	SIGN_INSTRUCTION_FIVE: 'si usas iPhone y sigue las instrucciones.',
	ESTIMATED_PAYMENT_TEXT: 'Con Bontu, pagarás tu crédito de acuerdo a un porcentaje de tus ventas. A continuación te mostramos el historial de tus ventas de los últimos 12 meses y una estimación de los pagos de tu crédito.',
	CREDIT_SUMMARY_TEXT: 'Estos son los detalles de tu oferta, pero puedes personalizar las condiciones modificando el monto, el plazo y la retención en el cotizador superior.',
	EXPECTED_SALES_TOOLTIP: 'Equivalente a tus ventas de los últimos 12 meses',
	ESTIMATED_PAYMENT_TOOLTIP: 'El monto total a pagar cada mes es estimado y va de acuerdo al % de retención seleccionado.',
	SHAREHOLDER: "Accionista",
	ADMINISTRATOR: "Administrador",
	UPDATE_PASSWORD: "Actualizar contraseña",
	PASSWORD_REQUIREMENTS: "Por razones de seguridad ingresa una nueva contraseña con los siguientes requerimientos:",
	CHARACTERS: "Entre 8 y 10 caracteres",
	UPPERCASE: "Una letra mayúscula",
	LOWERCASE: "Una letra minúscula",
	NUMBER: "Un número",
	ERROR_MESSAGE_RESOPNSE: "Se ha producido un error, póngase en contacto con el servicio de atención al cliente.",
	OFFER_NOT_EXIST: "Oferta no Existe",
	WORKING_CAPITAL: "Capital de trabajo",
	GOOD_FOR_YOUR_BUSINESS: "Bueno para tu negocio,",
	GOOD_FOR_YOU: "bueno para ti",
	CHOOSE_FILE: "Elige aquí tu archivo",
	ESTIMATED_MONTHLY_PAYMENT: "Pago estimado mensual",
	FORM: "Formulario",
	UPS: "Ups",
	ERROR_CLICKINK_ONE: "Hubo un error, ",
	ERROR_CLICKINK_TWO: "por favor inténtalo nuevamente.",
	RETRY: "Reintentar",
	OF: "de",
	SIGN_CONTRACT: "Firmar contrato",
	MESSAGE_CHECK: "Declaro bajo protesta de decir verdad que la actividad principal de mi negocio, cumple cabalmente con la legislación y regulación ambiental y social que le resulta aplicable al sector de la industria al que corresponde; así como que cuento con las licencias y permisos necesarios para el desarrollo de dicha actividad, mismos que son válidos y se encuentran vigentes.",
	MESSAGE_CHECK_TWO: "Así mismo declaro que he sido informado que BONTU se reserva el derecho, en cualquier momento durante el plazo de vigencia del contrato, de visitar mis instalaciones para evaluar mis actividades desde el punto de vista ambiental, social, de salud y seguridad ocupacional, así como solicitar cualquier tipo de evidencia, informe, documento, permiso o licencia, o llevar a cabo revisiones y/o auditorías para validar el cumplimiento de dicha regulación",
	MESSAGE_ERROR_REQUIRED: "El campo es requerido",
	MESSAGE_ERROR_INVALID: "La información ingresada es inválida",
	MESSAGE_ERROR_MIN_LENGTH: "Debe ser al menos ",
	MESSAGE_ERROR_MAX_LENGTH: "Como máximo pueden ser ",
	CHARACTER: " caracteres"
};

export default es;
