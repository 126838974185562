import React, { useEffect, useState } from "react";
import StepperGeneric from "../../../sharedComponents/Stepper/StepperGeneric";
import { makeStyles } from "@material-ui/core/styles";
import Documentos from "./documentos/documentos";
import DatosGenerales from "./datosGenerales/datosGenerales";
import Proyecciones from "./proyecciones/proyecciones";
import { getRquestData } from "../../../Services/request.service";
import ShowBackdrop from "../../../sharedComponents/backDrop";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { TermsAndConditions } from "../../termsAndConditions";

import DoneGif from "../../../../assets/images/backgrounds/done.gif";

export const RequestContext = React.createContext();

const useStyles = makeStyles((theme) => ({
	root: {
		height: "flex",
	},
	steppedComplete: {
		display: "flex"
	},
	details: {
		display: "flex",
		flexDirection: "column",
	},
	content: {
		flex: "1 0 auto",
	},
	cover: {
		width: 151,
	},
	controls: {
		display: "flex",
		alignItems: "center",
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));

export const Request = (props) => {
	const pathParamId = props.match.params.id;
	const classes = useStyles();

	const [requestData, setRequestData] = useState(null);

	const getSteps = (stepRef) => {
		return [
			{
				title: "Datos Generales", submit: "stepRef.current.sendValues", content:
					<RequestContext.Provider value={{ requestData }}>
						<DatosGenerales ref={stepRef} />;
</RequestContext.Provider >
			},
			{
				title: "Subir Documentación", submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<Documentos stepRef={stepRef} />
					</RequestContext.Provider >
			},
			{
				title: "Entrevista Personal", submit: "stepRef.current.handleFormSubmit", content:
					<RequestContext.Provider value={{ requestData }}>
						<TermsAndConditions stepRef={stepRef} />
					</RequestContext.Provider >
			},
			{
				title: "Proyecciones Financieras", submit: () => { }, content:
					<RequestContext.Provider value={{ requestData }}>
						<Proyecciones stepRef={stepRef} />
					</RequestContext.Provider >
			}];
	};

	const stepesCompleted =
		<div>
			<Card className={classes.steppedComplete}>
				<div className={classes.details}>
					<CardContent className={classes.content}>
						<Typography component="h5" variant="h5">
							¡Enhorabuena!
				</Typography>
						<Typography variant="subtitle1" color="textSecondary">
							Hemos recibido correctamente los datos, iniciamos el
				</Typography>
						<Typography variant="subtitle1" color="textSecondary">
							análisis de tu solicitud, pronto nos podremos en contacto
				</Typography>
					</CardContent>
				</div>
				<CardMedia
					className={classes.cover}
					image={DoneGif}
				/>
			</Card>

		</div>;

	useEffect(() => {
		if (pathParamId) {
			getRquestData(pathParamId).then((res) => {
				setRequestData(res);
			}
			).catch((error) => {
				console.error(error);
			});
		}
	}, [pathParamId]);

	const component = () => {
		if (!pathParamId) {
			return true;
		} else if (!requestData) {
			return false;
		} else {
			return true;
		}
	};

	return !component()
		? (
			<ShowBackdrop
				open={requestData ? false : true}>
			</ShowBackdrop>
		)
		: (
			<div className={classes.root}>
				<StepperGeneric
					getSteps={getSteps}
					aciveStep={pathParamId ? requestData.status.status : 0}
					stepesCompleted={stepesCompleted}
				/>
			</div>
		);
};