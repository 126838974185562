import React, { useLayoutEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLayout } from '../store/actions/app.actions';

const PublicRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(changeLayout({
            changeLayout:false,
        }));
    }, [dispatch, Component]);

    return (<Route {...rest} render={props => <Component {...props} />} />);
}

export default PublicRoute;