import { putPreviousStatus } from "../../app/Services/generalDataAmazon";

export default async function backButton(props) {

    let data = JSON.parse(localStorage.getItem("bontu_user"));
    let request_id = data.request.id
    let platform = data.platform.name

    var body_parameters = {
        "request_id": request_id,
        "platform": platform
    }

    await putPreviousStatus(body_parameters);
}