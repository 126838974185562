import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import amazonLogo from "../../../assets/images/logos/amazon.png";
import { useHistory } from "react-router-dom";
import { useUser } from "../../Context/UserContext";

const useStyles = makeStyles((theme) => ({
	backgroundImage: {
		backgroundImage: "url(//f.hubspotusercontent10.net/hubfs/6993919/raw_assets/public/Bontu%20Julio%202020/assets/static/images/home/factoraje.jpg)",
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: "100vh",
		padding: "0px 10%"
	},
	backgroundSquare: {
		backgroundColor: "#143947",
		color: "white",
		padding: "10%",
		textAlign: "left"
	},
	amazonButton: {
		background: "linear-gradient(180deg, rgba(245,220,153,1) 0%, rgba(236,190,64,1) 100%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "5px 30px",
		borderRadius: "50px",
		textTransform: "initial",
		fontWeight: "bold",
		border: "2px solid #ba942f",
		width: "200px",
		cursor: "pointer",
		marginTop: "20px"
	},
	oyoButton: {
		background: "linear-gradient(180deg, rgba(238,47,38,1) 0%, rgba(194,40,32,1) 100%);)",
		border: "2px solid #c22820",
		color: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "5px 25px",
		borderRadius: "50px",
		marginTop: "20px",
		textTransform: "initial",
		fontWeight: "bold",
		width: "200px",
		cursor: "pointer"
	},
	amazonImg: {
		height: "20px",
		marginRight: "10px",
		padding: "1px"
	}

}));

export default function Home() {
	const classes = useStyles();
	let history = useHistory();
	const { Auth } = useUser();
	const [isAmazon, setIsAmazon] = useState(false);

	const AmazonLogin = () => {
		Auth.federatedSignIn({ provider: "LoginWithAmazon" });
	};

	useEffect(() => {
		let _isAmazon = window.location.href.includes("amazon");
		setIsAmazon(_isAmazon);
	}, [history]);

	return (
		<div className={classes.backgroundImage}>
			<Grid container>
				<Grid item xs={12} md={6}>
					<div className={classes.backgroundSquare}>
						<Grid item xs={12}>
							<Typography variant="h4" align="left">Comienza a disfrutar de nuestra flexibilidad</Typography>
						</Grid>

						{isAmazon === true ?
							(<Grid item xs={12}>
								<Button onClick={() => AmazonLogin()} className={classes.amazonButton}><img className={classes.amazonImg} alt='AmazonButton' src={amazonLogo}></img> Iniciar Sesión</Button>
							</Grid>
							) :
							(< Grid item xs={12}>
								<Button onClick={() => history.push("/login")} className={classes.oyoButton}>Iniciar Sesión</Button>
							</Grid>
							)
						}
					</div>
				</Grid>
			</Grid>
		</div >
	);
}