import React from 'react';
import { Grid, Typography, Paper, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline",
        transform: 'scale(0.8)',

    },
    textColor: {
        color: "#5B6268",
        font: 'Roboto',
        fontSize: '50px',
        fontWeight: 'bold',
        marginRight: '5%',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            fontSize: '20px'
        }
    },
    textColor2: {
        color: "#5B6268",
        font: 'Roboto',
        fontSize: '45px',
        fontWeight: 'bold',
        marginRight: '5%',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '15px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            fontSize: '20px'
        }
    },
    greenText: {
        color: '#00C08B'
    },
    subText: {
        paddingTop: '5%',
        paddingBottom: '5%',
        paddingRight: '15px',
        paddingLeft: '15px',
            [theme.breakpoints.up('md')]: {
            width: '100%',
            fontSize: '4px'
        }
    },
    cartaTexto: {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        backgroundColor: "#FFFFFF",
        height: '160px'
    },
    imagen: {
        height: '239px',
        width: '358px',
        [theme.breakpoints.between('xs', 'sm',)]: {
            width: '100%',
        }
    },
    button: {
        margin: 'auto',
        fontSize: '8px',
        background: '#00C08B',
        color: '#ffff',
        paddingTop: '3%',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%'
        }
    },
    buttonText: {
        fontWeight: 'bold',
        [theme.breakpoints.up('lg')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '11px'
        }
    },
    paper: {
        padding: 'auto',
        margin: 'auto',
        Width: 1239,
        borderRadius: 10,
    },
    offerNumber: {
        backgroundColor: 'transparent',
        marginTop: '20px',
        marginLeft: '20px'
    },
    offerNumberContent: {
        height: '25px',
        width: '25px',
        display: 'inline-block',
        backgroundColor: '#00c08a',
        color: '#fff',
        borderRadius: '50%',
        textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'flex-start',
            paddingRight: '20px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '12px',
        },        
    }
}));


export default function RedirectButton(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Card className={classes.paper} elevation={0}>
                <Grid container>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ overflow: 'hidden', display: 'flex' }} data-test="image-premium">
                        <CardMedia
                            className={classes.imagen}
                            image={require("../../../assets/images/premium/image_premium_offer.png")}
                        >
                            <div className={classes.offerNumber}>
                                <div className={classes.offerNumberContent}>
                                    {4}
                                </div>
                            </div>
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <Grid container>
                            <Grid item xs={12} sm={7} md={8} lg={8} xl={8} className={classes.subText} data-test="text-premium">
                                <Typography gutterBottom variant="subtitle1" className={classes.textColor}>
                                    ¡Adquiere un crédito <span className={classes.greenText}>{"PREMIUM"}</span>!
                                </Typography>
                                <Typography className={classes.textColor2} >
                                    Eres elegible para recibir un crédito <span className={classes.greenText}> más grande </span>
                                    y a <span className={classes.greenText}> mayor plazo</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} lg={4} xl={4} className={classes.buttonContainer} data-test="button-premium">
                                <Button className={classes.button}>
                                    <Typography className={classes.buttonText}> ¡Quiero un crédito Premium!</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Card>
        </Paper>
    );
}