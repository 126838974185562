const en = {
	LEGALS_TITLE: "Legals",
	LEGALS_LINK1: "Incorporation notice",
	LEGALS_LINK2: "Terms and conditions of use",
	LEGALS_LINK3: "Notice of Privacy",
	LEGALS_LINK4: "Risk Legends",
	LEGALS_LINK5: "Financial education",
	LEGALS_LINK6: "UNE",
	LEGALS_LINK7: "Bureau of Financial Institutions",
	LEGALS_LINK8: "CONDUSEF",
	LEGALS_LEYEND: "Betacredit, S.A. de C.V., SOFOM, E.N.R., in its constitution and operation, it does not require authorization from the Ministry of Finance and Public Credit and is subject to the supervision of the National Banking and Securities Commission only in terms of prevention and detection of Operations with Resources of Illicit Origin and Financing of Terrorism.",
	BODY_TEXT: "Start enjoying our flexibility",
	BODY_LOGIN: "Log In",
	PLATFORM: "Platform",
	FULL_NAME: "Full Name",
	EMAIL: "Email",
	PHONE: "Phone",
	CREATED_AT: "Created At",
	AMOUNT: "Amount",
	CLABE_ACCOUNT: "Clabe Account",
	HOTEL_NAME: "Hotel Name",
	GENERAL_DATA_RL: "General Data of the Legal Representative",
	NAMES: "Names",
	FIRST_NAME: "First Name",
	SECOND_NAME: "Second Name",
	CELL_PHONE: "Cell Phone Number",
	RFC: "RFC",
	STATUS: "Status",
	FAIL_TO_UPLOAD_INFO: "Fail to upload information",
	DUE_DATE: "Due date",
	EXPECTED_SALES: "Expected sales",
	ESTIMATED_PAYMENT: "Estimated payment",
	PAYMENTS: "Payments",
	PAYMENTS_TABLE_TOOLTIP1: "Equivalent to your sales <br> in the last 12 months.",
	PAYMENTS_TABLE_TOOLTIP2: "Total monthly payment amount <br> according to the selected <br> withholding % includes <br> capital and interests.",
	FINISHED_APPLICATION: "¡You are done with your application!",
	GRATITUDE_MESSAGE: "We are reviewing your request, once approved, we will send you an email with the details of your disbursement.",
	GRATITUDE_MESSAGE_DOS	: "we're reviewing your digital signature, once your application is approved, we will send you an email with the details of your disbursement.",
	AMAZON_UPLOAD_STATEMENT: "Amazon Account Statement. Upload Screenshot from Step 2",
	AMAZON_UPLOAD_STATEMENT_DESC: "(seller performance statistics and proof of performance)",
	AMAZON_UPLOAD_PAYMENTS: "Payments Report",
	AMAZON_UPLOAD_DESC: "(from the oldest possible date until today)",
	AMAZON_UPLOAD_SALES_CTRL_PANEL: "Sales Control Panel",
	FINISH: "Finish",
	MAIN_BALANCE: "Saldo principal",
	DISBURSEMENTS: "Desembolsos",
	AMORTIZATION: "Amortización",
	CAPITALIZATION: "Capitalización",
	FINAL_BALANCE: "Saldo final",
	ACCRUED_INTEREST: "Interés debengado",
	PAYED_INTEREST: "Interés pagado",
	REMAINDER: "Remanente",
	SETTLEMENT_MONTH: "Mes de liquidación",
	PERIOD: "Período",
	AMORTIZATION_TABLE: "Amortization table",
	CREDIT_SUMMARY: "Credit Summary",
	TERM: "Term",
	MONTH: "Month",
	MONTHS: "Months",
	RETENTION: "Retention",
	CHANGE_CLABE: "Change your registered CLABE account",
	END_REQUEST: "Finish your request in our App",
	SUMMARY: "Summary",
	STEP_1_1: "To finalize your registration and start the credit application process, you need to change the bank account that you have registered in your Amazon Seller Central profile.",
	STEP_1_2: "Your assigned bank account is",
	BANK: "Bank",
	STEP_1_3: "Please upload a screenshot that shows the new bank account assigned to your profile",
	NEXT_STEPS: "Steps to follow",
	SEND: "Send",
	ALMOST_READY: "You are one step away from having your Bontu credit!",
	STEP_2_1: "To finish, you only need to download our app and any digital store, select your contract and sign it digitally, it is very easy!",
	FIND_OUT_APP_IN: "Find our app at",
	STEP_2_2: "After you download our app, follow the instructions that we send you via email to digitally sign your credit agreement.",
	THANKS_FOR_USING_BONTU: "Thanks for using Bontu!",
	UNDERSTOOD: "Understood",
	STEP_3_1: "You will receive an email with the instructions for signing, or else you can insert the following QR code directly in the Bontu application to complete this step.",
	STEP_3_2: "Follow the instructions on your mobile device and complete the Digital Autograph Signature process.",
	STEP_3_3: "You finished! Thank you for completing all the necessary steps to obtain your credit. We will send you an email shortly to the address we have on file to notify you of the result of your request. If authorized, you will receive the resources in the bank account you provided us.",
	STEP_3_4: "Any doubt or clarification, please look for us through contacto@bontu.mx or by phone 01-800-02-BONTU.",
	PERSONAL_INFORMATION: "Personal information",
	ADDITIONAL_INFORMATION: "Aditional information",
	CHANGE_BANK_ACCOUNT: "Change of Bank Account",
	GENERAL_INFORMATION: "General information",
	LEGAL_SHAREHOLDERS_DATA: "Data of the legal Representative and Shareholders",
	LEGAL_REPRESENTATIVE: "Legal Representative",
	SHAREHOLDERS: "Shareholders",
	ADDRESS: "Address",
	OTHERS: "Others",
	INSTRUCTIVE: "Instructive",
	STEP_1: "Step 1",
	STEP_2: "Step 2",
	STEP_3: "Step 3",
	STEP_4: "Step 4",
	STEP_5: "Step 5",
	GENERAL_DATA: "General Data",
	LEGAL_DOCUMENTS: "Legal Documents",
	AMAZON_SELLER_DOCS: "Amazon Seller Central Documents",
	THANKS: "¡Thanks!",
	SELECT_AN_OPTION: 'Select an option',
	SHOW_OFFER_DETAIL: "Show offer detail",
	OFFER_DETAIL: "Offer detail",
	ESTIMATED_PAYMENTS: 'Estimated payments',
	INTEREST_BONTU_QUOTE: '* In Bontu, the interest rate does not generate VAT',
	PREAUTHORIZED_LINE: 'Pre-authorized line of credit',
	WITHHOLDING_SALES: 'Withholding on sales',
	MONTHLY_INTEREST: 'Monthly interest rate',
	OPENING_COMMISSION: 'Opening commission',
	AMOUNT_RECEIVE: 'Amount to receive',
	GREETINS: "Hi",
	CONFIRM_OFFER_DATA: "Verify the following information and if any information is incorrect you can modify it.",
	CONFIRM_OFFER_DATA_TERMS: "I confirm that the data is correct.",
	CONFIRM_OFFER_DATA_EXPRESSION: "Excellent",
	CONFIRM_OFFER_DATA_NOTICE: "We have received your information and you will then receive an email to continue with your credit application process from the Bontu platform.",
	CONFIRM_OFFER_DATA_SUCCESS: "The data confirmation process was successful",
	CONFIRM_OFFER_DATA_THANKS: "Thanks for using Bontu",
	CONDIRM_OFFER_DATA_CONFIRM: "We already have your information registered. To continue with your credit application process, enter the Bontu platform.",
	CONFIRM_OFFER_DATA_EXPIRED: "Your offer is already expired",
	CONFIRM_OFFER_DATA_EXPIRED_MESSAGE: "Keep using XXXXXX and soon you will be able to receive an offer with Bontu again",
	FAIL_TO_UPLOAD_DOCUMENTS: "Fail to upload documents",
	UPLOAD_THE_FOLLOWING_DOCUMENTS: "Upload the following documents",
	INVALID_DOCUMENT: 'Invalid document',
	DRAG_AND_DROP_YOUR_FILE_HERE: 'Drag and drop your file here or',
	CHOOSE_YOUR_FILE: 'choose your file',
	DOCUMENTATION: 'Documentation',
	FINALIZE: 'Finalize',
	BEGIN: 'Begin',
	JUMP: 'Jump',
	CONTINUE: 'Continue',
	REQUESTED_AMOUNT: 'Requested amount',
	SIGN_YOUR_REQUEST: 'sign your request',
	YOU_ARE_ONE_STEP_AWAY: '¡you are one step away from getting your credit',
	MESSAGE_TO_SIGN_ONLINE: 'bontu gives you the experience to sign your credit request 100% online.',
	MESSAGE_TO_SIGN_ONLINE_TWO: 'we asked you to have your cellphone in your hand for doing  this process on our tool digital signing',
	QR_CODE_ONE: 'Scan the',
	QR_CODE_TWO: 'QR code',
	QR_CODE_THREE: 'on this screen with your',
	QR_CODE_FOUR: 'cellphone',
	QR_CODE_FIVE: 'click the button',
	QR_CODE_SIX: 'Sign contract.',
	SIGN_INSTRUCTION_ONE: 'To continue with the process from your cellphone, use your browser',
	SIGN_INSTRUCTION_TWO: 'Chrome',
	SIGN_INSTRUCTION_THREE: 'if you use Android',
	SIGN_INSTRUCTION_FOUR: ' Safari',
	SIGN_INSTRUCTION_FIVE: 'if you use iPhone and continue the instructions',
	ESTIMATED_PAYMENT_TEXT: 'With Bontu, you will pay your credit according to a percentage of your sales. Below we show you your sales history for the last 12 months and an estimate of your credit payments.',
	CREDIT_SUMMARY_TEXT: 'These are the details of your offer, but you can customize the conditions by modifying the amount, the term and the retention in the higher quote.',
	EXPECTED_SALES_TOOLTIP: 'Equivalent to your sales in the last 12 months',
	ESTIMATED_PAYMENT_TOOLTIP: 'The total amount to pay each month is estimated and is according to the percent of retention selected.',
	SHAREHOLDER: "Shareholder",
	ADMINISTRATOR: "Administrator",
	UPDATE_PASSWORD: "Update password",
	PASSWORD_REQUIREMENTS: "For security reasons enter a new password with the following requirements:",
	CHARACTERS: "Between 8 and 10 characters",
	UPPERCASE: "An uppercase letter",
	LOWERCASE: "A lowercase letter",
	NUMBER: "A number",
	ERROR_MESSAGE_RESOPNSE: "Error has been occurred please contact Customer Services",
	OFFER_NOT_EXIST: "Offer not Exist",
	WORKING_CAPITAL: "Working capital",
	GOOD_FOR_YOUR_BUSINESS: "Good for your business,",
	GOOD_FOR_YOU: "good for you",
	CHOOSE_FILE: "Choose your file here",
	ESTIMATED_MONTHLY_PAYMENT: "Estimated Monthly Payment",
	FORM: "Form",
	UPS: "Ups",
	ERROR_CLICKINK_ONE: "There was an error, ",
	ERROR_CLICKINK_TWO: "please try again.",
	RETRY: "Retry",
	OF: "of",
	SIGN_CONTRACT: "sign contract",
	MESSAGE_CHECK: "I declare under protest of saying the truth that the main activity of my business fully complies with the environmental and social legislation and regulation that is applicable to the industry sector to which it corresponds; as well as that I have the necessary licenses and permits for the development of said activity, which are valid and in force.",
	MESSAGE_CHECK_TWO: "I also declare that I have been informed that BONTU reserves the right, at any time during the term of the contract, to visit my facilities to evaluate my activities from an environmental, social, health and occupational safety point of view, as well as request any type of evidence, report, document, permit or license, or carry out reviews and / or audits to validate compliance with said regulation",
	MESSAGE_ERROR_REQUIRED: "The field is required",
	MESSAGE_ERROR_INVALID: "The information entered is invalid",
	MESSAGE_ERROR_MIN_LENGTH: "Must be at least ",
	MESSAGE_ERROR_MAX_LENGTH: "There can be a maximum of ",
	CHARACTER: " characters"
};

export default en;
