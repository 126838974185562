import React from 'react'
import { Grid, MenuItem, Card, CardContent, TextField, Typography } from '@material-ui/core'
import EventNoteIcon from '@material-ui/icons/EventNote';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const OfferDetailCards = ({ amount, retention_min, annualRatehandler, retention_current, months}) => {

    const { t } = useTranslation("UserApp");

    const useStyles = {
        cardStyle: {
            textAlign: 'center',
            borderRadius: '7px',
            Width: '397px',
            Height: '186px'
        },
        description: {
            fontWeight: 700,
            fontSize: '24px',
            color: '#00C08B',
            margin: '0%',
            marginTop: '8px'
        },
        subTxt: {
            fontSize: '14px',
            fontWeight: 500,
            color: '#979797',
            marginTop: '2px'

        },
        combo: {
            color: '#00C08B',
            height: '34px',
            marginTop: '8px',
            border: '2px solid #ECECEC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            fontSize: '50px',
            padding:5,
            justifyContent: 'center',
            alignItems: 'center'
        
        }
    };

    const handleChangeRetention = (event) => {
        var value = event.target.value;
        annualRatehandler(value)
    };

    const getArrayRetentions = () => {
        const output = [];
        let i = retention_min;
        while (Number(i).toFixed(2) <= retention_current) {
            output.push(Number(i).toFixed(2));
            i = i + 0.05;
        }
        return output;
    };

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    let formatter_num = new Intl.NumberFormat('en-US', {
    });

    return (
        <div style={{ margin: '15px 10px 15px 10px' }}>
            <Grid container spacing={4} justify="center">

                <Grid item xs={12} sm={4}>
                    <Card style={useStyles.cardStyle} >
                        <CardContent>
                            <AttachMoneyIcon style={{ fontSize: '40px', color: '#5B6268' }} />
                            <Typography style={useStyles.subTxt} data-test="amount" >{t('AMOUNT')}</Typography>
                            <Typography style={useStyles.description}>{formatter.format(amount)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Card style={useStyles.cardStyle}>
                        <CardContent>
                            <EventNoteIcon style={{ fontSize: '40px', color: '#5B6268' }} />
                            <Typography style={useStyles.subTxt} data-test="term">{t('TERM')}</Typography>
                            <div style={{ color: '#00C08B', fontSize: '24px', fontWeight: 'bold', marginTop: '8px' }}>{months} {t('MONTHS')}</div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Card style={useStyles.cardStyle}>
                        <CardContent>
                            <Typography style={{ fontSize: '32px', fontWeight: 'bold', color: '#5B6268' }}>%</Typography>
                            <Typography style={useStyles.subTxt} data-test="retention">{t('RETENTION')}</Typography>
                            <TextField
                                select
                                defaultValue={retention_current}
                                InputProps={{ disableUnderline: true }}
                                style={useStyles.combo}
                                onChange={(e) => handleChangeRetention(e)}
                            >
                                {
                                    getArrayRetentions().map(data => (
                                        <MenuItem key={data} value={data}><span style={{ color: '#00C08B', fontSize: '24px', fontWeight: 'bold' }}>{formatter_num.format(data * 100).replace(/\D00/, '')}%</span></MenuItem>
                                    ))
                                }
                            </TextField>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default OfferDetailCards;

OfferDetailCards.propTypes = {
    amount: PropTypes.number.isRequired,
    retention_min: PropTypes.number.isRequired
};