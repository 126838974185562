
import OfferSelect from "../../../assets/images/onboarding/OfferSelect.PNG"
import FilTheForm from "../../../assets/images/onboarding/FilTheForm.PNG"
import SignYourApplication from "../../../assets/images/onboarding/SignYourApplication.PNG"
import UploadYourDocumentation from "../../../assets/images/onboarding/UploadYourDocumentation.PNG"


const images = {
    OfferSelect: OfferSelect,
    FilTheForm: FilTheForm,
    SignYourApplication: SignYourApplication,
    UploadYourDocumentation: UploadYourDocumentation
};

export default images;