import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Typography, Grid, IconButton, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ShowSnackbar from "../../sharedComponents/snackBar";
import ShowBackdrop from "../../sharedComponents/backDrop";
import CustomFile from './CustomFile';
import Dropzone from './CustomDropzone';
import useClasses from './styles';
import { SendPrecargaImagen, SendCargaImagen, sendingDocuments } from "../../Services/uploadFiles.service";
import { useTranslation } from "react-i18next";

const UploadFileWithDropzone = forwardRef((props, ref) => {
    const classes = useClasses();
    const [documents, setDocuments] = useState([]);
    const [queueDocument, setQueueDocument] = useState([]);
    const [backDropState, setBackDropState] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [bodySnackBar, setBodySnackBar] = useState({
        message: "",
        severity: ""
    });
    const { t } = useTranslation("UserApp");

    const onDropAccepted = useCallback(async (data) => {
        const fileInformation = data.file[0];
        updateDocumentByIndex(
            data.index, {
            loading: true,
            fileName: fileInformation.name,
            size: fileInformation.size,
            error: null
        });
        let currentS3Key = await sendPreloadImage(documents[data.index], data.file[0], data.index);
        addQueueDocument({
            currentS3Key: currentS3Key,
            index: data.index
        });
    });

    const onDropRejected = async () => {
        setBodySnackBar({
            message: t('INVALID_DOCUMENT'),
            severity: "error",
        });
        setOpenSnackBar(true);
    };

    const onDeleteFile = useCallback(async (data) => {
        updateDocumentByIndex(data.index, { s3_key: null, loading: false });
    });

    const updateDocumentByIndex = (index, params) => {
        let newDocuments = [...documents];
        newDocuments[index] = {
            ...newDocuments[index],
            ...params
        }
        setDocuments(newDocuments);
    }

    const addQueueDocument = (params) => {
        let getQueueDocument = [...queueDocument];
        getQueueDocument.push(params)
        setQueueDocument(getQueueDocument);
    }

    const deleteQueueDocumentByIndex = (index) => {
        var array = [...queueDocument];
        array.splice(index, 1)
        setQueueDocument(array);
    }

    const sendPreloadImage = async (document, file, index) => {
        let body = {
            "user_platform": localStorage.getItem("user_platform"),
            "document": {
                "name": file.name,
                "type": document.ID
            }
        };
        try {
            const response = await SendPrecargaImagen(body);

            var formData = new FormData();
            formData.append("key", response.fields.key);
            formData.append("AWSAccessKeyId", response.fields.AWSAccessKeyId);
            formData.append("x-amz-security-token", response.fields["x-amz-security-token"]);
            formData.append("policy", response.fields.policy);
            formData.append("signature", response.fields.signature);
            formData.append("file", file, file.name);

            const responseSendImage =
                await SendCargaImagen(
                    `${response.url}`,
                    formData,
                    response.fields.AWSAccessKeyId,
                    response.fields["x-amz-security-token"]
                );
            if (responseSendImage === null) {
                updateDocumentByIndex(index, { error: true });
                return responseSendImage;
            }
            return response.fields.key;
        } catch (error) {
            updateDocumentByIndex(index, { error: true });
            console.log(error);
        }
    }

    useImperativeHandle(ref, () => ({
        submit: (event, handleNext) => {
            setBackDropState(!backDropState);

            let uploadedDocuments = []
            documents.map((element) => {
                if (!((element.subcode === 'ID-2') && (!element.custom_name))) {
                    uploadedDocuments.push({
                        "type": element.ID,
                        "name": element.custom_name,
                        "s3_key": element.s3_key
                    })
                }
            });

            const payload = {
                "documents": uploadedDocuments,
                "request_id": props.request_id,
                "state_machine": "smValidateSendingDocumentsProcess"
            };
            sendingDocuments(payload).then(async (response) => {
                setBodySnackBar({
                    message: response.message,
                    severity: "success",
                });
                setOpenSnackBar(true);
                setBackDropState(false);
                if (props.waitResponse) {
                    handleNext();
                    props.activeStephandler();
                }
            }
            ).catch((error) => {
                console.log(error);
                setBodySnackBar({
                    message: t('FAIL_TO_UPLOAD_DOCUMENTS'),
                    severity: "error",
                });
                setOpenSnackBar(true);
                setBackDropState(false);
            });
            if (!props.waitResponse) {
                handleNext();
                props.activeStephandler({ waitResponse: props.waitResponse });
            }
        }
    }));

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    useEffect(() => {
        setDocuments(props.docData.data.documents);
    }, [props.docData]);

    useEffect(() => {
        for (const current in queueDocument) {
            const index = Number(current);
            let currentElement = queueDocument[index];
            updateDocumentByIndex(
                currentElement.index, {
                s3_key: currentElement.currentS3Key,
                loading: false,
                custom_name: String(currentElement.currentS3Key).split('/')[2]
            });
            deleteQueueDocumentByIndex(index)
        }
    }, [queueDocument]);

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                    <div className={classes.subTitle} data-test='subtitle'>
                        {props.instructions !== '' ? props.instructions : t('UPLOAD_THE_FOLLOWING_DOCUMENTS')}
                    </div>
                    <br />
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                    <div className={classes.card}>
                        {
                            documents.length > 0 && documents.map((element, index) => {
                                return (
                                    <Grid container style={{ padding: 15 }} data-test="documentsList">
                                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} style={{ padding: 5 }} >
                                            <Typography variant="subtitle1" className={classes.documentText}>
                                                {element.name}
                                            </Typography>
                                            <Typography variant="subtitle2" className={classes.descriptionText}>
                                                {element.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
                                            {element.s3_key || element.loading ? (
                                                <CustomFile
                                                    classes={classes}
                                                    fileName={element.fileName}
                                                    size={element.size}
                                                />
                                            ) : (
                                                    <Dropzone
                                                        classes={classes}
                                                        index={index}
                                                        onDropAccepted={onDropAccepted}
                                                        onDropRejected={onDropRejected}
                                                    />
                                                )}
                                        </Grid>
                                        <Grid container xl={2} lg={2} md={2} sm={12} xs={12} direction="row" alignItems="center" justify="flex-end">
                                            {
                                                element.loading === true
                                                    ? (<CircularProgress size='30px' className={`${classes.margin10} ${classes.actionsColorSuccess}`} />)
                                                    : element.error === true
                                                        ? (<CheckCircleIcon fontSize="medium" className={`${classes.actionsColorError} ${classes.margin10}`} />)
                                                        : (typeof element.s3_key !== 'undefined' && element.s3_key !== null)
                                                            ? (<CheckCircleIcon fontSize="medium" className={`${classes.actionsColorSuccess} ${classes.margin10}`} />)
                                                            : (<CheckCircleIcon fontSize="medium" className={`${classes.margin10} ${classes.actionsColor}`} />)
                                            }
                                            <IconButton
                                                className={classes.actionsColor}
                                                aria-label="delete"
                                                onClick={(e) => { onDeleteFile({ index: index }) }}
                                                disabled={element.s3_key || element.loading ? false : true}  >
                                                <DeleteIcon fontSize="medium" className={classes.actionsColor} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </div>
                </Grid>
            </Grid>
            <ShowBackdrop open={backDropState || props.isLoading} data-test="backdrop" />
            <ShowSnackbar open={openSnackBar} data-test="snackbar" handleClose={handleClose} bodySnackBar={bodySnackBar} />
        </React.Fragment >
    );
});
export default UploadFileWithDropzone;