import React from "react";
import bontu from "../../assets/images/logos/bontu-blanco.png";
import "../../styles/index.css";

function SplashScreen() {
	return (
		<div id="splash-screen">
			<div className="center">
				<div className="logo">
					<img width="128" src={bontu} alt="logo" />
				</div>
				<div className="spinner-wrapper">
					<div className="spinner">
						<div className="inner">
							<div className="gap" />
							<div className="left">
								<div className="half-circle" />
							</div>
							<div className="right">
								<div className="half-circle" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(SplashScreen);
