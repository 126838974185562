import { actionTypes } from "../actions/user.actions";

const initialState = {
    userInfo: null,
    stateInfo: [],
    buttonTitle: null,
    actionButtonsDisable: false,
    valueRisk: [],
    shareholders:[]
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_GET_USERDATA:
            return {
                ...state,
                userInfo: action.payload
            };
        case actionTypes.USER_SAVEDATA:
            return {
                ...state,
                stateInfo: action.payload
            };
        case actionTypes.USER_BUTTON_TITLE:
            return {
                ...state,
                buttonTitle: action.payload
            };
        case actionTypes.USER_ACTION_BUTTONS_DISABLE:
            return {
                ...state,
                actionButtonsDisable: action.payload
            };
        case actionTypes.USER_SAVE_RISK:
            return {
                ...state,
                valueRisk: action.payload
            };
        case actionTypes.USER_SHARE_HOLDERS:
            return {
                ...state,
                shareholders: action.payload
            };
        default:
            return { ...state };
    }
}

export default user