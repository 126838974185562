import React, { useState } from "react";
import {
	Button,
	TextField,
	Grid,
	Paper,
	Typography
} from "@material-ui/core";
import { useUser } from "../../Context/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";


const useStyles = makeStyles(() => ({
	backgroundImage: {
		backgroundImage: "url(//f.hubspotusercontent10.net/hubfs/6993919/raw_assets/public/Bontu%20Julio%202020/assets/static/images/home/factoraje.jpg)",
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: "100vh"
	},
	loginForm: {
		justifyContent: "center",
		minHeight: "100vh"
	},
	buttonBlock: {
		width: "100%",
		backgroundColor: "#00C08B",
		color: "#fff"
	},
	loginBackground:
{
	justifyContent: "center",
	padding: "50px"
}
}));

export const ResetPassword = () => {
	const classes = useStyles();
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [codeSent, setCodeSent] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [, setIsConfirming] = useState(false);
	const [, setIsSendingCode] = useState(false);
	const { Auth } = useUser();

	const validateCodeForm = () => {
		return email.length > 0;
	};

	const validateResetForm = () => {
		return (
			code.length > 0 &&
password.length > 0 &&
password === confirmPassword
		);
	};

	const handleSendCodeClick = (event) => {
		event.preventDefault();

		setIsSendingCode(true);

		Auth.forgotPassword(email)
			.then(() => setCodeSent(true))
			.catch(() => setIsSendingCode(false));
	};

	const handleConfirmClick = (event) => {
		event.preventDefault();

		setIsConfirming(true);

		Auth.forgotPasswordSubmit(email, code, password).then(() => setConfirmed(true))
			.catch(() => setIsConfirming(false));
	};

	const renderRequestCodeForm = () => {
		return <form onSubmit={(event) => handleSendCodeClick(event)}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<TextField
						type="email"
						placeholder="Email"
						fullWidth
						name="username"
						variant="outlined"
						required
						autoFocus
						onChange={e => setEmail(e.target.value)}
						value={email}
					/>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						className={classes.buttonBlock}
						disabled={!validateCodeForm()}
					>
Recuperar Contraseña
					</Button>
				</Grid>
			</Grid>
		</form>;
	};
	const renderConfirmationForm = () => {
		return <form onSubmit={(event) => handleConfirmClick(event)}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<TextField
						type="tel"
						placeholder="Confirmation Code"
						fullWidth
						name="code"
						variant="outlined"
						required
						autoFocus
						onChange={e => setCode(e.target.value)}
						value={code}
					/>
				</Grid>
				<Grid item>
					<TextField
						type="password"
						placeholder="Password"
						fullWidth
						name="password"
						variant="outlined"
						required
						autoFocus
						onChange={e => setPassword(e.target.value)}
						value={password}
					/>
				</Grid>
				<Grid item>
					<TextField
						type="password"
						placeholder="ConfirmPassword"
						fullWidth
						name="confirmPassword"
						variant="outlined"
						required
						autoFocus
						onChange={e => setConfirmPassword(e.target.value)}
						value={confirmPassword}
					/>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						type="submit"
						className={classes.buttonBlock}
						disabled={!validateResetForm()}
					>
Confirmar
					</Button>
				</Grid>
			</Grid>
		</form>;
	};
	return (
		<div className={classes.backgroundImage}>
			<Grid container spacing={0} justify="center" direction="row">
				<Grid item>
					<Grid
						container
						direction="column"
						justify="center"
						spacing={2}
						className={classes.loginForm}
					>
						<Paper
							variant="elevation"
							elevation={2}
							className={classes.loginBackground}
						>
							<Grid item>
								<Typography component="h1" variant="h5">
Recuperar Contraseña
								</Typography>
							</Grid>
							<Grid item>
								{!codeSent
									? renderRequestCodeForm()
									: [!confirmed
										? renderConfirmationForm()
										: <Redirect to="/login" />]
								}
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};