import Amplify, { API, Auth } from "aws-amplify";
import environment from "../../environments/environment";

Amplify.configure(environment.cognitoConfig);

export async function getBontu(path, headers = {}, queryParams = null, noAuth = false) {
	const apiName = "ApiBontu";

	const data = {
		headers: {
			"Content-Type": "application/json",
			...headers
		},
		queryStringParameters: queryParams
	};

	if (!noAuth) {
		data['headers']["x-api-key"] = environment.public_key;
		data['headers']['Authorization'] = (await Auth.currentSession()).getIdToken().getJwtToken();
	}

	let response = API.get(apiName, path, data);
	return response;
}

export async function postBontu(path, headers, body, noAuth = false, multipart = false) {
	const apiName = "ApiBontu";
	const data = {
		headers: {
			...headers
		},
		body: body
	};

	if (!noAuth) {
		data['headers']["x-api-key"] = environment.public_key;
		data['headers']['Authorization'] = (await Auth.currentSession()).getIdToken().getJwtToken();
	}

	if (multipart)
		data['headers']["Content-Type"] = "multipart/form-data";
	else
		data['headers']["Content-Type"] = "application/json";

	return API.post(apiName, path, data);
}

export async function putBontu(path, headers, body) {
	const apiName = "ApiBontu";
	const data = {
		headers: {
			"x-api-key": environment.public_key,
			"Content-Type": "application/json",
			Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
			...headers
		},
		body: body
	};
	return API.put(apiName, path, data);
}

export async function deleteBontu(path, headers, body) {
	const apiName = "ApiBontu";
	const data = {
		headers: {
			"x-api-key": environment.public_key,
			"Content-Type": "application/json",
			Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
			...headers
		},
		body: body
	};
	return API.delete(apiName, path, data);
}