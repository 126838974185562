import React, { useEffect, useCallback} from "react";
import { Grid, Typography,makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch,useSelector } from "react-redux";
import { configActionsButtons } from "../../../../store/actions/app.actions";
import gratitudeMessage from '../../../../assets/images/gratitude/gratitudeMessage.png'

const useStyles = makeStyles((theme) => ({
    headline: {
        textAlign: 'center',
        fontSize: 18,
        marginTop: 0,
        width: "100%"
    },
    backgroundSquare: {
        background: "#eeeeee",
        padding: "25px"
    },
    center: {
        textAlign: "center"
    },
    backgroundImage: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100px",
        padding: "100px"
    },
    button: {
        background: '#00c08a',
        color: '#fff',
        width: '40%',
        textTransform: 'none',
        '&:hover': {
            background: '#00c08a',
        }
    },
    typografy:{
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '350px',
            height: '56px',
            color: '#003B4A',
            fontWeight: 'bold',
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            width: '600px',
            height: '40px',
            color: '#003B4A',
            fontWeight: 'bold',
            fontSize: 20,
        }
    },
    messageChange:{
        [theme.breakpoints.between('xs', 'sm')]: {
            width:'328px', 
            top:'529px',
            color:'#003B4A',
            fontSize: 16,
            fontWeight: 'bold'
        },
        [theme.breakpoints.up('md')]: {
            width:'540px', 
            color:'#003B4A'
        }
    }
}));


export default function Gratitude({titlehandler}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    const _userData = useSelector((state) => state.user.userInfo);
    
    const changeMessage = useCallback((event => {
        const newMessage = (_userData.status.status);
        var message;
        if(newMessage === 'Revisión de documentos' ){
            message = (t("GRATITUDE_MESSAGE"))
        }else {
           message =  (t("GRATITUDE_MESSAGE_DOS"))
        }       
        return message
    }),[_userData.status.status, t])

    useEffect(() => {
        dispatch(configActionsButtons({
            backButton: { show: false, disable: false },
            nextButton: { show: false, disable: false }
        }));
        titlehandler(null);
    }, [dispatch,titlehandler]);

    return (
        <div >
            <Grid container className={classes.center}>
                <Grid item xs={12} >
                    <div>
                        <div Align='center' style={{ marginBottom: '10%' }}>
                            <p>
                                <b data-test="middleText">
                                    <Typography className={classes.typografy} >
                                        {t("FINISHED_APPLICATION")}
                                    </Typography>
                                </b>
                            </p>
                        </div>
                        <div Align='center' margin-bottom='20px' style={{ marginBottom: '5%' }}>
                            <p data-test="gratitudeText">
                                <Typography className={classes.messageChange}>
                                    {changeMessage()}
                                </Typography>
                            </p>
                        </div>
                        <br />
                        <div Align='center' style={{ marginBottom: '75px' }}>
                            <img alt="" src={gratitudeMessage} />
                        </div  >
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}