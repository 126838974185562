import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    wh100: {
        width: '100%',
        height: '100%'
    },
    margin10: {
        margin: '10px 0px 8px 30px'
    },
    actionsColor: {
        color: '#5B6268'
    },
    iconColor: {
        color: '#C4C4C4'
    },
    actionsColorSuccess: {
        color: '#00C08B'
    },
    actionsColorError: {
        color: 'red'
    },
    documentText: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#5B6268',
    },
    descriptionText: {
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        color: '#5B6268',
        opacity: '0.6',
    },
    dropzone: {
        background: '#FFFFFF',
        border: '1px dashed #CCD2D7',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '16px',
        width: '100%',
        height: '100%',
        minHeight: '100px'
    },
    subTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '24px 16px',
        width: '90%',
        marginLeft: '25px',
        background: '#EAEDEF',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
          },
    },
    card: {
        alignItems: 'center',
        padding: '24px 16px',
        width: '90%',
        marginLeft: '25px',
        background: '#FFFFFF',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            marginBottom:'50px'
          },
    }
}));

export default styles;