import React, {forwardRef, useImperativeHandle, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "../../../assets/images/logos/error.png";
import DoneIcon from "../../../assets/images/logos/done.png";
import {SendPrecargaImagen, SendCargaImagen, sendKeyToStore} from "../../Services/uploadFiles.service";
import ShowSnackbar from "../../sharedComponents/snackBar";
import ShowBackdrop from "../../sharedComponents/backDrop";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: "none",
    },
}));

const UploadFiles = forwardRef((props, ref) => {
    const classes = useStyles();
    const [documents, setDocuments] = React.useState([]);
    //const [typePerson, ] = React.useState(props.docData.data.typePerson);
    const [backDropState, setBackDropState] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [bodySnackBar, setBodySnackBar] = React.useState({
        message: "",
        severity: ""
    });
    const [formPayload, setFormPayloadData] = React.useState([]);

    useImperativeHandle(ref, () => ({
        
       
        submit: (event, handleNext) => {
            console.log(ref);
            console.log("useImperativeHandle");
            setBackDropState(!backDropState);

            const payload = {
                "tipo_persona": localStorage.getItem("bontu_userTypePerson"),
                "user_platform": localStorage.getItem("user_platform"),
                "documents": formPayload
            };
            console.log(payload);

            sendKeyToStore(payload).then(async(response) => {
                    setBackDropState(backDropState);
                    setBodySnackBar({
                        message: response.message,
                        severity: "success",
                    });
                    setOpenSnackBar(true);
                    handleNext();
                    props.activeStephandler()
                }
            ).catch((error) => {
                console.log(error);
                setBackDropState(backDropState);
                setBodySnackBar({
                    message: "No fue posible guardar los documentos",
                    severity: "error",
                });
                setOpenSnackBar(true);
            });
        }

    }));

    const handleClose = (event, reason) => {
        console.log("handleClose");
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackBar(false);
    };

    useEffect(() => {
        setDocuments(props.docData.data.documents);
    }, [props.docData.data.documents]);

    const handleUploadChange = async (e) => {
        console.log("handleUploadChange");
        let typeID = e.target.id;
        console.log(typeID);
        var prog = document.getElementById(`${typeID}-Progress`);
        var err = document.getElementById(`${typeID}-Error`);
        var don = document.getElementById(`${typeID}-Done`);

        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = async () => {

            let body = {
                "user_platform": localStorage.getItem("user_platform"),
                "document": {
                    "name": file.name,
                    "type": typeID
                }
            };
            console.log(body);

            await UploadFile(body, err, don, prog, file, typeID);

        };

        reader.onerror = () => {
            console.log("error");
        };
    };

    const UploadFile = async (body, errorID, doneID, progress, file, typeID) => {
        console.log("UploadFile");
        console.log(body);
        console.log(errorID);
        console.log(doneID);
        console.log(progress);
        console.log(file);
        console.log(typeID);
        var formData = new FormData();
        var bodyResultL;
        var token, apiKey;
        HideOrShow(progress);
        await SendPrecargaImagen(body).then((response) => {
            console.log("SendPrecargaImagen");
            formData.append("key", response.fields.key);
            formData.append("AWSAccessKeyId", response.fields.AWSAccessKeyId);
            formData.append("x-amz-security-token", response.fields["x-amz-security-token"]);
            formData.append("policy", response.fields.policy);
            formData.append("signature", response.fields.signature);
            formData.append("file", file, file.name);
            bodyResultL = response;

            token = response.fields["x-amz-security-token"];
            apiKey = response.fields.AWSAccessKeyId;


        }).catch((error) => {
            HideOrShow(progress);
            HideOrShow(errorID);
            console.log("Error al subir el documento");
        });

        await SendCargaImagen(`${bodyResultL.url}`, formData, apiKey, token).then((response) => {
            console.log("SendCargaImagen");
            setFormPayloadData([...formPayload, {
                ...{
                    "type": typeID,
                    "name": file.name,
                    "s3_key": bodyResultL.fields.key
                }
            }]);

            HideOrShow(progress);
            HideOrShow(doneID);

        }).catch((error) => {
            HideOrShow(progress);
            HideOrShow(errorID);
            console.log("Error al subir el documento");
        });

    };


    const HideOrShow = (item) => {
        item.style.display === "none" ? item.style.display = "block" : item.style.display = "none";
    };

    return (
        <div className={classes.root} data-test='contest' style={{visibility: 'visible'}}>
            <div>
                <Typography gutterBottom variant="h5" component="h2" data-test="title">
                    Suba los siguientes documentos
                </Typography>
            </div>
            <br/>
            <div className={classes.root}>
                <div>
                    {
                        documents && (
                            <div>
                                <Grid container spacing={6} data-test="grid-render">
                                    <List component="nav" aria-label="secondary" style={{width: "200%"}}>
                                        {
                                            documents.map(row => (
                                                <div>
                                                    <input
                                                        accept="image/*"
                                                        className={classes.input}
                                                        id={row.ID}
                                                        multiple
                                                        type="file"
                                                        onChange={(e) => handleUploadChange(e)}
                                                    />
                                                    <label htmlFor={row.ID}>
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <InsertDriveFileIcon/>
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={row.name}
                                                                secondary={row.description}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" color="primary" component="span">
                                                                    <CloudUploadIcon/>
                                                                    <div>
                                                                        {
                                                                            <div style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                marginTop: "8px",
                                                                                marginLeft: "8px"
                                                                            }}>

                                                                                <div id={`${row.ID}-Progress`}
                                                                                     style={{display: "none"}}>
                                                                                    <CircularProgress size={25}/>
                                                                                </div>

                                                                                <div id={`${row.ID}-Error`}
                                                                                     style={{display: "none"}}>
                                                                                    <img src={ErrorIcon} alt=""
                                                                                         width="25" height="25"/>
                                                                                </div>

                                                                                <div id={`${row.ID}-Done`}
                                                                                     style={{display: "none"}}>
                                                                                    <img src={DoneIcon} alt=""
                                                                                         width="25" height="25"/>
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </List>
                                </Grid>
                            </div>
                        )
                    }
                </div>
            </div>
            <br/>
            <ShowBackdrop open={backDropState} data-test="backdrop"/>
            <ShowSnackbar open={openSnackBar} data-test="snackbar" handleClose={handleClose} bodySnackBar={bodySnackBar}/>
        </div>
    );
});


export default UploadFiles;