import React, { useState, useEffect } from "react";
import {
	Button,
	TextField,
	Grid,
	Paper,
	Typography,
	Container,
	IconButton,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../../auth/cognito/UserPool";
import SplashScreen from "../../sharedComponents/SplashScreen";
import { useTranslation } from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';

import Logo from "./../../../assets/images/logos/bontu.png"

const useStyles = makeStyles((theme) => ({
	backgroundImage: {
		backgroundImage: "url(//f.hubspotusercontent10.net/hubfs/6993919/raw_assets/public/Bontu%20Julio%202020/assets/static/images/home/factoraje.jpg)",
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: "100vh",
		display: "flex",
		flexDirection: "center",
		alignItems: "center",
		padding: "10px"
	},

	errorMessage: {
		color: "red",
		marginBottom: "10px",
		marginTop: "10px"
	},
	imageCenterGrid: {
		display: "flex",
		justifyContent: "center"
	},
	containerTextGrid: {
		backgroundColor: "#DADADA",
		borderRadius: 5
	},
	textColorBontu: {
		color: "#003B4A",
		textAlign: "center",
	},
	titleProduct: {
	    color: "#003B4A",
		textAlign: "center",
		fontWeight: "lighter",
		[theme.breakpoints.down('xs')]: {
          fontSize: "4.5vw",
        }
	},
	container:{
		overflowY: "auto",
		maxHeight: "95vh"
	},
	colorGreen: {
        color: "#00c08a",
        textDecoration: "none",
    },
    relative: {
        position: 'relative',
    }
}));

function Login() {
	const classes = useStyles();
    const { t } = useTranslation("UserApp");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)

	const [newPassword, setNewPassword] = useState("");
	const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
	const [confirmNewpassword, setConfirmNewpassword] = useState("");
	const [isConfirmedPasswordVisible, setIsconfirmedPasswordVisible] = useState(false)
	const [newpasswordRequired, setNewpasswordRequired] = useState(false);
	const history = useHistory();
	const { onLogin, user, error, setError, loading } = useUser();

	const initialValidationState = [
		{ name: t("CHARACTERS"), check: false, validation: RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{8,10}$/) },
		{ name: t("UPPERCASE"), check: false, validation: RegExp(/^(.*?)*[A-Z]{1,}(.*?)*$/) },
		{ name: t("LOWERCASE"), check: false, validation: RegExp(/^(.*?)[a-z]{1,}(.*?)$/) },
		{ name: t("NUMBER"), check: false, validation: RegExp(/^(.*?)[0-9]{1,}(.*?)$/) },
	]

	const [ validations, setValidation ] = useState(initialValidationState)

	useEffect(() => {
		if (user && user.platform.name === "oyo") {
			history.push("/records");
		} else if (user) {
			history.push("/request");
		}
	}, [history, user]);

	const cognitoLogin = (event) => {
		event.preventDefault();
		setError(null);

            if (( newPassword === confirmNewpassword )){
                const cognitoUser = new CognitoUser({
                    Username: email,
                    Pool: UserPool
                });

                const authDetails = new AuthenticationDetails({
                    Username: email,
                    Password: password
                });

                cognitoUser.authenticateUser(authDetails, {
                    onSuccess: () => {
                        onLogin();
                    },

                    onFailure: err => {
                        setError(err.message);
                    },

                    newPasswordRequired: (userAttributes, requiredAttributes) => {
                        setError(null);
                        setNewpasswordRequired(true);

                        newPassword && confirmNewpassword && cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                            onSuccess: () => {
                                onLogin();
                            },
                            onFailure: err => {
                                setError(err.message);
                            }
                        });
                    }
                });
            } else {
                setError( "Las contraseñas no coinciden, confirme las contraseñas" )
            }

	};

	const validate = ( value ) => {
		let isTrue = true
		initialValidationState.forEach( ( state, index ) => {
			const check = state.validation.test( value )
			if ( !check ) {
				isTrue = false
			}
		} )
		if (newPassword === confirmNewpassword){
		    return isTrue
		}
	}

	const handleChangePasswordChage = ( event ) => {
		const { value } = event.target
		if (String(value).length > 10) return true;
		setNewPassword( value )
		const tempValidations = [ ...validations ]
		initialValidationState.forEach( ( state, index ) => {
			const check = state.validation.test( value )
			tempValidations[index] = { ...state, check }
		} )
		setValidation( tempValidations )
	}

	const handleChangeVisible = ( component ) => {
		switch( component ) {
			case "password": { setIsPasswordVisible( !isPasswordVisible ); break }
			case "newwPassword": { setIsNewPasswordVisible( !isNewPasswordVisible ); break }
			case "confirmedPassword": { setIsconfirmedPasswordVisible( !isConfirmedPasswordVisible ); break }
			default: { setIsconfirmedPasswordVisible( !isConfirmedPasswordVisible ); break }
			// Also in default because there is no other option
		}
	}

	return (
		<div className={classes.backgroundImage}>
			{
				loading ? <SplashScreen /> : (
					<Container maxWidth = "xs" component = { Paper } variant = "elevation" elevation = { 2 } className = { classes.container } >
						<form style = { { padding: "1em" } } onSubmit = { cognitoLogin } >
							<Grid container spacing = { 3 } >
								<Grid item sm = { 12 } xs = { 12 } className = { classes.imageCenterGrid } >
									<img src = { Logo } alt = "logo bontu" />
								</Grid>
								<Grid item sm = { 12 } xs = { 12 } className = { classes.imageCenterGrid } >
									<Typography variant = "h5" className = { classes.titleProduct } > Adelanto de Capital de Trabajo </Typography>
								</Grid>
								{
									newpasswordRequired && 
										<React.Fragment>
											<Grid item xs = { 12 } >
												<Typography variant = "h5" className = { classes.textColorBontu } > <strong>{t("UPDATE_PASSWORD")}</strong> </Typography>
											</Grid>
											<Grid item xs = { 12 } className = { classes.containerTextGrid } >
												<Typography> {t("PASSWORD_REQUIREMENTS")} </Typography>
											</Grid>
											<Grid item xs = { 12 } >
												{
													validations.map( ( validation, key ) =>
														<Typography 
															style = { { alignItems: "center", display: "flex" } } 
															key = { key }
														>
															{ validation.check ? <CheckIcon className = { classes.colorGreen } /> : <RemoveIcon /> } { validation.name }
														</Typography>
													)
												}
											</Grid>

										</React.Fragment>
								}
								{
									!newpasswordRequired &&
										<Grid item xs = { 12 } >
											<TextField
												variant = "outlined"
												type = "email"
												label = "Usuario"
												value = { email }
												onChange={e => setEmail(e.target.value)}
												autoFocus
												required
												fullWidth
											/>
										</Grid>
								}
								{
									newpasswordRequired ? 
										<React.Fragment>
											<Grid item xs = { 12 }>
												<TextField
													variant = "outlined"
													type = { isNewPasswordVisible ? "text" : "password" }
													label = "Nueva contraseña"
													value = { newPassword }
													InputProps = { {
														endAdornment: <IconButton onClick = { () => handleChangeVisible( "newwPassword" ) } > 
															{ isNewPasswordVisible ?  <VisibilityOffIcon /> : <VisibilityIcon /> }
														</IconButton>
													} }
													onChange = { handleChangePasswordChage }
													required
													fullWidth
												/>
											</Grid>
											<Grid item xs = { 12 }>
												<TextField
													variant = "outlined"
													type = { isConfirmedPasswordVisible ? "text" : "password" }
													label = "Confirmar nueva contraseña"
													value = { confirmNewpassword }
													InputProps = { {
														endAdornment: <IconButton onClick = { () => handleChangeVisible( "confirmedPassword" ) } > 
															{ isConfirmedPasswordVisible ?  <VisibilityOffIcon /> : <VisibilityIcon /> }
														</IconButton>
													} }
													onChange={e => setConfirmNewpassword(e.target.value)}
													required
													fullWidth
												/>
											</Grid>

										</React.Fragment> 
									: <React.Fragment>
										<Grid item xs = { 12 }>
											<TextField
												variant = "outlined"
												type = { isPasswordVisible ? "text" : "password" }
												placeholder = "Contraseña"
												value = { password }
												InputProps = { {
													endAdornment: <IconButton onClick = { () => handleChangeVisible( "password" ) } > 
														{ isPasswordVisible ?  <VisibilityOffIcon /> : <VisibilityIcon /> }
													</IconButton>
												} }
												onChange={e => setPassword(e.target.value)}
												required
												fullWidth
											/>

										</Grid>
										<Grid item xs = { 12 } >
										    <Typography component = { Link } to="/resetPassword" style = { { color: "#00C08B", marginTop: "20px" } } >Olvidé mi contraseña</Typography>
										</Grid>
									</React.Fragment>
								}
								<Grid item xs = { 12 } >
									{
										error &&
											<Typography className={classes.errorMessage} > { error } </Typography>
									}
								</Grid>
								<Grid item xs = { 12 } >
									<Button 
										type = "submit" 
										variant = "contained" 
										color = "primary" 
										disabled = { 
											newpasswordRequired  ? !validate( newPassword ) : false
										}
										fullWidth 
										style = { { backgroundColor: "#00C08B" } } 
									>
										{ newpasswordRequired ? "Cambiar" : "Iniciar sesión" }
									</Button>
								</Grid>
							</Grid>
						</form>
					</Container>
				)
			}
		</div>
	);
}
export default Login;