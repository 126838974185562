import React from "react";
import { UserProvider } from "./app/Context/UserContext";
import ReactDOM from "react-dom";
import "./i18n";
import "./styles/index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./store/reducers";

let createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
let store = createStoreWithMiddleware(reducers);

ReactDOM.render(
	<Provider store={store}>
		<UserProvider>
			<App />
		</UserProvider>
	</Provider>
	,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
