import React, {forwardRef, useImperativeHandle, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { Container, FormControl, InputLabel, Grid, Typography, OutlinedInput, makeStyles } from "@material-ui/core";
import  {saveClientFormData} from "../../../../Services/generalData";
import ShowSnackbar from "../../../../sharedComponents/snackBar";
import ShowBackdrop from "../../../../sharedComponents/backDrop";
import { RequestContext } from "../request";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		display:"flex",
		alignContent: "center"
	},
	margin: {
		margin: theme.spacing(1),
	},
	container: {
		width: "80%",
		padding: "1rem 0.75rem 2rem",
		radius: "4px",
		margin: "1.5rem auto",
		font: "1.5"
	},
	typography: {
		textAlign: "center",
	},
	center: {
		display: "flex", 
		justifyContent: "center", 
		alignItems: "center", 
		marginTop: "8px"
	}
}));

function TextMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
		/>
	);
}

TextMaskCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	values: PropTypes.func.isRequired
};


const DatosGenerales = forwardRef((prop , ref) => {
	const classes = useStyles();
	const { t } = useTranslation("UserApp");
	const [backDropState, setBackDropState] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [bodySnackBar, setBodySnackBar] = useState({
		message:"",
		severity:""
	});
	let history = useHistory();
	
	const context = useContext(RequestContext);
	useImperativeHandle(ref, () => ({
		submit: (event, handleNext) => {
			setBackDropState(!backDropState);
			saveClientFormData(values).then((response) => {
				setBackDropState(backDropState);
				setBodySnackBar({
					message: response.message,
					severity:"success",
				});
				setOpenSnackBar(true);
				localStorage.setItem("user_platform", response.user_platform);
				history.push(`/oyoRequest/${response.request_id}`);
			}
			).catch((error) => {
				setBackDropState(backDropState);
				setBodySnackBar({
					message: t("FAIL_TO_UPLOAD_INFO"),
					severity:"error",
				});
				setOpenSnackBar(true);
			});
		}

	}));

	const [values, setValues] = useState({
		hotelName: "",
		nameRL: "",
		firstNameRL: "",
		secondNameRL: "",
		cellphoneNumber: "",
		emailRL: "",
		rfc: ""
	});

	const handleChange = (property) => (event) => {
		setValues({...values, [property]: event.target.value,
		});
	};

	useEffect(
		() => {
			setValues({
				hotelName: context.requestData ? context.requestData.company.business_name: "",
				nameRL: context.requestData ? context.requestData.client.names: "",
				firstNameRL: context.requestData ? context.requestData.client.first_name: "",
				secondNameRL: context.requestData ? context.requestData.client.second_name: "",
				cellphoneNumber: context.requestData ? context.requestData.client.telephone: "",
				emailRL: context.requestData ? context.requestData.client.email: "",
				rfc: context.requestData ? context.requestData.client.rfc: ""
			});
		}, [context.requestData]
	);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnackBar(false);
	};

	return (
		<div className={classes.root}>
			<Container className={classes.container}>
				<Grid container>
					<Grid item md={12}>
						<Typography className={classes.typography} gutterBottom variant="h5" component="h2">
							{t("HOTEL_NAME")}
						</Typography>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="hotelName"></InputLabel>
							<OutlinedInput
								type="text"
								value={values.hotelName}
								onChange={handleChange("hotelName")}
								name="hotel"
								id="hotelName"
								required={true}
							/>
						</FormControl>
						<hr />
					</Grid>
					<Grid item md={12}>
						<Typography className={classes.typography} gutterBottom variant="h5" component="h2">
							{t("GENERAL_DATA_RL")}
						</Typography>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="nameRL"> {t("NAMES")} </InputLabel>
							<OutlinedInput
								type="text"
								value={values.nameRL}
								onChange={handleChange("nameRL")}
								name="name"
								id="nameRL"
								labelWidth={80}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="firstNameRL"> {t("FIRST_NAME")} </InputLabel>
							<OutlinedInput
								type="text"
								value={values.firstNameRL}
								onChange={handleChange("firstNameRL")}
								name="firstName"
								id="firstNameRL"
								labelWidth={120}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="secondNameRL"> {t("SECOND_NAME")} </InputLabel>
							<OutlinedInput
								type="text"
								value={values.secondNameRL}
								onChange={handleChange("secondNameRL")}
								name="secondName"
								id="secondNameRL"
								labelWidth={120}
							/>
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="cellphoneNumber"> {t("CELL_PHONE")} </InputLabel>
							<OutlinedInput
								value={values.cellphoneNumber}
								onChange={handleChange("cellphoneNumber")}
								name="cellphone"
								id="cellphoneNumber"
								inputComponent={TextMaskCustom}
								labelWidth={120}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="emailRL"> {t("EMAIL")} </InputLabel>
							<OutlinedInput
								type="email"
								value={values.emailRL}
								onChange={handleChange("emailRL")}
								name="email"
								id="emailRL"
								labelWidth={140}
								required={true}
							/>
						</FormControl>
					</Grid>
					<Grid item md={12}>
						<FormControl fullWidth className={classes.margin} variant="outlined">
							<InputLabel htmlFor="rfc"> {t("RFC")} </InputLabel>
							<OutlinedInput
								type="text"
								value={values.rfc}
								onChange={handleChange("rfc")}
								name="rfc"
								id="rfc"
								labelWidth={30}
								required={true}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<ShowBackdrop open={backDropState}/>
				<ShowSnackbar open={openSnackBar} handleClose={handleClose} bodySnackBar={bodySnackBar}/>
			</Container>
		</div>
	);

});

export default DatosGenerales;