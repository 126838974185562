import React, { useEffect, useState } from "react";
import { getRequiredDocs } from "../../Services/generalDataAmazon";
import { getClientDocument } from "../../Services/clientService";
import ShowBackdrop from "../../sharedComponents/backDrop";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userData } from "../../../store/actions/user.actions";

const configurationScreen = {
    "dataColection": ["Pendiente de Información", "Pendiente de Archivos", "Revisión de documentos", "Revisiones especiales","Proceso de firma"],
    "offerCustom": ["Oferta Activa"],
    "amazonStatusCredit": ["Cambio de Cuenta CLABE", "Generando Contrato"],
    "revitions": ["Revisión de Elegibilidad", "Revisión de PLD"]
}

export default function Request(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [screen, setScreen] = useState(0);
    const user = useSelector((state) => state.user.userInfo);

    useEffect(() => {
        async function fetchData() {
            if (user) {
                const requiredDocuments = await getRequiredDocs(user.platform.id);
                const screenInformation = await getScreenInformation(user, requiredDocuments.requiredDocs[0]);
                dispatch(userData(screenInformation.data));
                localStorage.setItem("user_platform", user.client.user_platform);
                localStorage.setItem("bontu_userTypePerson", user.client.regime);
                setOpen(false);
                setScreen(screenInformation.screen);
            }
        }
        fetchData();
    }, [user, dispatch]);

    return (
        screen === 1 ? <Redirect to={{ pathname: '/request/data-collection' }} /> :
        screen === 2 ? <Redirect to={{ pathname: '/request/customize-offer' }} /> :
        screen === 3 ? <Redirect to={{ pathname: '/request/credit-resume' }} /> :
        <ShowBackdrop open={open} />
    );
}

const getScreenInformation = async (_userData, documentsRequired) => {

    let new_data = _userData;
    let screen = getCurrentScreen(_userData.status.status);
    let isRemedy = Boolean(_userData.status_work_flow.is_remedy);

    if (isRemedy) {
        let dataRemedy = await getClientDocument(3);
        let newDocumentRequired = getDocumentToRemedy(documentsRequired, dataRemedy, _userData.client.regime);
        screen = 1;
        new_data.requiredDocs = newDocumentRequired.requiredDocs;
        new_data.status_work_flow.document_step = newDocumentRequired.newCurrentStep;
    } else {
        new_data.status_work_flow.current_status = (screen === -1 ? 4 : new_data.status_work_flow.current_status);
        screen = (screen === -1 ? 1 : screen);
        new_data.requiredDocs = documentsRequired;
    }
    return { data: new_data, screen: screen };
}

const getCurrentScreen = (status) => {
    let currentScrean = 0;
    if (configurationScreen.amazonStatusCredit.includes(status)) {
        currentScrean = 3;
    }
    else if (configurationScreen.offerCustom.includes(status)) {
        currentScrean = 2;
    } else if (configurationScreen.dataColection.includes(status)) {
        currentScrean = 1;
    } else if (configurationScreen.revitions.includes(status)) {
        currentScrean = -1;
    }
    return currentScrean;
}

const getDocumentToRemedy = (documents, dataRemedy, personType) => {

    let documentByPersonType = (personType === "PF" ? documents[1].pf : documents[1].pm);

    const getMatchDocumentRemedy = matchDocumentRemedy(
        dataRemedy,
        documentByPersonType,
        documents[2],
        documents[3],
        documents[5]
    );

    documents[2] = getMatchDocumentRemedy.storageDocument2;
    documents[3] = getMatchDocumentRemedy.storageDocument3;
    documents[5] = getMatchDocumentRemedy.storageDocument5;

    if (personType === "PF") {
        documents[1].pf = getMatchDocumentRemedy.storageDocumentByPersonType;
        documents[1].pm = [];

    } else {
        documents[1].pf = [];
        documents[1].pm = getMatchDocumentRemedy.storageDocumentByPersonType;
    }
    return { requiredDocs: documents, newCurrentStep: getMatchDocumentRemedy.minStep }
}

const matchDocumentRemedy = (dataRemedy = [], documentByPersonType = [], document2 = [], document3 = [], document5 = []) => {
    let successFind = false;
    let mDR = {
        step: [],
        storageDocumentByPersonType: [],
        storageDocument2: [],
        storageDocument3: [],
        storageDocument5: [],
        minStep: 0
    }

    let objectData = {
        storageDocumentByPersonType: documentByPersonType,
        storageDocument2: document2,
        storageDocument3: document3,
        storageDocument5: document5
    }

    for (let index = 0; index < dataRemedy.length; index++) {
        let findTypeDocument = dataRemedy[index];
        successFind = false;

        for (let element in objectData) {
            if (successFind) {
                break;
            } else {
                const dataElement = objectData[element];
                for (let indexChildren = 0; indexChildren < dataElement.length; indexChildren++) {
                    if (dataElement[indexChildren].code === findTypeDocument.type) {
                        mDR[element].push(dataElement[indexChildren]);
                        mDR.step.push(dataElement[indexChildren].step);
                        successFind = true;
                        break;
                    }
                }
            }
        }
    }

    mDR.minStep = Math.min.apply(null, mDR.step);
    return mDR;
}