import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import environment from "../../../../environments/environment"
import {CircularProgress, Backdrop, Grid, Collapse, Card, 
    Divider, Typography, TextField, FormGroup, FormControlLabel, 
    Checkbox, Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import BontuLogo from '../../../../assets/images/logos/bontu.svg'
import axios from 'axios';
import BontuLaptop from '../../../../assets/images/backgrounds/laptop.svg'
import BontuChronometer from '../../../../assets/images/backgrounds/chronometer.svg'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(4),
        width: '100%',
        borderRadius: '12px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        background: 'rgba(255, 255, 255, 0.4)',
    },
    logos: {
        width: '100px',
        maxHeight: 30
    },
    greeting: {
        color: '#5B6268',
        fontSize: '20px',
        lineHeight: '22px',
        fontWeight: '500'
    },
    input: {
        width: '100%',
        margin: '10px 0'
    },
    buttonActive: {
        background: '#00C08B',
        color: 'white',
        width: '290px',
        marginTop: 20,
        padding: '5px 0',
        textTransform: 'Capitalize',
        fontSize: 16,
        '&:hover': {
            background: 'rgba(0, 170, 106)',
        },
        [theme.breakpoints.between('xs', 'sm')]:{
            width: '90%',
            borderRadius: '10px',
        }
    },
    zeroMargin: {
        margin: 0,
        lineHeight: '1.6rem',
        color: '#5B6268',
        fontSize: '12px',
        fontFamily: 'Roboto',
        width: '150%',
        [theme.breakpoints.between('xs', 'sm')]:
        {
            width: '100%',
            fontSize: '12px',
            fontFamily: 'Roboto',
            marginRight: '0px',
        },
    },
    colorGreen: {
        align: 'center',
        color: '#00C08B',
    },
    textCheckBox: {
        textAlign: 'left',
        color: 'rgba(91, 98, 104, 1)',
        fontSize: '12px',
        [theme.breakpoints.between('xs', 'sm')]:
        {
            fontSize: '12px',
            fontFamily: 'Roboto',
            width: '210px'
        }
    }
}));

const BontuCheckbox = withStyles({
    root: {
        color: '#00C08B',
        '&$checked': {
            color: '#00C08B',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ConfirmData = () => {
    const classes = useStyles();
    const routeParams = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [platformData, setPlatformData] = useState({});
    const [offerAvailable, setOfferAvailable] = useState(true);
    const [alreadyAssigned, setAlreadyAssigned] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [notExist, setNotExist] = useState(false);
    const { t } = useTranslation("UserApp");

    const validateOfferStatus = useCallback((token) => {
        const url = environment.cognitoConfig.API.endpoints[0].endpoint;
        axios.get(`${url}/offers/pre-approved-offers/${token}`)
            .then((response) => {
                if (response.status === 200) {
                    setPlatformData(response.data.platform);
                    setOfferAvailable(true);
                } else if (response.status === 204) {
                    setNotExist(true);
                    setOfferAvailable(false);
                }
            }).catch((error) => {
                if (error.response.data.message === "Offer has been expired") {
                    setIsExpired(true);
                    setOfferAvailable(false);
                } else if (error.response.data.message === "Offer already accepted") {
                    setOfferAvailable(false);
                    setAlreadyAssigned(true);
                } else {
                    setAlertSeverity('error');
                    setAlertMessage(t("ERROR_MESSAGE_RESOPNSE"));
                    setOpenAlert(true);
                }
            })
    },[t])

    useEffect(() => {
        validateOfferStatus(routeParams.token);
        setIsLoading(false);
    }, [routeParams,validateOfferStatus]);

    const submit = (data) => {
        setIsLoading(true);
         const url = environment.cognitoConfig.API.endpoints[0].endpoint;
         axios.post(`${url}/offers/pre-approved-offers/${data.token}`, data)
             .then(() => {
                 setAlertSeverity('success');
                 setAlertMessage(t('CONFIRM_OFFER_DATA_SUCCESS'));
                 setIsLoading(false);
                 setOfferAvailable(false);
                 setOpenAlert(true);
             })
             .catch(() => {
                 setAlertSeverity('error');
                 setAlertMessage(t("ERROR_MESSAGE_RESOPNSE"));
                 setIsLoading(false);
                 setOpenAlert(true);
             });
    }

    return (
        <>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" style={{ color: 'rgba(0, 162, 106)' }} />
            </Backdrop>
            <Grid container direction="row" justify="center" style={{ background: '#F5F6F7'}}>
                <Grid container item xs={12} sm={8} md={8} lg={6} spacing={0}>
                    <Card className={classes.root}>
                        <Grid container direction="row" justify="center" >
                            <Grid container item xs={5} justify="flex-end">
                                <div style={{ paddingTop: 0 }} dangerouslySetInnerHTML={{ __html: platformData.logo }}></div>
                            </Grid>
                            <Grid container item xs={1} justify="center" alignContent="center">
                                <Divider light orientation="vertical" style={{ height: '70%' }} />
                            </Grid>
                            <Grid container item xs={5} justify="flex-start" style={{ margin: '19px 0 15px 0' }} >
                                <img src={BontuLogo} alt="" className={classes.logos} />
                            </Grid>
                        </Grid>
                        <Divider light style={{ height: 3 }} />
                        {offerAvailable ? <UserData saveData={submit} t={t} classes={classes} routeParams={routeParams} openAlert={openAlert} alertSeverity={alertSeverity} alertMessage={alertMessage}/> : <ClientFeedback t={t} notExist={notExist} alreadyAssigned={alreadyAssigned} platformData={platformData}/>}
                        {isExpired && <Expired t={t}/>}
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

const UserData = (props) => {
    const {saveData, t, classes, routeParams, openAlert, alertSeverity, alertMessage} = props;
    const [clientAcceptance, setClientAcceptance] = useState(false);
    const [privacityAccept, setPrivacityAccept] = useState(false)
    const [activeAcept, setActiveAcept] = useState(true)
    const [dataClient, setDataClient] = useState(routeParams);
    const [errorMessage, setErrorMessage] = useState({
        full_name: "",
        rfc: "",
        email: "",
        clabe: "",
        telephone:""
    })

    useEffect(() => { 
        !checkIfError(errorMessage) && clientAcceptance && privacityAccept ? setActiveAcept(false) : setActiveAcept(true)
    },[errorMessage])
    
    const validation = (dataValidation) => {    
        if(dataValidation.value === ""){
            setErrorMessage({...errorMessage, [dataValidation.id]: (t("MESSAGE_ERROR_REQUIRED"))});
        }else{
            let pattern = new RegExp(dataValidation.attributes.getNamedItem('data-pattern').value.slice(1, -1))

            if (!(pattern.test(dataValidation.value))) {
                setErrorMessage({...errorMessage, [dataValidation.id]: (t("MESSAGE_ERROR_INVALID"))});
            }else {
                const minLength = dataValidation.attributes.getNamedItem('data-minLength').value
                const maxLenght = dataValidation.attributes.getNamedItem('data-maxLength').value

                if(dataValidation.value.length < minLength){
                    setErrorMessage({...errorMessage, [dataValidation.id]: `${t("MESSAGE_ERROR_MIN_LENGTH")}${minLength}${t("CHARACTER")}`});
                }else if(dataValidation.value.length > maxLenght){
                    setErrorMessage({...errorMessage, [dataValidation.id]: `${t("MESSAGE_ERROR_MAX_LENGTH")}${maxLenght}${t("CHARACTER")}`});
                }else{
                    setErrorMessage({...errorMessage, [dataValidation.id]: ""})
                }
            }
        }
    }

    const checkIfError = (dataError) => !Object.values(dataError).every(dataValue => dataValue === "")

    const handleChange = (e) => {
        setDataClient({...dataClient, [e.target.id]: e.target.value})
    }

    const acept = (clientAccept, privacity) => {
        setClientAcceptance(clientAccept)
        setPrivacityAccept(privacity)
        setActiveAcept(!(clientAccept && privacity && !checkIfError(errorMessage)))
    }

    return (
        <Grid container direction="row" style={{ marginTop: 10, padding: '20px 30px 0 30px' }}>
            <Grid container item xs={12}>
                <Typography variant="h5" gutterBottom className={classes.greeting}>¡{t("GREETINS")} {dataClient.full_name}!</Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography variant="subtitle2" gutterBottom style={{ marginTop: 10 }}>
                    {t("CONFIRM_OFFER_DATA")}
                </Typography>
            </Grid>
            <Grid container item xs={12} style={{ margin: '30px 0 0px 0' }}>
                <TextField
                    id="full_name"
                    label={t("FULL_NAME")}
                    variant="outlined"
                    value={dataClient.full_name}
                    className={classes.input}
                    onChange={handleChange}
                    onBlur={(e)=> validation(e.target)}
                    required
                    error={errorMessage["full_name"] !== ""}
                    helperText={errorMessage["full_name"]}
                    inputProps={{
                        'data-pattern': "/^(?:[a-zA-Zñá-úÁ-Ú\\.]+(?:\\s(?=[a-zA-Zñá-úÁ-Ú\\.]))?)+$/",
                        'data-minLength': 2,
                        'data-maxLength': 50,
                      }}
                />
                <TextField
                    id="rfc"
                    label={t("RFC")}
                    variant="outlined"
                    value={dataClient.rfc}
                    className={classes.input}
                    onChange={handleChange}
                    onBlur={(e)=> validation(e.target)}
                    required
                    error={errorMessage["rfc"] !== ""}
                    helperText={errorMessage["rfc"]}
                    inputProps={{
                        'data-pattern': "/^([A-ZÑ\\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$/",
                        'data-minLength': 13,
                        'data-maxLength': 13,
                      }}
                />
                <TextField
                    id="email"
                    label={t("EMAIL")}
                    variant="outlined"
                    value={dataClient.email}
                    className={classes.input}
                    onChange={handleChange}
                    onBlur={(e)=> validation(e.target)}
                    required
                    error={errorMessage["email"] !== ""}
                    helperText={errorMessage["email"]}
                    inputProps={{
                        'data-pattern': "/^((\"[\\w-\\s]+\")|([\\w-]+(?:\\.[\\w-]+)*)|(\"[\\w-\\s]+\")([\\w-]+(?:\\.[\\w-]+)*))(@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$)|(@\\[?((25[0-5]\\.|2[0-4][0-9]\\.|1[0-9]{2}\\.|[0-9]{1,2}\\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\]?$)/i",
                        'data-minLength': 2,
                        'data-maxLength': 50,
                      }}
                />
                <TextField
                    id="clabe"
                    label={t("CLABE_ACCOUNT")}
                    variant="outlined"
                    value={dataClient.clabe}
                    className={classes.input}
                    onChange={handleChange}
                    onBlur={(e)=> validation(e.target)}
                    required
                    error={errorMessage["clabe"] !== ""}
                    helperText={errorMessage["clabe"]}
                    inputProps={{
                        'data-pattern': "/^\\d+$/",
                        'data-minLength': 18,
                        'data-maxLength': 18,
                      }}
                />
                <TextField
                    id="telephone"
                    label={t("PHONE")}
                    variant="outlined"
                    value={dataClient.telephone}
                    className={classes.input}
                    onChange={handleChange}
                    onBlur={(e)=> validation(e.target)}
                    required
                    error={errorMessage["telephone"] !== ""}
                    helperText={errorMessage["telephone"]}
                    inputProps={{
                        'data-pattern': "/^\\d+$/",
                        'data-minLength': 10,
                        'data-maxLength': 10,
                      }}
                />
                <FormGroup item xs={12} sm={9} style={{ textAlign: 'left', marginTop: 10 , marginRight: 0}}>
                    <FormControlLabel
                        control={<BontuCheckbox
                            className={classes.checkbox}
                            checked={clientAcceptance}
                            onChange={() => acept(!clientAcceptance, privacityAccept)}
                            name="accpet" />}
                        label={<Typography className={classes.textCheckBox}> {t("CONFIRM_OFFER_DATA_TERMS")}</Typography>}
                    />
                    <FormControlLabel 
                        control={<BontuCheckbox
                            className={classes.checkbox}
                            checked={privacityAccept}
                            onChange={() => acept(clientAcceptance, !privacityAccept)}
                            name="acceptPrivacity"
                        />}
                        label={<Grid>
                            <p className={classes.zeroMargin} color="gray">
                                Acepto el
                                    <a target="_blank" href="https://bontu-userapp-assets.s3.amazonaws.com/documents/BET_Aviso+de+Privacidad+para+Clientes.+Alpha+Credit+(clean+22.03.21).pdf" className={classes.colorGreen} style={{ textDecoration: 'none' }}> Aviso de Privacidad </a>
                                    y los
                                    <a target="_blank" href="https://bontu-userapp-assets.s3.amazonaws.com/documents/22072020_T%C3%A9rminos+y+Condiciones_Adelanto+Capital+de+Trabajo.pdf" className={classes.colorGreen} style={{ textDecoration: 'none' }}> Términos y Condiciones</a>
                            </p>
                        </Grid>}
                    />
                </FormGroup>
            </Grid>
            <Grid container item xs={12} style={{ margin: '0px 0 20px 0' }} justify="center">
                <Button variant="contained"
                    className={classes.buttonActive}
                    disabled={activeAcept}
                    onClick={() =>saveData(dataClient)}
                >
                    {t("SEND")}
                </Button>
                <Collapse in={openAlert} style={{ width: '100%', marginTop: 20 }}>
                    <Alert severity={alertSeverity}>
                        {alertMessage}
                    </Alert>
                </Collapse>
            </Grid>
        </Grid>
    );
}

const ClientFeedback = (props) => {
    const {t, notExist, alreadyAssigned} = props;
    return (
        notExist ? <Grid container direction="row" justify="center" style={{ marginTop: 10, padding: '20px 30px 0 30px', height: '400px' }}>
            <Grid container item xs={12} justify="center" style={{ marginBottom: 20, color: '#5B6268' }}>
                <Typography variant="h5" align="inherit" style={{ textAlign: 'center' }}>
                    {t("OFFER_NOT_EXIST")}
                </Typography>
            </Grid>
        </Grid> :
            <Grid container direction="row" justify="center" style={{ marginTop: 10, padding: '20px 30px 0 30px' }}>
                <Grid container item xs={12} justify="center" style={{ marginBottom: 20, color: '#5B6268' }}>
                    <Typography variant="h5" align="inherit" style={{ textAlign: 'center' }}>
                        {alreadyAssigned ? `¡${t("CONFIRM_OFFER_DATA_THANKS")}!` : `¡${t("CONFIRM_OFFER_DATA_EXPRESSION")}!`}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="center">
                    <Typography variant="body1" align="center" style={{ color: '#5B6268' }}>
                        {alreadyAssigned ? `¡${t("CONFIRM_OFFER_DATA_CONFIRM")}!` : `¡${t("CONFIRM_OFFER_DATA_NOTICE")}!`}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="center" style={{ margin: '80px 0' }}>
                    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={BontuLaptop} alt=""/>
                        <img src={BontuLogo} alt="" style={{ position: 'absolute', marginTop: -30 }} />
                    </div>
                </Grid>
            </Grid>
    )
}

const Expired = (props) => {
    const {t, platformData} = props;
    return (
        <Grid container direction="row" justify="center" style={{ marginTop: 10, padding: '20px 30px 0 30px' }}>
            <Grid container item xs={12} justify="center" style={{ marginBottom: 20, color: '#5B6268' }}>
                <Typography variant="h5" align="inherit" style={{ textAlign: 'center' }}>
                    {t("CONFIRM_OFFER_DATA_EXPIRED")}
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="center">
                <Typography variant="body1" align="center" style={{ color: '#5B6268' }}>
                    ¡{t("CONFIRM_OFFER_DATA_EXPIRED_MESSAGE").replace('XXXXXX', platformData.name)}!
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="center" style={{ margin: '80px 0' }}>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={BontuChronometer} alt=""/>
                </div>
            </Grid>
        </Grid>
    )
}

export default ConfirmData;