import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles, Grid, Table, TableBody, TableCell, TableHead, TableRow,
        CardContent, Tooltip, TableContainer, Paper, Box } from "@material-ui/core";
import GraphicCapital from '../OfferDetail/GraphicCapital';
import { estimatedPayments } from "../../../Services/generalDataAmazon";
import { amountFormat } from "../../../sharedFunction/utils";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import InstructionForms from '../../../sharedComponents/InstructionForms'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    body: {
        fontSize: 14,

    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTooltip = withStyles({
    tooltip: {
      width: "150px",
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 30,
      borderRadius: "5px",
      fontSize: "12px",
      textAlign:"center",
      color: "#FFFFFF",
      backgroundColor: "#406C77",
    },
    arrow: {
        fontSize: "22px",
        "&::before": {
          backgroundColor: "#406C77"
        }
    }
})(Tooltip);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 200
    },
    tooltipSales: {
        paddingTop: 35,
        height: "40px"
    },
    tooltipAmount: {
        height: "55px",
        paddingTop: 20
    },
    iconoCentrado: {
        verticalAlign: "middle",
        marginRight: "5px",
        paddingLeft: "3px",
        color: "#666666",
        "&:hover": { 
            color: "#00C08B" 
        } 
    },
    minimumSizeGraphic: {
        marginTop: '100px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: "60px",
            marginTop: '30px'
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    tableStyle: {
        marginTop: '30px',
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            marginLeft: "auto",
            marginRight: "auto"
        }
    }
}));

export default function EstimatedPayments({ amount, percent, request_id, instructions }) {
    const classes = useStyles();
    const [rows, setRows] = useState([])
    const { t } = useTranslation("UserApp");

    useEffect(() => {
        newData({
            "amount": amount,
            "percent": percent,
            "terms": 12,
            "request_id": request_id
        })
    }, [amount, percent, request_id])

    const newData = (dataToSend) => {
        estimatedPayments(dataToSend).then((response) => {
            setRows(response);
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <InstructionForms mensaje={(instructions["estimatedPayments"] !== undefined && instructions["estimatedPayments"] !== '') ? instructions["estimatedPayments"] : t('ESTIMATED_PAYMENT_TEXT')} /> 
                </Grid>
                <Box clone order={{ xs: 3, sm: 2 }}>
                    <Grid item className={classes.minimumSizeGraphic} lg={6} md={6} sm={11} xs={11}>
                        <CardContent >
                            <GraphicCapital amount={amount} percent={percent} request_id={request_id}  ></GraphicCapital>
                        </CardContent>
                    </Grid>
                </Box>
                <Box clone  order={{ xs: 2, sm: 3 }}>
                    <Grid item className={classes.tableStyle} lg={6} md={6} sm={11} xs={11} alignContent='center'>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} align='center' aria-label="customized table" style={{  height: '654px', top: '1px', radius: '16px' }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" style={{ borderBlockColor: '#00C08B', color: '#003B4A', fontWeight: 'bold' }}>Fecha exigible</StyledTableCell>
                                        <StyledTableCell align="center" style={{ borderBlockColor: '#00C08B', color: '#003B4A', fontWeight: 'bold' }}>Ventas esperadas<StyledTooltip title={t("EXPECTED_SALES_TOOLTIP")} classes={{ tooltip: classes.tooltipSales }} placement="top" arrow><HelpOutlineIcon className={classes.iconoCentrado} /></StyledTooltip></StyledTableCell>
                                        <StyledTableCell align="center" style={{ borderBlockColor: '#00C08B', color: '#003B4A', fontWeight: 'bold' }}>Pago estimado<StyledTooltip title={t("ESTIMATED_PAYMENT_TOOLTIP")} classes={{ tooltip: classes.tooltipAmount }} placement="top" arrow><HelpOutlineIcon className={classes.iconoCentrado} /></StyledTooltip></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.date}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.date}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{amountFormat(row.expected_sales)}</StyledTableCell>
                                            <StyledTableCell align="center">{amountFormat(row.payment)}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
}

EstimatedPayments.propTypes = {
    amount: PropTypes.number,
    percent: PropTypes.number,
    request_id: PropTypes.number,
};


