import React from 'react';
import Dropzone from 'react-dropzone';
import BackupIcon from '@material-ui/icons/Backup';
import { Grid, Hidden, Link, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";

const useStyle = {
    background: '#FFFFFF',
    border: '1px dashed #CCD2D7',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px',
    width: '100%',
    height: '100%',
    minHeight: '100px'
};

const CustomDropzone = ({ index, onDropAccepted, onDropRejected, classes, aceptDocument }) => {
    const { t } = useTranslation("UserApp");
    const style = useStyle;
    return (
        <React.Fragment>
            <div className={`${classes.center} ${classes.wh100}`}>
                <Dropzone
                    onDropAccepted={(acceptedFile) => { onDropAccepted({ file: acceptedFile, index: index }) }}
                    onDropRejected={onDropRejected}
                    accept={aceptDocument}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section className={classes.wh100}>
                            <div {...getRootProps({ style: style })}>
                                <Grid container direction="row" justify="center" alignItems="center"
                                    className={classes.wh100}
                                >
                                    <input {...getInputProps()} />
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.center}>
                                        <BackupIcon fontSize="large" className={classes.iconColor} />
                                    </Grid>
                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={9} style={{paddingLeft:'10px'}}>
                                        <Hidden smUp>
                                            <Typography>
                                                <Link fontSize="large" variant="body2" color="textPrimary" >
                                                    {t('CHOOSE_FILE')}
                                                </Link>
                                            </Typography>
                                            <Typography variant="body2" style={{color:"#5B6268"}}>
                                                {"Formato: .pdf, .jpg o .png"}
                                            </Typography>
                                            </Hidden>

                                            <Hidden xsDown>
                                            <Typography style={{color:"#5B6268"}}>
                                                {t('DRAG_AND_DROP_YOUR_FILE_HERE')} &nbsp;
                                            <Link fontSize="large" variant="body2" color="textPrimary" >
                                                    {t('CHOOSE_YOUR_FILE')}
                                                </Link>
                                            </Typography>
                                            <Typography style={{color:"#5B6268"}}>
                                                {"Formato: .pdf, .jpg o .png"}
                                            </Typography>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        </React.Fragment>
    );
}

CustomDropzone.defaultProps = {
    aceptDocument: "image/*,application/pdf"
}

export default CustomDropzone;