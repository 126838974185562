
import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const InstructionForms = ({ mensaje }) => {

    const useStyles = {
        fondo: {
            color: '#003B4A',
            background: '#EAEDEF',
            display: 'flex',
            textAlign: 'left',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: '24px 16px',
            borderRadius: 8,
            Width:'100%'
        },
        texto: {
            fontSize: '16px',
            fontWeight: 500,
            color: '#515854',
        }
    };

    return (
        <div style={useStyles.fondo}>
            <Typography style={useStyles.texto} data-test="msg">{mensaje}</Typography>
        </div>
    )
}

InstructionForms.propTypes = {
    mensaje: PropTypes.string.isRequired
}

export default InstructionForms;