import React, { useState, useEffect } from 'react';
import {
    Fade,
    Slide,
    Button,
    Grid,
    makeStyles
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    indicators: {
        width: "100%",
        marginTop: "10px",
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

    },
    indicator: {
        paddingTop: '5%',
        fontSize: "15px",
        cursor: "pointer",
        transition: "200ms",
        color: "#afafaf",
        '&:hover': {
            color: "#1f1f1f"
        },
        '&:active': {
            color: "#1f1f1f"
        }
    },
    active: {
        color: 'rgba(0, 192, 139, 1)'
    },
    centerEnd: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: "center",
            paddingTop: '0%'
        },
    },
    buttonPrimary: {
        color: 'rgba(0, 192, 139, 1)',
        minWidth: 100,
        padding: '5px 0',
        textTransform: 'Capitalize',
        fontSize: 16,
        border: 'solid 1px rgba(0, 192, 139, 1)',
        margin: '3px',
        [theme.breakpoints.between('xs', 'sm')]:{
            margin: '10px',
            minWidth: 80,
            fontSize: 12
        }
    },
    button: {
        color: 'white',
        minWidth: 100,
        padding: '5px 0',
        margin: '10px',
        textTransform: 'Capitalize',
        fontSize: 16,
        background: 'rgba(0, 192, 139, 1)',
        '&:hover': {
            background: 'rgba(0, 192, 139, 1)'
        },
        [theme.breakpoints.between('xs', 'sm')]:{
            margin:'0%',
            minWidth: 80,
            fontSize: 12
        }
    },
    carousel: {
        justifyContent: 'flex-start',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: "center",
            justify: "center",
        },
    },
    itemCarousel:{
        justify:'center',
        
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingLeft: '6%',
            paddingBottom: '3%',
            justifyContent:'center',

        }
    }
}));


export default function Carousel(props) {
    const { t } = useTranslation("UserApp");
    const [active, setActive] = useState(0);
    const classes = useStyles();
    const timeout = 200;
    const animation = props.animation ? props.animation : 'fade';
    const [buttonTitle, setButtonTitle] = useState(t("CONTINUE"));

    const pressIndicator = (index) => {
        setActive(index);
    }

    const next = (value) => {
        setActive(value);
    }

    const stepManager = () => {
        const nextValue = active + 1 > props.children.length - 1 ? 0 : active + 1;
        if (nextValue === 0) {
            props.handleClose()
        } else {
            next(nextValue);
        }
    }

    useEffect(() => {
        if (active === props?.children?.length - 1) {
            setButtonTitle(t("BEGIN"))
        } else {
            setButtonTitle(t("CONTINUE"));
        }
    }, [active, props, t]);

    return (
        <React.Fragment>
            <div className={classes.root}>
                {
                    Array.isArray(props.children) ?
                        props.children.map((child, index) => {
                            return (
                                <CarouselItem key={index} active={index === active ? true : false} child={child} animation={animation} timeout={timeout} />
                            )
                        })
                        :
                        <CarouselItem key={0} active={true} child={props.children} animation={animation} timeout={timeout} />
                }

                <Grid container className={classes.carousel}>
                    <Grid item xl={5} lg={5} md={3} sm={3} xs={3} className={classes.itemCarousel}>
                        <Indicators classes={classes} length={props.children?.length} active={active} press={pressIndicator} />
                    </Grid>
                    <Grid item xl={7} lg={7} md={9} sm={12} xs={12} className={classes.centerEnd}>
                        {props.handleClose
                            ? (
                                <div>
                                    < Button
                                        data-test="buttonOmitir"
                                        onClick={props.handleClose}
                                        variant="outlined"
                                        className={classes.buttonPrimary}
                                    >{t("JUMP")}
                                    </Button>

                                    < Button
                                        data-test="buttonBegin"
                                        onClick={stepManager}
                                        variant="contained"
                                        className={classes.button}
                                    >{buttonTitle.toString()}
                                    </Button>
                                </div>
                            )
                            : null
                        }
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}


function CarouselItem(props) {
    return (
        <div className="CarouselItem" hidden={!props.active}>
            {props.animation === "slide" ?
                <Slide direction="left" in={props.active} timeout={props.timeout}>
                    <div>
                        {props.child}
                    </div>
                </Slide>
                :
                <Fade in={props.active} timeout={500} >
                    <div>
                        {props.child}
                    </div>
                </Fade>
            }
        </div>
    )
}

function Indicators(props) {
    const classes = props.classes;

    let indicators = [];
    for (let i = 0; i < props.length; i++) {
        const className = i === props.active ? `${classes.indicator} ${classes.active}` : `${classes.indicator}`;
        const item = <FiberManualRecordIcon key={i} size='small' className={className} onClick={() => { props.press(i) }} />;

        indicators.push(item);
    }

    return (
        <div className={`${classes.indicators}`}>
            {indicators}
        </div>
    )
}