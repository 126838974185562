import React from "react";
import Grid from "@material-ui/core/Grid";
import GenericForm from "../forms/genericForm";
import ShowBackdrop from "../../../sharedComponents/backDrop";

export default function GeneralData(props) {
    return (
        <div>
            <ShowBackdrop open={props.isLoading} data-test="backdrop" />
            <Grid container>
                <Grid item xs>
                    <GenericForm ref={props.stepRef} platform={props.platform} activeBackStephandler={props.activeBackStephandler} activeStephandler={props.activeStephandler} titlehandler={props.titlehandler}/>
                </Grid>
            </Grid>

        </div>

    );
}