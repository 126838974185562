import React from "react";
import { TermsAndConditions as PrivateTermsAndConditions } from "./termsAndConditions";
import { control_oyo_broker_constants } from "../../constants/control";
import { useHistory } from "react-router-dom";
import { useUser } from "../../Context/UserContext";


export const TermsAndConditions = props => {
	let history = useHistory();
	const { isAuthorized } = useUser();

	if (isAuthorized(control_oyo_broker_constants.CREDIT_REQUEST_VIEW))
		return <PrivateTermsAndConditions {...props} />;
	else {
		history.push("/unauthorized");
		return null;
	}
};
