import { postBontu } from "./requests";
import axios from "axios";

const pathPrecarga = "/documents/generic/preload";
const pathSendKey = "/documents/generic";
const pathSendingDocuments = "/request/sending-documents";

export function SendPrecargaImagen(body) {
	return postBontu(pathPrecarga, {}, body);
}

export function SendCargaImagen(url, body, apiKey, token) {
	axios.defaults.headers.common["authorizationtoken"] = token;
	axios.defaults.headers.common["X-Api-Key"] = apiKey;

	return axios.post(url, body).then((response) => {
		return response ? response : null;
	}).catch(() => {
		return null;
	});
}

export function sendKeyToStore(body) {
	return postBontu(pathSendKey, {}, body);
}

export function sendingDocuments(body) {
	return postBontu(pathSendingDocuments, {}, body);
}