import Paso1Cambio from "../../../assets/images/instructive/paso1-cambio.png";
import Paso2Cambio from "../../../assets/images/instructive/paso2-cambio.png";
import Paso3Cambio from "../../../assets/images/instructive/paso3-cambio.png";
import Paso4Cambio from "../../../assets/images/instructive/paso4-cambio.png";
import Paso5Cambio from "../../../assets/images/instructive/paso5-cambio.png";
import Paso6Cambio from "../../../assets/images/instructive/paso6-cambio.png";
import Paso7Cambio from "../../../assets/images/instructive/paso7-cambio.png";
import Paso8Cambio from "../../../assets/images/instructive/paso8-cambio.png";
import Paso9Cambio from "../../../assets/images/instructive/paso9-cambio.png";
import estado_cuenta_1 from "../../../assets/images/instructive/estado_cuenta_1.jpg";
import estado_cuenta_2 from "../../../assets/images/instructive/estado_cuenta_2.jpg";
import estado_cuenta_3 from "../../../assets/images/instructive/estado_cuenta_3.jpg";
import estado_cuenta_4 from "../../../assets/images/instructive/estado_cuenta_4.jpg";
import estado_cuenta_5 from "../../../assets/images/instructive/estado_cuenta_5.jpg";
import reporte_pagos_1 from "../../../assets/images/instructive/reporte_pagos_1.jpg";
import reporte_pagos_2 from "../../../assets/images/instructive/reporte_pagos_2.jpg";
import reporte_pagos_3 from "../../../assets/images/instructive/reporte_pagos_3.jpg";
import reporte_pagos_4 from "../../../assets/images/instructive/reporte_pagos_4.jpg";
import panel_control_1 from "../../../assets/images/instructive/panel_control_1.jpg";
import panel_control_2 from "../../../assets/images/instructive/panel_control_2.jpg";
import panel_control_3 from "../../../assets/images/instructive/panel_control_3.jpg";
import panel_control_4 from "../../../assets/images/instructive/panel_control_4.jpg";
import amazon_seller_central_01 from "../../../assets/images/instructive/amazon_seller_central_01.jpg";
import amazon_seller_central_02 from "../../../assets/images/instructive/amazon_seller_central_02.jpg";

const images = {
    paso1Cambio: Paso1Cambio,
    paso2Cambio: Paso2Cambio,
    paso3Cambio: Paso3Cambio,
    paso4Cambio: Paso4Cambio,
    paso5Cambio: Paso5Cambio,
    paso6Cambio: Paso6Cambio,
    paso7Cambio: Paso7Cambio,
    paso8Cambio: Paso8Cambio,
    paso9Cambio: Paso9Cambio,
    estado_cuenta_1: estado_cuenta_1,
    estado_cuenta_2: estado_cuenta_2,
    estado_cuenta_3: estado_cuenta_3,
    estado_cuenta_4: estado_cuenta_4,
    estado_cuenta_5: estado_cuenta_5,
    reporte_pagos_1: reporte_pagos_1,
    reporte_pagos_2: reporte_pagos_2,
    reporte_pagos_3: reporte_pagos_3,
    reporte_pagos_4: reporte_pagos_4,
    panel_control_1: panel_control_1,
    panel_control_2: panel_control_2,
    panel_control_3: panel_control_3,
    panel_control_4: panel_control_4,
    amazon_seller_central_01: amazon_seller_central_01,
    amazon_seller_central_02: amazon_seller_central_02
};

export default images;