const environment = {
	production: false,
	client_id: 'amzn1.application-oa2-client.9801cbca613c4c449c2bfa14d939c158',
	public_key: 'pWcema8rh958Uf0PxbBWg8YzszkxuzGU1BJrP26t',
	devURL: 'https://www.bontu.mx/',
	chat: {
		host: 'https://wchat.freshchat.com',
		token: '83ff6496-0c31-41db-9072-3f539aa3cca0'
	},
	cognitoConfig: {
	  Auth: {
		identityPoolId: 'us-east-1:45f0842c-2284-4260-a021-dc9e9a2f62e0',
		region: 'us-east-1',
		identityPoolRegion: 'us-east-1',
		userPoolId: 'us-east-1_nc38dhT6U',
		userPoolWebClientId: '36kafi113oqmnmj9ht682ciked',
		oauth: {
		  domain: 'prod-userappauth.auth.us-east-1.amazoncognito.com',
		  redirectSignIn: 'https://www.userapp.bontu.mx/callback',
		  redirectSignOut: 'https://www.userapp.bontu.mx/login',
		  responseType: 'token'
		}
	  },
	  API: {
		endpoints: [
		  {
			name: 'ApiBontu',
			endpoint: 'https://api.bontu.mx'
		  }
		]
	  }
	},
};

export default environment;