import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(4),
        width: '100%',
        padding: '0px',
        borderRadius: '10px',
        fontSize: '14px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        background: 'rgba(255, 255, 255, 0.4)',
    },
    logos: {
        width: '100px',
        maxHeight: 30
    },
    h3: {
        textAlign: 'center',
        margin: '15px 0',
        width: '100%',
        fontSize: '22px',
        fontWeight: 'bold'
    },
    h4: {
        margin: '15px 0',
        width: '100%',
        fontSize: '20px',
        fontWeight: 'bold'
    },
    h5: {
        margin: '15px 0',
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold'
    },
    h6: {
        margin: '15px 0',
        width: '100%',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    paragraph: {
        margin: '15px 0',
        fontSize: '15px'
    },

}));
export const PrivacyPolicy = (props) => {
	const classes = useStyles();


	return (
	<Grid container direction="row" justify="center" style={{ background: '#F5F6F7' }}>
	    <Grid container item xs={12} sm={8} md={8} lg={6} spacing={2} style={{ marginTop: 10, padding: '20px 0' }}>
          <Card className={classes.root}>
            <Grid container direction="row" style={{ padding: '50px' }}>
                <Grid container item xs={12}>
                    <Typography className={classes.paragraph}>
                    En aras de dar cumplimiento a lo establecido en la Ley Federal de Datos Personales en Posesión de Particulares y demás normatividad aplicable, ponemos a su disposición nuestro:</Typography>

                    <Typography data-test="title" variant="h3" className={classes.h3}>AVISO DE PRIVACIDAD</Typography>
                    <Typography className={classes.paragraph}><b>YU DIGITAL, S.A. DE C.V. y BETACREDIT, S.A. DE C.V., SOFOM, E.N.R. </b>(conjuntamente, “El Responsable”) como responsable del uso de sus datos personales, con domicilio ubicado en
                    Avenida Antonio Dovalí Jaime número 70, Torre C, Piso 7, Colonia Zedec Santa Fe, Alcaldía Álvaro Obregón, Código Postal 01210, Ciudad de México, México, le informa: que usted al solicitar
                    un producto o servicio financiero y previo a su otorgamiento, o previo al uso de nuestros servicios digitales (atención a clientes, uso de los sitios alojados en Internet, aplicaciones móviles,
                    o cualquier otro servicio que sea proporcionado por El Responsable, a través de cualquier medio tecnológico, digital, óptico, actual o próximo por inventarse) ha aceptado nuestro aviso de privacidad
                    y el tratamiento de sus datos conforme a lo establecido en este Aviso de Privacidad, (en adelante el “Aviso de Privacidad”).</Typography>

                    <Typography data-test="subtitle" variant="h4" className={classes.h4}>SOBRE EL TRATAMIENTO:</Typography>
                    <Typography  className={classes.paragraph}>Tenemos un gran interés por salvaguardar sus datos personales y brindarle la mejor atención posible, por lo que hemos tomado las medidas necesarias y razonables para resguardar sus datos personales
                    y continuamente realizamos nuestros mejores esfuerzos para actualizar nuestros sistemas y procesos con el fin de cumplir con los más altos estándares de seguridad y prevenir así cualquier vulneración
                    a sus datos personales. </Typography>
                    <Typography variant="h4" className={classes.h4}>¿QUÉ DATOS PERSONALES RECABAMOS Y CON QUÉ FINES?</Typography>
                    <Typography  className={classes.paragraph}>El responsable, ya sea directa o indirectamente, a través de personas con las que mantenga una relación comercial, sus filiales
                    o afiliadas, posee, recaba, recabará o podría recabar sus datos personales, incluidos aquéllos de carácter sensible relacionados o consistentes en:</Typography>
                    <Typography  className={classes.paragraph}>
                        <ol type="a">
                            <li>Datos de identificación</li>
                            <li>Datos biométricos para identificación (reconocimiento facial, voz y/o huella digital)</li>
                            <li>Datos de contacto</li>
                            <li>Datos laborales</li>
                            <li>Datos patrimoniales y/o financieros</li>
                            <li>Datos comerciales</li>
                            <li>Datos migratorios</li>
                            <li>Datos relativos a su historial crediticio</li>
                            <li>Datos correspondientes a su geolocalización</li>
                            <li>Datos identificados en la leyes aplicables para la prevención e identificación de operaciones con recursos de procedencia ilícita (“KYC”)</li>
                        </ol>
                    </Typography>
                    <Typography  className={classes.paragraph}>Los datos descritos serán utilizados para las siguientes finalidades necesarias: (i) otorgamiento; administración; y cobranza de los
                    productos y servicios financieros que usted solicite a El Responsable; (ii) ceder o transmitir los derechos de crédito de los productos o servicios
                    financieros que El Responsable le hubiese otorgado; (iii) realizar consultas y seguimiento a sus quejas o solicitudes relacionadas con los productos
                    y servicios financieros; (iv) dar cumplimiento a las obligaciones contractuales derivadas de su solicitud de productos y servicios financieros; (v)
                    prevenir y detectar fraudes o actos ilícitos en agravio de su persona o bien, de El Responsable; (vi) contratación, en su caso, de seguros y
                    asistencias relacionados con los productos y servicios financieros contratados por usted con El Responsable; (vii) verificar, confirmar y validar su
                    identidad durante la contratación y la prestación de los servicios; (viii) procedimientos de identificación del cliente; (ix) analizar solicitudes
                    de crédito; (x) determinación de perfil transaccional; (xi) procesos de auditoría externa e interna; (xii) dar cumplimiento a las disposiciones
                    legales aplicables a los productos y servicios financieros; y (xiii) dar cumplimiento a requerimientos de autoridades que nos regulan en materia
                    de prevención de lavado de dinero y financiamiento al terrorismo.</Typography>

                    <Typography  className={classes.paragraph}>La información que obtenemos de nuestros clientes contribuye a mejorar continuamente nuestros servicios y atención a clientes, por
                    lo que hacemos uso de sus datos para las finalidades no necesarias subsecuentes que nos permiten mejorar nuestros servicios, hacerle partícipe
                    de nuestros nuevos productos y brindarle una mejor experiencia de atención al cliente: (i) promoción, telemarketing, o publicidad; (ii)
                    ofrecimiento de productos y servicios financieros; (iii) mercadotecnia, estadística y reporteo; (iv) análisis  internos;  (v)  proyecciones
                    financieras;  (vi)  comportamientos  históricos;  (vii)  análisis  socio  demográfico;  (viii)  estrategias  de  mercado;  y  (ix)  prospección comercial.</Typography>

                    <Typography  className={classes.paragraph}>Una vez concluida su relación con El Responsable, ésta mantendrá sus datos personales en una base de datos segura y confidencial por un periodo
                    de 10 (diez) años en cumplimiento a las disposiciones que rigen a El Responsable; dentro de dicho término se podrá dar continuidad al tratamiento
                    de sus datos para los fines no necesarios.</Typography>

                    <Typography  className={classes.paragraph}>El tratamiento de los datos biométricos, incluidos los de carácter sensible, se limitarán única y exclusivamente a su identificación.</Typography>

                    <Typography  className={classes.paragraph}>El Responsable también recauda información de carácter no personal, o somete los datos personales a un proceso de
                    disociación que impiden que puedan ser asociados a persona alguna. La información disociada o de carácter no personal es utilizada para
                    propósitos estadísticos y analíticos a fin de obtener datos que nos permitan brindarle un mejor servicio y una mejor atención; el tratamiento
                    de la información descrita en el presente párrafo no será considerado como tratamiento de datos personales.</Typography>


                    <Typography variant="h4" className={classes.h4}>TRANSFERENCIA DE SUS DATOS PERSONALES</Typography>
                    <Typography  className={classes.paragraph}>Los terceros y las entidades receptoras, al realizarse la transferencia de sus datos personales, asumen las mismas obligaciones y/o responsabilidades
                    que El Responsable.</Typography>
                    <Typography  className={classes.paragraph}>El Responsable podrá transmitir sus datos personales en los siguientes supuestos establecidos en la Ley Federal de Protección de Datos Personales
                    en posesión de los Particulares, para lo cual El Responsable no requiere su consentimiento.</Typography>

                    <Typography  className={classes.paragraph}>Cuando la transferencia se realice a sus empresas  filiales  o  afiliadas,  así  como  empresas  con  las  que  mantenga
                    una  relación  comercial  y  sociedades  de  información crediticia, éstas podrán hacer uso de estos para: (i) otorgamiento; administración;
                    y cobranza de los productos y servicios financieros; (ii) analizar solicitudes de crédito; (iii) dar cumplimiento a las obligaciones contractuales
                    derivadas de su solicitud de productos o servicios financieros; (iv) prevenir y detectar fraudes o ilícitos en agravio de su persona, o bien,
                    de El Responsable, sus afiliadas o filiales; (v) contratación, en su caso, de seguros y asistencias relacionados con el producto o servicio
                    financiero por  usted,  por  El  Responsable;  (vi)  verificar,  confirmar  y  validar  su  identidad  durante  la  prestación  de  los
                    servicios;  (vii)  evaluar  la  calidad  de  nuestros  servicios  y productos financieros; (vii) conocimiento e información de nuestras
                    marcas y productos; (viii) implementación y desarrollo de las finalidades no necesarias descritas en el presente aviso; y (ix)
                    ofrecimiento de sus servicios y productos financieros.</Typography>

                    <Typography  className={classes.paragraph}>Sus datos personales podrán ser transferidos a entidades del sector financiero, ubicadas en territorio extranjero o
                    nacional (Estados Unidos Mexicanos) con las siguientes finalidades: (i) ceder o transmitir los derechos de crédito de los productos
                    o servicios financieros otorgados a usted; (ii) otorgamiento, administración y cobranza de los productos y servicios financieros;
                    (iii) dar cumplimiento a las obligaciones contractuales derivadas de su solicitud de productos y servicios financieros;
                    (iv) prevenir y detectar fraudes o ilícitos en agravio de su persona, o bien, de El Responsable, sus afiliadas o filiales; y
                    (v) dar atención a las auditorias para el debido cumplimiento de la legislación nacional o extranjera que le sea aplicable a El Responsable.</Typography>

                    <Typography  className={classes.paragraph}>El Responsable podrá, en todo momento, comunicar sus datos personales para atender requerimientos de las autoridades reguladoras competentes.
                    En el mismo sentido, El Responsable podrá, en cualquier momento, transferir sus datos personales para atender auditorías que terceros
                    puedan realizar para garantizar su funcionamiento y cumplimiento sobre la normatividad que le aplica.</Typography>

                    <Typography  className={classes.paragraph}>El titular de datos personales que presenta cualquier solicitud para entablar una relación jurídica con
                    El Responsable o con cualquiera de las empresas con las que ésta última  tenga  una  relación  comercial,  acepta
                    la  transferencia  de  sus  datos  personales,  en  los  términos  descritos  en  el  presente  Aviso  de  Privacidad
                    mediante  su consentimiento a través de la firma del Aviso de Privacidad corto, o bien, de la aceptación del Aviso de Privacidad
                    integral a través de los medios digitales, electrónicos, ópticos, analógicos, o cualquier otra tecnología presente o futura.</Typography>

                    <Typography variant="h4" className={classes.h4}>OPCIONES SOBRE EL TRATAMIENTO DE SUS DATOS PERSONALES Y EJERCICIO DE DERECHOS ARCO</Typography>

                    <Typography  className={classes.paragraph}>Antes de realizar cualquiera de los procedimientos indicados en la presente sección, es importante que considere que no en todos
                    los casos podremos atender su solicitud o cesar el tratamiento de forma inmediata, ya que es posible que, derivado de alguna
                    obligación legal, requiramos seguir tratando sus datos personales. Consecuentemente, usted deberá considerar que, para las
                    finalidades necesarias expresadas en el presente Aviso de Privacidad, la revocación de su consentimiento implicará que no
                    podamos seguir prestando el servicio que contrató, o la conclusión de su relación con nosotros.</Typography>

                    <Typography variant="h5" className={classes.h5}>Revocación de consentimiento</Typography>

                    <Typography  className={classes.paragraph}>Usted puede revocar su consentimiento para el tratamiento de sus datos personales con relación a las finalidades
                    que no sean necesarias conforme a lo establecido en el presente  Aviso  de  Privacidad,  mediante  el  envío  de  un  correo
                    electrónico  dirigido  a aviso.privacidad@bontu.mx  o  comunicándose  al  teléfono  (55)  52  92  75  11.  El Responsable
                    podrá  requerirle  que  se  identifique  como  Cliente  de  la  misma  previo  al  análisis  de  su  solicitud,  la
                    determinación  que  se  realice  a  este  respecto  le  será comunicada por El Responsable a través de correo electrónico
                    a la dirección de correo electrónico que usted provea para tales fines dentro del texto de su solicitud de revocación en un
                    término de 5 (cinco) días hábiles posteriores a su recepción, siempre y cuando usted provea toda la información requerida por
                    El Responsable para realizar  la  citada  revocación,  en  caso  contrario,  el  término  antes  mencionado  comenzará  a
                    contarse  a  partir  de  la  entrega  total  de  la  información  requerida  por  El Responsable. Conforme a lo anterior,
                    la revocación que usted realice deberá apegarse a lo especificado en el presente párrafo y no podrá ser un motivo para que
                    se le nieguen los productos y servicios que solicita o contrata con El Responsable.</Typography>

                    <Typography variant="h5" className={classes.h5}>Negación de tratamiento</Typography>

                    <Typography  className={classes.paragraph}>Usted  podrá  negarse  al  tratamiento  de  sus  datos  personales  con  relación  a  las  finalidades  que
                    no  sean  necesarias  conforme  a  lo  establecido  en  el  presente  Aviso  de Privacidad  mediante  el  envío  de  un
                    correo  electrónico  dirigido  a aviso.privacidad@bontu.mx  o  comunicándose  al  teléfono  (55)  52  92  75  11.  El
                    Responsable  podrá requerirle  que  se  identifique  como  Cliente  de  la  misma  previo  al  análisis  de  su  solicitud,
                    la  determinación  que  se  realice  a  este  respecto  le  será  comunicada  por  El Responsable a través de correo electrónico
                    a la dirección de correo electrónico que usted provea para tales fines dentro del texto de su solicitud de negación de tratamiento
                    de datos personales en un término de 5 (cinco) días hábiles posteriores a su recepción, siempre y cuando usted provea toda
                    la información requerida por El Responsable para  realizar  la  citada  negación,  en  caso  contrario,  el  término  antes
                    mencionado  comenzará  a  contarse  a  partir  de  la  entrega  total  de  la  información  requerida  por  El Responsable.
                    En caso de que usted no manifieste su negativa, se entenderá que autoriza el uso de sus datos personales para dichos fines.</Typography>

                    <Typography variant="h5" className={classes.h5}>Ejercicio de Derechos ARCO</Typography>

                    <Typography  className={classes.paragraph}>Usted podrá ejercer sus derechos ARCO (Acceso, Rectificación, Cancelación y Oposición) a través de la
                    presentación de la Solicitud de Ejercicio de Derechos ARCO conforme a lo indicado en la presente sección.</Typography>

                    <Typography  className={classes.paragraph}>
                        <ul>
                            <li>Correo electrónico dirigido a aviso.privacidad@bontu.mx </li>
                            <li>Número telefónico: (55) 52 92 75 11</li>
                        </ul>
                    </Typography>

                    <Typography  className={classes.paragraph}>Ejercicio del Derecho de Acceso: El titular podrá ejercer su derecho de acceso en cualquier momento, entendido éste como
                    la posibilidad de conocer qué datos personales poseemos del titular, para qué son utilizados y las condiciones en las que son tratados.
                    Para el ejercicio del derecho de acceso, el titular deberá:</Typography>

                    <Typography  className={classes.paragraph}>Acceder  a  la  Solicitud  de  Ejercicio  de  Derechos  ARCO  a  través  del
                    portal  www.bontu.mx  o  requerir,  vía  telefónica  al  número  (55)  52  92  75  11,  que  la  Solicitud  de Ejercicio de Derechos ARCO
                    (en lo sucesivo, la “Solicitud”) se le haga llegar por correo electrónico; en ambos casos, el titular deberá descargar e imprimir la Solicitud.</Typography>

                    <Typography  className={classes.paragraph}>
                        <ul>
                            <li>El titular deberá realizar el llenado físico de la Solicitud.</li>
                            <li>Una  vez  completada  la  Solicitud,  el  titular  deberá  escanearla  y  remitirla,  junto  con  su  identificación  oficial,  vía  correo  electrónico,
                            al  correo  electrónico aviso.privacidad@bontu.mx. En caso de que el titular realice la Solicitud por conducto de un representante legal,
                            éste deberá integrar al correo electrónico el documento que acredite dicha calidad.</li>
                            <li>Una vez recibida la Solicitud, El Responsable deberá:
                                <ol type="i">
                                    <li>En  caso  de  que  la  Solicitud  no  cumpla  con  los  requisitos  establecidos  más  adelante,  o  manifieste  información
                                    errónea,  imprecisa  o  incorrecta,  El Responsable podrá requerir al titular que, a través de correo electrónico dirigido a usted,
                                    o bien, por el medio que usted hubiese indicado en la Solicitud, subsane  la  deficiencia  presentada  en  su  Solicitud.
                                    El  titular  tendrá  un  plazo  de  10  (diez)  días  hábiles  para  atender  el  requerimiento  realizado  por  El Responsable.</li>
                                    <li>n  caso  de  que  la  Solicitud  se  encuentre  completa,  El  Responsable  deberá  atenderla  en  un  plazo  no  mayor  a
                                    20  (veinte)  días  hábiles  posteriores  a  la recepción de la Solicitud. Concluido el término indicado en el presente inciso,
                                    El Responsable dará a conocer la determinación adoptada al titular mediante el correo electrónico dirigido a usted, o bien,
                                    por el medio que usted hubiese indicado en la Solicitud.</li>
                                    <li>l  Responsable  deberá  hacer  efectivo  el  derecho  conforme  a  la  determinación  establecida  en  el  inciso  anterior
                                    en  los  15  (quince)  días  siguientes  a  la determinación, y proporcionará al titular la información requerida en copia fotostática,
                                    o bien, cuando las circunstancias lo permitan, en versión digital.</li>
                                </ol>
                            </li>
                        </ul>
                    </Typography>

                    <Typography  className={classes.paragraph}>Ejercicio del Derecho de Rectificación: El titular podrá requerir a El Responsable el ejercicio de su derecho de rectificación, entendido
                    éste como la solicitud de corrección de su información en caso de desactualización, o cuando ésta sea inexacta o se encuentre incompleta, a través del
                    siguiente procedimiento</Typography>

                    <Typography  className={classes.paragraph}>Acceder a la Solicitud a través del portal www.bontu.mx o requerir, vía telefónica al número (55) 52 92 75 11, que la Solicitud se le
                    haga llegar por correo electrónico; en ambos casos, el titular deberá descargar e imprimir la Solicitud.</Typography>

                    <Typography  className={classes.paragraph}>
                        <ul>
                            <li>El titular deberá realizar el llenado físico de la Solicitud.</li>
                            <li>Una vez completada la Solicitud, el titular deberá escanearla y remitirla, junto con su identificación oficial, vía correo electrónico,
                            al correo electrónico aviso.privacidad@bontu.mx. En caso de que el titular realice la Solicitud por conducto de un representante legal, éste
                            deberá integrar al correo electrónico el documento que acredite dicha calidad.</li>
                            <li>Para la rectificación, deberá incluir en la Solicitud el dato a rectificar e incluir en el correo electrónico la documentación que sustente
                            la rectificación, es decir, el comprobante que acredite la veracidad de los nuevos datos proporcionados.</li>
                            <li>Una vez recibida la Solicitud, El Responsable deberá:
                                <ol type="i">
                                    <li>(i)	En caso de que la Solicitud no cumpla con los requisitos establecidos más adelante, o manifieste información errónea, imprecisa
                                    o incorrecta, El Responsable podrá requerir al titular que, a través de correo electrónico dirigido a usted, o bien, por el medio que
                                    usted hubiese indicado en la Solicitud, subsane la deficiencia presentada en su Solicitud. El titular tendrá un plazo de 10 (diez)
                                    días hábiles para atender el requerimiento realizado por El Responsable.</li>
                                    <li>En caso de que la Solicitud se encuentre completa, El Responsable deberá atenderla en un plazo no mayor a 20 (veinte) días
                                    hábiles posteriores a la recepción de la Solicitud. Concluido el término indicado en el presente inciso, El Responsable dará a
                                    conocer la determinación adoptada al titular mediante correo electrónico dirigido a usted, o bien, por el medio que usted hubiese
                                    indicado en la Solicitud.</li>
                                    <li>El Responsable deberá hacer efectivo el derecho conforme a la determinación establecida en el inciso anterior en los 15
                                    (quince) días siguientes a la determinación y, en caso de que sea procedente, realizar la rectificación requerida por su titular.</li>
                                </ol>
                            </li>
                        </ul>
                    </Typography>

                    <Typography  className={classes.paragraph}>Ejercicio del Derecho de Cancelación: El titular podrá ejercer ante El Responsable su derecho a la cancelación de sus datos personales
                    entendido éste como la solicitud para el cese del tratamiento de datos por parte de El Responsable conforme a lo siguiente:</Typography>

                    <Typography  className={classes.paragraph}>Acceder a la Solicitud a través del portal www.bontu.mx o requerir, vía telefónica al número (55) 52 92 75 11, que la Solicitud se le
                    haga llegar por correo electrónico; en ambos casos, el titular deberá descargar e imprimir la Solicitud.</Typography>

                    <Typography  className={classes.paragraph}>
                        <ul>
                            <li>El titular deberá realizar el llenado físico de la Solicitud.</li>
                            <li>Una vez completada la Solicitud, el titular deberá escanearla y remitirla, junto con su identificación oficial, vía correo electrónico,
                            al correo electrónico aviso.privacidad@bontu.mx. En caso de que el titular realice la solicitud por conducto de un representante legal,
                            éste deberá integrar al correo electrónico el documento que acredite dicha calidad.</li>
                            <li>Para la cancelación, deberá incluir en la Solicitud el dato o los datos personales que desea que sean objeto de bloqueo para su
                            posterior supresión.</li>
                            <li>Una vez recibida la Solicitud, El Responsable deberá:
                                <ol type="i">
                                    <li>En caso de que la Solicitud no cumpla con los requisitos establecidos más adelante, o manifieste información errónea,
                                    imprecisa o incorrecta, El Responsable podrá requerir al titular que, a través de correo electrónico dirigido a usted, o bien,
                                    por el medio que usted hubiese indicado en la Solicitud, subsane la deficiencia presentada en su Solicitud. El titular tendrá
                                    un plazo de 10 (diez) días hábiles para atender el requerimiento realizado por El Responsable.</li>
                                    <li>En caso de que la Solicitud se encuentre completa, El Responsable deberá atenderla en un plazo no mayor a 20 (veinte) días
                                    hábiles posteriores a la recepción de la Solicitud. Concluido el término indicado en el presente inciso, El Responsable dará a
                                    conocer la determinación adoptada al titular mediante el correo electrónico dirigido a usted, o bien, por el medio que usted
                                    hubiese indicado en la Solicitud.</li>
                                    <li>El Responsable deberá hacer efectivo el derecho conforme a la determinación establecida en el inciso anterior en los 15
                                    (quince) días siguientes a la determinación y, en caso de que sea procedente, para lo cual, deberá bloquear el tratamiento de
                                    los datos personales para su posterior supresión.</li>
                                </ol>
                            </li>
                        </ul>
                    </Typography>

                    <Typography  className={classes.paragraph}>Ejercicio del Derecho de Oposición: El titular podrá ejercer en cualquier momento su derecho a la oposición de tratamiento de
                    sus datos personales, siempre y cuando exista una causa legítima que justifique su oposición al tratamiento de su información para las
                    finalidades específicas y que en caso de no atenderse representará un agravio al titular conforme a lo siguiente:</Typography>

                    <Typography  className={classes.paragraph}>Acceder a la Solicitud a través del portal www.bontu.mx o requerir, vía telefónica al número (55) 52 92 75 11, que la Solicitud
                    se le haga llegar por correo electrónico; en ambos casos, el titular deberá descargar e imprimir la Solicitud.</Typography>

                    <Typography  className={classes.paragraph}>
                        <ul>
                            <li>El titular deberá realizar el llenado físico de la Solicitud.</li>
                            <li>Una vez completada la Solicitud, el titular deberá escanearla y remitirla, junto con su identificación oficial, vía correo
                            electrónico, al correo electrónico aviso.privacidad@bontu.mx  En caso de que el titular realice la solicitud por conducto de
                            un representante legal, también deberá integrar al correo electrónico el documento que acredite dicha calidad.</li>
                            <li>Para la oposición, deberá incluir en la Solicitud de Ejercicio de Derechos ARCO los datos y fines específicos para los que
                            presenta la oposición y el agravio que se presentaría por su tratamiento.</li>
                            <li>Una vez recibida la Solicitud de Ejercicio de Derechos ARCO, El Responsable deberá:
                                <ol type="i">
                                    <li>En caso de que la Solicitud no cumpla con los requisitos establecidos más adelante, o manifieste información errónea,
                                    imprecisa o incorrecta, El Responsable podrá requerir al titular que, a través de correo electrónico dirigido a usted, o bien,
                                    por el medio que usted hubiese indicado en la Solicitud, subsane la deficiencia presentada en su Solicitud. El titular tendrá
                                    un plazo de 10 (diez) días hábiles para atender el requerimiento realizado por El Responsable.</li>
                                    <li>En caso de que la Solicitud se encuentre completa, El Responsable deberá atenderla en un plazo no mayor a 20
                                    (veinte) días hábiles posteriores a la recepción de la Solicitud. Concluido el término indicado en el presente inciso, El
                                    Responsable dará a conocer la determinación adoptada al titular mediante de correo electrónico dirigido a usted, o bien,
                                    por el medio que usted hubiese indicado en la Solicitud.</li>
                                    <li>El Responsable deberá hacer efectivo el derecho conforme a la determinación establecida en el inciso anterior
                                    en los 15 (quince) días siguientes a la determinación, para lo cual, en caso de que sea procedente la solicitud del titular,
                                    deberá incluir los datos del titular dentro de su listado de exclusión y cesar el tratamiento de los mismos.</li>
                                </ol>
                            </li>
                        </ul>
                    </Typography>
                    <Typography  variant="h6" className={classes.h6}>Requisitos de la Solicitud de Ejercicio de Derechos ARCO:</Typography>

                    <Typography  className={classes.paragraph}>Conforme a lo establecido en la presente sección, el titular podrá solicitar a El Responsable el ejercicio de sus derechos
                    ARCO por medio del documento denominado “Solicitud de Ejercicio de Derechos ARCO”, mismo que deberá contener como mínimo:</Typography>

                    <Typography  className={classes.paragraph}>
                        <ul>
                            <li>Nombre completo del titular.</li>
                            <li>Domicilio, correo electrónico u otro medio de contacto.</li>
                            <li>En los casos en los que se solicite la rectificación de datos personales, el titular deberá incluir las correcciones y la
                            documentación que sustente su petición.</li>
                            <li>Descripción clara y precisa de los datos personales sobre los que se ejercerá el derecho.</li>
                            <li>Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
                        </ul>
                    </Typography>

                    <Typography  className={classes.paragraph}>Para mayor facilidad y referencia en el ejercicio de sus derechos ARCO, hemos preparado una Solicitud de Ejercicio de Derechos
                    ARCO que podrá consultar a través del portal www.bontu.mx. Por favor considere que no en todos los casos podremos atender su solicitud o
                    concluir el tratamiento de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratado sus datos personales. </Typography>

                    <Typography  variant="h6" className={classes.h6}>Uso de Cookies</Typography>

                    <Typography  className={classes.paragraph}>Le informamos que en nuestra página de Internet utilizamos cookies a través de las cuales es posible monitorear su
                    comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página,
                    así como ofrecerle nuevos productos y servicios basados en sus preferencias.</Typography>

                    <Typography  className={classes.paragraph}>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo
                    de navegación en nuestra página de Internet, secciones consultadas, y páginas de Internet accedidas previo a la nuestra. Para
                    deshabilitarlas, deberá desactivar la opción de Cookies en su navegador. </Typography>

                    <Typography  variant="h6" className={classes.h6}>Limitación del uso o divulgación de sus datos personales</Typography>

                    <Typography  className={classes.paragraph}>Con el objeto de que usted pueda limitar el uso y divulgación de su información personal, a fin de no obtener
                    publicidad por nuestra parte, podrá inscribirse en el Registro Público de Usuarios administrado por la Comisión Nacional
                    para la Protección y Defensa de los Usuarios de Servicios Financieros (“CONDUSEF”). Para mayor información, consulte el
                    portal de Internet de la CONDUSEF.</Typography>

                    <Typography  className={classes.paragraph}>Además, usted podrá solicitar que sus datos personales no sean utilizados o divulgados para fines específicos
                    no necesarios expresados en el presente Aviso de Privacidad, a través de solicitud enviada por correo electrónico a la
                    dirección de correo electrónico aviso.privacidad@bontu.mx.</Typography>

                    <Typography  variant="h6" className={classes.h6}>Cambios al Aviso de Privacidad</Typography>

                    <Typography  className={classes.paragraph}>El presente Aviso de Privacidad podrá sufrir modificaciones, cambios y/o actualizaciones derivadas de nuevos
                    requerimientos legales, nuestras necesidades por los productos o servicios que ofrecemos, nuestras prácticas de privacidad,
                    nuestro modelo de negocio, o por otras causas. Sin embargo, nos comprometemos a mantenerlo informado sobre los cambios
                    que pueda sufrir el presente Aviso de Privacidad a través de la página del portal www.bontu.mx mostrando la última fecha
                    de actualización del presente Aviso de Privacidad.</Typography>

                    <Typography  className={classes.paragraph}>Usted acepta cuando se notifique/publique alguna modificación en el Aviso de Privacidad, y siga usando el sitio,
                    aplicaciones o cualquier otro medio tecnológico o contratando los servicios y productos de El Responsable, que se someterá
                    a las modificaciones realizadas, las cuales surtirán plenos efectos en la fecha de su publicación, salvo en aquellos casos
                    en que por disposición de la legislación aplicable sea necesario obtener su consentimiento expreso. </Typography>

                    <Typography  variant="h6" className={classes.h6}>Lesión a su derecho a la protección de datos personales</Typography>

                    <Typography  className={classes.paragraph}>Si usted considera que su derecho a la protección de datos personales ha sido lesionado por alguna conducta u
                    omisión por parte de El Responsable, o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección
                    de Datos Personales en Posesión de los Particulares, su Reglamento, y demás ordenamientos aplicables, podrá interponer su
                    inconformidad o denuncia ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
                    Personales (“INAI”). Para mayor información, consulte la página de internet del INAI.</Typography>

                    <Typography  variant="h6" className={classes.h6}>Datos de Contacto</Typography>

                    <Typography  className={classes.paragraph}>Oficial de Datos Personales: Marcela Esmeralda Estrella Flores<br/>
                    Domicilio: Avenida Antonio Dovalí Jaime, número 70, Torre C, Piso 7, Colonia Zedec Santa Fe, Alcaldía Álvaro Obregón, Ciudad de México, Código Postal 01210.<br/>
                    Teléfono: (55) 52 92 75 11<br/>
                    Correo Electrónico: aviso.privacidad@bontu.mx.</Typography>

                    <Typography style={{ textAlign: 'right',  width: '100%'}}><b>ÚLTIMA ACTUALIZACIÓN: JULIO DE 2020</b></Typography>

                </Grid>
            </Grid>
          </Card>
        </Grid>
    </Grid>
	);
};

// TermsAndConditions.prototype = {
// 	requestData: PropTypes.object
// }

export default PrivacyPolicy;