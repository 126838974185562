import {postBontu} from "./requests";

const postClientPath = "/client/oyo";

export function saveClientFormData(data){
	const body = {
		"platform": "oyo",
		data: data
	};
	return postBontu(postClientPath, {}, body);
}