import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import Carousel from './carousel';
import DataImage from './images'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    containerImage: {
        display: 'flex',
        position: 'relative',
        maxWidth: '100%',
        minHeight: '100px'
    },
    wraperImage: {
        position: 'relative',
        width: '100%',
        maxHeight: '300px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    img: {
        maxWwidth: '100%',
        maxHeight: '100%'
    },
    onboardingTitle: {
        fontSize: 18,
        paddingLeft: '0%',
        color: '#003B4A',
        fontWeight: 'bold',
        [theme.breakpoints.between('xs', 'sm')]:
        {
            paddingLeft: '5%',
            margin: 'red'
        }
    },
    stepNumber: {
        backgroundColor: 'transparent',
        position: 'center'
    },
    stepNumberContent: {
        height: '30px',
        width: '30px',
        display: 'inline-block',
        backgroundColor: '#00c08a',
        color: '#fff',
        borderRadius: '50%',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            height: '25px',
            width: '25px',
        },
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    centerLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    bold: {
        fontWeight: 'bold'
    },
    onboardingText: {
        marginTop: 10,
        minHeight: '80px',
        marginBottom: 30,
        color: '#5B6268',
        [theme.breakpoints.between('xs', 'sm')]: {
            marginBottom: 5
        }
    },
    onboardingTextDiv: {
        fontSize: '16px',
        fontStyle: 'Regular',
        fontFamily: "Roboto",
        fontWeight: '400px',
        lineHeight: '19px'
    }
}));

export default function Onboarding(props) {
    const classes = useStyles();

    let childrenCarousel = props.data.map(function (element, index) {
        return (
            <Grid key={`${'item-'}${index}`} item xs={12}>
                <Card key={index} className={classes.root} elevation={0}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className={classes.containerImage}>
                                <div className={classes.wraperImage}>
                                    <img className={classes.img}
                                        alt="onboarding"
                                        src={element.image ? DataImage[element.image] : element.imageUrl}
                                    />
                                </div>
                            </div>
                        </Grid>

                        {element.onboardingTitle ? (
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container>
                                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                        <div className={classes.stepNumber}>
                                            <div className={`${classes.stepNumberContent} ${classes.center} ${classes.bold}`}                                            >
                                                {String(index + 1)}
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.centerLeft}>
                                        <Typography className={classes.onboardingTitle} variant="h6" >{element.onboardingTitle}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        )
                            : null
                        }

                        {element.onboardingText ? (
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.onboardingText}>
                                <Grid container>
                                    {element.onboardingText.map((text, indexText) => {
                                        return (
                                            <Grid key={`${"onboardingText-"}${indexText.toString()}`} item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <div className={classes.onboardingTextDiv} dangerouslySetInnerHTML={{ __html: text }} />
                                            </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                            </Grid>
                        )
                            : null
                        }
                    </Grid>

                </Card>
            </Grid >

        );
    })

    return (
        <div>
            <Dialog
                open={props.open}
                disableBackdropClick={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                style={{
                    borderRadius: '10px',
                }}
            >
                <DialogContent style={{ maxWidth: '430px' }} data-test="dialogContent">
                    <Carousel children={childrenCarousel} handleClose={props.handleClose} />
                </DialogContent>
            </Dialog>
        </div >
    );
}