import React, { useState, useMemo, useCallback } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import environment from "../../environments/environment";
import { useDispatch } from "react-redux";
import { userData } from "../../store/actions/user.actions";
import { registerClient } from "../Services/clientService";
import { getUserInfo } from "../Services/generalDataAmazon";

Amplify.configure(environment.cognitoConfig);

const UserContext = React.createContext();

export const UserProvider = (props) => {
	const dispatch = useDispatch();
	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const logout = useCallback(() => {
		setUser(null);
		dispatch(userData(null));
		localStorage.removeItem("bontu_user");
		Auth.signOut();
	}, [dispatch]);

	const onLogin = useCallback(async () => {
		setLoading(true);
		const userInfo = await getUserInfo();
		dispatch(userData(userInfo));

		if (userInfo && userInfo.client.user_platform && userInfo.client.active) {
			localStorage.setItem("bontu_user", JSON.stringify(userInfo));
			setUser(userInfo);
			setLoading(false);
		} else {
			if (userInfo === null) {
				await clientRegister();
				const reloadUserInfo = await getUserInfo();
				dispatch(userData(reloadUserInfo));
				localStorage.setItem("bontu_user", JSON.stringify(reloadUserInfo));
				setUser(reloadUserInfo);
				setLoading(false);
			} else {
				setError("Invalid User");
				setLoading(false);
			}
		}
	}, [dispatch]);

	const clientRegister = async () => {
		const currentUser = await Auth.currentAuthenticatedUser();
		if (currentUser) {
			console.log(currentUser);
			const payloadUser = {
				platform_data: {
					name: currentUser.signInUserSession.getIdToken().payload.name,
					email: currentUser.signInUserSession.getIdToken().payload.email,
				},
				full_name:currentUser.signInUserSession.getIdToken().payload.name,
				email:currentUser.signInUserSession.getIdToken().payload.email,
				telephone: "",
				clabe: ""
			};

			const responseClient = await registerClient(payloadUser);
			if (responseClient.message !== "The client was created correctly") {
				setError("Invalid User");
				setUser(null);
			}
		} else {
			setError("Invalid User");
			setUser(null);
		}
	};

	const isAuthorized = useCallback(requiredControl => {
		let authUser = getAuthUser();
		let arrayControl = authUser.control.replace(/'/g, "\"");
		arrayControl = JSON.parse(arrayControl);
		let isAuth = false;
		arrayControl.forEach(control => {
			if (control === requiredControl) isAuth = true;
		});

		return isAuth;
	}, []);

	const getAuthUser = () => {
		return JSON.parse(localStorage.getItem("bontu_user"));
	};

	const value = useMemo(() => {
		return ({
			user,
			error,
			Hub,
			Auth,
			setUser,
			setError,
			logout,
			onLogin,
			isAuthorized,
			loading
		});
	}, [user, error, onLogin, logout, setError, isAuthorized, loading]);

	return <UserContext.Provider value={value} {...props} />;
};

export function useUser() {
	const context = React.useContext(UserContext);
	if (!context) {
		throw new Error("useUser must be into UserContext Provider");
	}
	return context;
}