import React, { useEffect, useState } from 'react';
import {
    Grid,
    CircularProgress,

    Hidden
} from '@material-ui/core';
import OfferCard from './OfferCard';
import OfferCarousel from './OfferCarousel';
import { getOffersFromEvaluationResult } from "../../../Services/generalDataAmazon";

const MainOfferCard = ({ listOfferhandler }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (data.length === 0) {
            getOffersFromEvaluationResult().then((response) => {
                setData(response);

            }).catch((error) => {
                console.log(error);
            });
        }
    },[]);

    return (
        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
            {
                Array.isArray(data.rows) ?
                    data.rows.map((element, index) => {
                        return (
                            <Hidden only={['sm', 'xs']}>
                                <Grid key={`${'MainOfferCard-'}${index.toString()}`} item xl={4} lg={4} md={6} xs={12} >
                                    <OfferCard
                                        key={`${'OfferCard'}${index.toString()}`}
                                        index={(index + 1)}
                                        amount={element.max_offer}
                                        months={element.term}
                                        retention={(element.retention * 100)}
                                        data={element}
                                        onClick={listOfferhandler}
                                    />
                                </Grid>
                            </Hidden>

                        );
                    })
                    : <CircularProgress style={{ color: '#00c08a' }}/>
            }
            <Hidden only={['md', 'lg', 'xl']}>
                <OfferCarousel children={Array.isArray(data.rows) ? data.rows.map(function (element, index) {
                    return (
                        <OfferCard
                                                key={`${'OfferCard'}${index.toString()}`}
                                                index={(index + 1)}
                                                amount={element.max_offer}
                                                months={element.term}
                                                retention={(element.retention * 100)}
                                                data={element}
                                                onClick={listOfferhandler}
                                            />
                    )
                }) : <CircularProgress />
            } />
            </Hidden>
        </Grid>
    );
}

export default MainOfferCard;