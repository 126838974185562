const currencyFormat = new Intl.NumberFormat('es-MX', { currency: 'MXN', style: 'currency' });

const amountFormat = (number) => {
    return currencyFormat.format(number);
};

const getMonthByDay = (date) => {
    return new Intl.DateTimeFormat('es-MX', {  month: 'long' }).format(date)
}

export { amountFormat, getMonthByDay };