import React, { useEffect } from "react";
import { useUser } from "../../Context/UserContext";
import SplashScreen from "../../sharedComponents/SplashScreen";
import { Redirect } from "react-router-dom";

export default function Callback() {
	const { onLogin, user } = useUser();

	useEffect(() => {
		onLogin();
	}, [onLogin]);

	if (user && user.platform.name === "oyo") {
		return <Redirect to="/records" />;
	} else if (user && user.platform.name) {
		return <Redirect to="/request" />;
	} else {
		return <SplashScreen />;
	}
}