import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert  from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

export default function SnackBar(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				autoHideDuration={5000}
				open={props.open}
				onClose={props.handleClose}
			>
				<Alert onClose={props.handleClose} variant="filled" severity={props.bodySnackBar.severity}>
					{props.bodySnackBar.message}</Alert>
			</Snackbar>
		</div>
	);
}