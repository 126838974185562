import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DataGrid from '../../../sharedComponents/DataGrid/DataGrid';
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { amountFormat } from '../../../sharedFunction/utils';
import { getAmortizationTable } from "../../../Services/generalDataAmazon";

const useStyles = makeStyles(() => ({
    backgroundSquare: {
        width: "100%",
        background: "#eeeeee",
        padding: "25px"
    }
}));


export default function AmortizationTable(props) {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const { t } = useTranslation("UserApp");

    //Table
    const columnDefs = [
        {
            field: "period",
            headerName: t("PERIOD"),
            maxWidth: 100
        },
        {
            field: "main_balance",
            headerName: t("MAIN_BALANCE"),
            cellRenderer(params) {
                return amountFormat(params.data.main_balance);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.main_balance}`;
            }
        },
        {
            field: "disbursements",
            headerName: t("DISBURSEMENTS"),
            cellRenderer(params) {
                return amountFormat(params.data.disbursements);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.disbursements}`;
            }
        },
        {
            field: "main_amortization",
            headerName: t("AMORTIZATION"),
            cellRenderer(params) {
                return amountFormat(params.data.main_amortization);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.main_amortization}`;
            }
        },
        {
            field: "capitalization",
            headerName: t("CAPITALIZATION"),
            cellRenderer(params) {
                return amountFormat(params.data.capitalization);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.capitalization}`;
            }
        },
        {
            field: "final_balance",
            headerName: t("FINAL_BALANCE"),
            cellRenderer(params) {
                return amountFormat(params.data.final_balance);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.final_balance}`;
            }
        },
        {
            field: "accrued_interest",
            headerName: t("ACCRUED_INTEREST"),
            cellRenderer(params) {
                return amountFormat(params.data.accrued_interest);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.accrued_interest}`;
            }
        },
        {
            field: "payed_interest",
            headerName: t("PAYED_INTEREST"),
            cellRenderer(params) {
                return amountFormat(params.data.payed_interest);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.payed_interest}`;
            }
        },
        {
            field: "remainder",
            headerName: t("REMAINDER"),
            cellRenderer(params) {
                return amountFormat(params.data.remainder);
            },
            autoHeight: true,
            valueGetter(params) {
                return `${params.data.remainder}`;
            }
        },
        {
            field: "settlement_month",
            headerName: t("SETTLEMENT_MONTH")
        }
    ];

    const defaultColDef = {
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 200
    };

    useEffect(() => {

        getAmortizationTable({ amount: props.amount, request_id: props.request_id }).then((response) => {
            setData(response["Amortization Table"]);
        }
        ).catch((error) => {
            console.log(error);
        });
    }, [props.amount, props.request_id]);

    return (
        <div className={classes.backgroundSquare}>
            <Typography variant="h6" component="h2" color="primary" className={classes.Typography} data-test="title">
                {t("AMORTIZATION_TABLE")}
            </Typography>
            <div
                style={{
                    height: "250px",
                    width: "100%",
                    cursor: "context-menu"
                }}
            >
                <DataGrid
                    data-test="dataAmotizationTable"
                    columnDefs={columnDefs}
                    data={data ? data : []}
                    defaultColDef={defaultColDef}
                />
            </div>
        </div>
    );
}