import {getBontu, postBontu} from "./requests";


const kycBasePath = "/oyo/kyc-questions";
const recordsPath = "/request/oyo/";

export function getKycFormData(){    
	return getBontu(kycBasePath, {}, {user_platform: "DEFAULT"});
}

export function getRecordsData(){    
	return getBontu(recordsPath, {}, {});
}

export function saveKycFormData(data, user_platform){
	const body = {
		user_platform: user_platform,
		data: data
	};
	return postBontu(kycBasePath, {}, body);
}