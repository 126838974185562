import React, { useEffect, useState } from 'react';
import {
    Grid, Typography, Card, CardContent, Button, FormControl, TextField
} from "@material-ui/core";

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateRangeIcon from '@material-ui/icons/DateRange';

import GraphicCredit from '../../../sharedComponents/graphicCredit/graphicCredit';
import PaymentsTable from "../PaymentsTable/paymentsTable"
import TableCredit from "../../../sharedComponents/tableCredit/tableCredit";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { getHistorico, getCreditParams, solicitudesCalculos, postCredito, getUserInfo } from "../../../Services/generalDataAmazon";

const useStyles = makeStyles((theme) => ({
    backgroundSquare: {
        padding: "25px",
        textAlign: "left"
    },
    squareIndex: {
        borderLeft: "15px solid",
        borderColor: "#00c08b",
        paddingLeft: "5px",
        borderTopLeftRadius: "8px",
        marginBottom: "10px",
        marginLeft: "10px"
    },
    cards: {
        backgroundColor: "#1c6c80",
        color: "white !important",
        borderRadius: "0px",
    },
    cardsInfo: {
        border: "1px solid #00c08b",
        textAlign: "left",
        borderRadius: "10px",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    button: {
        backgroundColor: "#00c08b",
        color: "white",
        padding: "6px 20px",
        borderRadius: "0px",
        borderTopRightRadius: "10px",
        boxShadow: "1px 1px 4px 0px #717171",
        '&:hover': {
            backgroundColor: "#00c08b",
        },
    },
    root: {
        width: "100%",
    }
}));

export default function SomosFlexibles(props) {
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    // Format $
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    function formatNumber(value) {
        var result = formatter.format(value)
        return result
    }

    // Personalize offer
    const [ofertaPersonalizada, setOfertaPersonalizada] = useState(false)
    const [showPersonalizarMiOferta, setShowPersonalizarMiOferta] = useState(false)
    const PersonalizeMyOffer = (data) => (event) => {
        if (data === false) {
            let array = {
                cantidad: montoPreautorizado,
                porcentaje: datosCotizador.porcentaje,
                meses: datosCotizador.meses
            }
            setDatosCotizador(array)
        }
        setShowPersonalizarMiOferta(data)
    }

    // VARIABLES

    // Datos componente gráfica}
    const [periodoTableGrafica, setPeriodoTableGrafica] = useState([])
    const [descuentoTableGrafica, setDescuentoTableGrafica] = useState([])
    const [labelsTableGrafica, setLabelsTableGrafica] = useState([])
    var descuentoTableGraficaIn = []
    var labelsTableGraficaIn = []
    const [descuentoTableCotizadorGrafica, setDescuentoTableCotizadorGrafica] = useState([])
    var descuentoTableCotizadorGraficaIn = []

    const [tasafijaanual, setTasafijaanual] = useState(0)
    const [cat, setCat] = useState(0);
    const [creditParams, setCreditParams] = useState(null)
    var comisionApertura = 0
    
    var pagoTotal = 0;
    var constanteCalculadora_initial = {
        'percentRenevue': 0,
        'tasaInteres': 0.,
        'iva': 0,
        'openFee': 0
    };
    const [constanteCalculadora, setConstanteCalculadora] = useState(constanteCalculadora_initial)

    // Variables para la calculadora 
    const [dataHistory, setDataHistory] = useState(null)
    var dataTable = []

    const [mesesTexto, setMesesTexto] = useState('false')
    var datosCotizador_initial = {
        cantidad: formatter.format(null),
        porcentaje: 0,
        meses: 12
    }
    const [datosCotizador, setDatosCotizador] = useState(datosCotizador_initial)
    const [montoPreautorizado, setMontoPreautorizado] = useState(0)
    var montoMinimo = 0
    const [plazo, setPlazo] = useState(12)
    const [desVentas, setDesVentas] = useState(0)
    

    // Datos necesarios para que funcione la grafica
    const [periodo, setPeriodo] = useState([])
    
    const [calculos, setCalculos] = useState({})

    var tablaResumen_initial = {
        request: {
            maxamount: 0,
            amount: 0,
            terms: 0,
            discountpercent: 0,
            interestrate: 0,
            comisionApertura: 0,
            montoRecibir: 0
        }
    }

    const [tablaResumen, setTablaResumen] = useState(tablaResumen_initial);

    const [tablaResumenCotizador, setTablaResumenCotizador] = useState({})
    const [isTablaResumen, setIsTablaResumen] = useState(false)
    const [ultimopagomayorcero, setUltimopagomayorcero] = useState(0)
    var ultimopagomayorceroIn = 0
   
    var tablaResumenCotizadorIn = {}
    // 

    const handleChangeState = (event) => {
        if (event.target.value > montoPreautorizado || event.target.value < montoMinimo) {
            mascaraInput(montoPreautorizado)
        } else {
            mascaraInput(event.target.value)
        }
    }

    async function getMontoMinimo(data_calculos) {
        if (!data_calculos) {
            montoMinimo = 2000;
            return;
        }

        montoMinimo = 2000;
    }
    
    function mascaraInput(input) {
        datosCotizador_initial.cantidad = 0;
        const cantidad = Number(input);
        datosCotizador_initial.cantidad = cantidad;
        datosCotizador_initial.porcentaje = desVentas;
        setDatosCotizador(datosCotizador_initial)
        setMesesTexto(plazo)
    }

    function calcularMontoMaximo(historial) {
       
        dataTable = [];
        setPlazo(12);
    

        const monto = [];
        for (let i = 0; i < plazo; i++) {
            monto.push(periodo[i]);
        }
        
        mascaraInput(montoPreautorizado);
        let montoRecibir_;
        let saldo = montoPreautorizado;
        let interes = 0;
        let iva = 0;
        let capital = 0;
        let pago = 0;
        
        
        for (let i = 0; i < plazo; i++) {
            interes = (saldo * constanteCalculadora.tasaInteres) / plazo;
            iva = interes * constanteCalculadora.iva;
            //
            if (saldo - (((periodo[i] * desVentas) - interes) - iva) < 0) {
                //
                capital = Number(saldo);
            } else {
                //
                capital = (((periodo[i] * desVentas) - interes) - iva);
            }
            pago = capital + interes + iva;
            
            saldo -= capital;
            const year = historial[i].year + 1;
            labelsTableGraficaIn.push((new Date(year, dataHistory[i]['month'] - 1)).toDateString());
            dataTable.push({
                mes: (new Date(year, historial[i].month - 1)).toDateString(),
                historia: monto[i],
                descuento: monto[i] * desVentas
            });
            if (pago > 0) {
                ultimopagomayorceroIn = i;
                descuentoTableGraficaIn.push(dataTable[i]['descuento']);
            }
        }
        setUltimopagomayorcero(ultimopagomayorceroIn)
        setLabelsTableGrafica(labelsTableGraficaIn)
        setDescuentoTableGrafica(descuentoTableGraficaIn)

        // this.labelultimopagomayorcero = this.data.labels[ultimopagomayorcero];
       
        comisionApertura = constanteCalculadora.openFee;
        montoRecibir_ = montoPreautorizado * (1 - (constanteCalculadora.openFee * (1 + constanteCalculadora.iva)));
        tablaResumen_initial = {
            request: {
                maxamount: montoPreautorizado,
                amount: montoPreautorizado,
                terms: plazo,
                discountpercent: desVentas,
                interestrate: creditParams.interest_rate,
                comisionApertura: comisionApertura,
                montoRecibir: montoRecibir_
            }
        };
        setTablaResumen(tablaResumen_initial)
        setIsTablaResumen(true)
    }

    function cambiarPorcentaje() {
        const meses = Number(mesesTexto);
        datosCotizador.meses = meses;
    }
    function cotizador(cantidad, plazo_get, porcentaje_get) {
        let periodo2 = periodo;
        let newPeriodo = periodo2.concat(periodo);

        porcentaje_get = porcentaje_get > 1 ? porcentaje_get / 100 : porcentaje_get;
        setPlazo(plazo_get);
        descuentoTableCotizadorGraficaIn = [];
        cantidad = Number(cantidad);
        pagoTotal = 0;
        dataTable = [];
        
        let montoRecibir_ = cantidad * (1 - (constanteCalculadora.openFee * (1 + constanteCalculadora.iva)));
        let saldo = Number(cantidad);
        let interes = 0;
        let iva = 0;
        let capital = 0;
        let pago = 0;

        //AQUI EMPIEZAN LOS CALCULOS
        for (let i = 0; i < plazo_get; i++) {
            //console.log("entra", i)
            interes = (saldo * constanteCalculadora.tasaInteres) / plazo_get;
            iva = interes * constanteCalculadora.iva;
            if (saldo - (((newPeriodo[i] * porcentaje_get) - interes) - iva) < 0) {
                capital = Number(saldo);
            } else {
                capital = (((newPeriodo[i] * porcentaje_get) - interes) - iva);
            }
            pago = capital + interes + iva;
            pagoTotal += pago;
            saldo -= capital;
            const year = dataHistory[0]['year'] + 1;
            //
            //
            dataTable.push({
                mes: (new Date(year, dataHistory[0]['month'] + i - 1)).toDateString(),
                descuento: pago ? pago : 0,
                historia: newPeriodo[i]
            });

            if (pago > 0) {
                ultimopagomayorceroIn = i;
                descuentoTableCotizadorGraficaIn.push(dataTable[i]['descuento']);
            }
        }
        setUltimopagomayorcero(ultimopagomayorceroIn)
        setDescuentoTableCotizadorGrafica(descuentoTableCotizadorGraficaIn)
        comisionApertura = constanteCalculadora.openFee;
        
        tablaResumenCotizadorIn = {
            request: {
                maxamount: montoPreautorizado,
                amount: datosCotizador.cantidad,
                terms: datosCotizador.meses,
                discountpercent: (Number(datosCotizador.porcentaje)),
                interestrate: creditParams.interest_rate,
                comisionApertura: comisionApertura,
                montoRecibir: montoRecibir_
            }
        };
        setTablaResumenCotizador(tablaResumenCotizadorIn)
    }
    function personalizarOferta() {
        datosCotizador_initial.porcentaje = .20;
        datosCotizador_initial.cantidad = datosCotizador.cantidad;
        datosCotizador_initial.meses = plazo;
        setDatosCotizador(datosCotizador_initial)
        cambiarPorcentaje();
        cotizador(datosCotizador.cantidad, datosCotizador.meses, datosCotizador.porcentaje);
        setOfertaPersonalizada(true)
        setShowPersonalizarMiOferta(false)
    }

    async function guardarCredito(body) {
        await postCredito(body).then((response) => {
                        }
                        ).catch((error) => {
                            console.log(error);
                        });

        const new_props = await getUserInfo().then((responseUserInfo) => {
            return responseUserInfo
        }
        ).catch((error) => {
            console.log(error);
        });
        new_props.requiredDocs = props.location.state.requiredDocs
        props.history.push({ pathname: '/request/credit-resume', state: new_props });
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    function solicitarCredito() {
        const d = new Date();
        const timef = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
        d.setMonth(d.getMonth() + 1);
        const datef = formatDate(d)
        d.setMonth(d.getMonth() + 1);
        const date = formatDate(d)

        const userCredit = {
            'terms': 0,
            'discountpercent': 0,
            'cat': creditParams.cat,
            'moratorio_interestrate': creditParams.moratorium_interest,
            'interestrate': constanteCalculadora.tasaInteres,
            'amount': 0,
            'totalpayment': 0,
            'commissionpercent': constanteCalculadora.openFee,
            'validuntil_date': date,
            'datef': datef,
            'timef': timef,
            'loanamount': 0,
            'percint': constanteCalculadora.tasaInteres,
            'type': creditParams.type,
            'maxamount': montoPreautorizado,
            "payments_list": {}
        };

        cotizador(datosCotizador.cantidad, plazo, desVentas);
        userCredit.loanamount = datosCotizador.cantidad - (datosCotizador.cantidad * constanteCalculadora.openFee);
        userCredit.terms = datosCotizador.meses;
        userCredit.discountpercent = desVentas;
        userCredit.amount = datosCotizador.cantidad;
        userCredit.totalpayment = pagoTotal;

        userCredit.payments_list = dataTable
        guardarCredito(userCredit);
    }
    function solicitarCreditoMaxima() {
        calcularMontoMaximo(dataHistory);
        solicitarCredito()
    }

    function resetOffer() {
        calcularMontoMaximo(dataHistory);
        setOfertaPersonalizada(false);
    }

    // GET LAMBDAS
    useEffect(() => {
        getHistorico().then((res) => {
            let result = res.items
            let periodoArray = []
            let periodoTableGraficaArray = []

            result.forEach(element => {
                periodoArray.push(element.sale)
                periodoTableGraficaArray.push(element.sale)
            });

            setPeriodo(periodoArray)
            setPeriodoTableGrafica(periodoTableGraficaArray)
            setDataHistory(res.items)
        }
        ).catch((error) => {
            console.log(error);
        });
        getCreditParams().then((res) => {
            let creditParamsData = res;
            let percentRenevue = creditParamsData.max_percent_revenue > 1 ? creditParamsData.max_percent_revenue / 100 : creditParamsData.max_percent_revenue;

            var constanteCalculadora_wrapper = {
                'percentRenevue': percentRenevue,
                'tasaInteres': creditParamsData.interest_rate,
                'iva': creditParamsData.taxes,
                'openFee': creditParamsData.opening_fee
            };
            setConstanteCalculadora(constanteCalculadora_wrapper);
            setDesVentas(percentRenevue);
            setMontoPreautorizado(creditParamsData.max_offer);
            setCat(creditParamsData.cat);
            setTasafijaanual(creditParamsData.annual_rate);

            localStorage.setItem('creditScore', JSON.stringify(creditParamsData));

            setCreditParams(res);
        }
        ).catch((error) => {
            console.log(error);
        });

        solicitudesCalculos(props.location.state.platform.name).then((res) => {
            setCalculos(res)
        }
        ).catch((error) => {
            console.log(error);
        });
    }, []);

    // Initialize functions
    useEffect(() => {
        if(dataHistory !== null && creditParams !== null)
        {
            getMontoMinimo(calculos);
            calcularMontoMaximo(dataHistory);
        }
    }, [dataHistory, creditParams, calculos]);

    return (
        <div style={{
            margin: "15px 10px 10px 15px"
        }}>

            <div className={classes.backgroundSquare}>
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.squareIndex}>
                        <Typography variant="h5" align="left" style={{ color: '#033a49', fontWeight: 'bold' }} data-test="title">Bienvenido {props.location.state.platform_data.name}, tienes un crédito pre-autorizado de</Typography>
                    </Grid>

                    <Grid container spacing={2} item xs={12} justify='center' alignItems='center'>
                        {/* Cuadros con monto, plazo y retencion */}
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cards} style={{ borderTopLeftRadius: '15px' }}>
                                <CardContent style={{ padding: '20px' }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <AttachMoneyIcon style={{ color: '#00c08b' }}></AttachMoneyIcon>
                                        {t("AMOUNT")}
                                    </Typography>
                                    {!showPersonalizarMiOferta ? <Typography variant="h5" align="left">{formatNumber(datosCotizador.cantidad)}</Typography> 
                                                                : <FormControl>
                                                                    <TextField
                                                                        type="number"
                                                                        id="amount-input"
                                                                        size="small"
                                                                        placeholder="Dinero"
                                                                        required
                                                                        inputProps={{ min: montoMinimo, max: montoPreautorizado }}
                                                                        name="amount"
                                                                        value={datosCotizador.cantidad}
                                                                        onChange={handleChangeState}
                                                                    />
                                                                 </FormControl>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cards}>
                                <CardContent style={{ padding: '20px' }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <DateRangeIcon style={{ marginRight: '5px', color: '#00c08b' }}></DateRangeIcon>
                                        {t("TERM")}
                                    </Typography>
                                    <Typography variant="h5" align="left">{plazo} {plazo > 1 ? t("MONTHS") : t("MONTH")}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cards} style={{ borderTopRightRadius: '15px' }}>
                                <CardContent style={{ padding: '20px' }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <span style={{ color: '#00c08b', fontWeight: 'bold', marginRight: '5px' }}>%</span>
                                        {t("RETENTION")}
                                    </Typography>
                                    <Typography variant="h5" align="left">
                                        {(ofertaPersonalizada ? (datosCotizador.porcentaje * 100) + '%' : (desVentas*100) + '%')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {showPersonalizarMiOferta ? <Grid container justify='flex-end' item xs={12} md={12} style={{ marginTop: '20px', }}>
                                                    <Button variant='contained' className={classes.button} style={{ backgroundColor: 'red', borderRadius: '30px' }} onClick={PersonalizeMyOffer(false)}>Cancelar</Button>
                                                    <Button variant='contained' className={classes.button} style={{ marginLeft: '10px', marginRight: '10px', borderRadius: '30px' }} onClick={personalizarOferta}>Cotizar</Button>
                                                </Grid> : null}

                    {!showPersonalizarMiOferta ? <Grid container spacing={2} item xs={12} style={{ marginTop: '20px' }}>
                                                    <Grid container justify='flex-end' item xs={12} md={12} style={{ marginBottom:'20px' }}>
                                                        <Button onClick={PersonalizeMyOffer(true)} variant='contained' style={{ backgroundColor: '#1c6c80', borderTopRightRadius: "0px", borderTopLeftRadius: "10px", }} className={classes.button}>Prefiero personalizar mi oferta</Button>
                                                        <Button variant='contained' className={classes.button} style={{ marginLeft: '10px' }} onClick={solicitarCredito}>¡Quiero mi oferta máxima!</Button>
                                                    </Grid>
                                                </Grid> : null}

                    <Grid container item xs={12}>
                        <Grid container item xs={12} md={6}>
                            {!ofertaPersonalizada ? <Grid item xs={12}>{isTablaResumen === true ? <TableCredit data={tablaResumen} /> : null}</Grid> : <Grid item xs={12}>{isTablaResumen === true ? <TableCredit data={tablaResumenCotizador} /> : null}</Grid>}
                            
                            <Grid item xs={12}>
                                <GraphicCredit data={tablaResumen} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <PaymentsTable percentRevenue={props.location.state.request.discountpercent} /> */}
                            {!ofertaPersonalizada ? <PaymentsTable periodoTableGrafica={periodoTableGrafica} descuentoTable={descuentoTableGrafica} labels={labelsTableGrafica} ultimopagomayoracero={ultimopagomayorcero} />
                                                : <PaymentsTable periodoTableGrafica={periodoTableGrafica} descuentoTable={descuentoTableCotizadorGrafica} labels={labelsTableGrafica} ultimopagomayoracero={ultimopagomayorcero} /> }
                        </Grid>
                    </Grid>

                    {!showPersonalizarMiOferta ? <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography variant='body1' style={{ marginTop: '40px', color: '#b5b3b3', fontSize: '16px', padding: '0px 15%' }}>
                                                            CAT promedio {cat * 100}% sin IVA para fines informativos y de comparación exclusivamente.
                                                            Fecha de cálculo: 18 de julio de 2018. Tasa fija anual de {tasafijaanual * 100}% sin IVA.
                                                            Cifras expresadas en Pesos M. N.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid style={{ textAlign: 'center', marginTop: '30px' }} item xs={12}>
                                                        {ofertaPersonalizada ? <Button style={{ marginRight: '10px', borderTopRightRadius: '0px', borderBottomRightRadius: '10px' }} className={classes.button} onClick={resetOffer} >Regresar a oferta máxima</Button> : null}
                                                        <Button className={classes.button} style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '10px' }} onClick={solicitarCreditoMaxima} >Solicitar esta cantidad</Button>
                                                    </Grid>
                                                </Grid> : null}

                </Grid>
            </div>
        </div>
    );
}