import React, { useEffect } from "react";
import {
	makeStyles,
	AppBar,
	Toolbar,
	Typography,
	Link,
	IconButton,
	Menu,
	MenuItem
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PersonIcon from '@material-ui/icons/Person';
import { useUser } from "../Context/UserContext";
import { useSelector } from "react-redux";
import bontu from "../../assets/images/logos/bontu.png";

const useStyles = makeStyles((theme) => ({
	header: {
		position: "static",
		backgroundColor: theme.palette.common.white,
	},
	grow: {
		flexGrow: 1,
	},
	title: {
		[theme.breakpoints.between('xs', 'sm')]: {
			display: "none"
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(2),
		}
	},
	logo: {
		[theme.breakpoints.between('xs', 'sm')]: {
			height: theme.spacing(4),
		},
		[theme.breakpoints.up('md')]: {
			height: theme.spacing(4),
			marginLeft: theme.spacing(12),
		}
	},
	sectionDesktop: {
		display: "flex",
		marginRight: '10px',
		marginTop: '10px',
		marginBottom: '10px'
	},
	userBtn: {
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"
	},
	userName: {
		[theme.breakpoints.between('xs', 'sm')]: {
			display: "none"
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: "22px",
			marginTop: "15%",
		}
	}
}));

export default function Header() {
	const { logout } = useUser();
	const user = useSelector((state) => state.user.userInfo);

	const classes = useStyles();
	let history = useHistory();
	const [userName, setUserName] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const [bontuUser, setBontuUser] = React.useState(null);

	useEffect(() => {
		setBontuUser(user);
		setUserName(user?.client.names);
	}, [user]);

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const onLogout = () => {
		setAnchorEl(null);
		setBontuUser(null);
		logout();
		history.push("/");
		window.location.reload();
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={onLogout}>Logout</MenuItem>
		</Menu>
	);

	return (
		<div className={classes.grow}>
			<AppBar className={classes.header}>
				<Toolbar>
					<Link href="" underline="none">
						<img src={bontu} className={classes.logo} alt="logo" />
					</Link>
					<Typography variant="body1" component="h6">
						<Link className={classes.title} onClick={() => history.push("/")} underline="none" color="primary">
							{"Inicio"}
						</Link>
					</Typography>
					{bontuUser && <div className={classes.grow} />}
					{bontuUser && <div className={classes.sectionDesktop}>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="primary"
							className={classes.userBtn}
						>
							<PersonIcon />

						</IconButton>
						{userName && <Typography variant="body2" color="textSecondary" className={classes.userName}>{userName}</Typography>}
					</div>
					}
				</Toolbar>
			</AppBar>
			{renderMenu}
		</div>
	);
}