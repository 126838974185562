import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
	root: {
        width: "100%",
        height: "auto"
    },
    Typography: {
        fontSize: 24,
        marginBottom: 10,
    },
    table: {
        minWidth: 650,
    },

}));

export default function TableCredit (props) {
    const [percentFormat, setFormat] = useState('');
    const [retention, setFormatRetention] = useState('');
    let meses = 'mes';

    useEffect(() => {
        console.log(props.data.request);
        let formatter = new Intl.NumberFormat("en-US", option);
        setFormat(formatter.format(props.data.request.interestrate));
        setFormatRetention(formatter.format(props.data.request.discountpercent));
        console.log(percentFormat);
        if(props.data.request.terms>1){
            meses = 'meses';
        }
    }, [meses]);
	const classes = useStyles();
    const { t } = useTranslation("UserApp");


    var option = {style: 'percent'};

	return (
        <Card className={classes.root} >
            <CardContent>
                <Typography variant="h6" component="h2" className={classes.Typography} data-test="title">
                    {t("CREDIT_SUMMARY")}
                </Typography>
                <List component="nav" aria-label="main mailbox folders" data-test="table-credit">
                    <ListItem><ListItemText primary={'Línea pre-autorizada: ' + Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(props.data.request.maxamount)} /></ListItem>
                    <ListItem><ListItemText primary={'Monto solicitado: ' +  Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(props.data.request.amount)}/></ListItem>
                    <ListItem><ListItemText primary={'Plazo: ' + props.data.request.terms + ' ' + meses}/></ListItem>
                    <ListItem><ListItemText primary={'Retención sobre ventas: ' + retention}/></ListItem>
                    <ListItem><ListItemText primary={'Tasa de interés mensual: ' + percentFormat}/></ListItem>
                    <ListItem><ListItemText primary={'Comisión por apertura: ' + Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(props.data.request.interestrate)}/></ListItem>
                    <ListItem><ListItemText primary={'Monto a recibir: ' + Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(props.data.request.amount)}/></ListItem>
                    <ListItem><ListItemText primary='* En Bontu, la tasa de interés no genera IVA'/></ListItem>
                </List>
            </CardContent>
        </Card>
	);
}