import React from 'react'
import { Typography, makeStyles, Grid  } from '@material-ui/core'
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    fondo: {
        backgroundColor: "#003B4A",
        margin: "auto",
        width: "100%",
        height: "100%"
    },
    textOne:{
        color: "#FFFFFF",
        fontFamily: 'Montserrat',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '14px',
            marginTop: '15px',
            marginLeft: '10px'
        },
        [theme.breakpoints.between('768', '1024')]: {
            width: '60%',
            fontSize: '20px',
            marginTop: '15%',
            marginLeft: '15%'
        },
        [theme.breakpoints.between('1024', '1366')]: {
            width: '100%',
            fontSize: '30px',
            marginTop: '15%',
            marginLeft: '15%'
        },
        [theme.breakpoints.up('1280', '950')]: {
            width: '80%',
            fontSize: '40px',
            marginTop: '15%',
            marginLeft:'20%'
        }
    },
    textTwo:{
        color: "#FFFFFF", 
        fontFamily: 'Montserrat',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '12px',
            marginLeft: '10px',
            marginTop:'3%'
        },
        [theme.breakpoints.between('768', '1024')]: {
            fontSize: '16px',
            marginTop: '20%',
            marginLeft: '10%'
        },
        [theme.breakpoints.between('1024', '1366')]: {
            fontSize: '22px',
            marginTop: '30%',
            marginLeft: '10%'
        },

        [theme.breakpoints.up('1280', '950')]: {
            width: '80%',
            fontSize: '32px',
            marginTop: '30%',
            marginLeft: '10%'
        }
    },

    logo:{
        [theme.breakpoints.between('xs', 'sm')]: {
            width:'30%',
            float: 'right',
            marginTop: '20px',
            textAlign: 'center'
        },
        [theme.breakpoints.between('768', '1024')]: {
            width:'40%',
            float: 'right',
            marginTop: '7.5%',
            textAlign: 'left'
        },
        [theme.breakpoints.between('1024', '1366')]: {
            width:'40%',
            float: 'right',
            marginTop: '15%',
            textAlign: 'left'
        },
        [theme.breakpoints.up('1280', '950')]: {
            width:'40%',
            float: 'right',
            marginTop: '15%',
            textAlign: 'center'
        }
    },
    
}));



const BackgroundHeader = ({ ImagenHeader }) => {
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    return (
        <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={7} xl={9}>
                <div style={{
                    backgroundImage: "url(" + ImagenHeader + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: '800px',
                    padding: "30%",
                    margin: " 0 auto",
                    maxWidth: '40%'
                }} data-test="header-img">

                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={3}>
                <Grid className={classes.fondo} >
                    <Grid className={classes.fondo}>
                        <div>

                            <div class="capital" style={{ width: '50%' }} data-test="titulo">
                                <Typography className={classes.textOne}>{t('WORKING_CAPITAL')}</Typography>
                            </div>


                            <div class="simboloCT" className={classes.logo} data-test="logo-svg">
                                <svg style={{ padding: "3px", marginLeft: "10%" }} width="26.5%" height="22.7%" viewBox="0 0 67 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M58.4302 1.86426H2.03906V31.7563C2.05017 33.9638 2.32802 36.162 2.86658 38.3029C6.34218 49.6767 16.5023 57.86 28.529 57.86H58.4302C60.3763 57.8189 62.2268 57.0082 63.5763 55.6053C64.9258 54.2024 65.6641 52.3218 65.6296 50.3755V9.38552C65.6516 8.41873 65.4827 7.4571 65.1327 6.55564C64.7826 5.65418 64.2582 4.8306 63.5895 4.13201C62.9208 3.43343 62.1209 2.87357 61.2356 2.48444C60.3503 2.09531 59.397 1.88456 58.4302 1.86426V1.86426Z" stroke="white" strokeWidth="2.28029" strokeMiterlimit="10" />
                                </svg>
                                <svg style={{ padding: "3px" }} width="8.83%" height="30.2%" viewBox="0 0 24 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5454 1.57617H6.72325C3.87952 1.57617 1.57422 3.88147 1.57422 6.72521V70.7112C1.57422 73.5549 3.87952 75.8602 6.72325 75.8602H17.5454C20.3892 75.8602 22.6945 73.5549 22.6945 70.7112V6.72521C22.6945 3.88147 20.3892 1.57617 17.5454 1.57617Z" stroke="white" strokeWidth="2.28029" strokeMiterlimit="10" />
                                </svg>
                                <svg style={{ padding: "3px" }} width="8.83%" height="36.07%" viewBox="0 0 25 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.0493 1.14062H7.22717C4.38343 1.14062 2.07812 3.44593 2.07812 6.28966V84.7113C2.07812 87.555 4.38343 89.8603 7.22717 89.8603H18.0493C20.8931 89.8603 23.1984 87.555 23.1984 84.7113V6.28966C23.1984 3.44593 20.8931 1.14062 18.0493 1.14062Z" stroke="white" strokeWidth="2.28029" strokeMiterlimit="10" />
                                </svg>
                            </div>


                            <div class="buenoParaTi" style={{ marginBottom: "5%" }} data-test="titulo">
                                <Typography className={classes.textTwo}>{t('GOOD_FOR_YOUR_BUSINESS')} <font color='##00C08B'>{t('GOOD_FOR_YOU')}</font> </Typography>
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default BackgroundHeader;