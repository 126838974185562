import { getBontu, postBontu } from "./requests";

export function getRemedies(request_id, uuid) {
	return getBontu("/clip/remedies", null, { request_id: request_id, uuid: uuid }, true);
}

export function getPresignedURLs(request_id, uuid, exts) {
	return postBontu("/clip/remedies/presignedUrl", null, {request_id: request_id, uuid: uuid, exts: exts}, true);
}

export function postUploadDocuments(documents) {
	return new Promise(async resolve => {
		let responses = [];
		let firebaseDocuments = []

		for(let type in documents){
			let payload = new FormData();
			for(let field in documents[type].fields){
				payload.append(field, documents[type].fields[field])
			}
			payload.append('file', documents[type].file, `${type}.${documents[type].ext}`);

			responses.push(fetch(documents[type].url, {
				method: 'POST',
				body: payload
			}));

			firebaseDocuments.push({
				type: type,
				name: `${type}.${documents[type].ext}`,
				s3_key: documents[type].fields['key']
			})
		}

		await Promise.all(responses)

		resolve(firebaseDocuments);
	})
}

export function postUpdateDocumentsStatus(request_id, uuid, firebaseDocuments) {
	return postBontu('/clip/remedies/presigned/documents', null, {request_id: request_id, uuid: uuid, documents: firebaseDocuments}, true)
}