import React from "react";
import RecordsTable from "./recordsTable";

export const Records = () => {
	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<RecordsTable data-test="recordsTable"/>
			</div>
		</div>
	);
};

export default Records;