import React from "react";
import Webcam from "react-webcam";
import Button from "@material-ui/core/Button";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

export default function Camera(props) {
	const webcamRef = React.useRef(null);
	const mediaRecorderRef = React.useRef(null);
	const [capturing, setCapturing] = React.useState(false);
	const [recordedChunks, setRecordedChunks] = React.useState([]);
	const [cameraAvailable, setCameraAvailable] = React.useState(false);

	const handleDataAvailable = React.useCallback(
		({ data }) => {
			if (data.size > 0) {
				setRecordedChunks((prev) => prev.concat(data));
				const blob = new Blob([data], {
					type: "video/mp4"
				});
				props.onVideoRecorded(blob);
			}
		},
		[setRecordedChunks, props]
	);

	const handleStartCaptureClick = React.useCallback(() => {
		setCapturing(true);
		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
			mimeType: "video/mp4"
		});
		mediaRecorderRef.current.addEventListener(
			"dataavailable",
			handleDataAvailable
		);
		mediaRecorderRef.current.start();
		props.onRecordingStarted();
	}, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable, props]);

	const handleStopCaptureClick = React.useCallback(() => {
		mediaRecorderRef.current.stop();
		setCapturing(false);
	}, [mediaRecorderRef]);

	// const handleDownload = React.useCallback(() => {
	// 	if (recordedChunks.length) {
	// 		const blob = new Blob(recordedChunks, {
	// 			type: "video/webm"
	// 		});
	// 		const url = URL.createObjectURL(blob);
	// 		const a = document.createElement("a");
	// 		document.body.appendChild(a);
	// 		a.style = "display: none";
	// 		a.href = url;
	// 		a.download = "react-webcam-stream-capture.webm";
	// 		a.click();
	// 		window.URL.revokeObjectURL(url);
	// 		setRecordedChunks([]);
	// 	}
	// }, [recordedChunks]);

	const onUserMedia = () => {
		if(props.onCameraAvailable){
			props.onCameraAvailable(true);
		}

		setCameraAvailable(true);
	};
	
	const onUserMediaError = () => {
		if(props.onCameraAvailable){
			props.onCameraAvailable(false);
		}

		setCameraAvailable(false);
	};

	return <>
		<Webcam audio={true} ref={webcamRef} width={props.width} height={props.height} onUserMedia={onUserMedia} onUserMediaError={onUserMediaError} />
		<br />
		{
			capturing ? (
				<Button startIcon={<StopIcon />} variant="contained" color="secondary" onClick={handleStopCaptureClick}>DETENER</Button>
			)
				:
				(
					cameraAvailable ? (
						<Button startIcon={<PlayArrowIcon />} variant="contained" color="primary" onClick={handleStartCaptureClick}>GRABAR {recordedChunks ? " DE NUEVO" : ""}</Button>
					) : (
						""
					) 
				)
		}
	</>;
}