import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UploadFiles from "../../../../sharedComponents/UploadFiles/UploadFiles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";


const useStyles = makeStyles((theme) => ({
	root: {
		height: "flex",
	},
	steppedComplete: {
		display: "flex"
	},
	details: {
		display: "flex",
		flexDirection: "column",
	},
	content: {
		flex: "1 0 auto",
	}
}));

export default function Proyecciones(props) {
	const classes = useStyles();
	useEffect(() => {
	}, []);

	const Documents = {
		data: {
			typePerson: "PM",
			documents: [
				{
					name: "Proyecciones Financieras (CSV, Excel)",
					ID: "PF"
				}
			]
		}
	};

	return (
		<div>
			<Card className={classes.steppedComplete}>
				<div className={classes.details}>
					<CardContent className={classes.content}>
						<Typography variant="h5" color="textSecondary">
                            En esta sección es necesario que adjuntes las
						</Typography>
						<Typography variant="h5" color="textSecondary">
                            proyecciones financieras del hotel en el formato de
						</Typography>
						<Typography variant="h5" color="textSecondary">
                            Excel que previamente fue proporcionado.
						</Typography>
						<Typography variant="h5" color="textSecondary">
                            Es importante que venga en el autorizado, de lo contrario
						</Typography>
						<Typography variant="h5" color="textSecondary">
                            no podemos inicar el análisis de tu solicitud.
						</Typography>
					</CardContent>
				</div>
			</Card>
			<UploadFiles docData={Documents} ref={props.stepRef} />
		</div>
	);
}

