import React from "react";
import { Request as PrivateRequest } from "./request";
import { control_oyo_broker_constants } from "../../../constants/control";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../Context/UserContext";


export const Request = props => {
	let history = useHistory();
	const { isAuthorized } = useUser();

	if (isAuthorized(control_oyo_broker_constants.CREDIT_REQUEST_VIEW))
		return <PrivateRequest {...props} />;
	else {
		history.push("/unauthorized");
		return null;
	}
};
