import React, { useEffect, useState, useRef } from 'react';

import {
    Grid, Typography, Card, CardContent, Accordion,
    AccordionSummary, AccordionDetails, Button, Stepper, Step
} from "@material-ui/core";

import qr_apple from "../../../../assets/images/qr/qr_apple.png";
import qr_google from "../../../../assets/images/qr/qr_google.png";
import google_play from "../../../../assets/images/qr/google-play-badge.svg";
import app_store from "../../../../assets/images/qr/apple-badge.svg";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CropFreeIcon from '@material-ui/icons/CropFree';

import UploadFiles from "../../../sharedComponents/UploadFiles/UploadFiles";
import PaymentsTable from "../PaymentsTable/paymentsTable"
import AmortizationTable from "../amortizationTable/amortizationTable"
import Instructive from '../../../sharedComponents/Instructive/instructive';
import TableCredit from "../../../sharedComponents/tableCredit/tableCredit";


import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import GraphicCredit from '../../../sharedComponents/graphicCredit/graphicCredit';

const useStyles = makeStyles((theme) => ({
    backgroundSquare: {
        padding: "25px",
        textAlign: "left"
    },
    squareIndex: {
        borderLeft: "15px solid",
        borderColor: "#00c08b",
        paddingLeft: "5px",
        borderTopLeftRadius: "8px",
        marginBottom: "10px",
        marginLeft: "10px"
    },
    cards: {
        backgroundColor: "#1c6c80",
        color: "white",
        borderRadius: "0px",
    },
    cardsInfo: {
        border: "1px solid #00c08b",
        textAlign: "left",
        borderRadius: "10px",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    button: {
        backgroundColor: "#00c08b",
        color: "white",
        padding: "6px 20px",
        borderRadius: "0px",
        borderTopRightRadius: "10px",
        boxShadow: "1px 1px 4px 0px #717171",
        '&:hover': {
            backgroundColor: "#00c08b",
        },
    },
    step: {
        minWidth: 'fit-content',
        backgroundColor: '#d7d7d7',
        color: '#adadad',
        textTransform: 'initial',
        fontSize: '16px',
        minHeight: 'initial',
        padding: '2px 12px',
        marginRight: '5px'
    },
    completedStep: {
        backgroundColor: '#00c08b',
        color: 'white',
    },
    root: {
        width: "100%",
    }
}));

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export default function CreditResume(props) {
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    const requiredDocs = props.location.state && props.location.state.requiredDocs ? props.location.state.requiredDocs['3'] : []

    const changeClabe = (requiredDocs && requiredDocs.length > 0 ? true : false);

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    function getSteps() {
        let array = [
            '1. ' + (changeClabe ? t("CHANGE_CLABE") : t("CREDIT_SUMMARY")),
            '2. ' + t("END_REQUEST"),
            //'3. '
        ]
        return array;
    }

    function nextStep() {
        let newStep = activeStep + 1
        setActiveStep(newStep)
    }

    const sendDocument = (event) => {
        event.preventDefault();
        stepRef.current.submit(event, nextStep);
    };

    const [expanded, setExpanded] = React.useState(false);
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded)
    };

    const docs = Array.isArray(requiredDocs) && requiredDocs.map(d => {
        let doc = {};
        doc.name = d.label;
        doc.description = d.alt;
        doc.ID = d.code;

        return doc;
    });

    const Documents = {
        data: {
            documents: docs
        }
    };

    let montoSolicitado = formatter.format(props.location.state.request.amount);
    let plazo = props.location.state.request.terms
    let retencionVentas = props.location.state.request.discountpercent
    let clabe = null
    var stepRef = useRef(null)

    useEffect(() => {
        stepRef.current = props.location.state.status.id
        if (props.location.state.status.sub_status === 'Cambio de Cuenta CLABE') {
            setActiveStep(0)
        } else if (props.location.state.status.sub_status === 'Generando Contrato') {
            setActiveStep(1)
        } else if (props.location.state.status.sub_status === 'Firmando Contrato') {
            setActiveStep(2)
        }
    }, [props]);

    const [open, setOpen] = useState(false);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    useEffect(() => {
        setOpen(open);
    },
        [open]
    )

    const dataInstructive = {
        modalData: {
            title: 'Cambio de Cuenta Bancaria',
            description: [
                { text: 'Para finalizar tu solicitud de crédito se te asignará una cuenta CLABE de Citibanamex, a la cual deberás cambiar en tu perfil de Amazon Seller Central y que será administrada por Bontu durante el periodo de tu crédito.', variant: 'body2' },
                { text: 'La cuenta CLABE que te asignará Bontu será única y personalizada y su único fin serápara el repago de tu crédito. En cada fecha de pago de Amazon, Bontu recibirá por parte de Amazon el monto de tus ventas netas, descontará el monto equivalente al porcentaje de descuento que hayas seleccionado para cubrir el pago del crédito y te depositará el remanente a tu cuenta de banco en un plazo máximo de 48 horas después de recibirlo.', variant: 'body2', }
            ]
        },
        carouselConfig: { indicators: true, showNavigation: true },
        handleAcept: { text: '¡Entendido!', action: event => { handleClose(); } },
        handleClose: { text: 'Cerrar', action: event => { handleClose(); } },
        content:
            [
                {
                    title: 'Paso 1',
                    description: 'Paso 1: Abre el menú de configuraciones de tu Amazon Seller Central',
                    image: 'paso1Cambio'

                },
                {
                    title: 'Paso 2',
                    description: 'Paso 2: Haz click en "Información de Cuenta" ',
                    image: 'paso2Cambio'
                },
                {
                    title: 'Paso 3',
                    description: 'Paso 3: Haz click en "Método de depósito"',
                    image: 'paso3Cambio'
                },
                {
                    title: 'Paso 4',
                    description: 'Paso 4: Verifica que tengas asignado el país México y haz click en "Reemplazar método de depósito"',
                    image: 'paso4Cambio'
                },
                {
                    title: 'Paso 5',
                    description: 'Paso 5: Verifica que estés en el país México y haz click en "Agregar método de depósito"',
                    image: 'paso5Cambio'
                },
                {
                    title: 'Paso 6',
                    description: 'Paso 6:  Llena los campos en blanco con la información bancaria que te proporcionamos',
                    image: 'paso6Cambio'
                },
                {
                    title: 'Paso 7',
                    description: 'Paso 7: Haz click en "Establecer método de depósito"',
                    image: 'paso7Cambio'
                },
                {
                    title: 'Paso 8',
                    description: 'Paso 8: Verifica que la información se haya guardado correctamente y toma un pantallazo (screenshot) de esta página',
                    image: 'paso8Cambio'
                },
                {
                    title: 'Paso 9',
                    description: 'Paso 9: Asegúrate de que la información esté correcta y toma un pantallazo (screenshot) de esta página',
                    image: 'paso9Cambio'
                }
            ]
    }

    return (
        <div style={{
            margin: "15px 10px 10px 15px"
        }}>

            <div className={classes.backgroundSquare}>
                <Grid container justify="center">
                    <Grid item xs={12} className={classes.squareIndex}>
                        <Typography variant="h5" align="left" style={{ color: '#033a49', fontWeight: 'bold' }} data-test="title" >{t("CREDIT_SUMMARY")}</Typography>
                    </Grid>

                    <Grid container spacing={2} item xs={12} justify='center' alignItems='center'>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cards} style={{ borderTopLeftRadius: '15px' }}>
                                <CardContent style={{ padding: '20px' }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <AttachMoneyIcon style={{ color: '#00c08b' }}></AttachMoneyIcon>
                                        {t("AMOUNT")}
                                    </Typography>
                                    <Typography variant="h5" align="left">{montoSolicitado}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cards}>
                                <CardContent style={{ padding: '20px' }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <DateRangeIcon style={{ marginRight: '5px', color: '#00c08b' }}></DateRangeIcon>
                                        {t("TERM")}
                                    </Typography>
                                    <Typography variant="h5" align="left">{plazo} {plazo > 1 ? t("MONTHS") : t("MONTH")}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cards} style={{ borderTopRightRadius: '15px' }}>
                                <CardContent style={{ padding: '20px' }}>
                                    <Typography variant="h6" align="left" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <span style={{ color: '#00c08b', fontWeight: 'bold', marginRight: '5px' }}>%</span>
                                        {t("RETENTION")}
                                    </Typography>
                                    <Typography variant="h5" align="left">
                                        {retencionVentas * 100}%
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" alignItems="center" item xs={12} style={{ marginTop: '30px' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                return (
                                    <Step className={classes.step} classes={{ completed: classes.completedStep }} key={label} {...stepProps}>
                                        {index < 2 ? <Typography style={{ fontWeight: 'bold' }}>{label}</Typography> : null}
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>

                    <Grid item xs={12}>
                        {activeStep === 0 ?
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <Accordion expanded={expanded} onChange={handleAccordionChange(true)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>{t("SUMMARY")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ flexWrap: 'wrap' }}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} md={6}>
                                                    <TableCredit data={props.location.state} />
                                                    <GraphicCredit data={props.location.state} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <PaymentsTable percentRevenue={retencionVentas} />
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <AmortizationTable amount={props.location.state.request.amount} request_id={props.location.state.request.id} />
                                            </Grid>
                                            <Grid container justify='center' style={{ marginTop: '20px' }} item xs={12}>
                                                <Button variant="contained" className={classes.button} onClick={handleAccordionChange(false)}>Ocultar</Button>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {!changeClabe && <Grid container justify="center" style={{ marginTop: '20px' }} item xs={12}>
                                    <Button variant="contained" className={classes.button} onClick={nextStep}> {t("NEXT")} </Button>
                                </Grid>}

                                {changeClabe && <Grid container justify="center" alignItems="center" style={{ marginTop: '40px', textAlign: "center" }}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '30px' }}> {t('CHANGE_BANK_ACCOUNT')} </Typography>
                                        <Typography variand="body1">
                                            {t("STEP_1_1")}
                                        </Typography>

                                        <Grid container item spacing={2} style={{ marginTop: '20px', marginBotton: '20px' }}>
                                            <Grid item xs={12} md={6}>
                                                <Card className={classes.cardsInfo}>
                                                    <CardContent style={{ padding: '10px' }}>
                                                        <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold' }}>{t("STEP_1_2")}: {clabe}</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Card className={classes.cardsInfo}>
                                                    <CardContent style={{ padding: '10px' }}>
                                                        <Typography variant="h6" style={{ fontSize: '18px', fontWeight: 'bold' }}>{t("BANK")}: <br></br> Citibanamex</Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} style={{ marginTop: '20px' }}>
                                            <Typography variant="body2">
                                                {t("STEP_1_3")}:
                                            </Typography>
                                        </Grid>

                                        <Grid container justify="center" style={{ marginTop: '20px', marginBottom: '20px' }} item xs={12}>
                                            <Button variant="contained" className={classes.button} onClick={handleOpen}> {t("NEXT_STEPS")} </Button>
                                        </Grid>

                                        <Grid container justify="center" item xs={12}>
                                            <UploadFiles data-test="uploadFile" docData={Documents} ref={stepRef} />
                                        </Grid>

                                        <Grid container justify="center" style={{ marginTop: '20px' }} item xs={12}>
                                            <Button variant="contained" className={classes.button} onClick={sendDocument}> {t("SEND")} </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                <Instructive open={open} data={dataInstructive} />
                            </Grid>
                            : null}

                        {activeStep === 1 ?
                            <Grid container justify="center" alignItems="center" item xs={12} style={{ marginTop: "20px", textAlign: "center" }}>
                                <Grid item xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ fontWeight: 'bold', marginBotton: '10px' }}> {t("ALMOST_READY")} </Typography>
                                        <FormatListBulletedIcon fontSize='large' style={{ marginBottom: '30px', color: '#00c08b' }}></FormatListBulletedIcon>
                                        <Typography variant="body1">
                                            {t("STEP_2_1")}
                                        </Typography>
                                        <Typography variant="body1" style={{ marginTop: "20px" }}>{t("FIND_OUT_APP_IN")}:</Typography>

                                        <Grid container item xs={12} style={{ marginTop: '20px' }}>
                                            <Grid item xs={12} md={6}>
                                                <img style={{ marginBotton: '5px', width: '50%' }} alt="Código QR de la App de Bontu en el AppStore" src={qr_apple}></img><br></br>
                                                <a href="https://apps.apple.com/app/id1477560182" target="_blank" rel="noopener noreferrer"><img alt="apple store" style={{ marginBotton: '5px', width: '60%' }} src={app_store}></img></a>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <img style={{ marginBotton: '5px', width: '50%' }} alt="Código QR de la App de Bontu en Google Play" src={qr_google}></img><br></br>
                                                <a href="https://play.google.com/store/apps/details?id=mx.alphacredit.bontu" target="_blank" rel="noopener noreferrer"><img alt="google play" style={{ marginBotton: '5px', width: '60%' }} src={google_play}></img></a>
                                            </Grid>
                                        </Grid>

                                        <Typography variant="body1" style={{ marginTop: "20px" }}>
                                            {t("STEP_2_2")}
                                        </Typography>
                                        <Typography variant="h5" style={{ marginTop: "20px" }}> {t("THANKS_FOR_USING_BONTU")} </Typography>

                                        <Grid container justify="center" style={{ marginTop: '20px' }} item xs={12}>
                                            <Button endIcon={<SendIcon />} variant="contained" className={classes.button} onClick={nextStep}> {t("UNDERSTOOD")} </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null}

                        {activeStep === 2 ?
                            <Grid container item xs={12} justify='center' alignItems='center'>
                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'center' }} variant='body1'>
                                        {t("STEP_3_1")}
                                    </Typography>

                                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <CropFreeIcon fontSize="large"></CropFreeIcon>
                                    </Grid>

                                    <Typography variant='body1' style={{ textAlign: 'center', marginTop: '20px' }}>
                                        {t("STEP_3_2")}
                                    </Typography>

                                    <Typography variant='body1' style={{ marginTop: '40px' }}>
                                        {t("STEP_3_3")}
                                        <br /><br />
                                        {t("STEP_3_4")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            : null}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}