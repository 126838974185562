import React from "react";
import { Switch } from "react-router-dom";
import Home from "../main/home/home";
import { Request as OyoRequest } from "../main/oyo/request";
import Login from "../main/login/login";
import Callback from "../main/login/callback";
import { Records } from "../main/oyo/records";
import { TermsAndConditions } from "../main/termsAndConditions";
import { PrivacyPolicy } from "../main/request/privacyPolicy/privacyPolicy";
import { ResetPassword } from "../main/login/resetPassword";
import PublicRoute from "../PublicRoute";
import PublicRouteWithoutLayout from '../PublicRouteWithoutLayout';
import { PrivateRoute } from "../PrivateRoute";
import Request from '../main/request/request';
import DataCollection from '../main/request/dataCollection/dataCollection';
import CreditResume from '../main/request/CreditResume/creditResume';
import SomosFlexibles from '../main/request/SomosFlexibles/somosFlexibles';
import ClipRemedies from '../main/clip/remedies';
import ConfirmDataOffer from '../main/request/offer/confirmData'
import ErrorSign from '../main/errorSign/errorSign'

const Router = () => (
	<Switch>
		{/*<Route exact path="/" render={() => <Redirect to="/amazon" />} />*/}
		<PublicRoute component={Home} path="/amazon" exact />
		{/*<PublicRoute component={Home} path="/oyo" exact />*/}
		<PublicRoute component={Home} path="/" exact />
		<PublicRouteWithoutLayout showHeader={false} showFooter={true} component={Login} path="/login" exact />
		<PublicRoute component={Callback} path="/callback" exact />
		<PublicRoute component={ResetPassword} path="/resetPassword" exact />
		<PublicRouteWithoutLayout showHeader={false} showFooter={false} component={ClipRemedies} path="/remedies/:uuid/:request_id" />
		<PrivateRoute component={OyoRequest} path="/oyoRequest/:id?" exact />
		<PrivateRoute component={Records} path="/records" exact />
		<PrivateRoute component={TermsAndConditions} path="/termsAndConditions" exact />
		<PublicRoute component={PrivacyPolicy} path="/request/privacy-policy" exact />
		<PrivateRoute component={Request} path="/request" exact />
		<PrivateRoute component={DataCollection} path="/request/data-collection" exact />
		<PrivateRoute component={SomosFlexibles} path="/request/customize-offer" exact />
		<PrivateRoute component={CreditResume} path="/request/credit-resume" exact />
		<PublicRouteWithoutLayout showHeader={false} showFooter={false} component={ConfirmDataOffer} path="/offer/confirm-data/:token/:full_name/:telephone/:clabe/:email/:rfc" exact />
		<PublicRouteWithoutLayout component={ErrorSign} path="/errorSign" exact />
		<PublicRoute component={() => <h4>Not Found</h4>} />
	</Switch>
);

export default Router;
