import React, { useEffect, useState } from "react";
import UploadFileWithDropzone from "../../../sharedComponents/UploadFileWithDropzone/UploadFileWithDropzone";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { actionButtonsDisable } from "../../../../store/actions/user.actions";
import { useDispatch } from "react-redux";


export default function LegalDocuments(props) {
	const [typePerson, setTypePerson] = useState(null);
	const [dataDocuments, setDataDocuments] = useState(null);
	const { t } = useTranslation("UserApp");
	const legalDocuments = props.requiredDocs;
	const documents_configuration = props.jsonDocuments
	const dispatch = useDispatch();

	const documentByPersonType = (type, data, documentsConfiguration) => {
		let documents = [];
		let newArrayDocuments = []
		let codes = []
		if (type === 'PM') {
			documents = data.pm;
		} else {
			documents = data.pf;
		}
		const generateObjectDocument = documents.map((element) => {
			let doc = {};
			doc['name'] = element['label'];
			doc['description'] = element['alt'];
			doc['ID'] = element['code'];
			return doc;
		});

		for (let index = 0; index < generateObjectDocument.length; index++) {

			const search = documentsConfiguration.filter(element => element.ID === generateObjectDocument[index].ID);

			if (!codes.includes(generateObjectDocument[index].ID)) {
				if (search.length > 0) {
					for (let indexChildren = 0; indexChildren < search.length; indexChildren++) {
						newArrayDocuments.push(search[indexChildren])
						codes.push(search[indexChildren].ID);
					}
				} else {
					newArrayDocuments.push(generateObjectDocument[index]);
					codes.push(generateObjectDocument[index].ID);
				}
			}
		}
		return newArrayDocuments;
	}

	useEffect(() => {
		dispatch(actionButtonsDisable(false));
		setTypePerson(localStorage.getItem("bontu_userTypePerson"));
		props.titleHandler(t('DOCUMENTATION'));
	}, [props, t, dispatch]);

	useEffect(() => {
		if (typePerson) {
			const getDocumentByPersonType = documentByPersonType(typePerson, legalDocuments, documents_configuration);
			setDataDocuments({
				data: {
					typePerson: typePerson,
					documents: getDocumentByPersonType
				}
			});
		}

	}, [typePerson, legalDocuments, documents_configuration]);

	return (
		<Grid container data-test="data-container" >
			<Grid item xl={12} lg={12} sm={12} xs={12} md={12} >
				{dataDocuments && (
					<UploadFileWithDropzone instructions={props.instructions} isLoading={props.isLoading} docData={dataDocuments} ref={props.stepRef} activeStephandler={props.activeStephandler} waitResponse={props.waitResponse} request_id={props.request_id} />
				)}
			</Grid>
		</Grid>
	);
}	