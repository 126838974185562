import React, { useEffect } from "react";
import UploadFiles from "../../../../sharedComponents/UploadFiles/UploadFiles";

export default function Documentos(props) {
	const [Person, setTypePerson] = React.useState("");

	useEffect(() => {
		setTypePerson("PM");
	}, []);

	const DocumentsPM = {
		data: {
			typePerson: "PM",
			documents: [
				{
					name: "Acta Constitutiva",
					ID : "AC"
				},
				{
					name: "Poderes Legales",
					ID: "PRL"
				},
				{
					name: "INE Rep. Legal(es)",
					ID: "ID"
				},
				{
					name: "Estado de Cuenta Bancario Hotel",
					ID: "ECBH"
				},
				{
					name: "Comprobante de Domicilio",
					description: "No mayor a 3 meses",
					ID: "CD"
				},
				{
					name: "Constancia de situación fiscal",
					description: "No mayor a 3 meses",
					ID: "CSF"
				}
			]
		}
	};

	const DocumentsPF = {
		data: {
			typePerson: "PF",
			documents: [
				{
					name: "Identificación Oficial",
					description: "(INE, IFE, Pasaporte, Cedula Profesional, FM2 o FM3)",
					ID : "ID"
				},
				{
					name: "Comprobante de Domicilio",
					description: "No mayor a 3 meses",
					ID: "CD"
				},
				{
					name: "Constancia de situación fiscal",
					description: "No mayor a 3 meses",
					ID: "CSF"
				}
			]
		}
	};

	return (
		<div data-test="content" style={{ visibility: 'visible'}}>
			<UploadFiles data-test="uploadFile" docData = {Person === "PF" ? DocumentsPF : DocumentsPM} ref={props.stepRef}/>
		</div>
	);
}