import React from "react";
import FreshChat from "react-freshchat";
import environment from "../../environments/environment";
export default function freshChat() {

	return (
		<FreshChat
			host={`${environment.chat.host}`}
			token={`${environment.chat.token}`}
			open={false}
			faqTags={{
				tags: ["bontu"],
				filterType: "category"

			}}
			config={{
				headerProperty:{
					backgroundColor: "#00C08B"
				}
			}}
		/>
	);
}