import React, { useLayoutEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLayout } from '../store/actions/app.actions';

const PublicRouteWithoutLayout = ({ component: Component, showHeader, showFooter, ...rest }) => {
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        dispatch(changeLayout({
            changeLayout:true,
            showHeader:showHeader,
            showFooter:showFooter
        }));
    }, [dispatch, Component, showHeader, showFooter]);

    return (<Route {...rest} render={props => <Component {...props} />} />);
};

export default PublicRouteWithoutLayout;