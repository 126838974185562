
import React from "react";
import i18next from "i18next";
import { AgGridReact } from "ag-grid-react";
// import { AgGridReact } from '@ag-grid-community/react';
// import { ModuleRegistry } from '@ag-grid-community/core';
// import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { CsvExportModule } from '@ag-grid-community/csv-export';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
// import * as AppActions from 'app/store/actions';
// import { useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import ButtonIconRenderer from "./buttonIconRenderer.js";
import RefreshIcon from "@material-ui/icons/Refresh";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import en from "./DagaGrid-i18n/en";
import es from "./DagaGrid-i18n/es";

// ModuleRegistry.registerModules([MasterDetailModule, ClientSideRowModelModule, CsvExportModule]);


const initialState = {
	mouseX: null,
	mouseY: null
};

function DataGrid(props) {
	i18next.addResourceBundle("es", "dataGrid", es);
	i18next.addResourceBundle("en", "dataGrid", en);

	let overlayNoRowsTemplate;
	let overlayLoadingTemplate;

	if (props.localeText === "es") {
		overlayNoRowsTemplate = "<span class=\"ag-overlay-loading-center\">Sin Registros</span>";
		overlayLoadingTemplate = "<span class=\"ag-overlay-loading-center\">Cargando...</span>";
	} else {
		overlayNoRowsTemplate = "<span class=\"ag-overlay-loading-center\">No Rows To Show</span>";
		overlayLoadingTemplate = "<span class=\"ag-overlay-loading-center\">Loading...</span>";
	}

	const [state, setState] = React.useState(initialState);
	const [gridApi, setApi] = React.useState([]);
	const [columnApi, setColumnAPI] = React.useState([]);
	// const dispatch = useDispatch();
	// const { t } = useTranslation('initialReview');

	const handleClick = event => {
		event.preventDefault();
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		});
	};

	/*function Alert(style, msg) {
		const body = {
			message: msg,
			variant: style
		};

		// dispatch(AppActions.showMessage({ message: body.message, variant: body.variant }));
	}*/

	const handleClose = () => {
		setState(initialState);
	};

	const defaultColDef = {
		editable: false,
		enableRowGroup: true,
		enablePivot: true,
		enableValue: true,
		sortable: true,
		resizable: true,
		filter: "agTextColumnFilter",
		flex: 1,
		floatingFilter: true,
		minWidth: 200
	};

	let localeText;

	if (props.localeText === "es") {
		localeText = es;
	} else {
		localeText = en;
	}

	const frameworkComponents = {
		buttonIconRenderer: ButtonIconRenderer
	};

	const rowGroupPanelShow = "always";

	const defaultColGroupDef = { marryChildren: true };

	const rowSelection = "single";

	const columnTypes = {
		numberColumn: {
			width: 130,
			filter: "agNumberColumnFilter"
		},
		medalColumn: {
			width: 100,
			columnGroupShow: "open",
			filter: false
		},
		nonEditableColumn: { editable: false },
		dateColumn: {
			filter: "agDateColumnFilter",
			filterParams: {
				comparator(filterLocalDateAtMidnight, cellValue) {
					const dateParts = cellValue.split("/");
					const day = Number(dateParts[0]);
					const month = Number(dateParts[1]) - 1;
					const year = Number(dateParts[2]);
					const cellDate = new Date(year, month, day);

					if (cellDate < filterLocalDateAtMidnight) {
						return -1;
					}
					if (cellDate > filterLocalDateAtMidnight) {
						return 1;
					}
					return 0;
				}
			}
		}
	};

	const onCellClicked = ev => {
		if (props.onCellClicked) props.onCellClicked(ev);
	};

	const onGridReady = params => {
		setApi(params.api);
		setColumnAPI(params.columnApi);
	};

	const onBtnExport = () => {
		try {
			const params = {
				columnSeparator: undefined,
				customFooter: undefined,
				customHeader: undefined,
				suppressQuotes: undefined
			};
			gridApi.exportDataAsCsv(params);
			handleClose();
		} catch (error) {
			console.log(error);
		}
	};

	const callRefreshAfterMillis = () => {
		try {
			setTimeout(function () {
				props.updateCells();
				handleClose();
			}, 1000);
			// Alert('success', t('ALERTUPDATESUCCESS'));
		} catch (error) {
			console.log(error);
		}
	};

	const resetGrid = () => {
		try {
			columnApi.resetColumnState();
			columnApi.resetColumnGroupState();
			gridApi.setSortModel(null);
			gridApi.setFilterModel(null);
			handleClose();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div
			onContextMenu={handleClick}
			className="ag-theme-material"
			style={{
				height: "100%",
				width: "100%",
				cursor: "context-menu"
			}}
		>
			<AgGridReact
				columnDefs={props.columnDefs}
				masterDetail={props.masterDetail ? props.masterDetail : false}
				detailCellRendererParams={props.detailCellRendererParams ? props.detailCellRendererParams : undefined}
				rowData={props.data}
				defaultColDef={props.defaultColDef ? props.defaultColDef : defaultColDef}
				rowGroupPanelShow={rowGroupPanelShow}
				defaultColGroupDef={defaultColGroupDef}
				columnTypes={columnTypes}
				onCellClicked={onCellClicked}
				rowSelection={rowSelection}
				localeText={localeText}
				overlayNoRowsTemplate={overlayNoRowsTemplate}
				overlayLoadingTemplate={overlayLoadingTemplate}
				rowDragManaged={props.rowDragManaged ? props.rowDragManaged : false}
				animateRows={props.animateRows ? props.animateRows : false}
				onGridReady={props.onGridReady ? props.onGridReady : onGridReady}
				onRowDragEnd={props.onRowDragEnd ? props.onRowDragEnd : false}
				context={props.context}
				frameworkComponents={frameworkComponents}
				rowHeight={props.rowHeight}
			>
			</AgGridReact>
			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<div>
					<MenuItem onClick={resetGrid}>
						<RefreshIcon /> Reset Grid
					</MenuItem>
					<br />
					<MenuItem onClick={callRefreshAfterMillis}>
						<RotateLeftIcon /> Actualizar datos
					</MenuItem>
					<br />
					<MenuItem onClick={onBtnExport}>
						<ImportExportIcon/> Exportar datos
					</MenuItem>
					<br />
				</div>
			</Menu>
		</div>
	);
}
export default DataGrid;