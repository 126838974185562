import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Button,
    Container,
    Stepper,
    Step,
    StepLabel,
    Hidden,
    Grid,
    Card,
    CardContent
 } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ProgressWithLevel from "./ProgressWithLevel";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import BackgroundHeader from "../BackgroundImage/BackgroundHeader";

import img1 from "../../../assets/images/backgrounds/capital_trabajo1.jpg";
import img2 from "../../../assets/images/backgrounds/capital_trabajo2.jpg";
import img3 from "../../../assets/images/backgrounds/capital_trabajo3.jpg";
import img4 from "../../../assets/images/backgrounds/capital_trabajo4.jpg";
import img5 from '../../../assets/images/backgrounds/capital_trabajo5.jpg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",

		"&$active": {
			color: "#000000",
		},
		"&$completed": {
			color: "#000000",
		},
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		background: "#00c08a",
		color: "#fff",
		"&$active": {
			color: "#172a33",
		},
		"&$over": {
			color: "#172a33",
		},
		"&$completed": {
			color: "#172a33",
		},
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	subTxt: {
		fontSize: "24px",
		fontWeight: 500,
		color: "#003B4A",
		marginTop: "20px",
		fontstyle: "normal",

	},
	backButton: {
        font: "roboto",
        backgroundColor: "#FFFFFF",
        color: "#00c08b",
        borderColor: "#00C08B",
        padding: "8px, 16px, 8px, 16px",
        borderRadius: "4px",
        borderTopRightRadius: "4px",
        marginRight: "16px",
        [theme.breakpoints.down("xs")]: {
            minWidth: 120, minHeight: 60
        }
    },
	continueButton: {
        backgroundColor: "#00c08b",
        color: "white",
        borderRadius: "4px",
        borderTopRightRadius: "4px",
        [theme.breakpoints.down("xs")]: {
            minWidth: 120, minHeight: 60
        }

    },

    titleStep: {
        fontSize:"3vw",
        fontWeight: "bold",
        color: "#004461",
        [theme.breakpoints.down("xs")]: {
            fontSize:"4vw"
        }
    },
    subtitleStep: {
        fontSize: "2.2vw",
        color: "#5B6268",
        [theme.breakpoints.down("xs")]: {
            fontSize: "3vw"
        }
    }
}));

function imageName(num){
    switch (num){
        case 0:
            return img1;
        case 1:
            return img2;
        case 2:
            return img3;
        case 3:
            return img4;
		default:
            return img5;
    }
}
export default function StepperGeneric(props) {
	const classes = useStyles();
	const stepRef = useRef();
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const steps = props.getSteps(stepRef);
	const _buttontitle = useSelector((state) => state.user.buttonTitle);
	const [buttonTitleName, setButtonTitleName] = useState(null);
	const { t } = useTranslation("UserApp");
	const _configActionsButtons = useSelector((state) => state.app.actionButton)
	const [configActionsButtons, setConfigActionsButtons] = useState(null);
	const [pageTitle, setPageTitle] = useState(null);
	const [isCancelButton] = useState(
		(typeof props.cancelButton !== "undefined")
			? props.cancelButton
			: true
	);

	const isStepOptional = (step) => {
		return false;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleBack = async () => {
		props.setIsLoading(true)
		await props.backButton()
		props.setIsLoading(false)
		props.activeBackStephandler();
	};

	const getFunction = async (event) => {
		event.preventDefault();
		stepRef.current.submit(event, handleNext);
	};

	useEffect(() => {
		setActiveStep(props.aciveStep);
		setPageTitle(props.pageTitle);
	}, [props]);

	useEffect(() => {
		_buttontitle && setButtonTitleName(_buttontitle);
	}, [_buttontitle]);

	useEffect(() => {
		setConfigActionsButtons(_configActionsButtons);
	}, [_configActionsButtons]);
	return (
		<>
		<BackgroundHeader ImagenHeader={imageName(activeStep)} />
		{pageTitle && (
                    <Container>
							<div className="row">
								<Typography className={classes.subTxt} data-test="Detalle de la oferta">{pageTitle}</Typography>
							</div>
                    </Container>
					)}
		<Container><form>
			<div style={{
				margin: "75px 0px 0px 0px",
			}}>
				<div>
					<Card className={classes.root}>
						<CardContent>
                            {steps[activeStep].showStep ?(
                            <Hidden mdUp><Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              style={{ margin: "auto"}}
                              md={6}
                              xs={10}
                              spacing={2}
                            >
                                <Grid item xs={4} style={{ textAlign: "right",}}>
                                    <ProgressWithLevel value={activeStep + 1} totalSteps={steps.length - 1} color="#00c08a" />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h4" className={classes.titleStep}>{steps[activeStep].title}</Typography>
                                    {activeStep + 1 < steps.length - 1 ? (
                                        <Typography variant="h6" className={classes.subtitleStep}>{t("NEXT")}: {steps[activeStep+1].title}</Typography>
                                    ) : ( "" )}
                                </Grid>
                            </Grid></Hidden>
                            ):""}

							{steps[activeStep].showStep ?(
							<Hidden smDown><Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((step, index) => {
                                        if(step.showStep){
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={step.title} {...stepProps} >
                                                    <StepLabel {...labelProps} >{step.title}</StepLabel>
                                                </Step>
                                            );
                                        }
                                        else
                                            return false;
                                    })}

							</Stepper></Hidden>
							):""}
							<div>
								{activeStep >= steps.length ? (
									<div>
										<Typography className={classes.instructions}>
											{props.stepesCompleted}
										</Typography>
										<NavLink to="/records" style={(!isCancelButton) ? { display: "none" } : {}}>
											<Button disabled={activeStep === 0} >
												{t("CLOSE")}
											</Button>
										</NavLink>
									</div>
								) : (
										<div>
											<Typography className={classes.instructions}>{steps[activeStep].content}</Typography>
											<div style={{
												display: "flex", justifyContent: "flex-end", marginTop: "8px"
											}}>
												<NavLink to="/records" style={(!isCancelButton) ? { display: "none" } : {}}>
													<Button disabled={activeStep === 0} className={classes.button}>
														{t("CLOSE")}
													</Button>
												</NavLink>

												{isStepOptional(activeStep) && (
													<Button
														variant="contained"
														color={classes.button}
														background={classes.button}
														onClick={handleSkip}
														className={classes.button}
													>
														{t("SKIP")}
													</Button>
												)}
												{configActionsButtons &&
													(
														<div>
															{configActionsButtons?.backButton?.show && (
																<Button
																	variant="outlined"
																	className={classes.backButton}
																	onClick={event => handleBack()}
																	disabled={configActionsButtons?.backButton?.disable}
																	margin="dense" 
																	size="large"
																>
																	{activeStep === steps.length - 1 && buttonTitleName ? (buttonTitleName) : activeStep === steps.length - 1 ? t("FINISH") : t("BACK")}
																</Button>
															)}

															{configActionsButtons?.nextButton?.show && (
																<Button
																	variant="contained"
																	className={classes.continueButton}
																	onClick={event => getFunction(event)}
																	disabled={configActionsButtons?.nextButton?.disable}
																	margin="dense" 
																	size="large"
																>
																	{activeStep === steps.length - 1 ? t("FINISH") : t("NEXT")}
																</Button>
															)}
														</div>
													)}
											</div>
										</div>
									)}
							</div>

						</CardContent>
					</Card>
				</div>
			</div>
		</form></Container>
		</>
	);
}