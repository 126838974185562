import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    style:{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh'
    }
}))

const Responsive = Component => function Responsive(){
    const classes = useStyles();
    const isDesktop = useMediaQuery('(min-width: 721px)')

    return (
        <div className={classes.style}>
            <Component isDesktop={isDesktop} />
        </div>
    )
}

export default Responsive