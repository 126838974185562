import React, { useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CanvasDraw from "react-canvas-draw";
import Camera from "../../sharedComponents/camera/camera";
import { SendPrecargaImagen, SendCargaImagen } from "../../Services/uploadFiles.service";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { RequestContext } from "./../oyo/request/request";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const TermsAndConditions = (props) => {
	const classes = {};
	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [recordedVideo, setRecordedVideo] = useState(null);
	const [canvasChanged, setCanvasChanged] = useState(false);
	const [canvasRef, setCanvasRef] = useState(null);
	const requestContext = useContext(RequestContext)
	const [generalData, ] = useState(requestContext);

	const [doodle, setDoodle] = useState(null);
	const [cameraAvailable, setCameraAvailable] = useState(undefined);

	const openDialog = () => {
		setIsDialogOpen(true);
	};

	const onDialogClose = () => {
		setIsDialogOpen(false);
	};

	const onVideoRecorded = React.useCallback((blobFile) => {
		setRecordedVideo(blobFile);
		console.log("Video:", blobFile);
		setIsRecording(false);
	}, []);

	const sendVideo = async () => {
		console.log("Video file:", recordedVideo);
		console.log("Image file:", doodle);

		let body = {
			"user_platform": localStorage.getItem("user_platform"),
			"document": {
				"name": "doodleSign.png",
				"type": "doodleSign"
			}
		};

		await sendFile(body, doodle);

		body.document.name = "videoSign.mp4";
		body.document.type = "videoSign";

		await sendFile(body, recordedVideo);

		setIsRecording(false);
		setRecordedVideo(undefined);
		setCanvasChanged(false);
		setIsDialogOpen(false);
		setDoodle(undefined);
	};

	const sendFile = async (body, file) => {
		var formData = new FormData();
		var bodyResultL;
		var token, apiKey;

		await SendPrecargaImagen(body).then((response) => {
			formData.append("key", response.fields.key);
			formData.append("AWSAccessKeyId", response.fields.AWSAccessKeyId);
			formData.append("x-amz-security-token", response.fields["x-amz-security-token"]);
			formData.append("policy", response.fields.policy);
			formData.append("signature", response.fields.signature);
			formData.append("file", file, body.document.name);
			bodyResultL = response;

			token = response.fields["x-amz-security-token"];
			apiKey = response.fields.AWSAccessKeyId;
		}).catch((error) => {
			console.log("Error al subir al precargar documento");
		});

		console.log("BODY URL:", bodyResultL);
		console.log("Form Data:", formData);
		console.log("ApiKey:", apiKey);
		console.log("Token:", token);
		await SendCargaImagen(`${bodyResultL.url}`, formData, apiKey, token).then((response) => {
			console.log("Documento cargado!:", response);
		}).catch((error) => {
			console.log("Error al cargar documento");
		});
	};

	const onCanvasChanged = React.useCallback(() => {
		setCanvasChanged(true);
	}, []);

	const onRecordingStarted = React.useCallback(() => {
		setIsRecording(true);
	}, []);

	if (canvasChanged && recordedVideo && canvasRef) {
		canvasRef.canvasContainer.children[1].toBlob((blobImage) => {
			console.log("blobImage:", blobImage);
			setDoodle(blobImage);
		}, "image/png", 1);
	}

	const onCameraAvailable = (isCameraAvailable) => {
		setCameraAvailable(isCameraAvailable);
		console.log("Camera available:", isCameraAvailable);
	};

	return (
		<div data-test="component">
			<div
				data-test="content"
				style={{
					margin: "20px"
				}}
			>
				<h1 data-test="title">TERMS AND CONDITIONS</h1>
				<br />
				<Card data-test="client-testing-info">
					<CardContent>
						<h2>Client</h2>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<b>Nombre</b>
								<br />
								<span data-test="name">
									{
										generalData && generalData.client ?
											`${generalData.client.names ? generalData.client.names : ""} ${generalData.client.first_name ? generalData.client.first_name : ""} ${generalData.client.second_name ? generalData.client.second_name : ""}`.trim()
											:
											""
									}
								</span>
							</Grid>
							<Grid item xs={4}>
								<b>Dirección</b>
								<br />
								<span data-test="address">
									{
										generalData && generalData.address ?
											`${generalData.address.city ? generalData.address.city : ""} ${generalData.address.neighborhood ? generalData.address.neighborhood : ""} ${generalData.address.postal_code ? "CP " + generalData.address.postal_code : ""} ${generalData.address.street ? "Calle " + generalData.address.street : ""} ${generalData.address.out_number ? "ext #" + generalData.address.out_number : ""} ${generalData.address.in_number ? "int #" + generalData.address.in_number : ""}`.trim()
											:
											""
									}
								</span>
							</Grid>
							<Grid item xs={4}>
								<b>RFC</b>
								<br />
								<span data-test="rfc">
									{
										generalData && generalData.client ?
											`${generalData.client.rfc ? generalData.client.rfc : ""}`
											:
											""
									}
								</span>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={4}>
								<b>Email</b>
								<br />
								<span data-test="email">
									{
										generalData && generalData.client ?
											`${generalData.client.email ? generalData.client.email : ""}`
											:
											""
									}
								</span>
							</Grid>
							<Grid item xs={4}>
								<b>Regime</b>
								<br />
								<span data-test="regime">
									{
										generalData && generalData.client ?
											`${generalData.client.regime ? generalData.client.regime : ""}`
											:
											""
									}
								</span>
							</Grid>
							<Grid item xs={4}>
								<b>Telephone</b>
								<br />
								<span data-test="telephone">
									{
										generalData && generalData.client ?
											`${generalData.client.telephone ? generalData.client.telephone : ""}`
											:
											""
									}
								</span>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={4}>
								<b>Account Key</b>
								<br />
								<span data-test="account_key">
									{
										generalData && generalData.client ?
											`${generalData.client.account_key ? generalData.client.account_key : ""}`
											:
											""
									}
								</span>
							</Grid>

							<Grid item xs={4}>
								<b>Business Activity</b>
								<br />
								<span data-test="business_activity">
									{
										generalData && generalData.client ?
											`${generalData.client.business_activity ? generalData.client.business_activity : ""}`
											:
											""
									}
								</span>
							</Grid>

							<Grid item xs={4}>
								<b>Nationality</b>
								<br />
								<span data-test="nationalityName">
									{
										generalData && generalData.nationality ?
											`${generalData.nationality.name ? generalData.nationality.name : ""}`
											:
											""
									}
								</span>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={4}>
								<b>Platform</b>
								<br />
								<span data-test="platformName">
									{
										generalData && generalData.platform ?
											`${generalData.platform.name ? generalData.platform.name : ""}`
											:
											""
									}
								</span>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<br />

				{
					generalData &&
					generalData.partners &&
					generalData.partners.length > 0 &&
					<br /> &&
					<Card data-test="partners">
						<CardContent>
							<h2>Partners</h2>
							{
								generalData.partners.map((partner, index) =>
									<Grid container spacing={2} key={index} data-test="partner">
										<Grid item>
											<p><b>Nombre: </b><span data-test="partner-names">{`${partner.names} ${partner.first_name} ${partner.second_name}`}</span></p>
											<p><b>Tipo: </b><span data-test="partner-type">{partner.type}</span></p>
											<p><b>CURP: </b><span data-test="partner-curp">{partner.curp}</span></p>
											<p><b>RFC: </b><span data-test="partner-rfc">{partner.rfc}</span></p>
											<p><b>Teléfono: </b><span data-test="partner-phone">{partner.phone}</span></p>
											<p><b>EMail: </b><span data-test="partner-mail">{partner.mail}</span></p>
											<p><b>Percentage: </b><span data-test="partner-percentage">{partner.percentage}</span></p>
										</Grid>
									</Grid>
								)
							}
						</CardContent>
					</Card>
				}

				<br />

				{
					generalData &&
					generalData.client_pld_risk_matrix &&
					generalData.client_pld_risk_matrix.length > 0 &&
					<Card data-test="risk-matrix">
						<CardContent>
							<h2>Matriz de riesgos</h2>
							{
								generalData.client_pld_risk_matrix.map((risk, index) =>
									<Grid container spacing={2} key={index}>
										<Grid item>
											<span data-test="risk"><b>{risk.question}</b>: {risk.description}</span>
										</Grid>
									</Grid>
								)
							}
						</CardContent>
					</Card>
				}

				<br />

				<object data="http://africau.edu/images/default/sample.pdf" type="application/pdf" width="100%" height="1080px" data-test="pdf">
					<p><a href="http://africau.edu/images/default/sample.pdf">PDF</a></p>
				</object>

				<Grid container>
					<Grid item>
						Aceptas los terminos y condiciones?
						<Checkbox
							data-test="accept-terms-checkbox"
							checked={acceptedTermsAndConditions}
							onChange={(event) => {
								setAcceptedTermsAndConditions(event.target.checked);
							}}
						/>
					</Grid>
				</Grid>

				<Grid container>
					<Button data-test="button-accept-terms" variant="contained" color="primary" disabled={!acceptedTermsAndConditions}
						onClick={openDialog}>
						Continuar
					</Button>
				</Grid>

				<div>
					<Dialog fullScreen open={isDialogOpen} onClose={onDialogClose} TransitionComponent={Transition}>
						<AppBar className={classes.appBar}>
							<Toolbar>
								<IconButton edge="start" color="inherit" onClick={onDialogClose} aria-label="close">
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" className={classes.title}>
									Firma con garabato
								</Typography>
								{
									recordedVideo && !isRecording && canvasChanged &&
									<Button autoFocus color="inherit" onClick={sendVideo}>
										Enviar Video y Garabato
									</Button>
								}
							</Toolbar>
						</AppBar>
						<Grid container spacing={2} style={{ margin: "10px" }}>
							<Grid item>
								<br />
								<Camera
									width="400"
									height="auto"
									onCameraAvailable={onCameraAvailable}
									onRecordingStarted={onRecordingStarted}
									onVideoRecorded={onVideoRecorded}
								/>
							</Grid>
							<Grid item>
								{
									isRecording &&
									<div>
										<Typography>Firma aquí</Typography>
										<CanvasDraw
											canvasWidth={400}
											canvasHeight={400}
											style={{ border: "1px solid black" }}
											brushRadius={2}
											onChange={onCanvasChanged}
											ref={ref => {
												setCanvasRef(ref);
											}}
										/>
									</div>
								}
							</Grid>
						</Grid>
					</Dialog>
				</div>

				<Dialog open={cameraAvailable === false && isDialogOpen}>
					<Grid container spacing={2} style={{ padding: "10px", overflow: "none" }}>
						<Grid container item>
							<Typography>Necesitas una camara para poder firmar los terminos y condiciones!</Typography>
						</Grid>

						<Grid container item>
							<Button onClick={() => { setIsDialogOpen(false); }}>OK</Button>
						</Grid>
					</Grid>
				</Dialog>
			</div>
		</div>
	);
};

// TermsAndConditions.prototype = {
// 	requestData: PropTypes.object
// }

export default TermsAndConditions;