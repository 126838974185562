import { actionTypes } from "../actions/app.actions";

const initialState = {
    actionButton: {
        backButton: { show: true, disable: false },
        nextButton: { show: true, disable: false }
    },
    configLayout: {
        changeLayout: false,
        showHeader: true,
        showFooter: true
    }
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.APP_SET_CONFIG_ACTIONS_BUTTONS:
            return {
                ...state,
                actionButton: {
                    ...state.actionButton,
                    ...action.payload,
                }
            };
        case actionTypes.APP_SET_HIDDEN_LAYOUT:
            return {
                ...state,
                configLayout: {
                    ...state.configLayout,
                    ...action.payload,
                }
            };
        default:
            return { ...state };
    }
}

export default app