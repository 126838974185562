export const actionTypes = {
    APP_SET_CONFIG_ACTIONS_BUTTONS: "APP_SET_CONFIG_ACTIONS_BUTTONS",
    APP_SET_HIDDEN_LAYOUT: "APP_SET_HIDDEN_LAYOUT",
};

export function configActionsButtons(data) {
    return dispatch => {
        return dispatch({ type: actionTypes.APP_SET_CONFIG_ACTIONS_BUTTONS, payload: data });
    };
}

export function changeLayout(data) {
    return dispatch => {
        return dispatch({ type: actionTypes.APP_SET_HIDDEN_LAYOUT, payload: data });
    };
}