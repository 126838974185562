import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(() => ({
    card: {
        width: '300px',
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        borderRadius:'10px',
        '& .MuiCardContent-root': {
            padding: '5px 0px 0px 0px'
        },
        '& h6': {
            fontWeight: 'bold'
        },
        '& span': {
            fontWeight: 'bold'
        },
    },
    media: {
        paddingTop: "70%",
        maxHeight: 300,
    },
    center: {
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '5px 0px'
    },
    color: {
        color: '#00c08a',
    },
    button: {
        background: '#00c08a',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#00c08a',
        }
    },
    offerNumber: {
        backgroundColor: 'transparent',
        marginTop: '-200px',
        marginLeft: '10px'
    },
    offerNumberContent: {
        height: '25px',
        width: '25px',
        display: 'inline-block',
        backgroundColor: '#00c08a',
        color: '#fff',
        borderRadius: '50%',
        textAlign: 'center',
    },
    bold: {
        fontWeight: 'bold'
    },
    solid: {
        borderBottom: "1px solid #D3D3D3",
        borderTop: "1px solid #D3D3D3"
    },
    solidCenter:{
        borderLeft: "1px solid #D3D3D3"
    },
    cardText:{
        fontWeight: "bold",
        fontSize: "16px",
        color: "#5B6268"
    },
    amountText: {
        fontWeight: "normal!important",
        fontSize: "16px",
        color: "#5B6268"
    },
    centerContainterButton:{
        padding: "13px 0px"
    }
}));

export default styles;