import React, {useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  Legend,
  AreaSeries,
} from '@devexpress/dx-react-chart-material-ui';
import classNames from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Stack, Animation } from "@devexpress/dx-react-chart";
import { getSalesData } from "../../Services/generalDataAmazon";

const TERM = 12;
const PERCENT_REVENUE = 0.20;
const setStyle = (style) => {
    const wrap = withStyles({ root: style });
    return (Target) =>
      wrap(({ classes, className, ...restProps }) => (
        <Target className={classNames(classes.root, className)} {...restProps} />
      ));
  };

const ChartRoot = setStyle({
    paddingRight: "20px"
  })(Chart.Root);

const format = () => (tick) => tick;

const LegendRoot = setStyle({
    display: "flex",
    margin: "auto",
    flexDirection: "row"
  })(Legend.Root);
  
  const LegendLabel = setStyle({
    whiteSpace: "nowrap"
  })(Legend.Label);

  const stacks = [
    {
      series: ["Ventas Esperadas", "Pago a realizar"]
    }
  ];

  let salesData = [];
export default function GraphicCredit(props) {
    
    
    useEffect(() => {
        getSalesData().then((res) => {
            //console.log(res)
            salesData = (res ? formatData(res.items) : null);
            console.log(salesData);
		}
		).catch((error) => {
			console.log(error);
        });
        
        /*let res = {
            items: [
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 225,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-9.0",
                    month: 1,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 37,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-9.0",
                    month: 2,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 49,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-9.0",
                    month: 3,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 176,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-10.0",
                    month: 4,
                    orders: 1
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 22,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-10.0",
                    month: 5,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 85,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-10.0",
                    month: 6,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 280,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-10.0",
                    month: 7,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 141,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-10.0",
                    month: 8,
                    orders: 1
                },
                {
                    logistic: null,
                    qualification: 0,
                    sale_id: 165,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-10.0",
                    month: 9,
                    orders: 3
                },
                {
                    logistic: null,
                    qualification: 0,
                    sale_id: 78,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-11.0",
                    month: 10,
                    orders: 2
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 212,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-11.0",
                    month: 11,
                    orders: 1
                },
                {
                    logistic: true,
                    qualification: 5,
                    sale_id: 18,
                    sale: 100300,
                    amazon_id: 7,
                    year: 2018.0,
                    date: "2018.0-11.0",
                    month: 12,
                    orders: 1
                }
              ]
        }
        salesData = formatData(res.items);
        console.log(salesData);
        setData(salesData);*/
    }, []);
    
    const formatData = (originalData) => {
        let amount = []
        let tableData = [];

        originalData.forEach((month) => {
            amount.push(month.sale);
        });

        for(let i = 0; i < TERM; i++) {
            let year = originalData[i].year + 1;
            let month = originalData[i].month - 1;
            let date = new Intl.DateTimeFormat('es-MX', {month: 'long'}).format(new Date(year, month));

            tableData.push({
               month: date[0].toUpperCase() + date.slice(1) + " " + year,
               history: amount[i],
               discount: amount[i] * PERCENT_REVENUE
               //history: new Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(amount[i]),
               //discount: new Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(amount[i] * PERCENT_REVENUE)
            });
        }
        console.log(tableData);
        return tableData;
    };

    return(
        <Paper>
            <Chart data-test="graphic-credit"
            data={salesData} rootComponent={ChartRoot}
            >
            <ArgumentAxis tickFormat={format} />
            <ValueAxis />
            <AreaSeries name="Pago a realizar" valueField="discount" argumentField="month" />
            <AreaSeries
                    name="Ventas Esperadas"
                    valueField="history"
                    argumentField="month"
                />
            
            <Animation />
            <Legend
                    position="bottom"
                    rootComponent={LegendRoot}
                    labelComponent={LegendLabel}
                />
                <Stack stacks={stacks} />
            </Chart>
        </Paper>
    )
}
  

