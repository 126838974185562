
export const actionTypes = {
    USER_GET_USERDATA: "USER_GET_USERDATA",
    USER_SAVEDATA: "USER_SAVEDATA",
    USER_BUTTON_TITLE: "USER_BUTTON_TITLE",
    USER_ACTION_BUTTONS_DISABLE: "USER_ACTION_BUTTONS_DISABLE",
    USER_SAVE_RISK: "USER_SAVE_RISK",
    USER_SHARE_HOLDERS: "USER_SHARE_HOLDERS"
};

export function userData(data) {
    localStorage.setItem("bontu_user", JSON.stringify(data));
    return dispatch => {
        return dispatch({ type: actionTypes.USER_GET_USERDATA, payload: data });
    };
}
export function saveData(savedata) {
    return dispatch => {
        return dispatch({ type: actionTypes.USER_SAVEDATA, payload: savedata });
    };
}
export function buttonTitle(buttontitle) {
    return dispatch => {
        return dispatch({ type: actionTypes.USER_BUTTON_TITLE, payload: buttontitle });
    };
}
export function actionButtonsDisable(isActionButtonsDisable) {
    return dispatch => {
        return dispatch({ type: actionTypes.USER_ACTION_BUTTONS_DISABLE, payload: isActionButtonsDisable });
    };
}
export function valueRisk(valuerisk) {
    return dispatch => {
        return dispatch({ type: actionTypes.USER_SAVE_RISK, payload: valuerisk });
    };
}
export function shareHolders(shareholders) {
    return dispatch => {
        return dispatch({ type: actionTypes.USER_SHARE_HOLDERS, payload: shareholders });
    };
    
}

