import React from 'react';
import PropTypes from 'prop-types';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Button,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import useStyles from './styles';
import { amountFormat } from '../../../sharedFunction/utils';
import { useSelector} from "react-redux";


const OfferCard = ({ index, amount, months, retention, data, onClick }) => {
    const { t } = useTranslation("UserApp");
    const classes = useStyles();
    const user = useSelector((state) => state.user.userInfo);

    const pictures = {
        picture1: user.platform.offers_images.images[0].s3_path,
        picture2: user.platform.offers_images.images[1].s3_path,
        picture3: user.platform.offers_images.images[2].s3_path,
    }

    return (
        <React.Fragment key={`${'offerCard'}${index}`}>
            <Card className={classes.card} style={{ margin: '20px auto' }}>
                <CardMedia
                    className={classes.media}
                    image={pictures[`${'picture'}${index}`]}
                >
                    <div className={classes.offerNumber}>
                        <div className={classes.offerNumberContent}>
                            {index}
                        </div>
                    </div>
                </CardMedia>
                <CardContent className={classes.center}>
                    <Typography className={`"MuiTypography--subheading" ${classes.cardText}`} variant="caption" data-test="amount">
                        {t('AMOUNT')}&nbsp;&#36;
                    </Typography>
                    <Typography className={`${classes.color}`} variant="h6" gutterBottom>
                        {amountFormat(amount)}
                    </Typography>
                    <Grid container className={`${classes.center} ${classes.solid}`}>
                        <Grid className={classes.center} item lg={6} xl={6} md={6} sm={6} xs={6}>
                            <Typography className={`"MuiTypography--subheading" ${classes.cardText}`} variant="caption" data-test="term">
                                {t('TERM')}
                            </Typography>
                            <DateRangeIcon fontSize="small" style={{ position: 'absolute', color: '#5B6268'}} />
                            <Typography className={classes.color} variant="h6" gutterBottom>
                                {months} {t('MONTHS')}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} xl={6} md={6} sm={6} xs={6} className={`${classes.center} ${classes.solidCenter}`}>
                            <Typography className={`"MuiTypography--subheading" ${classes.cardText}`} variant="caption" data-test="retention">
                                {t('RETENTION')} &#37;
                            </Typography>
                            <Typography className={classes.color} variant="h6" gutterBottom>
                                {retention} &#37;
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={`${classes.center} ${classes.centerContainterButton}`}>
                    <Button variant="contained" size="small" className={classes.button} data-test="showOfferDetail" onClick={e => onClick({ data: data, show: true })}>
                        {t('SHOW_OFFER_DETAIL')}
                    </Button>
                </CardActions>
            </Card>
        </React.Fragment>
    );
}

OfferCard.propTypes = {
    index: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    months: PropTypes.number.isRequired,
    retention: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};

export default OfferCard;