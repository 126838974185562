import React from "react";
import {makeStyles,
		Container,
		Typography,
		Link,
		Grid
} from "@material-ui/core"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	footer: {
		backgroundColor: "#172a33",
		padding: theme.spacing(0, 0),
		position: "absolute",
	},
	Typography: {
		color: "#fff",
		width: "100%",
	},
	legalsSection: {
		backgroundColor: "#003a49",
		color: "white",
		padding: "0% 10%",
		paddingBottom: "10px"
	},
	listLegals: {
		listStyleType: "none",
		paddingLeft: "0px",
		[theme.breakpoints.between('xs', 'sm')]: {
			paddingTop: '0%',
			paddingLeft: '0%',
			"& > li": {
				padding: "5px 0px",
				"& > a": {
					color: "#00c08a",
					textDecoration: "none",
					fontSize: "14px",
					"&:hover": {
						color: "#00ffb7",
						textDecoration: "underline"
					}
				}
			},
		},
		[theme.breakpoints.up('md')]: {
			"& > li": {
				padding: "5px 0px",
				"& > a": {
					color: "#00c08a",
					textDecoration: "none",
					fontSize: "12px",
					"&:hover": {
						color: "#00ffb7",
						textDecoration: "underline"
					}
				}
			}
		}
	},
	listLegals2: {
		listStyleType: "none",
		paddingLeft: "0px",
		[theme.breakpoints.between('xs', 'sm')]: {
			paddingTop: '0%',
			paddingLeft: '0%',
			"& > li": {
				padding: "5px 0px",
				"& > a": {
					color: "#00c08a",
					textDecoration: "none",
					fontSize: "12px",
					"&:hover": {
						color: "#00ffb7",
						textDecoration: "underline"
					}
				}
			}
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: '22%',
			marginLeft: '10%',
			"& > li": {
				padding: "5px 0px",
				"& > a": {
					color: "#00c08a",
					textDecoration: "none",
					fontSize: "12px",
					"&:hover": {
						color: "#00ffb7",
						textDecoration: "underline"
					}
				}
			}
		}
	},
	listInstitutions: {
		paddingLeft: "0px",
		paddingTop: "5%",
		"& > li": {
			display: "inline-block",
			padding: "0px 15px",
			maxWidth: "80px",

			"& li > img": {
				width: "100%"
			}
		},
		[theme.breakpoints.between('xs', 'sm')]: {
				paddingLeft: '17%',
				paddingTop: '10%',
				paddingBottom:'5%',
				justifyContent: 'flex-end',
				display: "inline-block",
				padding: '0px 2px',
			}
	},

	listText: {
		fontSize: "0.6em",
		textAlign: "justify",
		opacity: "0.5",
		[theme.breakpoints.between('xs', 'sm',)]: {
			paddingLeft: '10%'
		}
	},
	imagen: {
		height: '60px',
		width: '70px',
		[theme.breakpoints.between('xs', 'sm',)]: {
			height: '40px',
			width: '40px',
		}
	},
}));

export default function Footer() {
	const classes = useStyles();
	const { t } = useTranslation("UserApp");

	return (
		<footer className={classes.footer}>
			<div className={classes.legalsSection}>
				<Grid container spacing={0} padding={'0%'}>
					<Grid item xs={9} sm={3}>
						<h3>{t("LEGALS_TITLE")}</h3>
						<Grid className={classes.listLegals}>
							<li><a href="http://www.bontu.mx/legal/index.html#constitucion">{t("LEGALS_LINK1")}</a></li>
							<li><a href="http://www.bontu.mx/legal/index.html#terminos">{t("LEGALS_LINK2")}</a></li>
							<li><a href="http://www.bontu.mx/legal/index.html#aviso">{t("LEGALS_LINK3")}</a></li>
							<li><a href="http://www.bontu.mx/legal/index.html#leyendas">{t("LEGALS_LINK4")}</a></li>
						</Grid>
					</Grid>
					<Grid item xs={9} sm={3}>
						<Grid className={classes.listLegals2}>
							<li><a href="http://www.bontu.mx/legal/index.html#educacion">{t("LEGALS_LINK5")}</a></li>
							<li><a href="http://www.bontu.mx/legal/index.html#une">{t("LEGALS_LINK6")}</a></li>
							<li><a href="http://www.bontu.mx/legal/index.html#buro">{t("LEGALS_LINK7")}</a></li>
							<li><a href="http://www.bontu.mx/legal/index.html#condusef">{t("LEGALS_LINK8")}</a></li>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container spacing={0}>
							<Grid item xs={11} sm={18}>
								<Grid className={classes.listInstitutions}>
									<li>
										<a href="/">
											<img className={classes.imagen} src={require("../../assets/images/logos/logoUNE.png")} alt='' />
										</a>
									</li>
									<li><a href="https://www.gob.mx/condusef">
										<img className={classes.imagen} src={require("../../assets/images/logos/logoCONDUSEF.png")} alt='' />
									</a></li>
									<li><a href="https://www.buro.gob.mx/">
										<img className={classes.imagen} src={require("../../assets/images/logos/logoBURO.png")} alt='' />
									</a></li>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<p className="list-text">{t("LEGALS_LEYEND")}</p>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			<Container maxWidth="lg" align="center">
				<Typography align="center" className={classes.Typography}>
					{"©"}
					{new Date().getFullYear()}
					{" "}
					<Link color="inherit" href="https://bontu.mx/">
						Bontu
					</Link>{" | Todos los derechos reservados. Bontu es una empresa de AlphaCredit®."}
				</Typography>
			</Container>
		</footer>
	);
}
