import React from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Box,
    CircularProgress
 } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    circularLabel: {
        color: "#5B6268",
        fontWeight: "bold"
    },
     circularColor:{
        color: "#00c08a",
        width: "100px",
        zIndex: "99"
    },
    circularBack: {
        color: "#C2D4DC",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: "1"
    },
}));
const ProgressWithLevel = ({ value, totalSteps }) => {
    const classNames= useStyles();
    const { t } = useTranslation("UserApp");
    var percentage = (value * 100) / totalSteps;
      return (
        <Box position="relative" display="inline-flex" >
          <CircularProgress variant="static" value={percentage} className={classNames.circularColor} size={75}/>
          <CircularProgress variant="determinate" value={100} className={classNames.circularBack} size={75}/>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography data-test= "text" variant="caption" component="div" className={classNames.circularLabel}>{`${Math.round(
              value
            )}`} {t("OF")} {totalSteps}</Typography>
          </Box>
        </Box>
      );
}

ProgressWithLevel.propTypes = {
    value: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired
};

export default ProgressWithLevel;