import React, { useState, useEffect } from "react";
import { estimatedPayments } from "../../../Services/generalDataAmazon";
import { Line } from 'react-chartjs-2'

export default function GraphicCapital({ amount, percent, request_id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    newData({
      "amount": amount,
      "percent": percent,
      "terms": 12,
      "request_id": request_id
    })
  }, [amount, percent, request_id]);

  const newData = (dataToSend) => {
    estimatedPayments(dataToSend).then((response) => {
      setData(response);
    }).catch((error) => {
      console.log(error)
    })
  }

  const monthsFormat = (month) => {
    const monthsNames = {
      enero: 'Ene',
      febrero: 'Feb',
      marzo: 'Mar',
      abril: 'Abr',
      mayo: 'May',
      junio: 'Jun',
      julio: 'Jul',
      agosto: 'Ago',
      septiembre: 'Sep',
      octubre: 'Oct',
      noviembre: 'Nov',
      diciembre: 'Dic'
    }
    return monthsNames[month];
  }

  let months = []
  let payments = []
  let expected_sales = []

  data.map((payment, indexPayment) => {
    const format = payment['date'].split(" ")
    var date = monthsFormat(format[0]) + ' ' + format[1]
    months.push(date)
    payments.push(payment['payment'])
    expected_sales.push(payment['expected_sales'])
  })
  
  const optionsLegends = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 12,
        boxWidth: 12,
        usePointStyle: true,
        padding: 50
      }
    },
    scales: {
      yAxes: [{
        gridLines: {
          color: 'rgb(0, 192, 138,0.7)',
        }
      }],
      xAxes: [{
        gridLines: {
          display: false
        },
        offset: true
      }]

    }
  }

  const data_set = {
    labels: months,
    datasets: [
      {
        label: "Pago a realizar",
        data: payments,
        fill: true,
        backgroundColor: "rgba(0, 59, 74,0.8)",
        pointRadius: 0,
        lineTension: 0
      },
      {
        label: "Ventas esperadas",
        data: expected_sales,
        fill: true,
        backgroundColor: "rgba(153, 177, 183,0.8)",
        pointRadius: 0,
        lineTension: 0,
      }

    ]
  }
  return (
    
      <div style={{ width: '100%', height: '100%', minHeight:'400px' }}>
        <Line data={data_set} options={optionsLegends} />
      </div>
    
  );
}