import React, { useState } from 'react';
import {
    Fade,
    Slide,
    IconButton,
    Grid,
    makeStyles
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    indicators: {
        width: "100%",
        marginTop: "10px",
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

    },
    indicator: {
        fontSize: "15px",
        cursor: "pointer",
        transition: "200ms",
        color: "#afafaf",
        '&:hover': {
            color: "#1f1f1f"
        },
        '&:active': {
            color: "#1f1f1f"
        }
    },
    active: {
        color: "#00C08B"
    },
    centerEnd: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: "center",
        },
    },
    button: {
        padding: '0',
        fontWeight: 'bold',
        boxShadow: '0 0 5px 0 rgba(34, 34, 34, 0.4)'
    },
    buttonWrapper: {
        background: '#ffffff',
        color: '#58595B',
        borderRadius: '50%',
        position: 'absolute',
        top: '30%',
        padding: '0',
        '&:hover': {
            background: 'rgba(250, 250, 250, 1)'
        }
    },
    next: {
        right: '-15px'
    },
    prev: {
        left: '-15px'
    },
    icons: {
        fontSize: '1.8rem',
    }
}));


export default function Carousel(props) {
    const [active, setActive] = useState(0);
    const classes = useStyles();
    const timeout = 200;
    const animation = props.animation ? props.animation : 'slide';

    const pressIndicator = (index) => {
        setActive(index);
    }

    const next = (value) => {
        setActive(value);
    }
    const prev = (value) => {
        setActive(value);
    }

    const nextStep = () => {
        const nextValue = active + 1 > props.children.length - 1 ? 0 : active + 1;
        if (nextValue === 0) {
            setActive(0);
        } else {
            next(nextValue);
        }
    }
    const prevStep = () => {
        const prevValue = active - 1 < 0 ? props.children.length - 1 : active - 1;
        if (prevValue === -1) {
            setActive(props.children.length - 1);
        } else {
            prev(prevValue);
        }
    }


    return (
        <React.Fragment>
            <div className={classes.root}>
                {
                    Array.isArray(props.children) ?
                        props.children.map((child, index) => {
                            return (
                                <CarouselItem key={index} active={index === active ? true : false} child={child} animation={animation} timeout={timeout} />
                            )
                        })
                        :
                        <CarouselItem key={0} active={true} child={props.children} animation={animation} timeout={timeout} />
                }
                <div className={`${classes.buttonWrapper} ${classes.next}`}>
                    <IconButton className={`${classes.button} mui--align-middle`} onClick={nextStep}>
                        <NavigateNextIcon className={classes.icons}/>
                    </IconButton>
                </div>

                <div className={`${classes.buttonWrapper} ${classes.prev}`}>
                    <IconButton className={`${classes.button} mui--align-middle`} onClick={prevStep}>
                        <NavigateBeforeIcon className={classes.icons}/>
                    </IconButton>
                </div>
                <Grid container justify='center' alignItems='center'>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                        <Indicators classes={classes} length={props.children?.length} active={active} press={pressIndicator} />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}


function CarouselItem(props) {
    return (
        <div className="CarouselItem" hidden={!props.active}>
            {props.animation === "slide" ?
                <Slide direction="left" in={props.active} timeout={props.timeout}>
                    <div>
                        {props.child}
                    </div>
                </Slide>
                :
                <Fade in={props.active} timeout={500} >
                    <div>
                        {props.child}
                    </div>
                </Fade>
            }
        </div>
    )
}

function Indicators(props) {
    const classes = props.classes;

    let indicators = [];
    for (let i = 0; i < props.length; i++) {
        const className = i === props.active ? `${classes.indicator} ${classes.active}` : `${classes.indicator}`;
        const item = <FiberManualRecordIcon key={i} size='small' className={className} onClick={() => { props.press(i) }} />;

        indicators.push(item);
    }

    return (
        <div className={`${classes.indicators}`}>
            {indicators}
        </div>
    )
}