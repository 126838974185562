import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Typography, Card, Hidden ,Container,Button,Grid} from '@material-ui/core';
import Group2 from '../../../../assets/images/instructiveForQR/Group2.png';
import chromeLogo from '../../../../assets/images/instructiveForQR/chromeLogo.png';
import safariLogo from '../../../../assets/images/instructiveForQR/safariLogo.png';
import { useTranslation } from "react-i18next";
import { useDispatch} from "react-redux";
import { configActionsButtons } from "../../../../store/actions/app.actions";
import Environment from "../../../../environments/environment";
import InstructionForms from "../../../sharedComponents/InstructionForms";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    typography: {
        [theme.breakpoints.between('xs', 'sm')]: {
            textAlign: 'center',
            color: '#003B4A',
            fontWeight: 'bold',
            fontFamily: 'roboto',
            fontSize: '24px',
            height: '56px',
            marginBottom: '50px'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
            textAlign: 'center',
            color: '#003B4A',
            fontWeight: 'bold',
            fontFamily: 'roboto',
            fontSize: '24px',
            marginBottom: '50px'
        },
    },
    texts: {
        [theme.breakpoints.between('xs', 'sm')]: {
            fontFamily: 'roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            color: "#5B6268",
            width: '300px'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
            fontFamily: 'roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            color: "#5B6268",
            width: '400px'
        },
    },
    tesxtTwo: {
        [theme.breakpoints.between('xs', 'sm')]: {
            fontFamily: 'roboto',
            fontStyle: 'normal',
            fontSize: '16px',
            alignItems: 'center',
            color: '#5B6268',
            padding: '20px'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
            fontFamily: 'roboto',
            fontStyle: 'normal',
            fontSize: '16px',
            alignItems: 'center',
            color: '#5B6268',
            padding: '20px'
        },
    },
    gridStyle: {
        [theme.breakpoints.between('xs', 'sm' )]: {
            width: '100%',
            alignItems: 'center'
        },
        [theme.breakpoints.between('768','1024')]:{
            width: '100%',
            alignItems: 'center',
            marginInline: 'auto'
        },
        [theme.breakpoints.between( 'md','lg', 'xl')]: {
        }
    },
    NumberContent: {
        height: '25px',
        width: '25px',
        backgroundColor: '#00c08a',
        color: '#green',
        borderRadius: '50%',
        textAlign: 'center',
    },
    Number: {
        backgroundColor: 'transparent',
        marginTop: '-200px',
        marginLeft: '10px',
        Align: 'left',
    },
    card: {
        [theme.breakpoints.between('xs', 'sm')]: {
            backgroundColor: '#EAEDEF',
            Radius: '16px',
            marginLeft: '25px'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
           width: '100%',
            radius: '16px',
            backgroundColor: '#EAEDEF',
        },
    },
    divider: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
            top: '67px',
            border: '1px',
            height: '1px',
            backgroundColor: '#E0E4E7',
            alignSelf: 'center',
            marginTop: '50px',
            marginBottom: '50px'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
            width: '1px',
            top: '67px',
            border: '1px',
            height: '350px',
            backgroundColor: '#E0E4E7',
            alignSelf: 'center',
            marginRight: '50px',
            marginLeft: '50px'
        },
    },
    spam: {
        verticalAlign: 'middle'
    },
    button: {
        width: '170px',
        marginTop: '67px',
        marginBottom: '33px',
        backgroundColor: '#00C08B',
        color: '#FFFFFF',
        borderRadius: '4px',
        
    },
    divs: {
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'flex',
            marginTop: '50px',
            textAlign: 'center'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
            margin: '0 auto'
        }
    },
    chrome: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100px',
            display: 'inline-block',
            height: '100%',
            marginTop: '0%'
        },
        [theme.breakpoints.up('md', 'lg', 'xl')]: {
            width: '100px',
            display: 'inline-block',
            height: '100%',
            marginTop: '20%'
        }
    },
}));
export default function SignRequest(props) {
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    const dispatch = useDispatch();
    const [desktop,setDesktop] = useState ();
    const [mobile,setMobile] = useState ();
    const request = props.requestData;
    const instructions = props.instructions
    const fadQR= props.fadQR

    const changeBrowser = () => {
        let browser = navigator.userAgent.indexOf("Mobile");
        if(browser <= -1){
            setDesktop(browser)
        }else{
            setMobile(browser)
        }
    };

    const onSignClicked = () => {
        window.open(request.request.contract_url, "_blank");
    };

    useEffect(() => {
        dispatch(configActionsButtons({
            backButton: { show: false, disable: true },
            nextButton: { show: false, disable: true }
        }));
        changeBrowser();
       props.titlehandler(null);
    }, [dispatch,props.titlehandler]);

    return (
        <div data-test="data-container" >
            <Container maxWidth='100%' >
                <Grid container className={classes.gridStyle}  data-test="data-container">
                    <Grid item xs={12}>
                        <Typography className={classes.typography} >{t("YOU_ARE_ONE_STEP_AWAY")}</Typography>
                    </Grid>
                        <Card className={classes.card} >
                        <InstructionForms  className={classes.tesxtTwo} mensaje={instructions}/>
                        </Card>
                </Grid>
                <br></br>
                <CardContent className={classes.Card} style={{ backgroundColor: '#FFFFFF', top: '0px' }} >
                    <Grid container  data-test="data-container" >
                        <Hidden >
                            <Grid item xs={5} className={classes.gridStyle} hidden={desktop <= -1}>
                                <div>
                                    <div class="numeros">
                                        <div className={classes.Number} className={classes.NumberContent} style={{ color: '#FFFFFF' }}>
                                            <span className={classes.spam}>1</span>
                                        </div>
                                    </div>
                                    <Typography className={classes.texts}>{t('QR_CODE_FIVE')} <a href style={{fontWeight:'bold'}}>{t('QR_CODE_SIX')} </a></Typography>
                                    <div style={{marginInline:'55px'}}>
                                        <Button className={classes.button} variant="contained" size="large" onClick={onSignClicked}>
                                            {t('SIGN_CONTRACT')}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Hidden>
                        
                        <Hidden  >
                            <Grid item xs={5}  hidden={mobile >= 1}>
                                <div > 
                                    <div class="numeros">
                                        <div className={classes.Number} className={classes.NumberContent} style={{ color: '#FFFFFF' }}>
                                            <span className={classes.spam}>1</span>
                                        </div>
                                    </div>
                                    <Typography className={classes.texts}>{t('QR_CODE_ONE')}  <a href style={{fontWeight:'bold'}}> {t('QR_CODE_TWO')} </a> {t('QR_CODE_THREE')} <a href style={{fontWeight:'bold'}}>{t('QR_CODE_FOUR')} </a></Typography>
                                    <div class="maquetado">
                                        <div id="content" >
                                            <div> <img alt="" alignItems='center' src={`${Environment.cognitoConfig.API.endpoints[0].endpoint}/image?token=${encodeURIComponent(fadQR)}`}/></div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Hidden>
                        
                        <div className={classes.divider}></div>
                        <Grid item xs={5}  className={classes.gridStyle}>
                            <div class="numeros">
                                <div className={classes.Number} className={classes.NumberContent} style={{ color: '#FFFFFF' }}>
                                    <span className={classes.spam}>2</span>
                                </div>
                            </div>
                            <Typography  className={classes.texts} > {t('SIGN_INSTRUCTION_ONE')} <a href style={{fontWeight:'bold'}}> {t('SIGN_INSTRUCTION_TWO')} </a> {t('SIGN_INSTRUCTION_THREE')} <a href style={{fontWeight:'bold'}}> {t('SIGN_INSTRUCTION_FOUR')} </a> {t('SIGN_INSTRUCTION_FIVE')}</Typography>
                            <Grid container spacing={1} data-test="data-container">
                                <div className={classes.divs} >
                                    <div class="celular" style={{ marginLeft: '50px' }}>
                                        <img alt ="" src={Group2} />
                                    </div>
                                    <div className={classes.chrome} style={{ marginLeft: '50px' }}>
                                        <img alt="" style={{ marginLeft: '15px' }} src={chromeLogo} />
                                        <img alt="" style={{ marginTop: '15px' }} src={safariLogo} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Container>
        </div>
    );
}