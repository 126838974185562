import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Instructive from "../../../sharedComponents/Instructive/instructive";
import UploadFiles from "../../../sharedComponents/UploadFiles/UploadFiles";

const useStyles = makeStyles((theme) => ({
    backgroundSquare: {
        background: "#ffffff",
        padding: "25px"
    },
    center: {
        textAlign: "center",
        padding: "15px"
    },
    button: {
        backgroundColor: "#00c08b",
        color: "white",
        padding: "6px 20px",
        borderRadius: "0px",
        borderTopRightRadius: "10px",
        boxShadow: "1px 1px 4px 0px #717171",
        '&:hover': {
            backgroundColor: "#00c08b",
        },
    },
}));

export default function PlatformDocuments(props) {
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    const [open, setOpen] = useState(false);
    const platformDocuments = props.requiredDocs;


    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const dataInstructive = {
        modalData: {
            title: 'Estado de Cuenta',
            description: [
                { text: '', variant: 'body2' },
                { text: '', variant: 'body2', }
            ]
        },
        carouselConfig: { indicators: true, showNavigation: true },
        handleAcept: { text: '¡Entendido!', action: event => { handleClose(); } },
        handleClose: { text: 'Cerrar', action: event => { handleClose(); } },
        content:
            [
                {
                    title: 'Paso 1',
                    description: 'Paso 1: ',
                    image: 'estado_cuenta_1'

                },
                {
                    title: 'Paso 2',
                    description: 'Paso 2:  ',
                    image: 'estado_cuenta_2'
                },
                {
                    title: 'Paso 3',
                    description: 'Paso 3: ',
                    image: 'estado_cuenta_3'
                },
                {
                    title: 'Paso 4',
                    description: 'Paso 4: ',
                    image: 'estado_cuenta_4'
                },
                {
                    title: 'Paso 5',
                    description: 'Paso 5: ',
                    image: 'estado_cuenta_5'
                },
            ]
    }

    const docs = platformDocuments.map((d) => {
        let doc = {};

        doc['name'] = d['label'];
        doc['description'] = d['alt'];
        doc['ID'] = d['code'];

        return doc;
    });

    const documents = {
        data: {
            typePerson: (localStorage.getItem("bontu_userTypePerson") ? localStorage.getItem("bontu_userTypePerson") : "PF"),
            documents: docs
        }
    };

    return (
        <div className={classes.backgroundSquare}>
            <Grid container className={classes.center}>
                <Grid item xs></Grid>
                <Grid item xs={6}>
                    <UploadFiles docData={documents} ref={props.stepRef} data-test="upload-files" activeStephandler={props.activeStephandler}/>
                </Grid>
                <Grid item xs></Grid>
            </Grid>
            <Grid container className={classes.center}>
                <Grid item xs>
                    <Button variant="contained" className={classes.button} onClick={handleOpen}>
                        {t("INSTRUCTIVE")}
                    </Button>
                    <Instructive open={open} data={dataInstructive} />
                </Grid>
            </Grid>
        </div>
    );
}