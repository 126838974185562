import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

export default (props) => {
    return (
        <div>
            {props.displayName}
            <br></br>
            <InfoIcon style={{ fontSize: 15 }} />
        </div>
    );
};