import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./sharedComponents/header";
import Footer from "./sharedComponents/footer";
import i18next from "i18next";
import en from "./configs/navigation-i18n/en";
import es from "./configs/navigation-i18n/es";
import Router from "./configs/router";
import { useUser } from "./Context/UserContext";
import { useSelector, useDispatch } from "react-redux";
import { userData } from "../store/actions/user.actions";

i18next.addResourceBundle("en", "UserApp", en);
i18next.addResourceBundle("es", "UserApp", es);


const App = () => {
	const { logout } = useUser();
	const dispatch = useDispatch();
	const _userData = useSelector((state) => state.user.userInfo);
	const _configLayout = useSelector((state) => state.app.configLayout);

	useEffect(() => {
		if (window.location.pathname === "/" || !localStorage.getItem("bontu_user")) {
			logout();
		} else {
			!_userData && dispatch(userData(JSON.parse(localStorage.getItem("bontu_user"))));
		}
	}, [_userData, dispatch, logout])
	return (
		<BrowserRouter>
			{
				_configLayout.changeLayout === true ?
					[(_configLayout.showHeader === true ? <Header /> : ''),
					<Router />,
					(_configLayout.showFooter === true ? <Footer /> : '')]
					:
					[<Header />,
					<Router />,
					<Footer />]
			}

		</BrowserRouter>
	);
};

export default App;

