import React from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

function ButtonIconRenderer(props) {
	const invokeParentMethod = () => {
		props.context.methodFromParent(props);
	};
	const { btnIconDef } = props.context;
	const { headerName } = props.colDef;

	function ActionButton() {
		return (
			<div style={{ textAlign: "center" }}>
				<IconButton
					id={btnIconDef[headerName].id}
					color={btnIconDef[headerName].color}
					aria-label={btnIconDef[headerName].aria_label}
					onClick={invokeParentMethod}
				>
					<Icon fontSize="large">{btnIconDef[headerName].icon}</Icon>
				</IconButton>
			</div>
		);
	}

	function DownloadButton() {
		const href = btnIconDef[headerName].href ? btnIconDef[headerName].href : props.value;
		return (
			<div style={{ textAlign: "center" }}>
				<IconButton
					id={btnIconDef[headerName].id}
					color={btnIconDef[headerName].color}
					aria-label={btnIconDef[headerName].aria_label}
					onClick={invokeParentMethod}
					href={href}
					download
					style={{ color: btnIconDef[headerName].color }}
				>
					<Icon fontSize="large">{btnIconDef[headerName].icon}</Icon>
				</IconButton>
			</div>
		);
	}

	if (btnIconDef[headerName].btnAction === "download") {
		return <DownloadButton />;
	}
	return <ActionButton />;
}

export default ButtonIconRenderer;
