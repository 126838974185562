import React, { useLayoutEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLayout } from '../store/actions/app.actions';
import FreshChat from "./sharedComponents/freshChat";

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const dispatch = useDispatch();
	useLayoutEffect(() => {
		dispatch(changeLayout({
            changeLayout:false,
        }));
	}, [dispatch, Component]);

	return <Route {...rest} render={props => (
		localStorage.getItem("bontu_user")
			? [<Component {...props} />, <FreshChat />]
			: <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
	)} />;
};