import React from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import genericError from '../../../assets/images/clickink/genericError.svg';
import Logo from "./../../../assets/images/logos/bontu.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    logo:{
        marginTop: '30px'
    },
    center: {
        textAlign: 'center',
        position: 'absolute'
    },
    typography:{
        width: '350px',
        height: 'auto',
        color: '#003B4A',
        fontWeight: 'bold',
        fontSize: 24,
        marginTop: '10px',
        textAlign: 'center',
        fontFamily: 'DM Sans'
    },
    message:{
        width: '180px',
        height: 'auto',
        color: '#003B4A',
        fontWeight: 'bold',
        fontSize: 16,
        marginTop: '3px',
        textAlign: 'center',
        fontFamily: 'DM Sans'
    },
    message2:{
        width: '350px',
        height: 'auto',
        color: '#003B4A',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center',
        fontFamily: 'DM Sans'
    },
    image:{
        marginTop: '45px',
        marginBottom: '21px'
    },
    button: {
		color: 'white',
        fontSize: '12px',
        fontWeight: 'bold',
        background: '#00C08B',
        borderRadius: '15px',
        width: '100%',
        height: '48px',
		marginTop: '50px',
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '19px',
        [theme.breakpoints.up('sm')]: {
            width: '25%',
        },
	},
    centerButton: {
        alignItems: 'center', 
        justifyContent: 'center'
    }
}));

export default function ErrorSign () {

    const classes = useStyles();
    const { t } = useTranslation('UserApp');
    let history = useHistory();

    const retry = () => {
        history.push("/");
        window.location.reload();
    };

    return (
    <div>
        <Grid container className={classes.center}>
            <Grid item xs={12} >
                <div align='center'>
                    <Grid item sm={12} xs={12} className={classes.logo} data-test='logo'>
                        <img src = {Logo} alt = 'logo bontu' />
                    </Grid>
                    <b data-test='message'>
                        <Typography className={classes.typography}>
                            ¡{t('UPS')}!
                        </Typography>
                        <Typography className={classes.message}>
                            {t('ERROR_CLICKINK_ONE')}
                        </Typography>
                        <Typography className={classes.message2}>
                            {t('ERROR_CLICKINK_TWO')}
                        </Typography>
                    </b>
                </div>
                <br />
                <div align='center' className={classes.image} data-test='imageError'>
                    <img alt='' src={genericError} />
                </div>
            </Grid>
            <Grid container className={classes.centerButton}>
                <Button variant='contained' className={classes.button} data-test='button' onClick={retry}>{t('RETRY')}</Button>
            </Grid>
        </Grid>
    </div>
    );
}