import React, {useEffect, useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { getSalesData } from "../../../Services/generalDataAmazon";
import PaymentsTableHeader from "./paymentsTableHeader";


const useStyles = makeStyles((theme) => ({
	root: {
        width: "100%",
        height: "auto"
    },
    Typography: {
        marginBottom: 10,
	}
}));

export default function PaymentsTable (props) {
	const classes = useStyles();
	const [data, setData] = useState(null);
    const { t } = useTranslation("UserApp");
    const percentRevenue = (props.percentRevenue ? (props.percentRevenue / 100) : 0.20);
    const TERM = 12;   

	const columnDefs = [
		{
			field: "month",
            headerName: t("DUE_DATE"),
            headerTooltip: t("PAYMENTS_TABLE_TOOLTIP1"), 
            maxWidth: 150,
		},
		{
			field: "history",
            headerName: t("EXPECTED_SALES"),
            headerTooltip: t("PAYMENTS_TABLE_TOOLTIP1"),
			maxWidth: 150
		},
		{
			field: "discount",
            headerName: t("ESTIMATED_PAYMENT"),
            headerTooltip: t("PAYMENTS_TABLE_TOOLTIP2"),
			maxWidth: 150 
		}
    ];
    
	useEffect(() => {
		getSalesData().then((res) => {
            console.log(res)
            let salesData = (res ? formatData(res.items) : null);
			setData(salesData);
		}
		).catch((error) => {
			console.log(error);
        });
	}, []);
    
    const formatData = (formData) => {
        let amount = []
        let tableData = [];

        formData.forEach((month) => {
            amount.push(month.sale);
        });

        for(let i = 0; i < TERM; i++) {
            let year = formData[i].year + 1;
            let month = formData[i].month - 1;
            let date = new Intl.DateTimeFormat('es-MX', {month: 'long'}).format(new Date(year, month));

            tableData.push({
               month: date[0].toUpperCase() + date.slice(1) + " " + year,
               history: new Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(amount[i]),
               discount: new Intl.NumberFormat('es-MX', {currency: 'MXN', style: 'currency'}).format(amount[i] * percentRevenue)
            });
        }
        return tableData;
    };

	return (
        <Card className={classes.root}>
            <CardContent> 
                <Typography className={classes.Typography} data-test="title">
                    {t("PAYMENTS")}
                </Typography>
                <div
                    style={{
                        height: "600px",
                        maxWidth: "100%",
                    }}
                    className="ag-theme-alpine"
                >
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={data ? data : null}
                        frameworkComponents={{ agColumnHeader: PaymentsTableHeader }}
                        tooltipShowDelay={500}
                        data-test="paymentsTable"
                    >
                    </AgGridReact>
                </div>
            </CardContent>
        </Card>
	);
}