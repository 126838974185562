import React from 'react';
import {
    Grid,
    Typography,
    Hidden,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';

const CustomFile = ({ fileName, size, classes }) => {

    return (
        <React.Fragment>
            <div className={classes.dropzone}>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.wh100} >
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.center}>
                        <BackupIcon fontSize="large" className={classes.iconColor} />
                    </Grid>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={9} style={{paddingLeft:'10px'}} >
                        <Hidden smUp>
                            <Typography variant="body2">{fileName}</Typography>
                            <Typography variant="body2">{bytesToSize(size)}</Typography>
                        </Hidden>

                        <Hidden xsDown>
                            <Typography>{fileName}</Typography>
                            <Typography>{bytesToSize(size)}</Typography>
                        </Hidden>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}

const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export default CustomFile;