import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import EstimatedPayments from '../OfferDetail/EstimatedPayments';
import InstructionForms from '../../../sharedComponents/InstructionForms'
import { getMonthByDay } from '../../../sharedFunction/utils'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: '#143947',
    backgroundColor: theme.palette.background.paper,
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
    marginTop: '20px'
  },
  right: {
    textAlign: 'right',
  },
  appBar: {
    background: '#FFFFFF',
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  tabs: {
    backgroundColor: 'white',
    color: '#333',
    borderRadius: '5px',
    padding: '0 25px',
    margin: '2px',
    '&.selected': {
      color: '#FFFFFF',
      backgroundColor: '#00c08a',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 150, minHeight: 60, maxWidth: 150, maxHeight: 60
    }
  },
  no_margin: {
    margin: '-8px',
  },
  indicator: {
    backgroundColor: 'white',
  },
  disclaimer: {
    color: '#5B6268',
    fontStyle: 'Roboto',
    marginTop: '20px'
  },
  cat: {
    color: '#5B6268',
    fontStyle: 'Roboto',
    marginTop: '5px'
  },
}));

const CreditSummary = ({ amount, max_amount, months, retention, monthlyRate, openCommission, otherCommissions, cat_calculation_date_data, cat_without_tax, realAmount, terms, percent, request_id, estimatedPayment, instructions, epayment }) => {

  const { t } = useTranslation("UserApp");
  const classes = useStyles();
  const [openTab, setOpenTab] = React.useState(0);

  if (cat_without_tax % 1 !== 0) {
    cat_without_tax = cat_without_tax * 100;
  }

  let currentMonth = getMonthByDay(new Date(cat_calculation_date_data.year, cat_calculation_date_data.month-1, cat_calculation_date_data.day));

  const handleChange = (event, openedTab) => {
    setOpenTab(openedTab);
  };
  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  var vRealAmount = currencyFormat(realAmount),
    vAmount = currencyFormat(amount),
    vMaxAmount = currencyFormat(max_amount),
    vEstimatedPayment = currencyFormat(estimatedPayment);
  let formatter_num = new Intl.NumberFormat('en-US', {
  });

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} style={{backgroundColor:'#FFFFFF'}}>
        <Tabs value={openTab}
          initialSelectedIndex={0}
          onChange={handleChange}
          className={classes.appBar}
          classes={{
            indicator: classes.indicator
          }}
          aria-label="credit summary">
          <Tab className={classes.tabs} data-test="credit" classes={{ selected: "selected" }} label={t('CREDIT_SUMMARY')} {...a11yProps(0)} >{'CREDIT_SUMMARY'}</Tab>
          {epayment ? <Tab className={classes.tabs} classes={{ selected: "selected" }} label={t('ESTIMATED_PAYMENTS')} {...a11yProps(1)} >{'ESTIMATED_PAYMENTS'}</Tab>:null}
        </Tabs>
      </AppBar>
      <TabPanel value={openTab} index={0}>

        <Grid container spacing={0} >
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            <InstructionForms mensaje={(instructions["creditSummary"] !== undefined && instructions["creditSummary"] !== '') ? instructions["creditSummary"] : t('CREDIT_SUMMARY_TEXT')} /> 
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6} >
              <br />
              <Typography className={`${classes.right}`}>{t('PREAUTHORIZED_LINE')}: </Typography>
            </Grid>
            <Grid item xs={6} >
              <br />
              <Typography className={`${classes.bold}`} >{vMaxAmount}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6} >
              <Typography className={`${classes.right}`}>{t('REQUESTED_AMOUNT')}: </Typography>
            </Grid>
            <Grid item xs={6} >
              <Typography className={`${classes.bold}`} >{vAmount}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6}>
              <Typography className={`${classes.right}`}>{t('TERM')}: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${classes.bold}`}>{months} {t('MONTHS')}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6}>
              <Typography className={`${classes.right}`}>{t('WITHHOLDING_SALES')}: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${classes.bold}`} >{formatter_num.format(retention * 100).replace(/\D00/, '')}%</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6}>
              <Typography className={`${classes.right}`}>{t('MONTHLY_INTEREST')}: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${classes.bold}`} >{monthlyRate}%</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6}>
              <Typography className={`${classes.right}`}>{t('OPENING_COMMISSION')}: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${classes.bold}`} >{formatter_num.format(openCommission * 100).replace(/\D00/, '')}%</Typography>
            </Grid>
          </Grid>
          {otherCommissions && otherCommissions.map((commission, idCommission) => {
            var calc = 0;
            return (
              <Grid key={idCommission} container spacing={4} className={classes.no_margin}>
                <Grid item xs={6}>
                  <Typography className={`${classes.right}`}> {commission.name}: </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={`${classes.bold}`}>
                    {commission.formula && commission.formula.map((formula, idMath) => {
                      var vBase = formula.value;
                      if (formula.value === 'Base') {
                        vBase = amount;
                      }
                      if (formula.value === 'Periodo') {
                        vBase = months;
                      }
                      calc += vBase;
                      return null
                    })}
                    {currencyFormat(eval(calc))}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6}>
              <Typography className={`${classes.right}`}>{t('ESTIMATED_MONTHLY_PAYMENT')}: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${classes.bold}`} >{vEstimatedPayment}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.no_margin}>
            <Grid item xs={6}>
              <Typography className={`${classes.right}`}>{t('AMOUNT_RECEIVE')}: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={`${classes.bold}`} >{vRealAmount}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {otherCommissions ? otherCommissions.map((commission, idCom) => {
          return (<Typography key={idCom} className={`${classes.disclaimer}`}>{commission.disclaimer}</Typography>);
        }) : ''}
        <Typography className={`${classes.cat}`}><span style={{ fontSize: "16px", fontWeight: "bold" }}>{"*CAT sin IVA"} {cat_without_tax} {"%"}</span> {"informativo. Calculado al"} {cat_calculation_date_data.day} {"de"} {currentMonth} {"de"} {cat_calculation_date_data.year}</Typography>
      </TabPanel>
      <TabPanel value={openTab} index={1}>
        <Typography className={`${classes.bold}`} variant="h6" data-test="estimated">
          <EstimatedPayments amount={amount} terms={terms} percent={retention} request_id={request_id} instructions={instructions}/>
        </Typography>
      </TabPanel>
    </div>
  );
}
CreditSummary.propTypes = {
  index: PropTypes.number,
  amount: PropTypes.number,
  months: PropTypes.number,
  retention: PropTypes.number,
  terms: PropTypes.number,
  percent: PropTypes.number,
  request_id: PropTypes.number
};

export default CreditSummary;