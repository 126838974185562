import { getBontu, postBontu, putBontu } from "./requests";

const path = "/client";
const salesDataPath = "/clientes/sales/historial"
const amortizationDataPath = "/client/amazon/amortization";
const userInfo = "/flujo-piloto/cliente";
const businessInfo = "/flujo-piloto/catalogos/giros";
const countryInfo = "/flujo-piloto/catalogos/paises";
const creditData = "/flujo-piloto/catalogos/destino-credito";
const nationalityInfo = "/flujo-piloto/catalogos/nacionalidades";
const ocupationInfo = "/flujo-piloto/catalogos/ocupaciones";
const getFirebasePaht = "/firebase";
const putClientPath = "/flujo-piloto/cliente";
const historico = "/clientes/sales/historial";
const creditParams = "/evaluacion/ultima-evaluacion"
const solicitudesCalculosPath = "/solicitudes/calculos"
const requiredDocsPath = "/request/requiredDocs"
const postCreditoPath = "/solicitudes/guardar"
const usertType = { 'usertype': 'amazon' };
const formFields = "/solicitudes/form"
const pathGetOffersFromEvaluationResult = "/solicitudes/evaluation-result"
const pathOfferDetail = "/request/evaluation-result"
const previousStatus = "/flujo-piloto/previousstatus ";
const pathEstimatedPayments = "/solicitudes/pagos-estimados";
const pathCalcsOfferRetention = "/solicitudes/calcs-offer-retention";
const matrixRiskQuestions = "/matrix-risk/questions"

export function getClient(userPlatform) {
	return getBontu(path, null, { userPlatform: userPlatform });
}

export function getFormFields(params) {
	return getBontu(formFields, null, params);
}

export function getSalesData() {
	return getBontu(salesDataPath, {}, {})
}

export function getUserInfo() {
	return getBontu(userInfo, {}, {})
}

export function getAmortizationTable(params) {
	return getBontu(amortizationDataPath, {}, { montoSolicitado: params.amount, request_id: params.request_id })
}

export function getBusinessData() {
	return getBontu(businessInfo, {}, {})
}

export function getCountry() {
	return getBontu(countryInfo, {}, {})
}
export function getCreditData() {
	return getBontu(creditData, {}, {})
}
export function getNationality() {
	return getBontu(nationalityInfo, {}, {})
}
export function getOcupation() {
	return getBontu(ocupationInfo, {}, {})
}
export function getFirebase(body) {
	return postBontu(getFirebasePaht, null, body)
}

export function getHistorico(params) {
	return getBontu(historico, {}, {})
}

export function getCreditParams(params) {
	return getBontu(creditParams, {}, {})
}

export function solicitudesCalculos(amazonId) {
	return postBontu(solicitudesCalculosPath, null, { amazon_id: amazonId })
}

export function putClient(body) {
	return putBontu(putClientPath, usertType, body)
}

export function getRequiredDocs(params) {
	return getBontu(requiredDocsPath, {}, { platform_catalog_id: params })
}

export function postCredito(body) {
	return postBontu(postCreditoPath, null, body)
}
export function getOffersFromEvaluationResult() {
	return getBontu(pathGetOffersFromEvaluationResult, null, null)
}
export function selectedOfferDetails(body){
	return putBontu(pathOfferDetail, {}, body)
}
export function estimatedPayments(body) {
	return postBontu(pathEstimatedPayments,  {}, body)
}

export function putPreviousStatus(body){
	return putBontu(previousStatus, {}, body)
}

export function getCalcsOffer(body){
	return postBontu(pathCalcsOfferRetention, {}, body)
}
export function getMatrixRiskQuestions() {
	return getBontu(matrixRiskQuestions, {}, {})
}