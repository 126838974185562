import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { Grid, Card, CardContent, Button, makeStyles, Container } from "@material-ui/core";
import CreditSummary from '../OfferDetail/CreditSummary';
import OfferDetailCards from './OfferDetailCards';
import { useTranslation } from "react-i18next";
import { selectedOfferDetails, getCalcsOffer } from "../../../Services/generalDataAmazon";
import ShowBackdrop from "../../../sharedComponents/backDrop";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
    backgroundSquare: {
        padding: "25px",
        textAlign: "left"
    },
    cards: {
        color: "white",
        borderRadius: "0px",
    },
    continueButton: {
        backgroundColor: "#00c08b",
        color: "white",
        borderRadius: "4px",
        borderTopRightRadius: "4px",
        [theme.breakpoints.down('xs')]: {
            minHeight: 60,
            padding: "7px 16px",
            fontSize: "4vw",
        }

    },
    backButton: {
        font: 'roboto',
        backgroundColor: "#FFFFFF",
        color: "#00c08b",
        borderColor: '#00C08B',
        padding: "8px, 16px, 8px, 16px",
        borderRadius: "4px",
        borderTopRightRadius: "4px",
        marginRight: '16px',
        [theme.breakpoints.down('xs')]: {
            minHeight: 60,
            padding: "7px 16px",
            fontSize: "4vw",
        }
    },
    Tabs: {
        backgroundColor: '#00c08b'
    },
    subTxt: {
        fontSize: '24px',
        fontWeight: 500,
        color: '#003B4A',
        marginTop: '60px',
        fontstyle: 'normal'

    }
}));

const OfferDetails = forwardRef(({ titlehandler, offerData, listOfferhandler, activeStephandler }, ref) => {
    const maximumTerm = offerData.term
    const [annualRate, setannualRate] = useState(offerData.retention)
    const [isLoading, setIsLoading] = useState(false)
    const classes = useStyles();
    const { t } = useTranslation("UserApp");
    const _userData = useSelector((state) => state.user.userInfo);
    const [userData, setUserData] = useState(null);
    const [amountRecive, setAmountRecive] = useState(0);
    const [amountRequest, setAmountRequest] = useState(offerData.max_offer)
    const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState(0)
    const [epayments, setePayments] = useState(true)
    
    const annualRatehandler = async (value) => {
        setannualRate(parseFloat(value))
    }

    const cat_calculation_date = {
        day:offerData.cat_calculation_date_day,
        month:offerData.cat_calculation_date_month,
        year:offerData.cat_calculation_date_year
    };

    useImperativeHandle(ref, () => ({
        submit: (event, handleNext) => {
            console.log('useImperativeHandle:Offers detail');
            handleNext();
        }
    }));

    useEffect(() => {
        titlehandler(t("OFFER_DETAIL"))
    }, [t, titlehandler])

    useEffect(() => {
        setUserData(_userData);
    }, [_userData])

    useEffect(() => {
        async function fetchData() {
            const calcsRetentionChange = async (value) => {
                var calcsOffer
                var body = {
                    "offer_request": offerData.max_offer,
                    "months": maximumTerm,
                    "retention": parseFloat(offerData.retention),
                    "newRetention": parseFloat(value),
                    "monthlyRate": 0.05,
                    "openCommission": 0.1,
                    "realAmount": amountRecive
                }
                calcsOffer = await getCalcsOffer(body)
                return calcsOffer
        
            }
            if (annualRate) {
                setIsLoading(true)
                var calcs = await calcsRetentionChange(annualRate)
                setAmountRequest(calcs.requestAmount)
                setAmountRecive(calcs.amountToRecive)
                setEstimatedMonthlyPayment(calcs.new_estimated_payment)
                setIsLoading(false)
            }
        }
        fetchData();

    }, [annualRate, amountRecive, maximumTerm, offerData.max_offer, offerData.retention])

    const offerAcept = async (value) => {
        setIsLoading(true)
        let offer = {
            "request_id": userData.request.id,
            "amount": Number.parseFloat(amountRequest).toFixed(2),
            "status_id": userData.status_work_flow.next_status,
            "percint": annualRate,
            "terms": offerData.term,
            "evaluation_results_id": offerData.evaluation_results_id,
        }

        await selectedOfferDetails(offer);
        setIsLoading(false)
        titlehandler('Formulario')
        activeStephandler();
    }
    useEffect(()=>{
         if (userData) {
            const data = Object.values(userData.platform.steps_configuration.steps)
            for (let i = 0; i < data.length; i++) {
                if (data[i].ePayments !== undefined) {
                    setePayments(data[i].ePayments)
                }
            }
        }
    },[userData])

    return (
        <div>
            <ShowBackdrop open={isLoading} data-test="backdrop" />
            <Container maxWidth='100%'>
                <div className="row">
                    <OfferDetailCards amount={Number.parseFloat(amountRequest).toFixed(2)} retention_min={offerData.min_retention_percentage_app ? offerData.min_retention_percentage_app : 0.1} annualRatehandler={annualRatehandler} retention_current={Number.parseFloat(offerData.retention).toFixed(2)} months={maximumTerm} ></OfferDetailCards>
                </div>
                <Grid container spacing={2} classname="my-3">
                    <Grid item xs={12} sm={12}>
                        <Card className={classes.cards}>
                            <CardContent>
                                {userData && (
                                    <CreditSummary amount={amountRequest} max_amount={offerData.max_offer} months={offerData.term} retention={annualRate} monthlyRate={5} percent={offerData.max_retention_percentage_app} request_id={userData.request.id} openCommission={offerData.opening_fee > 0 ? offerData.opening_fee : 0} otherCommissions={offerData.commissions} cat_calculation_date_data={cat_calculation_date} cat_without_tax={offerData.cat_without_tax} realAmount={offerData.opening_fee > 0 ? amountRecive : offerData.max_offer} estimatedPayment={estimatedMonthlyPayment} instructions={userData.platform.steps_configuration.instructions} epayment={epayments}/>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                    <Grid container spacing={1} justify='Right' align='Right' style={{ marginTop: '20px', marginBottom: '100px' }}>
                        <Grid item xs={12} >
                            <Button variant="outlined" className={classes.backButton} margin="dense" size="large" onClick={listOfferhandler}> {t('BACK')}</Button>
                            <Button variant="contained" className={classes.continueButton} margin="dense" size="large" onClick={offerAcept} > {t('CONTINUE')}</Button>
                        </Grid>
                    </Grid>
            </Container>
        </div>
    );
})

export default OfferDetails