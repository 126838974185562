import React, {useEffect, useState, useRef, useCallback} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Responsive from './../mediaQuery/responsive';
import CircularProgress from '@material-ui/core/CircularProgress';
import "@fontsource/barlow";
import {useParams} from 'react-router-dom';
import {
    getRemedies,
    getPresignedURLs,
    postUploadDocuments,
    postUpdateDocumentsStatus
} from './../../Services/clipRemediesService';

const mobileStyles = makeStyles((theme) => ({
    body: {
        margin: '0',
        fontFamily: '"Barlow", "sans-serif"',
        width: '100vw',
        '& .topBar': {
            width: '100%',
            height: '7px',
            background: '#fc4c02',
            margin: '0 0 47px 0',
        },
        '& .container': {
            width: '100%',
        },
        '& .container .container': {
            marginLeft: '32px',
            marginRight: '32px',
            width: 'auto'
        },
        '& .container.main-container': {
            minHeight: '100vh'
        },
        '& .row': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },
        '& .col': {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 100%'
        },
        '& .center': {
            margin: 'auto'
        },
        '& .logo': {
            margin: '0 auto 39px auto',
            width: '100%'
        },
        '& .title': {
            fontSize: '2.16rem',
            margin: '0 auto 37px auto',
            lineHeight: '1.1'
        },
        '& .info-table': {
            background: '#fff6f2',
            paddingRight: '33px',
            paddingLeft: '33px'
        },
        '& .info-table .row': {
            paddingTop: '22px',
            paddingBottom: '22px'
        },
        '& .info-divider': {
            height: '3px',
            backgroundColor: '#ffdecf',
        },
        '& .bold': {
            fontWeight: 'bold'
        },
        '& .info-label': {
            margin: 'auto auto auto 0',
        },
        '& .info-value': {
            margin: 'auto 0 auto auto',
            textAlign: 'right'
        },
        '& .bold .info-label, .bold.info-value': {
            fontSize: '1.2rem'
        },
        '& .info-label, .info-value': {
            fontSize: '1.3rem',
        },
        '& .info-orange': {
            color: '#fc4b00'
        },
        '& .text-align-right': {
            textAlign: 'right'
        },
        '& .message': {
            textAlign: 'left',
            lineHeight: '1.4417',
            fontSize: '16px',
            fontWeight: '400'
        },
        '& .input': {
            display: 'flex',
            width: '100%',
            height: '55px',
            border: '2px solid #CCD0D3',
            borderRadius: '5px',
            marginBottom: '16px',
        },
        '& .input .radio-button': {
            minWidth: '25px',
            width: '25px',
            height: '25px',
            margin: 'auto 32px auto 34px',
            border: '4px solid #CCD0D3',
            borderRadius: '50%'
        },
        '& .input .icon:first-child': {
            margin: 'auto 24px auto 20px',
        },
        '& .input .icon:last-child': {
            margin: 'auto 40px auto 20px',
        },
        '& .input.disabled': {
            background: '#F8F8F8'
        },
        '& .input.disabled *': {
            color: '#BDC2C6',
        },
        '& .input.disabled svg path:first-child': {
            fill: '#BDC2C6'
        },
        '& .input .icon.disabled svg path:first-child': {
            fill: '#BDC2C6'
        },
        '& .label': {
            margin: 'auto auto auto 0',
            fontSize: '14px'
        },
        '& .input.selected': {
            borderColor: '#FC4C02'
        },
        '& .input.selected .radio-button': {
            borderColor: '#FC4C02',
            display: 'flex'
        },
        '& .input.selected .radio-button:before': {
            content: '""',
            display: 'block',
            width: '22px',
            height: '22px',
            margin: 'auto',
            background: '#FC4C02',
            borderRadius: '50%'
        },
        '& ul.message': {
            paddingLeft: '30px'
        },
        '& ul.message span': {
            left: '-10px',
            position: 'relative'
        },
        '& .button-active': {
            width: '100%',
            height: '48px',
            textDecoration: 'none',
            color: 'white',
            background: '#FC4C02',
            fontWeight: '600',
            fontSize: '16px',
            border: 'none',
            outline: 'none',
            borderRadius: ' 50px'
        },
        '& .button-disabled': {
            width: '100%',
            height: '48px',
            textDecoration: 'none',
            color: 'white',
            background: '#BDC2C6',
            fontWeight: '600',
            fontSize: '16px',
            border: 'none',
            outline: 'none',
            borderRadius: ' 50px'
        },
        '& footer': {
            height: '80px'
        },
        '& .hidden': {
            display: 'none'
        },
        '& .titleDocuments': {
            color: '#66737B',
            margin: 'auto auto auto 0',
            fontSize: '4vw'
        },
        '& .titleDocuments_small': {
            color: '#66737B',
            margin: 'auto auto auto 0',
            fontSize: '3vw'
        },
        '& .documents': {
            color: '#001724',
            margin: 'auto auto auto 0',
            fontSize: '5vw'

        },
        '& a': {
            textDecoration: 'none',
            color: '#0288d1'
        }
    }
}));

const desktopStyles = makeStyles((theme) => ({
    body: {
        margin: '0',
        fontFamily: '"Barlow", "sans-serif"',
        width: '100vw',
        '& .topBar': {
            width: '100%',
            height: '7px',
            background: '#fc4c02',
            margin: '0 0 47px 0',
        },
        '& .container': {
            width: '100%',
        },
        '& .container .container': {
            width: '720px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '& .container.main-container': {
            minHeight: '100vh'
        },
        '& .row': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },
        '& .col': {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 100%'
        },
        '& .center': {
            margin: 'auto'
        },
        '& .logo': {
            margin: '0 auto 39px auto'
        },
        '& .title': {
            fontSize: '2.16rem',
            margin: '0 auto 37px auto',
            lineHeight: '1.1'
        },
        '& .info-table': {
            background: '#fff6f2',
            paddingRight: '33px',
            paddingLeft: '33px'
        },
        '& .info-table .row': {
            paddingTop: '22px',
            paddingBottom: '22px'
        },
        '& .info-divider': {
            height: '3px',
            backgroundColor: '#ffdecf',
        },
        '& .bold': {
            fontWeight: 'bold'
        },
        '& .info-label': {
            margin: 'auto auto auto 0',
        },
        '& .info-value': {
            margin: 'auto 0 auto auto',
            textAlign: 'right'
        },
        '& .bold .info-label, .bold.info-value': {
            fontSize: '14px'
        },
        '& .info-label, .info-value': {
            fontSize: '1.3rem',
        },
        '& .info-orange': {
            color: '#fc4b00'
        },
        '& .text-align-right': {
            textAlign: 'right'
        },
        '& .message': {
            textAlign: 'left',
            lineHeight: '1.4417',
            fontSize: '2em',
            fontWeight: '400'
        },
        '& .input': {
            display: 'flex',
            width: '100%',
            height: '94px',
            border: '2px solid #CCD0D3',
            borderRadius: '5px',
            marginBottom: '16px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '& .input .radio-button': {
            minWidth: '35px',
            width: '35px',
            height: '35px',
            margin: 'auto 32px auto 34px',
            border: '4px solid #CCD0D3',
            borderRadius: '50%'
        },
        '& .input .icon:first-child': {
            margin: 'auto 24px auto 20px',
        },
        '& .input .icon:last-child': {
            margin: 'auto 40px auto 20px',
        },
        '& .input.disabled': {
            background: '#F8F8F8'
        },
        '& .input.disabled *': {
            color: '#BDC2C6',
        },
        '& .input.disabled svg path:first-child': {
            fill: '#BDC2C6'
        },
        '& .input .icon.disabled svg path:first-child': {
            fill: '#BDC2C6'
        },
        '& .label': {
            margin: 'auto auto auto 0',
            fontSize: '2em'
        },
        '& .input.selected': {
            borderColor: '#FC4C02'
        },
        '& .input.selected .radio-button': {
            borderColor: '#FC4C02',
            display: 'flex'
        },
        '& .input.selected .radio-button:before': {
            content: '""',
            display: 'block',
            width: '22px',
            height: '22px',
            margin: 'auto',
            background: '#FC4C02',
            borderRadius: '50%'
        },
        '& ul.message': {
            paddingLeft: '30px'
        },
        '& ul.message span': {
            left: '-10px',
            position: 'relative'
        },
        '& button': {
            width: '100%',
            height: '96px',
            color: '#8A949A',
            fontWeight: '600',
            fontSize: ' 1.5em',
            border: 'none',
            outline: 'none',
            borderRadius: ' 50px'
        },
        '& .button-active': {
            width: '100%',
            height: '96px',
            textDecoration: 'none',
            color: 'white',
            background: '#FC4C02',
            cursor: 'pointer',
            fontWeight: '600',
            fontSize: ' 1.5em',
            border: 'none',
            outline: 'none',
            borderRadius: ' 50px'
        },
        '& .button-disabled': {
            width: '100%',
            height: '96px',
            textDecoration: 'none',
            color: 'white',
            background: '#BDC2C6',
            fontWeight: '600',
            fontSize: ' 1.5em',
            border: 'none',
            outline: 'none',
            borderRadius: ' 50px'
        },
        '& footer': {
            height: '80px'
        },
        '& .hidden': {
            display: 'none'
        },
        '& .titleDocuments': {
            color: '#66737B',
            margin: 'auto auto auto 0',
            fontSize: '2em'
        },
        '& .titleDocuments_small': {
            color: '#66737B',
            margin: 'auto auto auto 0',
            fontSize: '1.5em'
        },
        '& .documents': {
            color: '#001724',
            margin: 'auto auto auto 0',
            fontSize: '1.5em'
        },
        '& a': {
            textDecoration: 'none',
            color: '#0288d1'
        }
    }
}));

const IDForm = props => {

    const crossEnabled = <svg width="28" height="28" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
            fill="#FC4C02"/>
    </svg>

    const crossDisabled = <svg width="28" height="28" viewBox="0 0 14 14" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
            fill="#B3B9BD"/>
    </svg>

    return <div>
        <p className="message">La imágen puede ser en formato JPG, PNG, PDF o HEIC.</p>
        <div>
            <label
                htmlFor="ID"
            >
                <input
                    accept="image/*,application/pdf,.heic"
                    className="hidden"
                    id="ID"
                    type="file"
                    onChange={props.handleUploadChange}
                />
                <div className="input">
                    <div className="icon">
                        <svg width="36" height="36" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0H2C0.89 0 0 0.9 0 2ZM12 6C12 7.66 10.66 9 9 9C7.34 9 6 7.66 6 6C6 4.34 7.34 3 9 3C10.66 3 12 4.34 12 6ZM9 10.9C7 10.9 3 12 3 14V15H15V14C15 12 11 10.9 9 10.9Z"
                                  fill="#001724"/>
                        </svg>
                    </div>
                    <div className={props.idFront ? "titleDocuments_small" : "titleDocuments"}>Frente (donde está tu
                        foto)
                        <div
                            className="documents">{props.idFront ? `${props.merchantName.names.toLowerCase()}-id-front.${props.idFront.ext}` : ''}</div>
                    </div>

                    <div className="icon">
                        {crossEnabled}
                    </div>

                </div>
            </label>
            {props.idOptionSelected === 'INE' &&
            <label
                htmlFor="ID-Back"
            >

                <input
                    accept="image/*,application/pdf,.heic"
                    className="hidden"
                    id="ID-Back"
                    type="file"
                    onChange={props.handleUploadChange}
                    disabled={!props.idFront}
                />
                    <div className={`input ${!props.idFront ? ' disabled' : ''}`}>
                    <div className="icon">
                    <svg width="36" height="36" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
                    fill="#001724"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                    d="M9 5.00001C6.79333 5.00001 5 6.79334 5 9.00001H6.19333C6.49333 9.00001 6.64 9.36001 6.43333 9.56667L4.57333 11.4267C4.44 11.56 4.23333 11.56 4.1 11.4267L2.24 9.56667C2.02667 9.36001 2.17333 9.00001 2.47333 9.00001H3.66667C3.66667 6.05334 6.05333 3.66667 9 3.66667C9.69333 3.66667 10.36 3.80001 10.9667 4.04667C11.4133 4.22667 11.5333 4.80001 11.1933 5.14001C11.0133 5.32001 10.74 5.39334 10.5 5.29334C10.04 5.10001 9.52667 5.00001 9 5.00001ZM11.5733 8.42667L13.4333 6.56667C13.56 6.44001 13.7733 6.44001 13.9 6.57334L15.76 8.43334C15.9733 8.64001 15.8267 9.00001 15.5267 9.00001H14.3333C14.3333 11.9467 11.9467 14.3333 9 14.3333C8.30667 14.3333 7.64 14.2 7.03333 13.9533C6.58667 13.7733 6.46667 13.2 6.80667 12.86C6.98667 12.68 7.26 12.6067 7.5 12.7067C7.96 12.9 8.47333 13 9 13C11.2067 13 13 11.2067 13 9.00001H11.8067C11.5067 9.00001 11.36 8.64001 11.5733 8.42667Z"
                    fill="white"/>
                    </svg>
                    </div>
                    <div className={props.idBack ? "titleDocuments_small" : "titleDocuments"}>Reverso
                    <div
                    className="documents">{props.idBack ? `${props.merchantName.names.toLowerCase()}-id-back.${props.idBack.ext}` : null}</div>
                    </div>
                    <div className="icon">
                {
                    props.idFront ?
                    crossEnabled
                    :
                    crossDisabled
                }
                    </div>
                    </div>
                </label>
                }


        </div>
    </div>
}

const CD = props => {

    return <div>
        <p className="message">
            <b>Comprobante de domicilio</b><br/>
            La imágen puede ser en formato JPG, PNG, o PDF.
        </p>

        <div>
            <label
                htmlFor="CD"
            >
                <input
                    accept="image/*,application/pdf,.heic"
                    className="hidden"
                    id="CD"
                    type="file"
                    onChange={props.handleUploadChange}
                />
                <div className="input">
                    <div className="icon">
                    </div>
                    <div className={props.cd ? "titleDocuments_small" : "titleDocuments"}>Comprobante de domicilio
                        <div
                            className="documents">{props.cd ? `${props.merchantName.names.toLowerCase()}-cd.${props.cd.ext}` : null}</div>
                    </div>
                    <div className="icon">
                        <svg width="28" height="28" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                                fill="#FC4C02"/>
                        </svg>
                    </div>
                </div>
            </label>
        </div>

        <ul className="message">
            <li><span>Recibo de luz, agua, internet, teléfono, etc.</span></li>
            <li><span>Con máximo 3 meses de antigüedad.</span></li>
        </ul>
    </div>
}

const ECB2 = props => {

    return <div>
        <p className="message">
            <b>Estado de Cuenta Bancario</b><br/>
            La imágen puede ser en formato JPG, PNG, o PDF.
        </p>

        <div>
            <label
                htmlFor="ECB2"
            >
                <input
                    accept="image/*,application/pdf"
                    className="hidden"
                    id="ECB2"
                    type="file"
                    onChange={props.handleUploadChange}
                />
                <div className="input">
                    <div className="icon">
                    </div>
                    <div className={props.ecb2 ? "titleDocuments_small" : "titleDocuments"}>Estado de Cuenta
                        <div
                            className="documents">{props.ecb2 ? `${props.merchantName.names.toLowerCase()}-ecb2.jpg` : null}</div>
                    </div>
                    <div className="icon">
                        <svg width="28" height="28" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                                fill="#FC4C02"/>
                        </svg>
                    </div>
                </div>
            </label>
        </div>

        <ul className="message">
            <li><span>Con máximo 3 meses de antigüedad.</span></li>
        </ul>
    </div>
}

const BASE = props => {
    return <div className={props.classes.body}>
        <div className="topBar"></div>

        <div className="container">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img className="logo"
                             src="https://bontu-assets-emails.s3.amazonaws.com/logo_clip-presta-clip%402x.png"
                             alt="Clip-Logo"/>
                    </div>
                </div>
            </div>

            <div className="container" style={{textAlign: 'center'}}>
                {props.noremedie &&
                < p className="message">
                    Hola <br/>Por el momento no tienes ningún documento para corregir
                </p>
                }
                {props.loading &&
                <div style={{margin: "auto"}}>
                    <div style={{justifyContent: "center", display: "flex"}}><CircularProgress/></div>
                    <p style={{margin: "auto"}}>Cargando...</p></div>
                }
                {props.uploading &&
                <div style={{margin: "auto"}}>
                    <div style={{justifyContent: "center", display: "flex"}}><CircularProgress/></div>
                    <p>Subiendo documentos, por favor espera...</p></div>
                }
                {props.footer &&
                <p className="center">
                    ¿Necesitas ayuda? Envía un correo a<br/>
                    <a href="mailto:help@clip.mx">help@clip.mx</a>
                </p>
                }

            </div>

        </div>

        <footer>

        </footer>
    </div>
}

const ClipRemedies = (props) => {
    const classes = props.isDesktop ? desktopStyles() : mobileStyles();

    const [merchantName, setMerchantName] = useState();
    const [remedies, setRemedies] = useState([]);
    const [DocLabels, setDocLabels] = useState([]);
    const ineElement = useRef();
    const passportElement = useRef()
    const [idOptionSelected, setIdOptionSelected] = useState();
    const [idFrontImage, setIdFrontImage] = useState();
    const [idBackImage, setIdBackImage] = useState();
    const [cdImage, setCdImage] = useState();
    const [ecb2Image, setEcb2Image] = useState();
    const [documentsUploaded, setDocumentsUploaded] = useState(false);
    const [uploadingDocuments, setUploadingDocuments] = useState(false);

    // Obtain url params
    const {request_id, uuid} = useParams()

    // First verify if the uuid request combination exists (to verify if this url was really generated)

    useEffect(() => {
        getRemedies(request_id, uuid).then((data) => {
            setMerchantName(data.merchant_name ? data.merchant_name : '-')
            setRemedies(data.remedies)
            setDocLabels(data.docs_label)
        })
    }, [request_id, uuid])

    const ineSelected = useCallback(event => {
        ineElement.current.classList.add('selected');
        passportElement.current.classList.remove('selected');
        setIdOptionSelected('INE');
    })

    const passportSelected = useCallback(event => {
        ineElement.current.classList.remove('selected')
        passportElement.current.classList.add('selected')
        setIdOptionSelected('PASSPORT');
    })

    const handleUploadChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const ext = file.name.substr(file.name.lastIndexOf('.')+1,file.name.length).toLowerCase();
        const document = {
            type: e.target.id, 
            file: file,
            ext: ext
        };
        if (e.target.id === 'ID') {
            setIdFrontImage(document)
        }
        if (e.target.id === 'ID-Back') {
            setIdBackImage(document)
        }
        if (e.target.id === 'CD') {
            setCdImage(document)
        }
        if (e.target.id === 'ECB2') {
            setEcb2Image(document)
        }
    }

    const uploadDocuments = useCallback(async () => {
        setUploadingDocuments(true);
        let exts = {};
        if(idFrontImage){
            exts["ID"]= idFrontImage.ext;
        }
        if(idBackImage){
            exts["ID-Back"]= idBackImage.ext;
        }
        if(cdImage){
            exts["CD"]= cdImage.ext;
        }
        if(ecb2Image){
            exts["ECB2"]= ecb2Image.ext;
        }
        const presignedURLs = await getPresignedURLs(request_id, uuid, exts);

        if  (idOptionSelected === 'PASSPORT')
            delete  presignedURLs['ID-Back']
        for (let type in presignedURLs) {
            if (type === 'ID') {
                presignedURLs[type].file = idFrontImage.file;
                presignedURLs[type].ext = idFrontImage.ext;
            }

            if (type === 'ID-Back') {
                presignedURLs[type].file = idBackImage.file;
                presignedURLs[type].ext = idBackImage.ext;
            }
            if (type === 'CD') {
                presignedURLs[type].file = cdImage.file;
                presignedURLs[type].ext = cdImage.ext;
            }
            if (type === 'ECB2') {
                presignedURLs[type].file = ecb2Image.file;
                presignedURLs[type].ext = ecb2Image.ext;
            }
        }

        const firebaseDocuments = await postUploadDocuments(presignedURLs);

        await postUpdateDocumentsStatus(request_id, uuid, firebaseDocuments)

        setDocumentsUploaded(true);
    })

    const documenReadyToUpload = () => {
        if (remedies.includes('ID') && remedies.includes('CD')) {
            return (validateIdDocuments() && cdImage)
        }
        if (remedies.includes('ID')) {
            return validateIdDocuments()
        }
        if (remedies.includes('CD')) {
            return cdImage ? true : false
        }
        if (remedies.includes('ECB2')) {
            return ecb2Image ? true : false
        }
    }

    const validateIdDocuments=()=>{
        if (idOptionSelected === 'INE')
            return idBackImage && idFrontImage
        else
            return idFrontImage

    }

    if (!merchantName)
        return <BASE classes={classes} loading={true}/>

    if (!remedies || !remedies.length)
        return <BASE classes={classes} footer={true} noremedie={true}/>

    if (uploadingDocuments && !documentsUploaded)
        return <BASE classes={classes} footer={true} uploading={true}/>

    return <div className={classes.body}>
        <div className="topBar"></div>

        <div className="container main-container">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img className="logo"
                             src="https://bontu-assets-emails.s3.amazonaws.com/logo_clip-presta-clip%402x.png"
                             alt="Clip-Logo"/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className="title center" style={{textAlign: documentsUploaded ? 'center' : 'left'}}>
                            {documentsUploaded ? '!Recibimos tu documentación' : 'Sube tu documentación'}
                        </h1>
                    </div>
                </div>
            </div>

            {
                documentsUploaded &&
                <div className="row">
                    <div className="col">
                        <svg className="center" width="90" height="90" viewBox="0 0 90 90" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M89.9985 15.1458C89.9592 6.77462 83.1614 0 74.782 0C72.7 0 70.6592 0.395561 68.9095 1.17568L13.4605 24.7223C5.55817 28.0646 0 35.8955 0 45.0008V74.7827C0 83.1857 6.81228 90 15.2186 90C17.3503 90 19.378 89.5588 21.2215 88.7667L76.4346 65.3242C84.392 62.0044 90 54.1472 90 45.0008L89.9985 15.1458Z"
                                  fill="#F1F8FF"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M74.249 25.0972C74.2228 19.5164 69.6909 15 64.1047 15C62.7167 15 61.3561 15.2637 60.1897 15.7838L23.2237 31.4815C17.9554 33.7097 14.25 38.9303 14.25 45.0005V64.8551C14.25 70.4571 18.7915 75 24.3957 75C25.8169 75 27.1687 74.7059 28.3977 74.1778L65.2064 58.5495C70.5113 56.3363 74.25 51.0981 74.25 45.0005L74.249 25.0972ZM26.3641 44.9013C27.1088 44.1708 28.3162 44.1708 29.061 44.9013L37.8918 53.5633L59.8835 31.9923C60.6282 31.2618 61.8356 31.2618 62.5804 31.9923C63.3251 32.7228 63.3251 33.9071 62.5804 34.6376L39.3946 57.38C39.3498 57.4353 39.3013 57.4887 39.2491 57.5398C39.1393 57.6476 39.0194 57.7394 38.8924 57.8154L38.8283 57.852C38.097 58.2759 37.1391 58.1797 36.5107 57.5633C36.4089 57.4634 36.321 57.355 36.247 57.2405L26.3641 47.5466C25.6194 46.8161 25.6194 45.6318 26.3641 44.9013Z"
                                  fill="#0B99AC"/>
                        </svg>
                    </div>
                </div>
            }

            <br/>

            <div className="container info-table" style={{background: documentsUploaded ? '#F2F9FC' : '#fff6f2'}}>
                <div className="row">
                    <div className="col">
                        <p className="info-label info-orange bold"
                           style={{color: documentsUploaded ? '#0288d1' : '#fc4b00'}}>Estatus:</p>
                    </div>
                    <div className="col">
                        <p className="info-value"
                           style={{color: documentsUploaded ? 'black' : '#fc4b00'}}>{documentsUploaded ? 'En Revisión' : 'Rechazado'}</p>
                    </div>
                </div>

                <div className="info-divider"
                     style={{backgroundColor: documentsUploaded ? '#c2e2f4' : '#ffdecf'}}></div>

                <div className="row">
                    <div className="col">
                        <p className="info-label info-orange text-align-left"
                           style={{color: documentsUploaded ? '#0288D1' : '#fc4b00'}}>Documento{remedies.length > 1 ? 's' : ''}:</p>
                    </div>
                    <div className="col">
                        <p className="info-value"
                           style={{color: documentsUploaded ? 'black' : '#fc4b00'}}>{DocLabels.join(', ')}</p>
                    </div>
                </div>
            </div>


            <div className="container">
                {
                    !documentsUploaded ?
                        <p className="message">
                            !Hola, {merchantName.names}!<br/>
                            <br/>
                            Necesitamos que cargues de nuevo tus<br/>
                            documentos. Por favor, sigue estas<br/>
                            recomendaciones:<br/>
                            <br/>
                            - Evita que la imágen se vea borrosa<br/>
                            - Procura que todos los datos sean legibles.
                        </p>
                        :
                        null
                }

                {
                    documentsUploaded &&
                    <p className="message">
                        !Hola, {merchantName.names}!<br/>
                        <br/>
                        Nuestro equipo revisará tus documentos y si<br/>
                        todo está en orden, nos pondremos en<br/>
                        contacto contigo en un máximo de 72 horas<br/>
                        hábiles.<br/>
                        <br/>
                    </p>
                }

                {
                    remedies.includes('ID') && !documentsUploaded ?
                        <div>
                            <p className="message">
                                <b>Identificación oficial</b><br/>
                                Selecciona una opción:
                            </p>

                            <div>
                                <div className={`input${idOptionSelected === 'INE' ? ' selected' : ''}`}
                                     ref={ineElement} onClick={ineSelected}>
                                    <div className="radio-button"></div>
                                    <div className="label">
                                        Credencial de Elector INE
                                    </div>
                                </div>

                                <div className={`input${idOptionSelected === 'PASSPORT' ? ' selected' : ''}`}
                                     ref={passportElement} onClick={passportSelected}>
                                    <div className="radio-button"></div>
                                    <div className="label">
                                        Pasaporte
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    idOptionSelected && !documentsUploaded ?
                        <IDForm handleUploadChange={handleUploadChange} idFront={idFrontImage} idBack={idBackImage} idOptionSelected={idOptionSelected}
                                merchantName={merchantName}/>
                        :
                        null
                }

                {
                    remedies.includes('CD') && !documentsUploaded ?
                        <CD handleUploadChange={handleUploadChange} merchantName={merchantName} cd={cdImage}/>
                        :
                        null
                }

                {
                    remedies.includes('ECB2') && !documentsUploaded ?
                        <ECB2 handleUploadChange={handleUploadChange} merchantName={merchantName} ecb2={ecb2Image}/>
                        :
                        null
                }

                {
                    !documentsUploaded && !uploadingDocuments ?
                        <button onClick={uploadDocuments} disabled={!documenReadyToUpload()}
                                className={documenReadyToUpload() ? 'button-active' : 'button-disabled'}>Subir
                            documentos</button>
                        :
                        null
                }

                {
                    documentsUploaded &&
                    <p class="center">
                        ¿Necesitas ayuda? Envía un correo a<br/>
                        <a href="mailto:help@clip.mx">help@clip.mx</a>
                    </p>
                    &&
                    <button className='button-active'><a className='button-active' href="/">Entendido</a></button>
                }
            </div>

        </div>

        <footer></footer>
    </div>
};

export default Responsive(ClipRemedies);
