import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Carousel from './carousel';
import DataImage from './images'

const useStyles = makeStyles({
    root: {
        maxWidth: '100%',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center'
    },
    media: { height: 380, },
    mediaCustom: {
        minHeight: '50vh',
        maxHeight: '50vh',
        minWidth: '120vh',
        maxWidth: '120vh'
    },
    img: {
        display: 'block',
        margin: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

export default function Instructive(props) {
    const classes = useStyles();

    let childrenCarousel = props.data.content.map(function (element, index) {
        return (
            <Grid key={`${'item-'}${index}`} item xs={12}>
                <Card key={index} className={classes.root} variant="elevation">
                    <CardActionArea>
                        {element.title ?
                            <CardContent>
                                <Typography variant="h4">{element.title}</Typography>
                            </CardContent>
                            : null
                        }
                        <CardMedia
                            className={`${classes.mediaCustom} ${classes.img} `}
                            image={element.image ? DataImage[element.image] : element.imageUrl}
                            title={element.title}
                        />
                        {element.description ?
                            <CardContent>
                                <Chip label={element.description} color="primary" />
                            </CardContent>
                            : null
                        }
                    </CardActionArea>
                </Card>
            </Grid>

        );
    })

    let childrenContent = Array.isArray(props.data.modalData.description) ?
        props.data.modalData.description.map(function (element, index) {
            return (
                <React.Fragment key={`${'fragment-'}${index}`}>
                    <Typography
                        key={`${'contentText-'}${index}`}
                        variant={element.variant ? element.variant : 'body1'}
                    >
                        {element.text}
                    </Typography>
                    <br />
                </React.Fragment>

            );
        }) : null

    return (
        <div>
            <Dialog
                open={props.open}
                disableBackdropClick={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">{props.data.modalData.title}</DialogTitle>
                <DialogContent>
                    <Grid container>
                        {childrenContent}
                    </Grid>
                    <Carousel children={childrenCarousel} carouselConfig={props.data.carouselConfig} />
                </DialogContent>
                <DialogActions>
                    {props.data.handleAcept
                        ? < Button data-test="buttonAccept" onClick={props.data.handleAcept.action} color="primary">{props.data.handleAcept.text}</Button>
                        : null
                    }
                    {props.data.handleClose
                        ? < Button data-test="buttonClose" onClick={props.data.handleClose.action} color="primary">{props.data.handleClose.text}</Button>
                        : null
                    }
                </DialogActions>
            </Dialog>
        </div >
    );
}