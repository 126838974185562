import React, { forwardRef, useState, useCallback, useEffect, useLayoutEffect } from "react";
import { Grid, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MainOfferCard from '../OfferCard/MainOfferCard'
import OfferDetails from '../offerSelect/offerDetails'
import { useDispatch, useSelector } from "react-redux";
import { configActionsButtons } from "../../../../store/actions/app.actions";
import RedirectButton from '../../../sharedComponents/RedirectButton/RedirectButton'


export const RequestContext = React.createContext();
const useStyles = makeStyles((theme) => ({
    subTxt: {
        color: '#003B4A',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '50px',


    },
    subTxt2: {
        color: '#003B4A',
        fontSize: '19px',
        lineHeight: '30px',
        top: '1051px'
    },
    img: {
        width: '1100px',
        height: '601px',
        top: '230px'
    },
    
})
)


const Offer = forwardRef(({ titlehandler, activeStephandler }, ref) => {
    const _userData = useSelector((state) => state.user.userInfo);
    const [userData, setUserData] = useState(null);

    const [ListOffer, setListOffer] = useState(true)
    const [offerData, setofferData] = useState(null)
    const classes = useStyles();
    const dispatch = useDispatch();

    const listOfferhandler = useCallback((value) => {
        setofferData(value.data)
        setListOffer(!ListOffer)
    },[ListOffer])

    useEffect(() => {
        _userData && setUserData(_userData);
    }, [_userData])

    useEffect(() => {
        let showBackButton = false;
        let showNextButton = false;
        if (userData?.platform.name === "tienda-nube" && ListOffer) {
            showBackButton = true;
        }

        dispatch(configActionsButtons({
            backButton: { show: showBackButton, disable: false },
            nextButton: { show: showNextButton, disable: false }
        }));
    }, [userData,ListOffer,dispatch])
    
    useLayoutEffect(() => {
        const user = userData?.client?.names;
        titlehandler(`¡Bienvenido(a) ${user}!`); 
    },[userData, titlehandler])
    
    return (
        <React.Fragment>
            {
                ListOffer ? (
                    <div item style={{ margin: '15px 10px 15px 10px' }} data-test="main">
                        <Grid container spacing={4} justify="center">                            
                            <Grid item data-test="text-bontu">
                                <CardContent Align='center' style={{ width: 'auto', margin: 'auto', maxWidth: '756px' }}>
                                    <Typography className={classes.subTxt2}>
                                        <Typography className={classes.subTxt}>Elige la mejor oferta que tenemos para ti</Typography>
                                        <br></br>
                                        Bontu existe para empresarios como tú,
                                        que quieren llevar su negocio a nuevos horizontes. Escoge la opción que mejor
                                        resuelva tus necesidades para hacerte de materia prima, herramienta,
                                        material de trabajo o lo que requieras para crecer.

                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid container spacing={2} classname="my-3" >
                                <Grid item xs={12} sm={12} >
                                    <MainOfferCard listOfferhandler={listOfferhandler} />
                                </Grid>
                            </Grid>
                            {userData?.request?.is_premium?(<Grid container style={{paddingLeft:'8%', paddingRight:'8%', paddingTop:'43px'}}>
                                <Grid item xs={12}>
                                    <RedirectButton />
                                </Grid>
                            </Grid>):null}
                        </Grid>
                    </div>
                ) : <OfferDetails titlehandler={titlehandler} offerData={offerData} listOfferhandler={listOfferhandler} activeStephandler={activeStephandler} />
            }

        </React.Fragment>
    )
})

export default Offer