import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Grid, Typography, FormControl,
  TextField, Tab, Box, Checkbox,
  MenuItem, CircularProgress, withStyles, Tooltip,
  createMuiTheme, ThemeProvider, Hidden
} from "@material-ui/core";
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import TabList from '@material-ui/lab/TabList';
import ShowBackdrop from "../../../sharedComponents/backDrop";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import { getCreditData, getCountry, getNationality, getOcupation, getFormFields, putClient, getFirebase, getMatrixRiskQuestions } from "../../../Services/generalDataAmazon";
import InstructionForms from "../../../sharedComponents/InstructionForms";
import { configActionsButtons } from "../../../../store/actions/app.actions";
import { useDispatch, useSelector } from "react-redux";
import { saveData, shareHolders } from "../../../../store/actions/user.actions";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from "react-i18next";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useUser } from "../../../Context/UserContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.primary,
  },
  buttonEnd: { textAlign: "end" },
  tabs: {
    backgroundColor: '#00c08a',
    color: '#FFFFFF',
    '&:focus': {
      color: '#FFFFFF',
    },
  },
  indicator: {
    backgroundColor: '#012b35',
  },
  buttonAdd: {
    margin: theme.spacing(1),
    background: '#00c08a',
    color: '#fff',
    '&$active': {
      color: '#172a33',
    },
    '&$over': {
      color: '#172a33',
    },
    '&$completed': {
      color: '#172a33',
    },
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: '#00c08a',
    '&$active': {
      color: '#172a33',
    },
    '&$over': {
      color: '#172a33',
    },
    '&$completed': {
      color: '#172a33',
    },
  },
  check: {
    color: "#9e9eb7",
    flexGrow: 1
  },
  ceroMargin: {
    margin: 0
  },
  formControl: {

  },
  colorGreen: {
    color: '#00c08a',
    textDecoration: 'none',
  },
  loader: {
    color: "#143947"
  },
  end: {
    aling: "end"
  },
  radio: {
    '&$checked': {
      color: '#00c08a',
    },
  },
  icono: {
    align: "sub",
    height: "18px",
    color: "#666666",
    "&:hover": {
      color: "#00C08B"
    }
  },
  p: {
    margin: 0,
    color: "#5B6268",
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  radioError: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px'
    },
  }
}));

const themeTypography = createMuiTheme();

themeTypography.typography.h4 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [themeTypography.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const StyledTooltip = withStyles({
  tooltip: {
    borderRadius: "5px",
    fontSize: "12px",
    textAlign: "center",
    color: "#FFFFFF",
    backgroundColor: "#406C77",
  },
  arrow: {
    fontSize: "22px",
    "&::before": {
      backgroundColor: "#406C77"
    }
  }
})(Tooltip);

const GenericForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation("UserApp");
  const [backDropState, setBackDropState] = React.useState(false);
  const [formFields, setFormFields] = useState([])
  const [radioQuestions, setRadioQuestions] = useState([])
  const [countryData, setCountryData] = useState([])
  const [creditData, setCreditData] = useState([])
  const [matrixRiskQuestions, setMatrixRiskQuestions] = useState([])
  const [matrixRiskAnswers, setMatrixRiskAnswers] = useState([])
  const [nationalityData, setNationalityData] = useState([])
  const [ocupationData, setOcupationData] = useState([])
  const [shrink, setShrink] = useState([])
  const [accepted_terms_and_conditions, setAccepted_terms_and_conditions] = useState(false)
  const [tabValue, setTabValue] = useState('');
  const [dataSelects, setDataSelects] = useState([]);
  const [state, setState] = useState({
    ocupacion: '',
    nacionalidad: '',
    country_id: '',
  });
  const [postalCodeData_third_1, setPostalCodeDataT1] = useState([])
  const [postalCodeData_third_2, setPostalCodeDataT2] = useState([])
  const [postalCodeData, setPostalCodeData] = useState([])
  const [postalCodeDataAC, setPostalCodeDataAC] = useState()
  const [postalCodeDataAD, setPostalCodeDataAD] = useState()
  const [errorData, setErrorData] = useState([])
  const [matrixRisk, setMatrixRisk] = useState([]);
  const requestData = JSON.parse(localStorage.getItem("bontu_user"))
  const [errorMessage, setErrorMessage] = useState([])
  const [regimes, setRegimes] = useState(
    {
      regimes: [
        {
          name: "",
          value: "",

        }
      ],
      count: 0
    }
  );

  const _stateInfo = useSelector((info) => info.user.stateInfo);
  const _userData = useSelector((data) => data.user.userInfo);
  const { logout } = useUser();
  let history = useHistory();

  //Circular Progress states
  const [areTabsLoaded, setAreTabsLoaded] = useState(false);
  //Para las tabs
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const dispatch = useDispatch();
  const [disabledState, setDisabledState] = useState({
    postalCode: true,
    city: false,
    county: false,
    select_colonia: false
  });
  //Para agregar accionistas
  const [shareholders, setShareholders] = useState([]);
  const [legalRepresentative, setLegalRepresentative] = useState([]);
  const [administrators, setAdministrators] = useState([]);
  const [third, setThird] = useState([]);
  const [aditionalClientData, setAditionalClientData] = useState([{}]);
  const [shareholdersDisabled, setShareholdersDisabled] = useState(false);

  const addShareholders = () => {
    let array = [...shareholders];
    array.push({ type: "AC", percentage: 0 });
    setShareholders(array);
  }

  const deleteShareholder = (index) => {
    var array = [...shareholders]
    array.splice(index, 1)

    setShareholders(array);
    const getSumShareholders = sumShareholders(-1, array);
    setShareholdersDisabled(getSumShareholders === 100 ? true : false);
  }

  const handleShareholderChange = (event) => {
    const elementName = event.target.id ? event.target.id : event.target.name;
    const index = Number(String(elementName).slice((String(elementName).lastIndexOf("_") - 1 >>> 0) + 2));
    const name = event.target.name.split('_s_')[0];
    const customName = String(name).slice(0, (String(name).lastIndexOf("_")));
    const customNameUnderScore = String(customName).slice(0, (String(customName).lastIndexOf("_")));
    const value = event.target.value;
    let getSumShareholders = 0;
    let _sum = 0;
    let newData = shareholders
    if (name === ('postal_code_AC_' + index)) {
      if (event.target.value.length < 5) {
        setErrorData({
          ...errorData,
          [event.target.name]: true
        })

        setErrorMessage({
          ...errorMessage,
          [event.target.name]: event.target.attributes.getNamedItem('data-error-minLength').value,
        });

      } else { //Aquí ya está bien el código y con 5 dígitos
        setBackDropState(!backDropState);
        const postalCode = {
          "path": "sepomex/" + event.target.value,
          "ref": "master-data"
        }
        var neighborhoodStatenameAC = "colonia-input_AC_";
        var cityStatenameAC = "city_AC_";
        var townHallStatenameAC = "municipio-input_AC_";
        var StateStatenameAC = "estado-input_AC_";

        getFirebase(postalCode).then((response) => {

          setShrink({
            ...shrink,
            [neighborhoodStatenameAC]: true,
            [townHallStatenameAC]: true,
            [cityStatenameAC]: true,
            [StateStatenameAC]: true,
          });
          setDisabledState({
            ...disabledState,
            [cityStatenameAC]: true,
            [townHallStatenameAC]: true,
            [StateStatenameAC]: true,
          });
          setPostalCodeDataAC(response);
          setBackDropState(backDropState);
        }
        ).catch((error) => {
          console.log(error);
        });
      }
    }
    if (name === 'percentage') {
      if (Number(value) === 100) {
        const findIndexArray = newData[index];
        newData = []
        findIndexArray.percentage = Number(value)
        newData.push(findIndexArray);
        setShareholdersDisabled(true);
      }
      else {
        getSumShareholders = sumShareholders(index, newData);
        _sum = getSumShareholders + Number(value);
        if (!isNaN(_sum)) {
          if (_sum <= 100 && Number(value) <= 100) {
            newData[index][name] = Number(value);
          } else {
            _sum = 100 - getSumShareholders
            newData[index][name] = _sum;
          }
          setShareholdersDisabled(_sum >= 100 ? true : false);
        } else {
          const oldValue = shareholders[index].percentage ? shareholders[index].percentage : 0;
          newData[index][name] = oldValue
        }
      }
    } else {
      newData[index][customNameUnderScore] = value;
    }
    setShareholders(newData);
    validate(event);
    if ((accepted_terms_and_conditions === true) && (checkIfError(errorData) === false)) {
      //Si ya aceptó los términos y condiciones y no hay errores vamos a checar lo demás
      isDisbalsed(errorData, radioQuestions);
    }
  }

  const sumShareholders = (index, data = []) => {
    let _sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (index !== i) {
        _sum += Number(data[i].percentage)
      }
    }
    return _sum;
  }

  const handleAditionalClientData = (id, label) => (event) => {
    const name = event.target.name;
    const customName = String(name).slice(0, (String(name).lastIndexOf("_")));

    if (customName === 'postal_code') {
      if (event.target.value.length < 5) {
        setErrorData({
          ...errorData,
          [event.target.name]: true
        })

        setErrorMessage({
          ...errorMessage,
          [event.target.name]: event.target.attributes.getNamedItem('data-error-minLength').value,
        });

      } else { //Aquí ya está bien el código y con 5 dígitos
        setBackDropState(!backDropState);
        const postalCode = {
          "path": "sepomex/" + event.target.value,
          "ref": "master-data"
        }
        var neighborhoodStatename 
        var cityStatename
        var townHallStatename
        var StateStatename
        getFirebase(postalCode).then((response) => {

          if(name === 'postal_code_third1'){
            neighborhoodStatename = "colonia-input_third_1";
            cityStatename = "city_third_1";
            townHallStatename = "municipio-input_third_1";
            StateStatename = "estado-input_third_1";

            setPostalCodeDataT1(response);
          }else if (name === 'postal_code_third2'){
            neighborhoodStatename = "colonia-input_third_2";
            cityStatename = "city_third_2";
            townHallStatename = "municipio-input_third_2";
            StateStatename = "estado-input_third_2";

            setPostalCodeDataT2(response);
          }

          setShrink({
            ...shrink,
            [neighborhoodStatename]: true,
            [townHallStatename]: true,
            [cityStatename]: true,
            [StateStatename]: true,
          });
          setDisabledState({
            ...disabledState,
            [cityStatename]: true,
            [townHallStatename]: true,
            [StateStatename]: true,
          });

          //Quitamos el mensaje de error porque ya está bien
          setErrorData({
            ...errorData,
            [name]: false,
            [townHallStatename]: false,
            [cityStatename]: false,
            [StateStatename]: false
          })
          setErrorMessage({
            ...errorMessage,
            [name]: "",
            [townHallStatename]: "",
            [cityStatename]: "",
            [StateStatename]: ""
          });
          setBackDropState(backDropState);
        }
        ).catch((error) => {
          console.log(error);
        });
      }
    } else if ((event.target.type === 'text') || (event.target.type === 'date')) {
      validate(event);
    } else { //Es un select
      //Quitamos el mensaje de error
      setErrorData({
        ...errorData,
        [name]: false
      })
      setErrorMessage({
        ...errorMessage,
        [name]: ""
      });
    }

    const value = event.target.value;
    const customId = String(id).slice(0, (String(id).lastIndexOf("_")));
    const customLabel = String(label).slice(0,(String(label).lastIndexOf("*")));

    if (customId === 'third') {
      third.map((th) => {
        if(th.label === customLabel){
          let aux = third.filter(element => element.label === customLabel)
          aux[0][customName] = value;
        }
      })
    } else {
      let newData = [...aditionalClientData]
      newData[0][name] = value;

      setAditionalClientData(newData);
    }
    validate(event);
    if ((accepted_terms_and_conditions === true) && (checkIfError(errorData) === false)) {
      //Si ya aceptó los términos y condiciones y no hay errores vamos a checar lo demás
      isDisbalsed(errorData, radioQuestions);
    }
  }

  const addLegalRepresentative = () => {
    let array = [...legalRepresentative];
    array.push({ type: "RL", names: '', first_name: '', second_name: '' });
    setLegalRepresentative(array);
  }

  const handleLegalRepresentativeChange = (event) => {
    const elementName = event.target.id ? event.target.id : event.target.name;
    const index = Number(String(elementName).slice((String(elementName).lastIndexOf("_") - 1 >>> 0) + 2));
    const name = event.target.name.split('_rl_')[0];
    const value = event.target.value;
    let newData = [...legalRepresentative]
    newData[index][name] = value;
    setLegalRepresentative(newData);
    validate(event);
    if ((accepted_terms_and_conditions === true) && (checkIfError(errorData) === false)) {
      //Si ya aceptó los términos y condiciones y no hay errores vamos a checar lo demás
      isDisbalsed(errorData, radioQuestions);
    }
  }

  const deleteLegalRepresentative = (index) => {
    var array = [...legalRepresentative]
    array.splice(index, 1)
    setLegalRepresentative(array);
  }

  const addAdministrators = () => {
    let array = [...administrators];
    array.push({ type: "AD" });
    setAdministrators(array);
  }

  const handleAdministratorsChange = (event) => {
    const elementName = event.target.id ? event.target.id : event.target.name;
    const index = Number(String(elementName).slice((String(elementName).lastIndexOf("_") - 1 >>> 0) + 2));
    const name = event.target.name.split('_ad_')[0];
    const customName = String(name).slice(0, (String(name).lastIndexOf("_")));
    const customNameUnderScore = String(customName).slice(0, (String(customName).lastIndexOf("_")));

    if (name === ('postal_code_AD_' + index)) {
      if (event.target.value.length < 5) {
        setErrorData({
          ...errorData,
          [event.target.name]: true
        })

        setErrorMessage({
          ...errorMessage,
          [event.target.name]: event.target.attributes.getNamedItem('data-error-minLength').value,
        });

      } else { //Aquí ya está bien el código y con 5 dígitos
        setBackDropState(!backDropState);
        const postalCode = {
          "path": "sepomex/" + event.target.value,
          "ref": "master-data"
        }
        var neighborhoodStatenameAD = "colonia-input_AD_";
        var cityStatenameAD = "city_AD_";
        var townHallStatenameAD = "municipio-input_AD_";
        var StateStatenameAD = "estado-input_AD_";
        getFirebase(postalCode).then((response) => {

          setShrink({
            ...shrink,
            [neighborhoodStatenameAD]: true,
            [townHallStatenameAD]: true,
            [cityStatenameAD]: true,
            [StateStatenameAD]: true,
          });
          setDisabledState({
            ...disabledState,
            [cityStatenameAD]: true,
            [townHallStatenameAD]: true,
            [StateStatenameAD]: true,
          });
          setPostalCodeDataAD(response);
          setBackDropState(backDropState);
        }
        ).catch((error) => {
          console.log(error);
        });
      }
    }

    const value = event.target.value;
    let newData = [...administrators]
    newData[index][customNameUnderScore] = value;
    setAdministrators(newData);
    validate(event);
    if ((accepted_terms_and_conditions === true) && (checkIfError(errorData) === false)) {
      //Si ya aceptó los términos y condiciones y no hay errores vamos a checar lo demás
      isDisbalsed(errorData, radioQuestions);
    }
  }

  const deleteAdministrators = (index) => {
    var array = [...administrators]
    array.splice(index, 1)
    setAdministrators(array);
  }

  const validate = (event) => {
    var validData = [event.target.value];

    var fieldLength = validData[0].length;

    const patternError = () => {
      setErrorData({
        ...errorData,
        [event.target.name]: true
      })
      setErrorMessage({
        ...errorMessage,
        [event.target.name]: event.target.attributes.getNamedItem('data-error-pattern').value,
      });
    }
    const minLengthError = () => {
      setErrorData({
        ...errorData,
        [event.target.name]: true
      })
      setErrorMessage({
        ...errorMessage,
        [event.target.name]: event.target.attributes.getNamedItem('data-error-minLength').value,
      });
    }
    const maxLengthError = () => {

      setErrorData({
        ...errorData,
        [event.target.name]: true
      })
      var thisErrorMessage = 'Demasiados caracteres.';
      if (event.target.attributes.getNamedItem('data-error-maxLength') != null) {
        thisErrorMessage = event.target.attributes.getNamedItem('data-error-maxLength').value;
      }
      setErrorMessage({
        ...errorMessage,
        [event.target.name]: thisErrorMessage,
      });
    }

    const matchErrorMessage = (elementOriginalName) => {
      setErrorData({
        ...errorData,
        [event.target.name]: true
      })
      setErrorMessage({
        ...errorMessage,
        [event.target.name]: event.target.attributes.getNamedItem(elementOriginalName).value,
      });
    }

    const disableErrorMessage = (originalInput, confirmInput) => {
      setErrorData({
        ...errorData,
        [originalInput]: false,
        [confirmInput]: false
      })
      setErrorMessage({
        ...errorMessage,
        [originalInput]: "",
        [confirmInput]: "",
      });
    }

    //Validamos primero si es requerido
    if (event.target.attributes && event.target.attributes.getNamedItem('required') !== null) {
      if (event.target.value === '') { //Si está vacío
        setErrorData({
          ...errorData,
          [event.target.name]: true
        })
        setErrorMessage({
          ...errorMessage,
          [event.target.name]: event.target.attributes.getNamedItem('data-error-required').value
        });
      } else {
        //Si no está vacío le quitamos el mensaje de error
        setErrorData({
          ...errorData,
          [event.target.name]: false
        })
        setErrorMessage({
          ...errorMessage,
          [event.target.name]: ""
        });
        if (event.target.attributes.getNamedItem('data-match-email')) {
          const elementName = event.target.id ? event.target.id : event.target.name;
          const index = Number(String(elementName).slice((String(elementName).lastIndexOf("_") - 1 >>> 0) + 2));
          const originalInputName = "mail_rl_" + index
          const confirmInputName = "mail_confirm_input_rl_" + index
          if (event.target.name === "mail_confirm_input_rl_" + index) {
            const originalInputValue = document.getElementsByName("mail_rl_" + index)
            if (event.target.value !== originalInputValue[0].value) {
              matchErrorMessage('data-match-email')
            } else {
              disableErrorMessage(originalInputName, confirmInputName)
            }
          } else if (event.target.name === "mail_rl_" + index) {
            const confirmInputValue = document.getElementsByName("mail_confirm_input_rl_" + index)
            if (confirmInputValue[0].value !== event.target.value) {
              matchErrorMessage('data-match-email')
            } else {
              disableErrorMessage(confirmInputName, originalInputName)
            }
          }
        }

        if (event.target.attributes.getNamedItem('data-match-phone')) {
          const elementName = event.target.id ? event.target.id : event.target.name;
          const index = Number(String(elementName).slice((String(elementName).lastIndexOf("_") - 1 >>> 0) + 2));
          const originalInputName = "phone_rl_" + index
          const confirmInputName = "phone_confirm_input_rl_" + index
          if (event.target.name === "phone_confirm_input_rl_" + index) {
            const originalInputValue = document.getElementsByName("phone_rl_" + index)
            if (event.target.value !== originalInputValue[0].value) {
              matchErrorMessage('data-match-phone')
            } else {
              disableErrorMessage(originalInputName, confirmInputName)
            }
          } else if (event.target.name === "phone_rl_" + index) {
            const confirmInputValue = document.getElementsByName("phone_confirm_input_rl_" + index)
            if (confirmInputValue[0].value !== event.target.value) {
              matchErrorMessage('data-match-phone')
            } else {
              disableErrorMessage(confirmInputName, originalInputName)
            }
          }
        }
        //Si no está vacío Validar primero si tiene el atributo para el patrón
        if (event.target.attributes.getNamedItem('data-pattern')) {
          var pattern;

          if (event.target.name === 'neighborhood' || event.target.name === 'neighborhood_pm') {
            pattern = /^(?:[a-zA-Zñá-úÁ-Ú]+(?:\s(?=[a-zA-Zñá-úÁ-Ú]))?)+$/;
          } else {
            var patternOriginal = event.target.attributes.getNamedItem('data-pattern').value;
            var patternCutted = patternOriginal.slice(1, -1);
            pattern = new RegExp(patternCutted);
          }
          if (!(pattern.test(validData[0]))) {
            patternError()
          } else { //Validamos el número de caracteres sólo si no trae errores de patrón
            if (event.target.attributes.getNamedItem('data-minLength')) { //Validamos si el campo tiene el atributo minLength
              var validMinLength = event.target.attributes.getNamedItem('data-minLength').value;
              if (fieldLength < validMinLength) {
                minLengthError()
              }
            }
            if (event.target.attributes.getNamedItem('data-maxLength')) { //Validamos si el campo tiene el atributo maxLength
              var validMaxLength = event.target.attributes.getNamedItem('data-maxLength').value;
              if (fieldLength > validMaxLength) {
                maxLengthError()
              }
            }
          }
        }


      }
    }
  }


  //Función que devuelve true si un elemento es verdadero
  function isTrue(element, index, array) {
    return element;
  }
  //Función que devuelve true si algún elemento del arreglo tiene error true.
  const checkIfError=(data)=>{
    let arrayToCheck = Object.values(data);
    var found = false;
    if (arrayToCheck.some(isTrue)) { found = true; }
    return found;
  }
  const checkIfComplete = (data, questions) => {
    var elementsRequiredEmpty = {...data};
    var elementsRequiredEmptyMessage = {};
    var elementQuestions = {...questions}

    formFields.map((form, idxFF) => {
      if (form.regime === tabValue) { //Vamos a checar sólo los campos que estén en el form que se llenó

        form.fields.form.sections.map((section, indexSectFields) => {
          section.fields && section.fields.map((field, idx) => {
            //Validamos si el campo es requerido y si está vacío
            //No aplica para ciudades y municipios
            if ((
              (field.required) &&
              (field.type !== "radiobutton") &&
              (!state[field.name]) &&
              (field.name !== "city") &&
              (field.name !== "city_pm") &&
              (field.name !== "county") &&
              (field.name !== "county_pm") &&
              (field.name !== "client_pld_risk_matrix")) ||
              ((field.type === "radiobutton") && (field.required) && (!elementQuestions[field.name]))
            ) {
              elementsRequiredEmpty[field.name] = true;
              elementsRequiredEmptyMessage[field.name] = field.error.required;
            } else { //Le quitamos el error
              delete elementsRequiredEmpty[field.name];
              elementsRequiredEmptyMessage[field.name] = '';
            }

          })
          section.subsection && section.subsection.map((subsection, indexSubfield) => {
            subsection.fields.map((subfield) => {
              //Si algún subfield requerido está vacío, lo manda como error.
              if ((subfield.required) && (!state[subfield.name]) && (subsection.repeater !== true)) {
                elementsRequiredEmpty[subfield.name] = true;
                elementsRequiredEmptyMessage[subfield.name] = subfield.error.required;
              } else { //Le quitamos el error
                delete elementsRequiredEmpty[subfield.name];
                elementsRequiredEmptyMessage[subfield.name] = '';
              }
            })
          })
        })
      }
    })
    console.log(elementsRequiredEmpty);
    //Le asignamos su error

    setErrorData({
      ...elementsRequiredEmpty
    })

    //Mostramos el mensaje de error requerido

    setErrorMessage({
      ...elementsRequiredEmptyMessage
    })
    if (checkIfError(elementsRequiredEmpty)) { //Si hay algún error, regresa false porque quiere decir que no está completo
      return false;
    }
    return true;
  }

  const generateMatrixRisk = () => {
    let array = [...matrixRisk];
    matrixRiskQuestions.map((q) => {
      matrixRiskAnswers.map((a) => {
        const splitName = String(a.question).split('*')[0];
        if ((q.show) && (q.label === splitName)) {
          q.inherent_risk.map((r) => {
            if (r.risk === a.answer) {
              array.push(
                {
                  'question': q.description,
                  'value': r.value,
                  'description': r.risk,
                  'show': q.show,
                  'PM': q.pm,
                  'PF': q.pf
                })
            }
          })
          setMatrixRisk(array)
        }
      })
    })
    return array
  }
  const handleCheck = (event) => {
    setAccepted_terms_and_conditions(event.target.checked);
    if (event.target.checked === true) {
      isDisbalsed(errorData, radioQuestions);
    } else {
      setMatrixRisk([])
      dispatch(configActionsButtons({
        nextButton: { show: true, disable: true }
      }));
    }
  }

  const getQuestionsMatrix = (question, answers, value, show) => { //revisa si el elemento del formulario forma parte de la matriz de riesgos      

    if (answers != null) {
      answers.map((answer) => {
        if (answer.name === value) {
          let array = matrixRiskAnswers.filter(element => element.question !== question)
          array.push({ question: question, answer: answer.label });
          setMatrixRiskAnswers(array);
        }
      })
    } else if ((answers === null) && (show)) {
      let array = matrixRiskAnswers.filter(element => element.question !== question)
      array.push({ question: question, answer: value });
      setMatrixRiskAnswers(array);
    }
  }
  const handleRadio = (question, answers, show) => (event) => {
    const name = event.target.name;
    const splitName = String(name).split('-')[0];
    const _copyErrorData = {...errorData}
    _copyErrorData[splitName] = false;
    
    const _copyQuestions = {...radioQuestions}
    _copyQuestions[splitName]= event.target.value;

    setRadioQuestions({
      ...radioQuestions,
      [splitName]: event.target.value,
    });
    setErrorData({
      ...errorData,
      [splitName]: false
    })

    setErrorMessage({
      ...errorMessage,
      [splitName]: '',
    });

    getQuestionsMatrix(question, answers, event.target.value, show)
    if(!(state.actividad_del_sector === "Gasolineras" || state.actividad_del_sector === "Transporte" )){
      if(checkIfError(_copyErrorData) === false){
         //Si ya aceptó los términos y condiciones y no hay errores vamos a checar lo demás  
         isDisbalsed(_copyErrorData, _copyQuestions);
         }
     }

  };

  const handleChangeState = (question, answers, value, show) => (event) => {

    getQuestionsMatrix(question, answers, event.target.value, show)
    const name = event.target.name;

    const _copyErrorData = {...errorData}
    _copyErrorData[name] = false;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setShrink({
      ...shrink,
      [name]: true,
    });

    const lengthError = () => {
      setErrorData({
        ...errorData,
        [event.target.name]: true
      })

      setErrorMessage({
        ...errorMessage,
        [event.target.name]: event.target.attributes.getNamedItem('data-error-minLength').value,
      });
    }
    //Para la asignación del código postal
    if ([name] == 'postal_code' || [name] == 'postal_code_pm') {

      if (state.country_id === 150 || state.country_id_pm === 150) { //Sólo si el país es México
        if (event.target.value.length < 5) {
          lengthError();

        } else { //Aquí ya está bien el código y con 5 dígitos
          setBackDropState(!backDropState);
          const postalCode = {
            "path": "sepomex/" + event.target.value,
            "ref": "master-data"
          }
          var cityStatename = "city";
          var countyStatename = "county";
          var neighborhoodStatename = "neighborhood";
          getFirebase(postalCode).then((response) => {
            if ([name] != 'postal_code') {
              cityStatename = "city_pm";
              countyStatename = "county_pm";
              neighborhoodStatename = "neighborhood_pm";

            }
            setShrink({
              ...shrink,
              [cityStatename]: true,
              [countyStatename]: true,
              [neighborhoodStatename]: true,
            });
            setDisabledState({
              ...disabledState,
              [cityStatename]: true,
              [countyStatename]: true
            });
            setPostalCodeData(response);
            setBackDropState(backDropState);
          }
          ).catch((error) => {
            console.log(error);
          });

          //Quitamos el mensaje de error porque ya está bien
          setErrorData({
            ...errorData,
            [name]: false,
            [cityStatename]: false,
            [countyStatename]: false
          })
          setErrorMessage({
            ...errorMessage,
            [name]: "",
            [cityStatename]: "",
            [countyStatename]: ""
          });
        }
      } else {
        //Quitamos el mensaje de error porque no es México
        setErrorData({
          ...errorData,
          [name]: false
        })
        setErrorMessage({
          ...errorMessage,
          [name]: ""
        });
      }
    } else if ((event.target.type === 'text') || (event.target.type === 'date')) { //Por lo pronto validamos sólo si es un input text y no es el código postal
      validate(event);
    } else { //Es un select
      //Quitamos el mensaje de error
      setErrorData({
        ...errorData,
        [name]: false
      })
      setErrorMessage({
        ...errorMessage,
        [name]: ""
      });
    }

    if(!(state.actividad_del_sector === "Gasolineras" || state.actividad_del_sector === "Transporte" )){
      if(checkIfError(_copyErrorData) === false){
         //Si ya aceptó los términos y condiciones y no hay errores vamos a checar lo demás
             isDisbalsed(_copyErrorData, radioQuestions);
         }
     }
  };

  const getRegimesFromJson = (json) => {
    let regime = []
    for (let i = 0; i < json.length; i++) {
      regime.push({
        name: json[i].regime,
        value: (json[i].regime === "PF"
          ? "Persona Física"
          : "Persona Moral")
      });
    }
    setRegimes({ regimes: regime, count: json.length });
    setAreTabsLoaded(true);
  }

  const getItemsToSelect = (source) => {
    let items = []

    let dataSource
    let infoDataSourse = []

    if (dataSelects.length > 0) {
      dataSelects.map((ds) => {
        if (ds.select === source) {
          dataSource = source
          infoDataSourse = ds.options
        }
      })
    }

    switch (source) {
      case "nationalityData":
        items.push(
          nationalityData.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          )
          )
        );
        break;
      case "countryData":
        items.push(
          countryData && countryData.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          )
          )
        )
        break;
      case "creditData":
        items.push(
          creditData && creditData.map((element) => (
            <MenuItem key={element.name} value={element.name}>
              {element.name}
            </MenuItem>
          )
          )
        )
        break;
      case "ocupationData":
        items.push(
          ocupationData && ocupationData.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          )
          )
        )
        break;
      case "postalCodeData":

        items.push(
          postalCodeData?.colonias && postalCodeData?.colonias.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.nombre}
            </MenuItem>
          )
          )

        )
        break;
      case "postalCodeDataAC":

        items.push(
          postalCodeDataAC?.colonias && postalCodeDataAC?.colonias.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.nombre}
            </MenuItem>
          )
          )

        )
        break;
      case "postalCodeDataAD":

        items.push(
          postalCodeDataAD?.colonias && postalCodeDataAD?.colonias.map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.nombre}
            </MenuItem>
          )
          )

        )
        break;
      case dataSource:
        items.push(
          infoDataSourse.map((element, ide) => (
            <MenuItem key={ide} value={element.risk}>
              {element.risk}
            </MenuItem>
          )
          )
        )
        break;
        case "postalCodeData_third_1":
        items.push(
        postalCodeData_third_1?.colonias && postalCodeData_third_1?.colonias.map((element) => (
          <MenuItem key={element.id} value={element.id}>
            {element.nombre}
          </MenuItem>
        )
        )
      );
      break;
      case "postalCodeData_third_2":
        items.push(
        postalCodeData_third_2?.colonias && postalCodeData_third_2?.colonias.map((element) => (
          <MenuItem key={element.id} value={element.id}>
            {element.nombre}
          </MenuItem>
        )
        )
      );
      break;
    }

    return items;
  }

  const changePostalCode = (event) => {
    const postalCode = {
      "path": "sepomex/" + _userData.address.postal_code,
      "ref": "master-data"
    }
    getFirebase(postalCode).then((response) => {
      setPostalCodeData(response)
    })
  }

  useEffect(() => {
    let formFieldsJson = {}
    if (requestData) {

      if (requestData.platform.name === "amazon" || requestData.platform.name === "tienda-nube" ||
      requestData.platform.name === "clip_pm") {
        
        formFieldsJson = { "platform": requestData.platform.name }
      } else {
        formFieldsJson = { "platform": requestData.platform.name, "regime": "PF" }
      }
      setTabValue((requestData.client.regime === "AMBOS") ? "PF" : requestData.client.regime);
      if (formFields.length === 0) {
        getFormFields(formFieldsJson).then((response) => {
          if (response.length > 0) {
            setFormFields(response);
            getRegimesFromJson(response);
          }
        }
        ).catch((error) => {
          console.log(error);
        });
      }
    }
    }, [formFields, requestData]);

  useEffect(() => {
    if (ocupationData.length === 0) {
      getOcupation().then((response) => {
        console.log("gettin' ocupations");
        setOcupationData(response);
      }).catch((error) => {
        console.log(error);
      });
    }

    if (nationalityData.length === 0) {
      getNationality().then((response) => {
        setNationalityData(response);
        console.log("gettin' nationalities");
      }).catch((error) => {
        console.log(error);
      });
    }

    if (matrixRiskQuestions.length === 0) {
      getMatrixRiskQuestions().then((response) => {
        setMatrixRiskQuestions(response);
        if (response) {
          let auxSelect = [...dataSelects]
          let auxThird = [...third]
          response.map((r, idr) => {
            if (r.input_type === "select" && r.show === true) {
              if (r.inherent_risk.length > 0) {
                auxSelect.push({select:r.label, options:r.inherent_risk})
              }
              setDataSelects(auxSelect)
            }else if(r.show === true && r.partner_type){
              auxThird.push({label: r.label, type: r.partner_type})
              setThird(auxThird)
            }
          })
        }
      }).catch((error) => {
        console.log(error);
      });
    }

    if (creditData.length === 0) {
      getCreditData().then((response) => {
        setCreditData(response);
        console.log("gettin' creditData");
      }).catch((error) => {
        console.log(error);
      });
    }

    if (countryData.length === 0) {
      getCountry().then((response) => {
        setCountryData(response);
        console.log("gettin' countries");
      }).catch((error) => {
        console.log(error);
      });
    }
  }, []);

  useEffect(() => {
    props.titlehandler(t('FORM'))
  }, [props, t])

  const isDisbalsed = (data, questions) => {
    let isOk = checkIfError(data);
    let isCompleted = checkIfComplete(data, questions);
    console.log('Algún elemento tiene error: ', isOk);
    console.log('Está completo: ', isCompleted);
    console.log('Aceptó los términos: ', accepted_terms_and_conditions);

    dispatch(configActionsButtons({
      nextButton: { show: true, disable: (isCompleted === true && isOk === false ? false : true) }
    }));

  }

  const formatDataHareholders = () => {
    let formatData = [];
    const exclude = ['id', 'created_at', 'created_by', 'modified_at', 'modified_by', 'client_id', 'mail_confirm_input', 'phone_confirm_input','label']
    let data = shareholders.concat(legalRepresentative);
    if (administrators.length > 0) {
      data = data.concat(administrators);
    }
    if(third && third.map){
      let newData = third.filter(element => element.names)
      newData.map((n, idx) =>{
        if(n.type === 'T1'){
          newData[idx]['state_catalog_id'] = postalCodeData_third_2.estado_id;
          newData[idx]['country_id'] = '150';
          newData[idx]['neighborhood'] = "";
          newData[idx]['city'] = postalCodeData_third_2.ciudad_nom 
          newData[idx]['country'] = postalCodeData_third_2.ciudad_nom 
        }else{
          newData[idx]['state_catalog_id'] = postalCodeData_third_1.estado_id;
          newData[idx]['country_id'] = '150';
          newData[idx]['neighborhood'] = "";
          newData[idx]['city'] = postalCodeData_third_1.ciudad_nom 
          newData[idx]['country'] = postalCodeData_third_1.ciudad_nom 
        }
        
        setThird(newData);
      })
      data = data.concat(newData);
    }
    for (let index = 0; index < data.length; index++) {
      let newJson = {}
      for (let element in data[index]) {
        if (!exclude.includes(element)) {
          newJson[element] = data[index][element];
        }
      }
      formatData.push(newJson);
    }
    return formatData;
  }

  useEffect(() => {
    const filterRl = _userData.partners.filter(element => element.type === 'RL')
    setLegalRepresentative(filterRl);
    setState({
      ...state,
      nacionalidad: _userData?.client?.nationality_id,
      curp: _userData?.client?.curp,
      country_id: _userData?.address?.country_id,
      ocupacion: _userData?.client?.employment_id,
      business_name: _userData?.client?.names,
      pais_constitucion_empresa: _userData?.client?.nationality_id,
      company_creation_date: _userData?.company?.company_creation_date,
      country_id_pm: _userData?.address?.country_id,
      postal_code: _userData?.address?.postal_code,
      city: _userData?.address?.city,
      county: _userData?.address?.county,
      street: _userData?.address?.street,
      neighborhood: _userData?.address.neighborhood,
      out_number: _userData?.address?.out_number,
      in_number: _userData?.address?.in_number,
      clave_ciec: _userData?.fiscal_credentials?.ciec_key,
    })
    const filterAC = _userData.partners.filter(element => element.type === 'AC')
    setShareholders(filterAC);
    const getSumShareholders = sumShareholders(-1, filterAC);
    getSumShareholders === 100 && setShareholdersDisabled(true);

  }, [_userData])



  useImperativeHandle(ref, () => ({
    submit: (event, handleNext) => {
      setBackDropState(!backDropState);
      dispatch(saveData({
        ..._stateInfo,
        GenericForm: state
      }));
      dispatch(shareHolders(
        shareholders
      ));

      if (tabValue === 'PF') {
        var client = {}
        if (requestData.platform.name !== "amazon") {
          client = {
            "client": {
              "names": _userData.client.names ? _userData.client.names : '',
              "rfc": _userData.client.rfc ? _userData.client.rfc : '',
              "email": _userData.client.email ? _userData.client.email : '',
              "telephone": _userData.client.telephone ? _userData.client.telephone : '',
              "account_key": _userData.client.account_key ? _userData.client.account_key : 0
            }
          }
        } else {
          client = {
            "client": {
              "names": state.names ? state.names : '',
              "rfc": state.rfc ? state.rfc : '',
              "email": state.email ? state.email : '',
              "telephone": state.telephone ? state.telephone : '',
              "account_key": state.account_key ? state.account_key : 0
            }
          }
        }
        const payloadPF = {
          "client": {
            "names": client.client.names,
            "first_name": state.first_name ? state.first_name : '',
            "second_name": state.second_name ? state.second_name : '',
            "rfc": client.client.rfc,
            "curp": state.curp ? state.curp : '',
            "email": client.client.email,
            "regime": "PF",
            "telephone": client.client.telephone,
            "employment_id": state.employment_id ? state.employment_id : 1,
            "account_key": client.client.account_key,
            "nationality_id": state.nationality_id ? state.nationality_id : 110,
            "accepted_terms_and_conditions": accepted_terms_and_conditions,
            "spouse_name": aditionalClientData[0].fullName ? aditionalClientData[0].fullName : ''
          },
          "address": {
            "city": postalCodeData && postalCodeData.ciudad_nom ? postalCodeData.ciudad_nom : state.city,
            "county": postalCodeData && postalCodeData.municipio_nom ? postalCodeData.municipio_nom : state.county,
            "street": state.street,
            "in_number": state.in_number,
            "country_id": state.country_id,
            "out_number": state.out_number,
            "postal_code": state.postal_code,
            "neighborhood": state.neighborhood
          },
          "platform_data": [
            { "description": "token", "value": (state.token) ? state.token : '' },
            { "description": "firstSale", "value": (state.firstSale) ? state.firstSale : '' }
          ],
          "business_type": {
            "name": null
          },
          "fiscal_credentials": {
            "ciec_key": state.clave_ciec ? state.clave_ciec : 0,
          },
          "client_pld_risk_matrix": generateMatrixRisk(),
          'request': {
            'credit_destination': state.destino_de_credito
          },
          "partners": formatDataHareholders(),
          'platform': {
            'platform_name': _userData.platform.name
          }
        }
        console.log(payloadPF)

        putClient(payloadPF).then(async (response) => {
          if (response.canceled) {  
            history.push("/");
            logout();
          } else {
            handleNext();
            props.activeStephandler();
          }
          setBackDropState(backDropState);
        }).catch((error) => {
          setBackDropState(backDropState);
          console.log(error);
        });
        localStorage.setItem("bontu_userTypePerson", payloadPF.client.regime);
      } else {
        const payloadPM = {
          'address': {
            'city': postalCodeData && postalCodeData.ciudad_nom ? postalCodeData.ciudad_nom : state.city,
            'county': postalCodeData && postalCodeData.municipio_nom ? postalCodeData.municipio_nom : state.county,
            'street': state.street,
            'in_number': state.in_number,
            'country_id': state.country_id_pm,
            'out_number': state.out_number,
            'postal_code': state.postal_code,
            'neighborhood': state.neighborhood
          },
          'platform_data': [
            { "description": "token", "value": (state.token) ? state.token : '' },
            { "description": "firstSale", "value": (state.firstSale) ? state.firstSale : '' }
          ],
          'fiscal_credentials': {
            'ciec_key': state.clave_ciec
          },
          'client': {
            'rfc': state.rfc,
            'email': state.email,
            'regime': "PM",
            'telephone': state.telephone,
            'nationality_id': state.country_id_pm,
            'account_key': state.account_key,
            'accepted_terms_and_conditions': accepted_terms_and_conditions,
            'spouse_name': aditionalClientData[0].fullName
          },
          'partners': formatDataHareholders(),
          'company': {
            'business_name': state.business_name,
            'company_creation_date': state.company_creation_date
          },
          'business_type': {
            'name': null
          },
          'client_pld_risk_matrix': generateMatrixRisk(),
          'request': {
            'credit_destination': state.destino_de_credito
          },
          'platform': {
            'platform_name': _userData.platform.name
          }
        }

        console.log(payloadPM);
        putClient(payloadPM).then(async (response) => {
          if (response.canceled) {
            logout();
            history.push("/");
          } else {
            handleNext();
            props.activeStephandler();
          }
          setBackDropState(backDropState);
        }).catch((error) => {
          setBackDropState(backDropState);
          console.log(error);
        });
        localStorage.setItem("bontu_userTypePerson", payloadPM.client.regime);

      }
    }
  }));

  const tabPanel = (formPanel, indexTabPanel) => {
    return (
      <TabPanel key={indexTabPanel} value={formPanel.regime}>
        <>
          <InstructionForms mensaje={formPanel.fields.instructions ? formPanel.fields.instructions : ''} />
          <form className={classes.root} md={12}>
            {formPanel.fields.form.sections && formPanel.fields.form.sections.map((section, indexSections) => {
              return (
                <Grid key={indexSections} container spacing={4} style={{ margin: '15px 0' }}>
                  <Grid item xs={12}>
                    <ThemeProvider theme={themeTypography}><Typography variant="h4" align="left" className={classes.colorGreen}>{section.title}</Typography></ThemeProvider></Grid>
                  {section.fields && section.fields.map((field, idx) => {
                    if (section.title === 'Información Personal' || section.title === 'Información general' || section.title === 'Información Adicional') {
                      if ((field.name === 'telephone' || field.name === 'email' || field.name === 'names' || field.name === 'account_key'
                        || field.name === 'first_name' || field.name === 'second_name' || field.name === 'rfc') && (requestData.platform.name !== "amazon")) {
                        var val = field.name
                        var reqD = ''
                        if (val === 'names') {
                          const f_name = requestData.client['first_name'] !== undefined ? requestData.client['first_name'] : ''
                          const s_name = requestData.client['second_name'] !== undefined ? requestData.client['second_name'] : ''
                          reqD = requestData.client[`${val}`] + ' ' + f_name + ' ' + s_name
                          field.required = reqD === '' ? field.required : false

                        } else {
                          reqD = requestData.client[`${val}`]
                          field.required = reqD === '' ? field.required : false
                        }
                        return (
                          <Grid key={idx} item xs={12} sm={4}>
                            <FormControl className={classes.formControl} fullWidth>
                              <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue={changePostalCode}
                                InputLabelProps={{ shrink: true }}
                                id={field.id}
                                label={field.label}
                                placeholder={field.placeholder}
                                value={reqD === '' ? state[field.name] : reqD}
                                name={field.name}
                                onChange={handleChangeState(null, null, null, null)}
                                helperText={errorMessage[field.name]}
                                error={errorData[field.name]}
                                onBlur={handleChangeState(null, null, null, null)}
                                required={field.required}
                                type={field.type}
                                disabled={reqD === '' ? !field.disabled : field.disabled}
                                inputProps={{
                                  'data-pattern': field.pattern,
                                  'data-error-pattern': field.error.pattern,
                                  'data-error-email': field.error.email,
                                  'data-error-required': field.error.required,
                                  'data-minLength': field.minLength,
                                  'data-error-minLength': field.error.minlength,
                                }}
                              />

                            </FormControl>
                          </Grid>
                        );
                      }
                    }
                    //Hay 3 casos especiales en que el render del campo cambia: fechas, ciudad y municipio.
                    if (field.type === "date") {
                      return (
                        <Grid key={idx} item xs={12} sm={4}>
                          <FormControl className={classes.formControl} fullWidth>
                            <TextField
                              fullWidth
                              variant="outlined"
                              defaultValue=""
                              InputLabelProps={{ shrink: true }}
                              id={field.id}
                              label={field.label}
                              placeholder={field.placeholder}
                              value={state[field.name]}
                              name={field.name}
                              onChange={handleChangeState(null, null, null, null)}
                              helperText={errorMessage[field.name]}
                              error={errorData[field.name]}
                              onBlur={handleChangeState(null, null, null, null)}
                              required={field.required}
                              type={field.type}
                              inputProps={{
                                'data-pattern': field.pattern,
                                'data-error-pattern': field.error.pattern,
                                'data-error-email': field.error.email,
                                'data-error-required': field.error.required,
                                'data-minLength': field.minLength,
                                'data-error-minLength': field.error.minlength,
                              }}
                            />

                          </FormControl>
                        </Grid>
                      );
                    }
                    switch (field.name) {

                      case "city":
                      case "city_pm":
                        return (
                          <Grid key={idx} item xs={12} sm={4}>
                            <FormControl className={classes.formControl} fullWidth>
                              <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue=""
                                InputLabelProps={{ shrink: true }}
                                id={field.id}
                                label={field.label}
                                placeholder={field.placeholder}
                                value={postalCodeData && postalCodeData.municipio_nom ? postalCodeData.ciudad_nom : state[field.name]}
                                name={field.name}
                                onChange={handleChangeState(null, null, null, null)}
                                helperText={errorMessage[field.name]}
                                error={errorData[field.name]}
                                onBlur={handleChangeState(null, null, null, null)}
                                required={field.required}
                                disabled={disabledState[field.name]}
                                type={field.type}
                                inputProps={{
                                  'data-pattern': field.pattern,
                                  'data-error-pattern': field.error.pattern,
                                  'data-error-email': field.error.email,
                                  'data-error-required': field.error.required,
                                  'data-minLength': field.minLength,
                                  'data-error-minLength': field.error.minlength,
                                }}
                              />

                            </FormControl>
                          </Grid>
                        );
                      case "county":
                      case "county_pm":
                        return (
                          <Grid key={idx} item xs={12} sm={4}>
                            <FormControl className={classes.formControl} fullWidth>
                              <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue=""
                                InputLabelProps={{ shrink: true }}
                                id={field.id}
                                label={field.label}
                                placeholder={field.placeholder}
                                value={postalCodeData && postalCodeData.municipio_nom ? postalCodeData.municipio_nom : state[field.name]}
                                name={field.name}
                                onChange={handleChangeState(null, null, null, null)}
                                helperText={errorMessage[field.name]}
                                error={errorData[field.name]}
                                onBlur={handleChangeState(null, null, null, null)}
                                required={field.required}
                                disabled={disabledState[field.name]}
                                type={field.type}
                                inputProps={{
                                  'data-pattern': field.pattern,
                                  'data-error-pattern': field.error.pattern,
                                  'data-error-email': field.error.email,
                                  'data-error-required': field.error.required,
                                  'data-minLength': field.minLength,
                                  'data-error-minLength': field.error.minlength,
                                }}
                              />

                            </FormControl>
                          </Grid>
                        );
                      default:
                        if (field.type !== 'radiobutton') {
                          return (
                            <Grid key={idx} item direction="row" justify="flex-start" alignItems="baseline" xs={12} sm={4}>
                              <Grid container xs={12}>
                                <Grid xs={11}>
                                  <FormControl className={classes.formControl} fullWidth>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      id={field.id}
                                      InputLabelProps={{ shrink: true }}
                                      label={field.label}
                                      placeholder={field.placeholder}
                                      value={state[field.name]}
                                      name={field.name}
                                      onChange={handleChangeState(field.label, null, state[field.name], field.matrixRisk)}
                                      helperText={errorMessage[field.name]}
                                      error={errorData[field.name]}
                                      onBlur={handleChangeState(field.label, null, state[field.name], field.matrixRisk)}
                                      required={field.required}
                                      type={field.type}
                                      multiline={field.multiline}
                                      select={field.type === "select" ? true : false}
                                      inputProps={{
                                        'data-pattern': field.pattern,
                                        'data-error-pattern': field.error.pattern,
                                        'data-error-email': field.error.email,
                                        'data-error-required': field.error.required,
                                        'data-minLength': field.minLength,
                                        'data-error-minLength': field.error.minlength,
                                        'data-maxLength': field.maxLength,
                                        'data-error-maxLength': field.error.maxlength,
                                      }}
                                    >
                                      {getItemsToSelect(field.source)}
                                    </TextField>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                  {field.tooltip && field.tooltip.show ?
                                    <StyledTooltip title={field.tooltip.text} placement="top" arrow><HelpOutlineIcon className={classes.icono} /></StyledTooltip>
                                    : ''}
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        } else {
                          const fields = []
                          const dataSection = []
                          return (
                            <Grid key={idx} container spacing={4} style={{ margin: '15px 0' }} xs={11} sm={11} md={12} >
                              <Grid item direction="row" justify="flex-start" alignItems="flex-end" xs={12}>
                                <Grid xs={12} container>
                                  <Grid key={idx} item xs={12} sm={12} md={9} lg={10} xl={10}>
                                    <FormLabel>{field.label}</FormLabel>
                                  </Grid>
                                  <Grid key={idx} xs={12} sm={12} md={3} lg={2} xl={2}>
                                    <RadioGroup row value={(radioQuestions[field.name])} onChange={handleRadio(field.label, field.options, field.matrixRisk)}>
                                      {field.options && field.options.map((radioOptions) => {
                                        if (radioOptions.fields) {
                                          fields.push({ "id": radioOptions.id, "fields": radioOptions.fields })
                                        } else if (radioOptions.subsection) {
                                          dataSection.push({ "id": radioOptions.id, "subsection": radioOptions.subsection, "instructions": radioOptions.instructions })
                                        }
                                        return (
                                          <Grid>
                                            <Hidden xsDown>
                                              <FormControlLabel key={field.id} name={field.id} value={radioOptions.id} style={{ color: '#5B6268' }} control={<Radio style={{ color: '#00c08a' }} />} label={radioOptions.label} labelPlacement="top" />
                                            </Hidden>
                                            <Hidden smUp>
                                              <FormControlLabel key={field.id} name={field.id} value={radioOptions.id} style={{ color: '#5B6268' }} control={<Radio style={{ color: '#00c08a' }} />} label={radioOptions.label} labelPlacement="end" />
                                            </Hidden>
                                          </Grid>
                                        )
                                      })}
                                    </RadioGroup>
                                    <FormHelperText className={classes.radioError} error>{errorMessage[field.name]}</FormHelperText>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={4} style={{ margin: '15px 0' }}>
                                  {fields && fields.map((aditionalData, indexAditionaData) => {
                                    if (radioQuestions[field.name] === aditionalData['id']) {
                                      return (
                                        aditionalData.fields && aditionalData.fields.map((fieldsData, indexFieldsData) => {
                                          if ((fieldsData.name === 'city_third_1' || fieldsData.name === 'municipio-input_third_1' || fieldsData.name === 'estado-input_third_1') ||
                                          (fieldsData.name === 'city_third_2' || fieldsData.name === 'municipio-input_third_2' || fieldsData.name === 'estado-input_third_2')) {
                                            let values
                                            if((fieldsData.name === 'estado-input_third_1')||(fieldsData.name === 'municipio-input_third_1')||(fieldsData.name === 'city_third_1')){
                                              values = fieldsData.name === 'estado-input_third_1' ? (postalCodeData_third_1 && postalCodeData_third_1.estado_nom ? postalCodeData_third_1.estado_nom : third[fieldsData.name]) : (postalCodeData_third_1 && postalCodeData_third_1.municipio_nom ? postalCodeData_third_1.ciudad_nom : third[fieldsData.name])
                                            }else if((fieldsData.name === 'estado-input_third_2')||(fieldsData.name === 'municipio-input_third_2')||(fieldsData.name === 'city_third_2')){
                                              values = fieldsData.name === 'estado-input_third_2' ? (postalCodeData_third_2 && postalCodeData_third_2.estado_nom ? postalCodeData_third_2.estado_nom : third[fieldsData.name]) : (postalCodeData_third_2 && postalCodeData_third_2.municipio_nom ? postalCodeData_third_2.ciudad_nom : third[fieldsData.name])
                                            }
                                            return (
                                              <Grid key={indexAditionaData} item xs={12} sm={4}>
                                                <FormControl className={classes.formControl} fullWidth>
                                                  <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue=""
                                                    id={fieldsData.id}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={fieldsData.label}
                                                    value={values}
                                                    name={fieldsData.name}
                                                    onChange={handleAditionalClientData(aditionalData.id, field.label)}
                                                    helperText={errorMessage[fieldsData.name]}
                                                    error={errorData[fieldsData.name]}
                                                    onBlur={handleAditionalClientData(aditionalData.id, field.label)}
                                                    required={fieldsData.required}
                                                    type={fieldsData.type}
                                                    disabled={disabledState[fieldsData.name]}
                                                    inputProps={{
                                                      'data-pattern': fieldsData.pattern,
                                                      'data-error-pattern': fieldsData.error.pattern,
                                                      'data-error-email': fieldsData.error.email,
                                                      'data-error-required': fieldsData.error.required,
                                                      'data-minLength': fieldsData.minLength,
                                                      'data-error-minLength': fieldsData.error.minlength,
                                                    }}
                                                  />

                                                </FormControl>
                                              </Grid>
                                            );
                                          }
                                          if (fieldsData.name === 'gender_') {
                                            return (
                                              <Grid key={indexAditionaData} item xs={12} sm={4}>
                                                <FormControl className={classes.formControl} fullWidth>
                                                  <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue=""
                                                    id={fieldsData.id}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={fieldsData.label}
                                                    value={third[fieldsData.name]}
                                                    name={fieldsData.name}
                                                    onChange={handleAditionalClientData(aditionalData.id, field.label)}
                                                    helperText={errorMessage[fieldsData.name]}
                                                    error={errorData[fieldsData.name]}
                                                    onBlur={handleAditionalClientData(aditionalData.id, field.label)}
                                                    required={fieldsData.required}
                                                    type={fieldsData.type}
                                                    select={true}
                                                    inputProps={{
                                                      'data-pattern': fieldsData.pattern,
                                                      'data-error-pattern': fieldsData.error.pattern,
                                                      'data-error-email': fieldsData.error.email,
                                                      'data-error-required': fieldsData.error.required,
                                                      'data-minLength': fieldsData.minLength,
                                                      'data-error-minLength': fieldsData.error.minlength,
                                                    }}
                                                  >
                                                    <MenuItem key={1} value={"Masculino"}>{"Masculino"}</MenuItem>
                                                    <MenuItem key={2} value={"Femenino"}>{"Femenino"}</MenuItem>
                                                  </TextField>

                                                </FormControl>
                                              </Grid>
                                            );
                                          } else {
                                            return (
                                              <Grid key={indexFieldsData} item xs={12} sm={4}>
                                                <FormControl className={classes.formControl} fullWidth>
                                                  <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    defaultValue={""}
                                                    id={fieldsData.id}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={fieldsData.label}
                                                    value={aditionalData.id === 'third' ? third[fieldsData.name] : aditionalClientData[fieldsData.name]}
                                                    name={fieldsData.name}
                                                    onChange={handleAditionalClientData(aditionalData.id, field.label)}
                                                    helperText={errorMessage[fieldsData.name]}
                                                    error={errorData[fieldsData.name]}
                                                    onBlur={handleAditionalClientData(aditionalData.id, field.label)}
                                                    required={fieldsData.required}
                                                    type={fieldsData.type}
                                                    disabled={postalCodeData ? disabledState[fieldsData.name] : fieldsData.disabled}
                                                    select={fieldsData.type === "select" ? true : false}
                                                    inputProps={{
                                                      'data-pattern': fieldsData.pattern,
                                                      'data-error-pattern': fieldsData.error.pattern,
                                                      'data-error-email': fieldsData.error.email,
                                                      'data-error-required': fieldsData.error.required,
                                                      'data-minLength': fieldsData.minLength,
                                                      'data-error-minLength': fieldsData.error.minlength,
                                                      'data-maxLength': fieldsData.maxLength,
                                                      'data-error-maxLength': fieldsData.error.maxlength,
                                                    }}
                                                  >
                                                    {getItemsToSelect(fieldsData.source)}
                                                  </TextField>
                                                </FormControl>
                                              </Grid>
                                            );
                                          }
                                        }));
                                    }
                                  })}
                                  {dataSection && dataSection.map((sub, indexSub) => {
                                    if (radioQuestions[field.name] === sub['id']) {
                                      return (
                                        <Grid key={indexSub} xs={10} >
                                          <FormLabel>{sub.instructions}</FormLabel>
                                          {sub.subsection && sub.subsection.map((sec, indexSec) => {
                                            return (
                                              <Grid item xs={12}>
                                                <span variant="h5" align="left" style={{ fontSize: '16px', lineHeight: '22px', color: '#666666' }}>{sec.subtitle}</span>
                                                <IconButton size="small" aria-label="add" onClick={sec.type === 'AC' ? addShareholders : addAdministrators} disabled={sec.type === 'AC' ? shareholdersDisabled : false}>
                                                  <AddCircleIcon className={sec.type === 'AC' && shareholdersDisabled === true ? '' : classes.buttonDelete} fontSize="large" />
                                                </IconButton>
                                                {sec.fields && sec.repeater === true && sec.type === 'AC' && shareholders.map((element, indexHolders) => {
                                                  let children = sec.fields.map((subfield, indexSubfield) => {
                                                    const splitName = String(subfield.name).split('_s_')[0];
                                                    if (subfield.name === 'city_AC_' || subfield.name === 'municipio-input_AC_' || subfield.name === 'estado-input_AC_') {
                                                      return (
                                                        <Grid key={indexHolders} item xs={12} sm={4}>
                                                          <FormControl className={classes.formControl} fullWidth>
                                                            <TextField
                                                              fullWidth
                                                              variant="outlined"
                                                              defaultValue={element[splitName]}
                                                              id={`${subfield.id}${indexHolders}`}
                                                              InputLabelProps={{ shrink: true }}
                                                              label={subfield.label}
                                                              value={subfield.name === 'estado-input_AC_' ? (postalCodeDataAC && postalCodeDataAC.estado_nom ? postalCodeDataAC.estado_nom : shareholders[indexHolders][splitName]) : (postalCodeDataAC && postalCodeDataAC.municipio_nom ? postalCodeDataAC.ciudad_nom : shareholders[indexHolders][splitName])}
                                                              name={`${subfield.name}${indexHolders}`}
                                                              onChange={handleShareholderChange}
                                                              helperText={errorMessage[`${subfield.name}${indexHolders}`]}
                                                              error={errorData[`${subfield.name}${indexHolders}`]}
                                                              onBlur={handleShareholderChange}
                                                              required={subfield.required}
                                                              type={subfield.type}
                                                              disabled={disabledState[subfield.name]}
                                                              multiline={subfield.multiline}
                                                              inputProps={{
                                                                'data-pattern': subfield.pattern,
                                                                'data-error-pattern': subfield.error.pattern,
                                                                'data-error-email': subfield.error.email,
                                                                'data-error-required': subfield.error.required,
                                                                'data-minLength': subfield.minLength,
                                                                'data-error-minLength': subfield.error.minlength,
                                                              }}
                                                            />

                                                          </FormControl>
                                                        </Grid>
                                                      );
                                                    } else {
                                                      return (
                                                        <Grid key={indexSubfield} item xs={12} sm={4}>
                                                          <FormControl className={classes.formControl} fullWidth>
                                                            <TextField
                                                              fullWidth
                                                              variant="outlined"
                                                              defaultValue={element[splitName]}
                                                              id={`${subfield.id}${indexHolders}`}
                                                              InputLabelProps={{ shrink: true }}
                                                              label={subfield.label}
                                                              value={shareholders[indexHolders][splitName]}
                                                              name={`${subfield.name}${indexHolders}`}
                                                              onChange={handleShareholderChange}
                                                              helperText={errorMessage[`${subfield.name}${indexHolders}`]}
                                                              error={errorData[`${subfield.name}${indexHolders}`]}
                                                              onBlur={handleShareholderChange}
                                                              required={subfield.required}
                                                              type={subfield.type}
                                                              disabled={postalCodeDataAC ? disabledState[subfield.name] : subfield.disabled}
                                                              multiline={subfield.multiline}
                                                              select={subfield.type === "select" ? true : false}
                                                              inputProps={{
                                                                'data-pattern': subfield.pattern,
                                                                'data-error-pattern': subfield.error.pattern,
                                                                'data-error-email': subfield.error.email,
                                                                'data-error-required': subfield.error.required,
                                                                'data-minLength': subfield.minLength,
                                                                'data-error-minLength': subfield.error.minlength,
                                                                'data-maxLength': subfield.maxLength,
                                                                'data-error-maxLength': subfield.error.maxlength,
                                                              }}
                                                            >
                                                              {getItemsToSelect(subfield.source)}
                                                            </TextField>
                                                          </FormControl>
                                                        </Grid>
                                                      );
                                                    }
                                                  })
                                                  return (
                                                    <Grid container style={{ paddingLeft: 15, marginTop: 10 }}>
                                                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container>
                                                          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                                            <Typography style={{ fontSize: '14px', lineHeight: '45px' }}>{`${t("SHAREHOLDER")} ${"#"} ${(indexHolders + 1).toString()}`}</Typography>
                                                          </Grid>
                                                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.buttonEnd}>
                                                            <IconButton size="small" aria-label="delete" onClick={e => deleteShareholder(indexHolders)} style={{ marginRight: -15 }}>
                                                              <RemoveCircleIcon className={classes.buttonDelete} fontSize="large" />
                                                            </IconButton>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container spacing={4}>
                                                          {children}
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  );
                                                })
                                                }
                                                {sec.fields && sec.repeater === true && sec.type === 'AD' && administrators.map((element, indexAdmin) => {
                                                  let children = sec.fields.map((subfield, indexSubfield) => {
                                                    const splitName = String(subfield.name).split('_ad_')[0];
                                                    if (subfield.name === 'city_AD_' || subfield.name === 'municipio-input_AD_' || subfield.name === 'estado-input_AD_') {
                                                      return (
                                                        <Grid key={indexSubfield} item xs={12} sm={4}>
                                                          <FormControl className={classes.formControl} fullWidth>
                                                            <TextField
                                                              fullWidth
                                                              variant="outlined"
                                                              defaultValue={element[splitName]}
                                                              id={`${subfield.id}${indexAdmin}`}
                                                              InputLabelProps={{ shrink: true }}
                                                              label={subfield.label}
                                                              value={subfield.name === 'estado-input_AD_' ? (postalCodeDataAD && postalCodeDataAD.estado_nom ? postalCodeDataAD.estado_nom : administrators[indexAdmin][splitName]) : (postalCodeDataAD && postalCodeDataAD.municipio_nom ? postalCodeDataAD.ciudad_nom : administrators[indexAdmin][splitName])}
                                                              name={`${subfield.name}${indexAdmin}`}
                                                              onChange={handleAdministratorsChange}
                                                              helperText={errorMessage[`${subfield.name}${indexAdmin}`]}
                                                              error={errorData[`${subfield.name}${indexAdmin}`]}
                                                              onBlur={handleAdministratorsChange}
                                                              required={subfield.required}
                                                              type={subfield.type}
                                                              disabled={disabledState[subfield.name]}
                                                              multiline={subfield.multiline}
                                                              inputProps={{
                                                                'data-pattern': subfield.pattern,
                                                                'data-error-pattern': subfield.error.pattern,
                                                                'data-error-email': subfield.error.email,
                                                                'data-error-required': subfield.error.required,
                                                                'data-minLength': subfield.minLength,
                                                                'data-error-minLength': subfield.error.minlength,
                                                              }}
                                                            />

                                                          </FormControl>
                                                        </Grid>
                                                      );
                                                    } else {
                                                      return (
                                                        <Grid key={indexSubfield} item xs={12} sm={4}>
                                                          <FormControl className={classes.formControl} fullWidth>
                                                            <TextField
                                                              fullWidth
                                                              variant="outlined"
                                                              defaultValue={element[splitName]}
                                                              id={`${subfield.id}${indexAdmin}`}
                                                              label={subfield.label}
                                                              InputLabelProps={{ shrink: true }}
                                                              value={administrators[indexAdmin][splitName]}
                                                              name={`${subfield.name}${indexAdmin}`}
                                                              onChange={handleAdministratorsChange}
                                                              helperText={errorMessage[`${subfield.name}${indexAdmin}`]}
                                                              error={errorData[`${subfield.name}${indexAdmin}`]}
                                                              onBlur={handleAdministratorsChange}
                                                              required={subfield.required}
                                                              type={subfield.type}
                                                              disabled={postalCodeDataAD ? disabledState[subfield.name] : subfield.disabled}
                                                              multiline={subfield.multiline}
                                                              select={subfield.type === "select" ? true : false}
                                                              inputProps={{
                                                                'data-pattern': subfield.pattern,
                                                                'data-error-pattern': subfield.error.pattern,
                                                                'data-error-email': subfield.error.email,
                                                                'data-error-required': subfield.error.required,
                                                                'data-minLength': subfield.minLength,
                                                                'data-error-minLength': subfield.error.minlength,
                                                                'data-maxLength': subfield.maxLength,
                                                                'data-error-maxLength': subfield.error.maxlength,
                                                              }}
                                                            >
                                                              {getItemsToSelect(subfield.source)}
                                                            </TextField>
                                                          </FormControl>
                                                        </Grid>
                                                      );
                                                    }
                                                  })

                                                  return (
                                                    <Grid container style={{ paddingLeft: 15, marginTop: 10 }}>
                                                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container>
                                                          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                                            <Typography style={{ fontSize: '14px', lineHeight: '45px' }}>{`${t("ADMINISTRATOR")} ${"#"} ${(indexAdmin + 1).toString()}`}</Typography>
                                                          </Grid>
                                                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.buttonEnd}>
                                                            <IconButton size="small" aria-label="delete" onClick={e => deleteAdministrators(indexAdmin)} style={{ marginRight: -15 }}>
                                                              <RemoveCircleIcon className={classes.buttonDelete} fontSize="large" />
                                                            </IconButton>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container spacing={4}>
                                                          {children}
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  );
                                                })
                                                }
                                              </Grid>
                                            )
                                          })}
                                        </Grid>
                                      );
                                    }
                                  })}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        }
                    }

                  })}
                  {section.subsection && section.subsection.map((subsection, indexSubsect) => {
                    return (
                      <Grid key={indexSubsect} container spacing={4} style={{ margin: '15px 0' }}>
                        <Grid item xs={12}>
                          <span variant="h5" align="left" style={{ fontSize: '16px', lineHeight: '22px', color: '#666666' }}>{subsection.subtitle}</span>
                          <IconButton size="small" aria-label="add" onClick={subsection.type === 'AC' ? addShareholders : addLegalRepresentative} disabled={subsection.type === 'AC' ? shareholdersDisabled : false}>
                            <AddCircleIcon className={subsection.type === 'AC' && shareholdersDisabled === true ? '' : classes.buttonDelete} fontSize="large" />
                          </IconButton>
                        </Grid>
                        {subsection.fields && !subsection.repeater && subsection.fields.map((subfield, indexSubfield) => {

                          return (
                            <Grid key={indexSubfield} item xs={12} sm={4}>
                              <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  defaultValue=""
                                  id={subfield.id}
                                  label={subfield.label}
                                  placeholder={subfield.placeholder}
                                  value={state[subfield.name]}
                                  name={subfield.name}
                                  onChange={handleChangeState(null, null, null, null)}
                                  helperText={errorMessage[subfield.name]}
                                  error={errorData[subfield.name]}
                                  onBlur={handleChangeState(null, null, null, null)}
                                  required={subfield.required}
                                  type={subfield.type}
                                  multiline={subfield.multiline}
                                  select={subfield.type === "select" ? true : false}
                                  inputProps={{
                                    'data-pattern': subfield.pattern,
                                    'data-error-pattern': subfield.error.pattern,
                                    'data-error-email': subfield.error.email,
                                    'data-error-required': subfield.error.required,
                                    'data-minLength': subfield.minLength,
                                    'data-error-minLength': subfield.error.minlength,
                                    'data-maxLength': subfield.maxLength,
                                    'data-error-maxLength': subfield.error.maxlength,
                                  }}
                                >
                                  {getItemsToSelect(subfield.source)}
                                </TextField>

                              </FormControl>
                            </Grid>
                          );
                        })}

                        {subsection.fields && subsection.repeater === true && subsection.type === 'AC' && shareholders.map((element, indexHolders) => {

                          let children = subsection.fields.map((subfield, indexSubfield) => {

                            const splitName = String(subfield.name).split('_s_')[0];

                            return (
                              <Grid key={indexSubfield} item xs={12} sm={4}>
                                <FormControl className={classes.formControl} fullWidth>
                                  <TextField

                                    fullWidth
                                    variant="outlined"
                                    defaultValue={element[splitName]}
                                    id={`${subfield.id}${indexHolders}`}
                                    label={subfield.label}
                                    placeholder={subfield.placeholder}
                                    value={shareholders[indexHolders][splitName]}
                                    name={`${subfield.name}${indexHolders}`}
                                    onChange={handleShareholderChange}
                                    helperText={errorMessage[`${subfield.name}${indexHolders}`]}
                                    error={errorData[`${subfield.name}${indexHolders}`]}
                                    onBlur={handleShareholderChange}
                                    required={subfield.required}
                                    type={subfield.type}
                                    multiline={subfield.multiline}
                                    select={subfield.type === "select" ? true : false}
                                    inputProps={{
                                      'data-pattern': subfield.pattern,
                                      'data-error-pattern': subfield.error.pattern,
                                      'data-error-email': subfield.error.email,
                                      'data-error-required': subfield.error.required,
                                      'data-minLength': subfield.minLength,
                                      'data-error-minLength': subfield.error.minlength,
                                      'data-maxLength': subfield.maxLength,
                                      'data-error-maxLength': subfield.error.maxlength,
                                    }}
                                  >
                                    {getItemsToSelect(subfield.source)}
                                  </TextField>
                                </FormControl>
                              </Grid>
                            );
                          })

                          return (
                            <Grid container style={{ paddingLeft: 15, marginTop: 10 }}>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container>
                                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Typography style={{ fontSize: '14px', lineHeight: '45px' }}>{`${t("SHAREHOLDER")} ${"#"} ${(indexHolders + 1).toString()}`}</Typography>
                                  </Grid>
                                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.buttonEnd}>
                                    <IconButton size="small" aria-label="delete" onClick={e => deleteShareholder(indexHolders)} style={{ marginRight: -15 }}>
                                      <RemoveCircleIcon className={classes.buttonDelete} fontSize="large" />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                  {children}
                                </Grid>
                              </Grid>
                            </Grid>

                          );
                        })
                        }

                        {subsection.fields && subsection.repeater === true && subsection.type === 'RL' && legalRepresentative.map((element, indexLegal) => {
                          let children = subsection.fields.map((subfield, indexSubfield) => {
                            const splitName = String(subfield.name).split('_rl_')[0];
                            return subfield.hidden ?
                              <Box visibility="hidden">
                                <TextField></TextField>
                              </Box> : (
                                <Grid key={indexSubfield} item xs={12} sm={4}>
                                  <FormControl className={classes.formControl} fullWidth>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      defaultValue={element[splitName]}
                                      id={`${subfield.id}${indexLegal}`}
                                      label={subfield.label}
                                      placeholder={subfield.placeholder}
                                      value={legalRepresentative[indexLegal][splitName]}
                                      name={`${subfield.name}${indexLegal}`}
                                      onChange={handleLegalRepresentativeChange}
                                      helperText={errorMessage[`${subfield.name}${indexLegal}`]}
                                      error={errorData[`${subfield.name}${indexLegal}`]}
                                      onBlur={handleLegalRepresentativeChange}
                                      required={subfield.required}
                                      type={subfield.type}
                                      multiline={subfield.multiline}
                                      select={subfield.type === "select" ? true : false}
                                      inputProps={{
                                        'data-pattern': subfield.pattern,
                                        'data-match-email': subfield.match_email,
                                        'data-match-phone': subfield.match_phone,
                                        'data-error-pattern': subfield.error.pattern,
                                        'data-error-email': subfield.error.email,
                                        'data-error-required': subfield.error.required,
                                        'data-minLength': subfield.minLength,
                                        'data-error-minLength': subfield.error.minlength,
                                        'data-maxLength': subfield.maxLength,
                                        'data-error-maxLength': subfield.error.maxlength,
                                      }}
                                    >
                                      {getItemsToSelect(subfield.source)}
                                    </TextField>
                                  </FormControl>
                                </Grid>
                              );
                          })

                          return (
                            <Grid container style={{ paddingLeft: 15, marginTop: 10 }}>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container>
                                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Typography style={{ fontSize: '14px', lineHeight: '45px' }}>{`${t("LEGAL_REPRESENTATIVE")} ${"#"} ${(indexLegal + 1).toString()}`}</Typography>
                                  </Grid>
                                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={classes.buttonEnd}>
                                    <IconButton size="small" aria-label="delete" onClick={e => deleteLegalRepresentative(indexLegal)} style={{ marginRight: -15 }}>
                                      <RemoveCircleIcon className={classes.buttonDelete} fontSize="large" />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                  {children}
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })
                        }
                      </Grid>

                    );
                  })}
                </Grid>
              );
            })}
            {state.actividad_del_sector === "Gasolineras" || state.actividad_del_sector === "Transporte" ? 
            <Grid item spacing={1} style={{ margin: '15px 0' }} className={classes.check} xs={12}>
              <Grid item xs={12} sm={12}>
                <Box display="flex" alignItems="flex-start">
                  <Checkbox name="accepted_terms_and_conditions" color="primary" className={classes.ceroMargin} onChange={handleCheck}/>
                    <p className={classes.p}>
                      <span>{t("MESSAGE_CHECK")}</span>
                      <br/><br/>
                      <span>{t("MESSAGE_CHECK_TWO")}</span></p>
                </Box>
              </Grid>
            </Grid> : ""}
          </form>
        </>
      </TabPanel>
    )
  }

  const tabPanelFormFields = (json) => {

    if (requestData.client.regime === "AMBOS") {
      let tabPanels = []

      tabPanels.push(
        json.map((e, indexJsonObject) => {
          return (
            <div key={indexJsonObject}>{tabPanel(e, indexJsonObject)}</div>
          );
        })
      );

      return tabPanels
    } else if (json.length < 2) {
      return json.map((e, indexJson) => {
        return (
          <div key={indexJson}>{tabPanel(e, indexJson)}</div>
        );
      })
    } else {
      let tabPanels = []

      json.map((e, indexJson) => {
        if (e.regime === requestData.client.regime) {
          tabPanels.push(
            <div key={indexJson}>{tabPanel(e, indexJson)}</div>
          )
        }
      })
      return tabPanels
    }

  }

  return (
    <div className={classes.root}>
      <ShowBackdrop open={backDropState} data-test="backdrop" />
      <Grid container direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <div className={classes.backgroundWhite}>
            <TabContext
              value={tabValue}
            >
              {!areTabsLoaded && (
                <CircularProgress className={classes.colorGreen} />
              )}

              {areTabsLoaded && requestData && (
                <>
                  <AppBar position="static"
                    style={{ marginBottom: 20 }}
                    className={classes.tabs}
                  >
                    {requestData.client.regime === 'AMBOS' ?
                      <TabList onChange={handleChange}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { backgroundColor: "#012b35" } }}
                        aria-label="simple tabs example"
                      >
                        {regimes.regimes.map((element, key) => {
                          return <Tab key={key} label={element.value} value={element.name} />
                        })}
                      </TabList>
                      : ''}
                  </AppBar>
                  {tabPanelFormFields(formFields).map((form, key) => {
                    return (
                      <div key={key}> {form} </div>
                    )
                  })}
                </>
              )}
            </TabContext>
          </div>
        </Grid>
      </Grid>
    </div>
  );
});
export default GenericForm;