import React from "react";
import { Records as PrivateRecords } from "./records";
import { control_oyo_broker_constants } from "../../../constants/control";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../Context/UserContext";


export const Records = props => {
	let history = useHistory();
	const { isAuthorized } = useUser();

	if (isAuthorized(control_oyo_broker_constants.CREDIT_REQUEST_LIST_VIEW))
		return <PrivateRecords {...props} />;
	else {
		history.push("/unauthorized");
		return null;
	}
};
