import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import background from "../../../../assets/images/backgrounds/background.jpg";
import DataGrid from "../../../sharedComponents/DataGrid/DataGrid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import { getRecordsData } from "../../../Services/kycForm.service";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	backgroundImage: {
		backgroundImage: "url(" + background + ")",
		backgroundSize: "cover",
		backgroundPosition: "center",
		minHeight: "80vh",
		height: "auto"
	},
	backgroundSquare: {
		color: "white",
		padding: "25px",
		textAlign: "left"
	},
	root: {
		width: "100%",
	},
	oyoButton: {
		float: "right",
		margin: "20px"
	}
}));

export default function RecordsTable () {
	const classes = useStyles();
	const [data, setData] = useState();
	let history = useHistory();
	const { t } = useTranslation("UserApp");

	const columnDefs = [
		{
			field: "names",
			headerName: t("FULL_NAME"),
			minWidth: 200
		},
		{
			field: "email",
			headerName: t("EMAIL"),
			minWidth: 200
		},
		{
			field: "telephone",
			headerName: t("PHONE"),
			minWidth: 200
		},
		{
			field: "created_at",
			headerName: t("CREATED_AT"),
			minWidth: 200
		},
		{
			field: "amount",
			headerName: t("AMOUNT"),
			minWidth: 200
		},
		{
			field: "general_description",
			headerName: t("STATUS"),
			minWidth: 200
		},
	];
    

	const onCellClicked = ev => {
		let id = ev.data.id;
		history.push(`/oyoRequest/${id}`);
	};
    
	useEffect(() => {
		getRecordsData().then((res) => {
			console.log(res)
			setData(res ? res : null);
		}
		).catch((error) => {
			console.log(error);
		});
	}, []);

	const updateCells = () => {
		getRecordsData().then((res) => {
			setData(res);
		}
		).catch((error) => {
			console.log(error);
		});
	};

	return (
		<div style={{
			margin: "15px 10px 10px 15px"
		}}>
			<Button id="btnNewRequest" variant="contained" color="primary" className={classes.oyoButton} onClick={() => history.push("/oyoRequest")} style={{margin: "10px 0px 10px 10px"}}>
                Nueva Solicitud
			</Button>
            
			<Button id="btnQuoteCredit" variant="contained" color="primary" className={classes.oyoButton} onClick={() => console.log("")} style={{margin: "10px 0px 10px 10px"}}>
                Cotizar un Crédito
			</Button>

			<div className={classes.backgroundSquare}>
				<Card className={classes.root}>
					<CardContent> 
						<div
							style={{
								height: "250px",
								width: "100%",
								cursor: "context-menu"
							}}
						>
							<DataGrid
								data-test="dataGridComponent"
								columnDefs={columnDefs}
								data={data?.rows ? data.rows : null}
								onCellClicked={onCellClicked}
								updateCells={updateCells}
							>
							</DataGrid>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	);
}
