import React, { useState } from 'react';
import { Fade, Slide, IconButton } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        position: "relative"
    },
    indicators: {
        width: "100%",
        marginTop: "10px",
        textAlign: "center"
    },
    indicator: {
        fontSize: "15px",
        cursor: "pointer",
        transition: "200ms",
        color: "#afafaf",
        '&:hover': {
            color: "#1f1f1f"
        },
        '&:active': {
            color: "#1f1f1f"
        }
    },
    active: {
        color: "#494949"
    },
    buttonWrapper: {
        position: "absolute",
        height: "calc(100% - 20px - 10px)",
        backgroundColor: "transparent",
        top: 0,
        '&:hover': {
            '& $button': {
                backgroundColor: "black",
                filter: "brightness(120%)",
                opacity: 0.4
            }
        }
    },
    hiddenNavigation: {
        opacity: "0 !important",
    },
    button: {
        margin: "0 10px",
        position: "relative",
        top: "calc(50% - 20px)",
        backgroundColor: "#494949",
        color: "white",
        fontSize: "30px",
        transition: "200ms",
        cursor: "pointer",
        '&:hover': {
            opacity: "0.6 !important"
        }
    },
    next: {
        right: 0
    },
    prev: {
        left: 0
    }
});


export default function Carousel(props) {
    const [active, setActive] = useState(0);
    const classes = useStyles();
    const timeout= 200;
    const animation = props.animation ? props.animation : 'fade';

    const next = (event) => {
        const nextValue = active + 1 > props.children.length - 1 ? 0 : active + 1;

        setActive(nextValue);

        if (event)
            event.stopPropagation();
    }

    const prev = (event) => {
        const prevValue = active - 1 < 0 ? props.children.length - 1 : active - 1;

        setActive(prevValue);

        if (event)
            event.stopPropagation();
    }

    const pressIndicator = (index) => {
        setActive(index);
    }

    return (
        <div >
            <div className={classes.root}>
            
                {
                    Array.isArray(props.children) ?
                        props.children.map((child, index) => {
                            return (
                                <CarouselItem key={index} active={index === active ? true : false} child={child} animation={animation} timeout={timeout} />
                            )
                        })
                        :
                        <CarouselItem key={0} active={true} child={props.children} animation={animation} timeout={timeout} />
                }

                <div className={`${classes.buttonWrapper} ${classes.next}`}>
                    <IconButton className={`${classes.button} ${classes.next} ${props.carouselConfig.showNavigation ? "" : classes.hiddenNavigation} mui--aligh-middle`} onClick={next}>
                        <NavigateNextIcon />
                    </IconButton>
                </div>

                <div className={`${classes.buttonWrapper} ${classes.prev}`}>
                    <IconButton className={`${classes.button} ${classes.prev} ${props.carouselConfig.showNavigation ? "" : classes.hiddenNavigation} mui--align-middle`} onClick={prev}>
                        <NavigateBeforeIcon />
                    </IconButton>
                </div>

                {props.carouselConfig.indicators ? <Indicators classes={classes} length={props.children.length} active={active} press={pressIndicator} /> : null}

            </div>
        </div>
    )
}


function CarouselItem(props) {
    return (
        <div className="CarouselItem" hidden={!props.active}>
            {props.animation === "slide" ?
                <Slide direction="left" in={props.active} timeout={props.timeout}>
                    <div>
                        {props.child}
                    </div>
                </Slide>
                :
                <Fade in={props.active} timeout={500} >
                    <div>
                        {props.child}
                    </div>
                </Fade>
            }
        </div>
    )
}

function Indicators(props) {
    const classes = props.classes;

    let indicators = [];
    for (let i = 0; i < props.length; i++) {
        const className = i === props.active ? `${classes.indicator} ${classes.active}` : `${classes.indicator}`;
        const item = <FiberManualRecordIcon key={i} size='small' className={className} onClick={() => { props.press(i) }} />;

        indicators.push(item);
    }

    return (
        <div className={`${classes.indicators}`}>
            {indicators}
        </div>
    )
}